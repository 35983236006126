// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.notfound_content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:4%;
}
.notfound_error_cont{
  text-align: center;
}
.not_found_img{
    height:45vh;
    width: 28vw;
}
.error_404_cont{
    margin-top:5px;
    font-size:24px;
    font-weight:800;
}
.error_404_cont2{
    font-size:16px;
    font-weight:500;
    color: #011E60;
}
.notfound_home{
    display: flex;
    justify-content: center;
    align-items: center;
}
.notfound_home_button{
    margin-top:20px;
    background-color: #011E60;
    height:6vh;
    width: 9vw;
}`, "",{"version":3,"sources":["webpack://./src/NetworkError/PageNotFound.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,cAAc;IACd,cAAc;IACd,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;IACf,cAAc;AAClB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,UAAU;IACV,UAAU;AACd","sourcesContent":["\n\n.notfound_content{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top:4%;\n}\n.notfound_error_cont{\n  text-align: center;\n}\n.not_found_img{\n    height:45vh;\n    width: 28vw;\n}\n.error_404_cont{\n    margin-top:5px;\n    font-size:24px;\n    font-weight:800;\n}\n.error_404_cont2{\n    font-size:16px;\n    font-weight:500;\n    color: #011E60;\n}\n.notfound_home{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.notfound_home_button{\n    margin-top:20px;\n    background-color: #011E60;\n    height:6vh;\n    width: 9vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
