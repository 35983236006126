import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./ModalTpIssueConfirm.css";

const ModalTpIssueConfirm = ({ yespop, message, closepop, mstTpIssue,legStatus }) => {
  const issue = mstTpIssue;
  const handleYes = () => {
    const dataToSend = false;
    yespop(dataToSend, issue);
  };
  const handleClose = () => {
    const dataToSend = false;
    closepop(dataToSend);
  };
  useEffect (()=>{
    console.log(legStatus,"popuplegstatus456")


  },[legStatus])
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerconfirm">
          <div className="modalc-background">
            {mstTpIssue == "No Light" ||
            mstTpIssue == "Low Light" ||
            mstTpIssue == "Not Spliced" ||
            mstTpIssue == "Broken Port" ||
            mstTpIssue == "Port in use" ? (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    {mstTpIssue == "Port in use" ? (
                      <span>
                        Reporting this issue will present the option to move the
                        order to different facilities - Do you wish to continue?
                      </span>
                    ) : (
                      <span>
                        Reporting this issue will be tracked via Salesforce case
                        and present the option to move the order to different
                        facilities - Do you wish to continue?
                      </span>
                    )}
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : mstTpIssue === "Drop Placement" ? (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    <span>
                      This will present option to change facility assign to
                      different port on the same MST or move to a different MST
                      -Do you wish to continue?
                    </span>
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : mstTpIssue === "Casa Device" ? (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    <span>
                      Records update only will record CASA Serial No associated
                      with MST port. Do you wish to continue?
                    </span>
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal-issue">
                  
                  <div className="issue-title">
                  {legStatus==="AVAILABLE" ?   <span>
                    Reporting this issue will record the installation of a 2 port Terminal port splitter. To have O2 records updated, please call QF - Facility Support. Do you wish to continue: 
                    </span> 
                    :
                    <span>
                      Reporting this issue will record the installation of a 2
                      port Terminal port splitter and record the working
                      subscriber as working on the first port of the splitter.
                      To have O2 records updated, please call QF - Facility
                      Support. Do you wish to continue?
                    </span>
}
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalTpIssueConfirm;
