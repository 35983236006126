import React, { useState } from 'react'

const Modalalert = () => {
  const [message, setMessage] = useState('Fetching O2 Data');

  
  const showMessageWithDelay = () => {
    // setTimeout(() => {
    //   setMessage('Updating O2 Network Path');
    // }, 1000); // 3000 milliseconds = 3 seconds
  };
  showMessageWithDelay();

  return (
    <div style={{padding:"30px 30px 0px 30px ",color:"#fff"}}>{message}</div>
  )
} 

export default Modalalert