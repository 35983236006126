import logo from './logo.svg';
import './App.css';
import Router from './Router';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  return (
    <div className="">
      <Router></Router>
    </div>
  );
}

export default App;
