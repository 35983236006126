// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
  padding: 8px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  margin-bottom: 4px;
}

li:hover {
  background-color: #f0f0f0;
}

li.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.underline {
  border-bottom: 1px solid black;
  width: 200px;
  margin: 0 0 0 120px;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0 0 0 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  position: absolute;
  color: white;
  z-index: 2;
  background-color: black;
}

.dropdown-menu li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
  color: black;
}

.toggle-arrow {
  transition: transform 0.3s ease;
}

.open {
  transform: rotate(180deg);
}
.dropDownOption{
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  font-weight: bold;
  outline:none;  
}`, "",{"version":3,"sources":["webpack://./src/OntReview/css/ONTDropdown.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,4BAA4B;EAC5B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;EAC9B,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,8BAA8B;EAC9B,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["ul {\n  list-style: none;\n  padding: 0;\n}\n\nli {\n  cursor: pointer;\n  padding: 8px;\n  /* border: 1px solid #ccc; */\n  border-radius: 4px;\n  margin-bottom: 4px;\n}\n\nli:hover {\n  background-color: #f0f0f0;\n}\n\nli.selected {\n  background-color: #007bff;\n  color: #fff;\n  border-color: #007bff;\n}\n\n.underline {\n  border-bottom: 1px solid black;\n  width: 200px;\n  margin: 0 0 0 120px;\n}\n\n.dropdown-menu {\n  list-style: none;\n  padding: 0;\n  margin: 0 0 0 120px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 200px;\n  position: absolute;\n  color: white;\n  z-index: 2;\n  background-color: black;\n}\n\n.dropdown-menu li {\n  padding: 8px;\n  cursor: pointer;\n}\n\n.dropdown-menu li:hover {\n  background-color: #f0f0f0;\n  color: black;\n}\n\n.toggle-arrow {\n  transition: transform 0.3s ease;\n}\n\n.open {\n  transform: rotate(180deg);\n}\n.dropDownOption{\n  background: transparent;\n  border: none;\n  border-bottom: 1px solid black;\n  font-weight: bold;\n  outline:none;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
