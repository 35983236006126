import React, { useState, useEffect } from "react";
import {
  
  faDownload,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link} from "react-router-dom";
import Topbar from "../topbar";
import "./FeedbackReview.css";
import ReviewedFeedback from "./ReviewedFeedback";
import axios from "axios";
import Modalerror from "../ModalError/ErrorModal";
import FeedbackTab from "../FeedbackTab";

const ArchiveFeedbackReview = () => {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const data = [
    {
      CUID: "AD32344",
      ROLE: "ADMIN",
      NAME: "COMPLETED",
    },
    {
      CUID: "AD32344",
      ROLE: "ADMIN",
      NAME: "COMPLETED",
    }
  
  ];

  const [buttonStates, setButtonStates] = useState(
    Array(data.length).fill("Open")
  );
  
  const [showObj, setshowObj] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [showReviewedFeed, setShowReviewedFeed] = useState(false);
  const handleRevFeedClick = (row) => {
    console.log(row)
    setshowObj(row)
    setShowReviewedFeed(true);
  };
  const handleRevFeedClose = () => {
    setShowReviewedFeed(false);
  };
  const [showerrmodal, setShowerror] = useState(false);
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const [feedRevData, setFeedRevData] = useState([]);
  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            "/getFeedbacks/archive",
          headers
        );
        const data = response.data;

        setFeedRevData(data); // Assuming your API response is an array of objects
      } catch (error) {
        console.log(error.response, "jk");
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      }
    };
    fetchData();
  }, []);

  const exportToExcel = () => {
    const datas = feedRevData;
    const capital = (obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toUpperCase()] = obj[key];
      }
      return newObj;
    };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "FEEDBACK_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  
  const handleDropdownChange = (index, value) => {
    const newRows = feedRevData.map((row, i) => 
      i === index ? { ...row, status: value } : row
    );
    setFeedRevData(newRows);
  };
  const handleCommentChange = (index, value) => {
    const newRows = feedRevData.map((row, i) => 
      i === index ? { ...row, comments: value } : row
    );
    setFeedRevData(newRows);
  };
  const handleSave = (row) => {
    console.log(row,"check");

  };
  return (
    <div>
      <Topbar />
      <FeedbackTab/>
      <div className="row">
        <div className="second-nav-rev">
        <div className="col-2 mob-col-12">
          <div className="content-one-rev">

            <div className="hidden-mob-feed">
              <Link to="/search">
                <FontAwesomeIcon
                  className="ico-nav mb-2 p-3 mob-padding-5"
                  style={{ color: "#011E60" }}
                  icon={faHome}
                />
              </Link>  
            </div>
            <h6 className="feed_head">Archive</h6>
          </div>
          </div>
        </div>
      </div>
      <div>
        <div className="feed_rev_text " style={{flexDirection:"row-reverse",marginRight:"80px"}}> 
        {/* <div className="">
          {" "}
          Feedback Review Page, Where You Can Review And Manage All Customer
          Feedback To Ensure Continuous Improvement Of Our Products And
          Services.
        </div> */}
        <div className="excel-button-feedback ">
        <button onClick={exportToExcel} className="excelbuttonfeedback">
                Export{" "}
                <span className="hidden-mob">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </button>
          </div>
          </div>
          <div className="mob-overflow-scroll tab-feed">
          <table className="custom-table">
            <thead className="custom-thead">
              <tr className="table-row-rev">
                {Object.keys(data[0]).map((key) => (
                  <th key={key} className="custom-th">
                    {key}
                  </th>
                ))}
                <th className="custom-th">Feedback Time</th>
                <th className="custom-th">Form Review</th>
                <th className="custom-th">Action</th>
                <th className="custom-th">Comments</th>
                <th className="custom-th">Action By</th>
                <th className="custom-th">Action Time</th>
              </tr>
            </thead>
            <tbody className="custom-tbody">
              {/* Map through data to create table rows */}
              {feedRevData.map((row,rowIndex) => (
                <tr key={rowIndex} className="table-row-rev">
                  {/* {Object.values(row).map((value, i) => (
                    <td key={i} className="custom-td">
                      {value}
                    </td>
                  ))} */}
                  <td>{row.cuid}</td>
                  <td>{row.role}</td>
                  <td>{row.name}</td>
                  <td>{row.feedbackTime}</td>
                  <td className="custom-td">
                    <button
                      // onClick={() => handleButtonClick(index)}
                      onClick={()=>handleRevFeedClick(row)}
                      className="savebuttonfeedback"
                    >
                      Open
                      {/* {buttonStates[index]} */}
                    </button>
                   
                {showerrmodal && <Modalerror onDataFromError={handleDataFromError}/>}
                  </td>
                  <td>{row.action}</td>
                  <td>{row.comments}</td>
                  <td>{row.actionBy}</td>
                  <td>{row.actionTime}</td>
                </tr>
              ))}
               {showReviewedFeed&& (
                  <ReviewedFeedback onClose={handleRevFeedClose} dataFeed={showObj}/>
                )}
            </tbody>
          </table>
     
        </div>
      </div>
      
    </div>
  );
};

export default ArchiveFeedbackReview;
