import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalFacilityInfo.css";
import { FaTh } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ModalFacilityInfo = ({ facility, facilityconfirm, close , passvalue }) => {
  console.log(facilityconfirm,'ss')
  const navmst = useNavigate();
  console.log(facility, "check");
  const handlePassvalue = (data) =>{
    passvalue(data)
  }
  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    close(dataToSend);
  };
  const handleMstClick = (val) => {
    const splitname = val;
    const mstname = splitname.split(',')[0].trim();
    navmst("/mstmain/" +"ME="+mstname+ "/NA/NA");
  };
  const navfdh = useNavigate();
  const handleFdhClick = (val) =>{
    const fdhname = val;
    navfdh("/main/" +"ME="+fdhname + "/NA/NA/NA/NA");
  }

  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal-faci">
              
              {
                facilityconfirm == true ? 
                <>
                <div className="m-header">
                <span style={{ color: "#001f6a" }}>
                  <b>Please confirm facilities</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
                 <div className="modalcontent">
                <div className="mob-oveflow-scroll">
                  <table>
                    <tbody>
                      {facility.length > 0 ? (
                        <>
                          {facility.map((item, index) => (
                            <tr key={index}>
                              {Object.keys(item).map((key, i) => (
                                <React.Fragment key={i} >
                                  <tr>
                                    <th className="th-faci">{key}</th>
                                    {key === "MST, TERMINAL PORT" ? (
                                     
                                        <td
                                          className="th-faci"
                                          onClick={() =>
                                            handleMstClick(item[key])
                                          }
                                        >
                                          {item[key]}
                                        </td>
                                      
                                    ) : key === "FDH ID" ?  (
                                     
                                       <td
                                          className="th-faci"
                                          onClick={() =>
                                            handleFdhClick(item[key])
                                          }
                                        >
                                          {item[key]}
                                        </td>
                                     
                                    ): (
                                      <>
                                        <td className="th-faci">{item[key]}</td>
                                      </>
                                    )}
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="mt-2">Please confirm the above details are correct</p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="m-2"><button className="btn-yes-check"  onClick={()=>handlePassvalue('Yes')} >Yes</button></div>
                  <div className="m-2"><button className="btn-no-check"   onClick={ ()=>handlePassvalue('No')} >No</button></div>
                </div>
              </div>
                </>

                :

                <>
                <div className="m-header">
                <span style={{ color: "#001f6a" }}>
                  <b>Facility Information</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
                 <div className="modalcontent">
                <div className="mob-oveflow-scroll">
                  <table>
                    <tbody>
                      {facility.length > 0 ? (
                        <>
                          {facility.map((item, index) => (
                            <tr key={index}>
                              {Object.keys(item).map((key, i) => (
                                <React.Fragment key={i} >
                                  <tr>
                                    <th className="th-faci">{key}</th>
                                    {key === "MST, TERMINAL PORT" ? (
                                     
                                        <td
                                          className="th-faci"
                                          onClick={() =>
                                            handleMstClick(item[key])
                                          }
                                        >
                                          {item[key]}
                                        </td>
                                      
                                    ) : key === "FDH ID" ?  (
                                     
                                       <td
                                          className="th-faci"
                                          onClick={() =>
                                            handleFdhClick(item[key])
                                          }
                                        >
                                          {item[key]}
                                        </td>
                                     
                                    ): (
                                      <>
                                        <td className="th-faci">{item[key]}</td>
                                      </>
                                    )}
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                        
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
                </>
              }
             
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalFacilityInfo;
