import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalMstIssue.css";

const ModalMstIssue = ({addIssue,closemodissue}) => {
  const [selectedValue,setSelectedvalue] = useState("Trees");
  const handleClick = () => {
    const dataToSend = false;
    addIssue(dataToSend,selectedValue);
    // audittype(selectedOption, isChecked);
    console.log("checkbox",selectedValue);
  };
 const handleChange = (e) =>{
    const value = e.target.value;
   setSelectedvalue(value);
   console.log(value,"changefu");
 }
//  const selectedValues = [];
//  if(trees){
//     selectedValues.push('Tress')
//  }

  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    closemodissue(dataToSend);
  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal">
              <div className="m-header">
                <span>
                  <b>CHOOSE ISSUE</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
              <div className="modalcontent">
                  <div className="m-check-issue">
                    <input
                      class="form-check-input-issue"
                      checked={selectedValue === "Trees"}
                      value= "Trees"
                      type="radio" 
                      name="option"
                      onChange={handleChange}
                    />
                    <span>Trees</span>
                  </div>
                  <div className="m-check-issue">
                    <input
                      class="form-check-input-issue"
                      checked={selectedValue === "Private Property"}
                      value="Private Property"
                      type="radio" 
                      name="option"
                      onChange={handleChange}
                    />
                    <span>Private Property</span>
                  </div>
                  <div className="m-check-issue">
                    <input
                      class="form-check-input-issue"
                      checked={selectedValue === "Safety Concerns"}
                      type="radio" 
                      name="option"
                      value="Safety Concerns"
                      onChange={handleChange}
                    />
                    <span>Safety Concerns</span>
                  </div>
                  <div className="m-check-issue">
                    <input
                      class="form-check-input-issue"
                      checked={selectedValue === "Other Resident"}
                      value="Other Resident"
                      type="radio" 
                      name="option"
                      onChange={handleChange}
                    />
                    <span>Other Resident</span>
                  </div>
              </div>
              
                <button className="alert-bottom" onClick={handleClick}>
                  ADD
                </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalMstIssue;
