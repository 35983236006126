import React from "react";
import ReactDOM from "react-dom";
import { faCircleExclamation, faClose, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Modal.css";
import { useNavigate } from "react-router-dom";

const ErrorModal = ({ onDataFromError }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    // Call the function received from the parent with the data
    onDataFromError(dataToSend);
    navigate('/search');
  };
  const handleReload = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    // Call the function received from the parent with the data
    onDataFromError(dataToSend);
    window.location.reload();
  };
  return (
    <>
      {ReactDOM.createPortal(
        <div className="overlay-modal">
          <div className="modal-error">
            <div className="modal-icon-error">
              {/* use fontawesome icon instead */}

              {/* <FontAwesomeIcon icon={faClose} /> */}
              <b className="title">
                <FontAwesomeIcon className="error-icon" icon={faTriangleExclamation} />
              </b>
              
            </div>
            <div >
              {/* use fontawesome icon instead */}

              {/* <FontAwesomeIcon icon={faClose} /> */}
              <b className="errormsg-error">Network Error</b>
              
            </div>

            <p className="content">
              An error has occured while creating an error report.
            </p>

            <div className="bottom-error">
            <button className="dismissbutton" onClick={handleClick}>
              Back to Home
              </button>
              <button className="reloadbutton" onClick={handleReload}>
                  Reload
              </button> 
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ErrorModal;
