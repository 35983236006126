// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-tab{
  height: 2.3rem;
  width: 100%;
  background-color: #EAEEF1;
  color: #5f5f5f;
  font-weight: 500;
}
.reviewtab-active{
  font-weight: 700;
  color: #011e60;
  border-bottom: 3px solid #011e60;
}
.section-tab-review{
  position: relative;
    padding: 8px;
    left: 47px;
    cursor: pointer;
    width: 10rem;
    font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/ReviewtabMst.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,yBAAyB;EACzB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,gCAAgC;AAClC;AACA;EACE,kBAAkB;IAChB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".review-tab{\n  height: 2.3rem;\n  width: 100%;\n  background-color: #EAEEF1;\n  color: #5f5f5f;\n  font-weight: 500;\n}\n.reviewtab-active{\n  font-weight: 700;\n  color: #011e60;\n  border-bottom: 3px solid #011e60;\n}\n.section-tab-review{\n  position: relative;\n    padding: 8px;\n    left: 47px;\n    cursor: pointer;\n    width: 10rem;\n    font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
