import React from "react";
import "./css/contentloading.css";

const Contentloading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    <div> <p>Loading FDH Cutsheet.....</p></div>
    </div>
  );
};

export default Contentloading;
