import React, { useState } from "react";
import { useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import ModalPage from "./ModalWhite/modalpage";
import "./App.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/Lumen Logo White.png";
import ModalFeedback from "./ModalWhite/ModalFeedback";
import FeedbackIcon from "./assets/feedbackIcon.png";

function Topbar(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const navigate = useNavigate();
  const storedName = sessionStorage.getItem("Name");
  const role = sessionStorage.getItem("Role");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const[isFeedHovered, setIsFeedHovered] = useState(false);
  const [pageactive, setPageactive] = useState(false);
  const [showpagemodal, setPageModal] = useState(false);
  const [showdrop, setDropdown] = useState(false);

  useEffect(() => {
    if (storedName == null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (props.data == true) {
      setPageactive(props.data);
    } else {
      setPageactive(false);
    }
    // setpagesavePop(p)
  }, [props.data]);

  const onpagenav = () => {
    if (pageactive == true) {
      setPageModal(true);
    }
    if (pageactive == false) {
      navigate("/search");
    }
  };

  const handleLogout = () => {
    // sessionStorage.clear();
    // navigate('/');
    setDropdown(!showdrop);
  };
  const handleFeedbackClick = () => {
    setShowFeedbackModal(true);
  };
  const handleFeedbackClose = () => {
    setShowFeedbackModal(false);
  };
  const callLogout = () =>{
    sessionStorage.clear();
    navigate('/')
  }
  const callAccess = () =>{
    navigate('/access')
  }
  const handleDataFromPage = () => {
    setPageModal(false);
  };
  return (
    <>
      {showpagemodal && <ModalPage onDataFromChild={handleDataFromPage} />}
      <nav className="navbar navbar-dark navbar-expand-lg bg-nav">
        <a className="navbar-brand navbar-mob" href="#">
          <img src={CenturyLinklogo} className="ctllogo" />
        </a>
        <div>
          <span
            style={{ paddingRight: "10px" }}
            className="hidden-dekstop hidden-tab"
          >
            {/* <Link to="/search"> */}
            <FontAwesomeIcon
              onClick={onpagenav}
              className="ico-nav"
              style={{ color: "white" }}
              icon={faHome}
            />
            {/* </Link> */}
          </span>
          <button
            className="navbar-toggler  mob-button"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarText">
          {/* <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Home<span className="sr-only">(current)</span>
                </a>
              </li>
            </ul> */}

          <span
            className="d-flex flex-row navbar-text"
            style={{ right: "4rem", position: "absolute", float: "right" }}
          >
            <div style={{ "padding-right": "15px" ,'text-align':'right','font-size':'13px'}}>
              <span >Welcome {storedName ? storedName : ""} </span><br></br>
              <span style={{'font-size':'11px'}} >{role}</span>
            </div>
            {/* <div style={{ "padding-right": "15px"}}>
                <FontAwesomeIcon className="ico-nav" icon={faBell} />
              </div> */}
            <div style={{ "padding-right": "15px" }}>
              <FontAwesomeIcon className="ico-nav" icon={faUserCircle} />
            </div>
            <div className="feedback-nav">
                <div
                  onClick={handleFeedbackClick}
                  onMouseEnter={()=> setIsFeedHovered(true)}
                      onMouseLeave={()=> setIsFeedHovered(false)}
                  className="feedback_message_icon"
                >
                  <img className="feedIcn" src={FeedbackIcon} alt="" style={{width:"22px"}}></img>
                  {isFeedHovered && <div className="hover-text-feed">Feedback</div>}
                </div>

                {showFeedbackModal && (
                  <ModalFeedback onClose={handleFeedbackClose} />
                )}
              </div>
            <div className="logout-hover">
            <div 
                style={{ width: '10px', textAlign: 'center' }} title="Logout"
                >
                   <FontAwesomeIcon
                className="ico-nav"
                icon={faSignOut}
                onClick={handleLogout}
              />
            </div>        
              {/* <span className="text-hidden" > */}
              {showdrop && (
                <div className="dropdown">
                  <div className="dropdown-content" style={{'color':'black'}}>
                    {
                      role == 'Super Admin' && 
                      <><a onClick={callAccess}>Manage Access</a></>
                    }
                    
                    <a onClick={callLogout} >Log out</a>
                  </div>
                </div>
              )}

              {/* </span> */}
            </div>
          </span>
        </div>
      </nav>
    </>
  );
}

export default Topbar;
