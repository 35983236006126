import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./App.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faEdit,
  faPen,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/ctllogo.png";
import Topbar from "./topbar";
import FdhTable from "./fdhtable";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MstTable from "./msttable";
import { API_KEY, BASE_API_URL } from "./environment";
import Modalerror from "./ModalError/ErrorModal";
import ModalPage from "./ModalWhite/modalpage";

function SecondTopbar(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const { fdhName } = useParams();
  const { mstportCount } = useParams();
  const { latitude } = useParams();
  const { longitude } = useParams();
  const { address } = useParams();
  const { fdhstring } = useParams();
  const [data, setData] = useState([]);
  const { jobNumber } = useParams();
  const [showerrmodal, setShowerror] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [fdhview, setFdhListview] = useState(false);
  const [fdhnameset, setFdhName] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [mstfilteredSuggestions, setmstFilteredSuggestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pageactive, setPageactive] = useState(false);
  const [showpagemodal, setPageModal] = useState(false);
  const [valuedata, setvaluedata] = useState('');
  const Cuid = sessionStorage.getItem("encryptedKey");
  
  const { mstName } = useParams();
  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };
  const nav = useNavigate();

  useEffect(() => {
    getLatLong();
    if(props.data == true){
      setPageactive(props.data)
    }else{
      setPageactive(false)
    }
    console.log(props,'ss')
    // setpagesavePop(p)
  }, [props.data]);
console.log(props.onDataFrom)
  const getLatLong = () => {
    setShowerror(false);
    fetch(process.env.REACT_APP_BASE_API_URL + "/getMst?mst=" + mstName , headers)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then((data) => {
      setvaluedata(data[0]);
        props.onDataFrom(data[0]);
    })
    .catch((error) => {
      if (error) {
        if (
          error.status === 502 ||
          error.status === 500
        ) {
          setShowerror(true);
        }
        if(error.status === 400 || error.status == 404){
          
        }
      } else {
      }
    });
  }
 
  const formattedDate = new Date(props.auditTimeval?props.auditTimeval:'NA').toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedTime = new Date(props.auditTimeval?props.auditTimeval:'NA').toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });
console.log(props.auditTimeval, formattedTime, formattedDate, 'echecl')
  //to close list
  const onpagenav = ()=>{
    if(pageactive == true){
      setPageModal(true)
      
    }
    if(pageactive == false) {
      nav("/search")
    }
  }
  //var fdh = props.fdhNamevalue;

  useEffect(() => {
    

    axios
    .get(process.env.REACT_APP_BASE_API_URL + "/getFdhByMst?mst=" + mstName , headers )
    .then((response) => {
      const data = response.data;
      if(data.status === "400"){
        
        setmstFilteredSuggestions([]);

      }else{
        var datafdh = data[0]?data[0]:'NA';
        setFdhName(datafdh);
      setmstFilteredSuggestions(data);
      handleFDHList(datafdh);
      }
    })
    .catch((error) => {
      setmstFilteredSuggestions([]);
      if (error.response) {
        if(error.reponse.status === 404){
          setmstFilteredSuggestions([]);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
   

     
  }, []);

  const handleFDHList = (fdhvalue) =>{
    axios
    .get(process.env.REACT_APP_BASE_API_URL + "/getMstListByFdh?fdh=" + fdhvalue , headers )
    .then((response) => {
      const data = response.data;
      if(data.message === "No Records Found for the FDH"){
        setFilteredSuggestions([]);
      }else{
      setFilteredSuggestions(data);
      console.log(filteredSuggestions, "sugg");
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    if (inputValue.length >= 2) {
      if (inputValue.includes("ME=")) {
        if (inputValue.length >= 5) {
          axios
            .get(process.env.REACT_APP_BASE_API_URL + "/searchByFdh?fdh" + inputValue ,  headers)
            .then((response) => {
              const data = response.data;

              setFilteredSuggestions(data);
            });
        }
      } else {
        axios
          .get(process.env.REACT_APP_BASE_API_URL + "/searchByFdh?fdh=" + inputValue , headers)
          .then((response) => {
            const data = response.data;

            setFilteredSuggestions(data);
            console.log(filteredSuggestions, "sugg");
          });
      }
    }
  };
  const removeList = () => {
    setFilteredSuggestions([]);
  };
  const change = () => {
    setFdhListview(true);
  };
  const handleSelectSuggestion = (event) => {
    var inputstring = event.target.value;
    if(inputstring !== '0' ){
      nav("/mstmain/" + inputstring + "/" + fdhstring +'/' + mstportCount);
    }
   
  };
  const handleFDHSelectSuggestion = (event) =>{
    var inputstring = event.target.value;
    setFilteredSuggestions([])
    if(inputstring !== '0'){
      axios.get(process.env.REACT_APP_BASE_API_URL+ "/getMstListByFdh?fdh=" + inputstring , headers)
      .then((response) => {
        const data = response.data;

        setFilteredSuggestions(data);
        console.log(filteredSuggestions, "sugg");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
          if (error.response.status === 404) {
            setFilteredSuggestions([])
          }
        } else {
          setShowerror(true);
        }
      });
    }
    
  }
  const handleDataFromPage = () => {
    setPageModal(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const navfdh = useNavigate();
  const handleFdh = () =>{
    // const fdhname = props.fdhNamevalue;
  // const fdhnames = fdhname.split('/')[0];
    navfdh("/main/" +fdhnameset + "/NA/NA/NA/NA");
  }
  return (
    <>
     {showpagemodal && <ModalPage onDataFromChild={handleDataFromPage} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="second-nav">
        <div className="container">
          <div className="row">
            <div className="col-2 mob-col-12">
              <div className="content-one">
                <span className="hidden-mob">
                  {/* <Link > */}
                    <FontAwesomeIcon onClick={onpagenav}
                      className="ico-nav mb-2 p-3 mob-padding-5"
                      style={{ color: "#011E60" }}
                      icon={faHome}
                    />
                  {/* </Link> */}
                </span>
                <h6>MST</h6>
              </div>
            </div>
            <div className="col-7 mob-col-12">
              <div className="content-two tab-content-two">
                <div className="d-flex flex-row mob-b-row adjust-tab-content">
                  <div
                    className="d-flex flex-column mob-col-4 auditcount"
                    style={{ "padding-right": "28px" }}
                  >
                    <div>
                      <span>MST Name:</span>
                    </div>
                    <div>
                      <span>FDH Name:</span>
                    </div>

                    <div>
                      <span>Audit Status:</span>
                    </div>
                    {/* <div>
                      <span>Audit Date & Time:</span>
                    </div> */}
                   
                    {/* <div className="mt-2">
                      <span>MST:</span>
                    </div> */}
                  </div>
                  <div className="d-flex flex-column mob-col-8 auditcount">
                    <div>
                      <span>{mstName}</span>
                    </div>
                    <div onClick={handleFdh}>
                      <span style={{cursor : "pointer"}}>{fdhnameset}</span>
                    </div>
                    <div className="">
                      <span>
                      {props.status} 
                      </span>
                    </div>
                    {/* <div className="">
                    <span>{
                        formattedDate === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedDate} </span>
                        )
                      }</span>, &nbsp;
                      <span>{
                        formattedTime === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedTime}  CST</span>
                        )
                      }</span>
                    </div> */}
                     
                    
                  </div>
                  <div
                    className="d-flex flex-column mob-col-12 pb-2 mob-remove-padding-left tab-remove-padding-left"
                    style={{ "padding-left": "20px" }}
                  >
                    <select
                          className="searchresult-input mb-2"
                          value={selectedOption}
                          onChange={handleSelectSuggestion}
                        >
                          <option value="0">Select MST</option>
                          {filteredSuggestions?.map((option) => (
                            <option key={option.deviceName} value={option.deviceName}>
                              {option.deviceName}
                            </option>
                          ))}
                        </select>
                   
                    
                    <div>
                    <select
                          className="searchresult-input"
                          value={selectedOption}
                          onChange={handleFDHSelectSuggestion}
                        >
                          <option value="0">Select FDH</option>
                          {mstfilteredSuggestions?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 mob-col-6">
              
              <div className="content-two tab-counts">
              <div className="d-flex flex-column mob-col-12 mob-remove-padding-left tab-padding">
                <div className="d-flex flex-column">
                <div>{mstportCount == "NA" ? (
                      <></>
                    ) : (
                      <>

                        <span style={{'font-size':'12px!important'}}>
                          MST Port Count: <span>{mstportCount}</span>
                        </span>
                      </>
                    )}
                    </div>
                    <div>
                      <>
                        <span style={{'font-size':'12px!important'}}>
                          MST Address: <span>{valuedata.mstAddress?valuedata.mstAddress:'NA'}</span>
                        </span>
                      </>
                
                    </div>
                    <div>
                    <>
                        <span style={{'font-size':'12px!important'}}>
                          Distribution Cable: <span>{valuedata.cableName?valuedata.cableName:'NA'}</span>
                        </span>
                      </>
                    </div>
                    {/* <div>
                      <>
                        <span style={{'font-size':'12px!important'}}>
                          Lat, Long: <span>{valuedata.latitude?valuedata.latitude:'NA'}, {valuedata.longitude?valuedata.longitude:'NA'}</span>
                        </span>
                      </>
                
                    </div> */}
              </div>
                </div>
              
              
              </div>
            
            </div>

            {/* <div className="col-3 mob-col-6">
            <div className="content-three">
              <div className="d-flex flex-column">
                <div
                  className="d-flex flex-column"
                  style={{ "text-align": "right", "padding-right": "12px" }}
                >
                  <div className="">
                    <span className="confirm">Confirmed</span>
                  </div>
                  <div className="">
                    <span>On Field Location</span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

function MstMain() {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [fdhNamevalue, setFDHNamevalue] = useState();
  const [datafromchild, setDatafromChild] = useState("");
  const [auditStat, setAuditStat] = useState("");
  const [wireState, setWirestate] = useState("");
  const [auditTime, setAuditTime] = useState("");
  var transfer = {};
  const receiveDataFromChild = (data) => {
    setFDHNamevalue(data);
  };
  const receivestatus = (data)=>{
    setAuditStat(data);
  }
  const receivedata = (data)=>{
    setDatafromChild(data);
  }
  const receivewirestate = (data) =>{
    console.log('cec',data)
    transfer = data;
    setWirestate(data)
  }
  const receivedataTime = (data)=>{
    setAuditTime(data)
  }
  return (
    <>
      <Topbar data={datafromchild}></Topbar>
      <SecondTopbar onDataFrom={receivewirestate} data={datafromchild} auditTimeval={auditTime} status={auditStat} fdhNamevalue={fdhNamevalue} />
      <Outlet />
      <MstTable wireState = {wireState} onPageReviewtime={receivedataTime} onPagesaveData={receivedata}  onPageStatus={receivestatus} onDataFromChild={receiveDataFromChild} ></MstTable>
    </>
  );
}

export default MstMain;
