// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 900ms infinite;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    width: 0;
    content: "\\2026";
  }
  .dialog-FullAddress{
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 5px !important;
    margin-bottom: 5px;
    padding: 12px;
    font-size: 12px;
    overflow: unset !important;
    text-overflow: unset !important;
    white-space: wrap;
    min-height: 14px;
  }
  .dialog-body-MaxHeight{
    max-height: 150px;
    overflow-y: scroll;
  }
  .dialog-FullAddress:hover{
    background-color: #002a8a;
    color: white;
  }
  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/StatusPop/statusdialog.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,sBAAsB;IACtB,gDAAgD;IAChD,wDAAwD;IACxD,QAAQ;IACR,gBAAgB;EAClB;EACA;IACE,qCAAqC;IACrC,6BAA6B;IAC7B,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,0BAA0B;IAC1B,+BAA+B;IAC/B,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,yBAAyB;IACzB,YAAY;EACd;EACA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".status-loading:after {\n    overflow: hidden;\n    display: inline-block;\n    vertical-align: bottom;\n    animation: ellipsis steps(4, end) 900ms infinite;\n    -webkit-animation: ellipsis steps(4, end) 900ms infinite;\n    width: 0;\n    content: \"\\2026\";\n  }\n  .dialog-FullAddress{\n    background-color: rgba(0, 0, 0, 0.06);\n    border-radius: 5px !important;\n    margin-bottom: 5px;\n    padding: 12px;\n    font-size: 12px;\n    overflow: unset !important;\n    text-overflow: unset !important;\n    white-space: wrap;\n    min-height: 14px;\n  }\n  .dialog-body-MaxHeight{\n    max-height: 150px;\n    overflow-y: scroll;\n  }\n  .dialog-FullAddress:hover{\n    background-color: #002a8a;\n    color: white;\n  }\n  @keyframes ellipsis {\n    to {\n      width: 1.25em;\n    }\n  }\n  @-webkit-keyframes ellipsis {\n      to {\n        width: 1.25em;\n      }\n    }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
