import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./App.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faEdit,
  faPen,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/ctllogo.png";
import Topbar from "./topbar";
import ModalPage from "./ModalWhite/modalpage";
import FdhTable from "./fdhtable";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_KEY } from "./environment";
import Modalerror from "./ModalError/ErrorModal";

function SecondTopbar(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const { fdhName } = useParams();
  const role = sessionStorage.getItem("Role");
  
  const [inputCUID, setCuid] = useState("");
  const [inputMail, setMailId] = useState("");
  const [datauser, setdataUser] = useState("");
  const [loadingTest, setloadingText] = useState(false);
  const [toggleErrModal, setToggleErrModal] = useState(false);
  const nav = useNavigate();
  var userdata = "";
  useEffect(() => {
    // setpagesavePop(p)
    if (role !== "Super Admin") {
      sessionStorage.clear();
      nav("/");
    }
  }, []);
  const handleErrModal = () => {
    setToggleErrModal(true);
  };

  //to close list
  const handlesearch = (event) => {
    setCuid(event.target.value);
    setMailId("");
  };
  const handlemail = (event) => {
    setCuid("");
    setMailId(event.target.value);
  };
  const SearchCUID = async (start, end) => {
    // setBlackmod(true);
    setloadingText(true);
    try {
      const responsetwo = await axios.get(
        "https://centurylink-prod.apigee.net/v1/QFAST/getUserDetails?userInfo=" +
          inputCUID +
          inputMail +
          "&apikey=74rtvsz2HLMGSYKpBtu4JUbkEtabhps8"
      );
      userdata = responsetwo.data;
      if (userdata) {
        setloadingText(false);
      }
      setdataUser(userdata);
      console.log(userdata);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setToggleErrModal(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setToggleErrModal(true);
        }
      } else {
        setToggleErrModal(true);
      }
    }

    // const mergedData = await mergeArraysById(data, O2data);

    // Create a map to store the merged data using the id as the key
  };
  
  return (
    <>
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
      <div className="second-nav">
        <div className="container">
          <div className="row">
            <div className="col-3 mob-col-12 tab-res">
              <div className="content-one ">
                <span className="hidden-mob">
                  <Link to="/search">
                    <FontAwesomeIcon
                      className="ico-nav mb-2 p-3 mob-padding-5"
                      style={{ color: "#011E60" }}
                      icon={faHome}
                    />
                  </Link>
                </span>
                <h6>Manage Access</h6>
              </div>
            </div>
            <div className="col-5 mob-col-12  ">
              <div className="content-two ">
                <div className="d-flex flex-row  search-container-mob">
                  <div
                    className="d-flex flex-column auditcount"
                    style={{ "padding-right": "28px", "padding-left": "20px" }}
                  >
                    <div>
                      <span>Enter CUID:</span>
                    </div>
                    <div>
                      <span>
                        <input
                          className="inp-access email-box-mob"
                          placeholder="CUID"
                          onChange={handlesearch}
                          value={inputCUID}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column ">
                    <div className="text-enter">
                      <span>OR</span>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ "padding-left": "20px" }}
                  >
                    <div>
                      <span>Enter Mail:</span>
                    </div>
                    <div>
                      <span>
                        <input
                          className="inp-access email-box-mob"
                          value={inputMail}
                          onChange={handlemail}
                          placeholder="Email"
                        />{" "}
                      </span>
                    </div>

                    <div></div>
                    <div>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ "padding-left": "20px" }}
                  >
                    {/* <p>Job Number: <span>{props.status}</span></p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 mob-col-6 ">
              <div
                className="content-two mob-content-button"
                style={{ "justify-content": "end" }}
              >
                <button
                  className="but-dark mob-but-access "
                  onClick={SearchCUID}
                >
                  Search
                </button>
                {loadingTest && (
                  <>
                    <div className="loadersss"></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Access data={datauser}></Access>
    </>
  );
}

function Access(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const { fdhName } = useParams();

  const nav = useNavigate();
  const [toggleErrModal, setToggleErrModal] = useState(false);
  const [userrole, setUserrole] = useState("0");
  const [adminrole, setAdminrole] = useState(false);
  const [adminlist, setUseradminlist] = useState([]);
  const [userlistdata, setUserlist] = useState([]);
  const [errormesg, seterrormesg] = useState("");
  const [successmesg, setsuccessmesg] = useState(false);
  const [searcTerm, setSearchTerm] = useState();
  const [selectedRole, setSelectedRole] = useState('all');
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [accessinfo, setaccessinfo] = useState([]);
  var userlistitem = [];

  useEffect(() => {
    if (!searcTerm) {
      setCurrentPage(1);
      setSearchTerm("");
      setUseradminlist(userlistdata);
    } else {
      const filteredData = adminlist.filter((item) =>
        Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searcTerm.toLowerCase())
      );
      setUseradminlist(filteredData);
    }
  }, [searcTerm, adminlist]);

  useEffect(()=>{
    if(props.data.cuId){
      usersearch();
    }
    
  },[props.data.cuId])
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  const filteredDatatable = selectedRole === 'all' ? adminlist : adminlist.filter(item => item.role === selectedRole);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  var currentItems = filteredDatatable.slice(indexOfFirstItem, indexOfLastItem);
  // setUseradminlist(currentItems)
  //   const [searchResults, setSearchResults] = useState(currentItems);
  const handleErrModal = () => {
    setToggleErrModal(true);
  };
  const handleSearch = (event) => {
    const searcTerm = event.target.value;
    setSearchTerm(searcTerm);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(filteredDatatable.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    console.log(props);
    userlist();
    // setpagesavePop(p)
  }, []);

  const  handleroleFilter = (event) =>{
    if(event.target.value !== "0"){
      setSelectedRole(event.target.value); 
    } else{
      setSelectedRole('all');
    }
  }
  const deleteitem = async (item) => {
    console.log(item);
    try {
      const responsetwo = await axios.delete(
        process.env.REACT_APP_BASE_API_URL + "/deleteUser?deleteId=" + item.cuId, headers
      );
      var userdata = responsetwo.data;
      userlist();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setToggleErrModal(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setToggleErrModal(true);
        }
      } else {
        setToggleErrModal(true);
      }
    }
  };

  const handleCheckboxChange = (check) => {
    seterrormesg("");
    setUserrole(check.target.value);
  };

  const userlist = async () => {
    const data = { 
      "cuid": "", 
      "emailId": "" 
   } 
    try {
      const responsetwo = await axios.post(
        process.env.REACT_APP_BASE_API_URL + "/getAuditAdminDtls?",data, headers
      );
      var userdata = responsetwo.data;
      userlistitem = userdata;
      setUseradminlist(userlistitem);
      setUserlist(userlistitem);
      console.log(userlistitem);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setToggleErrModal(true);
        }
      } else {
        // setToggleErrModal(true);
      }
    }
  };
  const usersearch = async () => {
    const data = { 
      "cuid": props.data.cuId, 
      "emailId": "",
      "role":""
   } 
    try {
      const responsetwo = await axios.post(
        process.env.REACT_APP_BASE_API_URL + "/getAuditAdminDtls?",data, headers
      );
      var userdata = responsetwo.data;
      setaccessinfo(userdata[0])
      console.log(userdata);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setToggleErrModal(true);
        }
      } else {
        // setToggleErrModal(true);
      }
    }
  };
  const Adduser = async () => {
    // setBlackmod(true);
    seterrormesg("");
    setsuccessmesg(true);
    var allowuser = true;
    if (userrole == "0") {
      allowuser = false;
      seterrormesg("Please Select role");
    }
    const data = [
      {
        cuId: props.data.cuId,

        emailId: props.data.emailId,

        role: userrole,

        userName: props.data.name,

        status: "Active",
        encryptedKey:"",
      },
    ];
    if (allowuser === true) {
      try {
        const responsetwo = await axios.post(
          process.env.REACT_APP_BASE_API_URL + "/saveUserDtls?",
          data, headers
        );
        var userdata = responsetwo.data;
        if (userdata) {
          setsuccessmesg(false);
        }
        userlist();
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setToggleErrModal(true);
          }
        } else {
          setToggleErrModal(true);
        }
      }
    }

    // const mergedData = await mergeArraysById(data, O2data);

    // Create a map to store the merged data using the id as the key
  };
  //to close list

  return (
    <>
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
      <div className="container" style={{ "font-family": "Poppins" }}>
        <div className="row mt-3">
          <div className="d-flex flex-row access-text">
            <div className="d-flex flex-column">
              <div className="pb-1">
                <b className="title-search">Search Result</b>
              </div>
              {props.data.cuId ? (
                <>
                  <div className="pb-1">
                    <span className="">
                      CUID:&nbsp;<b>{props.data.cuId}</b>
                    </span>
                  </div>
                  <div className="pb-1">
                    <span className="">
                      Mail:&nbsp;<b>{props.data.emailId}</b>
                    </span>
                  </div>
                  <div className="pb-1">
                    <span className="">
                      Username:&nbsp;<b>{accessinfo.userName?accessinfo.userName:'NA'}</b>
                    </span>
                  </div>
                  <div className="pb-1">
                    <span className="">
                      Role:&nbsp;<b>{accessinfo.role?accessinfo.role:'NA'}</b>
                    </span>
                  </div>
                  <div className="pb-1">
                    <span className="">
                      Status:&nbsp;<b>{accessinfo.status?accessinfo.status:'NA'}</b>
                    </span>
                  </div>
                  <div className="pb-1">
                    {props.data.cuId && (
                      <span className="">
                        Message: {props.data.cuId ? "User Found" : "Not Found"}
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <>User Not Found</>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="d-flex justify-content-between">
            <div className="pb-1">
              {props.data.cuId ? (
                <select
                  class="form-select"
                  style={{ "font-size": "12px" }}
                  onChange={(event) => handleCheckboxChange(event)}
                  aria-label="Default select example"
                >
                  <option value="0">Select Role</option>
                  {/* <option value="Auditor">Auditor</option>
                  <option value="Port Changer">Port Changer</option>
                  <option value="Reviewer">Reviewer</option> */}
                  <option value="View Only">View Only</option>
                  <option value="Super Admin">Super Admin</option>
                  <option value="Field Technician">Field Technician</option>
                  <option value="Field Operations">Field Operations</option>
                  <option value="SRO">SRO</option>
                  <option value="Facility Support">Facility Support</option>
                  <option value="Structure Load">Structure Load</option>
                  <option value="Engineer">Engineer</option>
                  <option value="Cable Technician">Cable Technician</option>
                  <option value="BSW Order Desk">BSW Order Desk</option>
                  <option value="Field Activation">Field Activation</option>
                  <option value="Address Add">Address Add</option>

                  
                </select>
              ) : (
                <></>
              )}
              {/* <span>
                <input
                  className="m-1 reviewinp"
                  type="checkbox"
                 
                  // checked={o2Updated}
                  id="flexCheckDefault"
                ></input>
                <label class="form-check-label" for="flexCheckDefault">
                  Super Admin
                </label>
              </span> */}
            </div>
            <div className="pb-1">
              {props.data.cuId && (
                <button
                  className="but-dark-outline mob-but-access"
                  onClick={Adduser}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex justify-content-center">
            <span style={{ color: "red" }}>{errormesg}</span>
            {successmesg && (
              <>
                <div className="notification">User Added !!</div>
              </>
            )}
          </div>
        </div>
        <div className="mt-3 d-flex align-items-center" style={{"gap":"10px"}}>
          <div>
          <input
            type="text"
            style={{ width: "200px" }}
            className="inp-access email-box-mob"
            placeholder="Search by CUID or E-Mail"
            value={searcTerm}
            onChange={handleSearch}
          />
          </div>
           <div className="">
        <select 
                  class="form-select"
                  style={{ "font-size": "12px" }}
                  onChange={(event) => handleroleFilter(event)}
                  aria-label="Default select example"
                >
                  <option value="0">Select Role</option>
                  <option value="View Only">View Only</option>
                  <option value="Super Admin">Super Admin</option>
                  <option value="Field Technician">Field Technician</option>
                  <option value="Field Operations">Field Operations</option>
                  <option value="SRO">SRO</option>
                  <option value="Facility Support">Facility Support</option>
                  <option value="Structure Load">Structure Load</option>
                  <option value="Engineer">Engineer</option>
                  <option value="Cable Technician">Cable Technician</option>
                  <option value="BSW Order Desk">BSW Order Desk</option>
                  <option value="Field Activation">Field Activation</option>
                  <option value="Address Add">Address Add</option>

                  
                </select>
        </div>
        </div>
       
        <div className="mob-overflow-table">
          <table className="table table-bordered mt-4">
            <thead className="thead-light">
              <tr>
                <th>CUID</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                <>
                  {currentItems.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{item.cuId}</td>
                      <td>{item.emailId}</td>
                      <td>{item.role}</td>
                      <td>{item.status}</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => deleteitem(item)}
                          icon={faTrash}
                          style={{ color: "red" }}
                        ></FontAwesomeIcon>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={5}>User not available</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="row" style={{'width':'100vh','overflow':'scroll'}}>
          <ul className="pagination">
            {itemsPerPage > 5 && (
              <>
                {pageNumbers.map((number) => (
                  <li
                    className={
                      currentPage == number ? "box-pagin active" : "box-pagin"
                    }
                    key={number}
                    onClick={() => paginate(number)}
                  >
                    <span>{number}</span>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
function ManageAccess() {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [datafromchild, setDatafromChild] = useState("");
  const [auditStat, setAuditStat] = useState("");
  const receivedata = (data) => {
    setDatafromChild(data);
  };
  const receivestatus = (data) => {
    setAuditStat(data);
  };
  return (
    <>
      <Topbar data={datafromchild}></Topbar>
      <SecondTopbar data={datafromchild} status={auditStat} />

      {/* <Outlet /> */}
    </>
  );
}

export default ManageAccess;
