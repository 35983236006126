import http from './axios';

const loginAuth = data =>{

    return http.post('/api/login',data);

} 

const Service = {

    loginAuth

}

export default Service ;