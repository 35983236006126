import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./ModalFDH.css";

const ModalFDH = ({ onDataFromChild }) => {
    console.log(onDataFromChild)
//   const closeFunction = () => {
//     props.action();
//   };

  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;

    // Call the function received from the parent with the data
    onDataFromChild(dataToSend);
  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modals">
            
              <div
                style={{
                  color: "#000",
                  padding: "30px 40px",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
                 Successfully Updated!
              </div>
              {/* <Modalalert /> */}
            </div>
            <div className="modal-bottom">
              <button className="alert-bottoms" onClick={handleClick}>OK</button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalFDH;
