import React, { useState, useEffect, useReducer, useRef } from "react";
import Contentloading from "./Contentloading";
import "./css/fdhdatatab.css";
import axios from "axios";
import * as XLSX from 'xlsx';
// import { DownloadTableExcel } from "react-export-table-to-excel";
import ModalWhite from "../ModalWhite/ModalFDH";
import Modalerror from "../ModalError/ErrorModal";
import {  API_KEY,BASE_API_URL } from "../environment";
import { faDownload, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ModalImageUpload from "../ModalWhite/ModalImage";
import ModalLevelConfirm from "../ModalWhite/ModalLevelConfirm";
import ModalReviewConfirm from "../ModalWhite/ModalReviewConfirm";
import ModalDelete from "../ModalWhite/ModalDelete";
import Modalsplitter from "../ModalWhite/ModalSplitter";
import { headersImage } from "../headers";
const initialState = [];

const Fdhdatatab = (data) => {
  console.log(data,"datacheck");
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
  const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showmodaldel, setModaldelete] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [postData, setPostData] = useState([]);
  const role = sessionStorage.getItem("Role");
  var [enableEdit, setenableEdit] = useState(false);
  const [showdelaudit, setshowdeleteaudit] = useState(false);
  const [tabData, setTabData] = useState();
  const [showLevelConfirm, setshowLevelConfirm] = useState(false);
  const [getIndex, setIndex] = useState("");
  const [fdhToggle, setfdhToggle] = useState(false);
  const [filter, setfilter] = useState([]);
  const [showmodalimg, setShowmodalimage] = useState(false);
 
  const navigate = useNavigate();
  const [allProperties, setAllproperties] = useState([]);
  const [saveToggle, setSaveToggle] = useState(false);
  const [cmsStand, setCmsStand] = useState([]);
  var username = sessionStorage.getItem("Name");
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setIschecked] = useState(false);
  const[hideNds,setHideNds] = useState(true);
  var [imageBase64, setImageBase64]= useState("");
  var [viewimageupload,setactiveviewimage] = useState(false);
  
  var [imageURLdata, setImageURL] = useState({});
  const [status, setStatus] = useState("Pending");
  const tableRef = useRef(null);
  useEffect(() => {
    // if (data.tdata == "") {
    //   console.log("im here too");
    // }
      setTabData(data.nodata);  
  }, [data.nodata]);

  useEffect(() => {
    // alert(data.activeIndex);
    
    console.log(data)
    checkaccess()
    setIschecked(false);
    // fetchDataSplit();
    if (data.tdata != "") {
      console.log("im here");
      setAllproperties(Object.keys(data.tdata[0]));
      setfdhToggle(true);
      setfilter(data.tdata);
      getImageUpload(data.tdata)
      handleChange();
      setCmsStand(() => {
        const convertedData = data.tdata.reduce((acc, item) => {
          const { id, cmsStand, splitterLeg } = item;
          if (!acc[id]) {
            acc[id] = {};
          }

          if (!acc[id][splitterLeg]) {
            acc[id][splitterLeg] = { cmsStand };
          }

          return acc;
        }, {});

        return convertedData;
      });
    }

    if (data.loading == true) {
      setfdhToggle(false);
    }

    if (data.tdata == "") {
      setfilter([]);
    }
    setErrorMessage('')
  }, [data.tdata]);

  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const handleImageClose = () => {
    setShowmodalimage(false);
  };
  const handleImageBase = (data) => {
      setImageBase64(data)
  };
  const handleImagePass = () => {
    setShowmodalimage(false);
  };
 
 

  const getImageUpload = (data) => {
    axios.get(
      process.env.REACT_APP_BASE_API_IMAGE_URL  +"/get?fileName=" + data[0].fdh + '.png', headersImage
    )
    .then(response => {  
      const promiseResult = response.data[0].imageBase64;
      setactiveviewimage(true);
      setImageURL(promiseResult);  
    })  
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };

  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setshowdeleteaudit(true);
        break;
       case "Field Operations":
        case "Cable Technician":
        case "Structure Load":
        setenableEdit(true);
        break;
      case "Facility Support":
      case "SRO":
      case "View Only":
        setenableEdit(false);
        break;
      case "Field Technician":
        setenableEdit(false);
        break;
      case "Engineer":
        navigate("/");
        break;
        default
        : break;
    }
  };

  useEffect(() => {
    console.log(cmsStand);
  }, [cmsStand]);

  const cancelData = () => {
    setPostData([]);
    var arrdata = data.tdata;
    arrdata.map((d, rowIndex) => {
      const cms = document.getElementById(
        "deviceType" + "-" + d.id + "-" + rowIndex
      );
      cms.value = "";
      const executiveCommands = document.getElementById(
        "remarks" + "-" + d.id + "-" + rowIndex

      );
      executiveCommands.value = "";
    });
    setSaveToggle(false);
  };

  const saveData = (value) => {
    console.log(isChecked, "ddd");
    var statusreview = "Pending";
    if (isChecked === true) {
      // setStatus('Completed')
      statusreview = "Completed";
    } else {
      setStatus("Pending");
    }
    const propertiesToRemove = ["splitterLegStatus", "rowIndex"];

    var updatedData;
    if(postData.length > 0){
      updatedData = postData.map((item) => {
        const newItem = { ...item };
        propertiesToRemove.forEach((prop) => delete newItem[prop]);
        return newItem;
      });
    }else{
      updatedData=[null];
    }
     

    console.log(updatedData);

    // const transformedData = updatedData.reduce((acc, curr) => {
    //   const { id, ...rest } = curr;
    //   if (!acc[id]) {
    //     acc[id] = [];
    //   }

    //   acc[id].push(rest);
    //   return acc;
    // }, {});

    // console.log(transformedData);
  var fdhName = data.reviewdata.deviceName ? data.reviewdata.deviceName: "NA";
    const apiUrl =
      process.env.REACT_APP_BASE_API_URL +
      "/postReviewComment?" +
      "reviewBy=" +
      username +
      "&reviewStatus=" +
      statusreview +
      "&reviewDevice=FDH" +
      "&reviewDeviceName="+ 
      fdhName
      ;
    axios
      .post(apiUrl, updatedData, headers)
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          data.handleItemClick(null,null,'save');
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };

  const handleField = (e, row, rowIndex, property, from) => {
    let newData = {
      ...row,
      rowIndex: rowIndex,
      deviceType: document.getElementById(
        "deviceType" + "-" + row.id + "-" + rowIndex
      ).value,

      remarks: e.target.value,
    };

    setSaveToggle(true);
    console.log(newData);
    setTimeout(
      setPostData((prevstate) => {
        let bool = false;
        prevstate.map((item, i) => {
          if (
            i === rowIndex &&
            item.id === row.id &&
            row.splitterLeg === item.splitterLeg
          ) {
            bool = true;
          }
        });

        console.log(bool);
        if (bool) {
          return [
            ...prevstate.slice(0, rowIndex),
            newData,
            ...prevstate.slice(rowIndex + 1),
          ];
        } else {
          return [...prevstate, newData];
        }
      }),
      100
    );
  };

  // console.log(comments, "comments");

  useEffect(() => {
    console.log(postData);
  }, [postData]);

  const handlecheckbox = (event) => {
    console.log(isChecked, "ddddd");
    setIschecked(!isChecked);
  };
  const handleChangeCms = (e, row, rowIndex, property, from) => {
    setErrorMessage("");
    const value = e.target.value;
    console.log(e.target.value);
    setSaveToggle(true);
    let newData;
    var data = '';
    if(/^[a-zA-Z0-9\s=]*$/.test(value)){
      row[property]=e.target.value;
    if (from == "c") {
      newData = {
        ...row,
        rowIndex: rowIndex,
        deviceType: e.target.value,
        remarks: document.getElementById(
          "remarks" + "-" + row.id + "-" + rowIndex
        ).value,
      };
    } else if (from == "e") {
      newData = {
        ...row,
        rowIndex: rowIndex,
        deviceType: document.getElementById(
          "deviceType" + "-" + row.id + "-" + rowIndex
        ).value,

        remarks: e.target.value,
      };
    }
    let bool = false;
    setTimeout(
      setPostData((prevstate) => {
        const existingEntry = prevstate.findIndex(
          (entry) =>
            entry.id === row.id && row.splitterLeg === entry.splitterLeg
        );
        if (existingEntry !== -1) {
          return [
            ...prevstate.slice(0, existingEntry),

            newData,

            ...prevstate.slice(existingEntry + 1),
          ];
        } else {
          return [...prevstate, newData];
        }
      }),

      100
    );
    }else{
      
     if(from == 'e'){
      data = row[property]
      const remarks= document.getElementById(
        "remarks" + "-" + row.id + "-" + rowIndex
      );
      remarks.value = data;
      setErrorMessage('Please Enter Valid Input, Special Characters are not allowed');
     }
     else if(from == "c"){
      data = row[property];
      const device =document.getElementById(
        "deviceType" + "-" + row.id + "-" + rowIndex
      );
      device.value= data;
      setErrorMessage('Please Enter Valid Input, Special Characters are not allowed');
     }
    }
    
  };

  // console.log(cmsData, "cmsdtaa");

  const handleDataFromChild = () => {
    setModaldelete(false)
    setWhitemod(false);
  };

  const includesWithNullCheck = (inputString, targetSubstring) => {
    return (
      typeof inputString === "string" &&
      typeof targetSubstring === "string" &&
      (inputString.includes(targetSubstring) || inputString === targetSubstring)
    );
  };

  const handleChange = (e) => {
    const filteredData = data.tdata;
    console.log(filteredData);
    setfilter([...filteredData]);
    // console.log(e.target.value);
    // console.log(data.tdata);
    // setIndex(e.target.value);

    // if (e.target.value != "0") {
    //   const filteredData = data.tdata.filter(
    //     (item) => item.id == e.target.value
    //   );
    //   setfilter([...filteredData]);
    // }
  };

  useEffect(() => console.log(filter), [filter]);
  const togglePopover = () => {
    // setIsPopoverOpen(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };

  //excel new code
  const exportToExcel = () => {
     const datas=data.tdata;
     const capital = (obj) =>{
      const newObj={};
      for (const key in obj){
        newObj[key.toUpperCase()]= obj[key];
      }
      return newObj;
     };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "FDH_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  const handleNdsHide = () =>{
    setHideNds(!hideNds);
  }
  const handleDelete = () =>{
    setshowLevelConfirm(true);
  }
  const handlecloseLevelmodal = () => {
    setshowLevelConfirm(false);
  };
  const handleLevelconfirm = () => {
    setshowLevelConfirm(false);
    handlecalldelete();
  };
  const handlecalldelete = ()=>{
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/deleteAuditData?' + '&deviceType=fdh' + '&fdh=' +  data.reviewdata.deviceName;
    axios
    .delete(apiUrl, headers)
    .then((response) => {
      setModaldelete(true);
      setPostData([]);
      data.handleItemClick(null,null,'saves');
      
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          setShowerror(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }
  return (
    <>
     {showLevelConfirm && (
        <ModalReviewConfirm
          closePopUps={handlecloseLevelmodal}
          confirmModules={handleLevelconfirm}
        />
      )}
     
     {showmodalimg && <ModalImageUpload data={data.tdata[0].fdh}
     imagerole = {viewimageupload}
     imageurl = {imageURLdata}
    onDataFromChild={handleImagePass}
        closemodissue={handleImageClose}  
        imgbase64 = {handleImageBase}
      />}
      {showwhitemodal && <ModalWhite onDataFromChild={handleDataFromChild} />}
      {showmodaldel && <ModalDelete  onDataFromChild={handleDataFromChild} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {/* {isPopoverOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the Data in Valid Field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}
       {
          errorMessage && (
<div className="d-flex justify-content-center">
        <span style={{ color: 'red' }}>{errorMessage}</span>
        </div>
          )
        }
      <div className="dropDown_container mob-flex-wrap">
        <div className="dropDown_1 mob-dropDown_1">
          {filter.length > 0 && (
            <div>
              <div class="form-check">
                <input
                  class="m-1 reviewinp"
                  type="checkbox"
                  onChange={handlecheckbox}
                  checked={isChecked}
                  disabled={!enableEdit}
                  id="flexCheckDefault"
                ></input>
                <label class="form-check-label" for="flexCheckDefault">
                  Completed Review
                </label>
              </div>
            </div>
          )}
          {filter.length > 0 && (
            
             <div class="form-check form-switch">
                <input class="form-check-input neisltog mob-form-check-input" type="checkbox" onClick={handleNdsHide} role="switch" id="flexSwitchCheckDefault"/>
                <label class="form-check-label" for="flexSwitchCheckDefault">Enable Niesl</label>
              </div> 
             
             )}
          {/* <div>Select Splitter:</div>
          <select
            className="dropDownOption"
            id="splitterOptions"
            onChange={handleChange}
          >
            <option readonly value="0">
              Please Select{" "}
            </option>

            {data.tdata != "" &&
              data.spkeys.map((value) => {
                return <option value={value}>{value + " Splitter"}</option>;
              })}
          </select> */}
        </div>

        <div className="dropDown_2 mob-dropDown_2 tab-dropDown_2">
          {
            enableEdit && (
              <>
               {filter.length > 0 && (
            <button className="cancelBtn" onClick={cancelData}>
              Cancel
            </button>
          )}
              </>
            )
          }
         
        </div>
        <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
          {filter.length > 0 && showdelaudit && (
            <button className="excelbutton" onClick={handleDelete}>
              Delete
            </button>
          )}
        </div>
        <div className="dropDown_2 mob-dropDown_2">
          {
            enableEdit && (
              <>
              {filter.length > 0 && (

                <button  className="excelbutton" id="saveBtn" onClick={saveData}>
                  Save
                </button>
              )}
              </>
            )
          }        
        </div>
        { viewimageupload === true && (
          <div className="mob-dropDown_2 tab-margin-button-set">
            <button className="excelbutton" onClick={handleImageShow}>
              View &nbsp;
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faImage} />
              </span>
            </button>
          </div>
          
        )}
        <div className="mob-dropDown_2">
          {
            filter.length > 0 && (
              <div>
                <button onClick={exportToExcel} className="excelbutton">
            Export  <span className="hidden-mob"><FontAwesomeIcon
                    icon={faDownload}
                  /></span>

            </button>
              </div>
            )
          }
         
          </div>
        
      </div>
    
         
          
    

      <div
        className="scrollable-tbody-container scrollable-tbody-container2-mob"
        width="85%"
      >
        <table className="tabletab scrollable-table" ref={tableRef}>
          {data.tdata != "" && filter != "" ? (
            <>
              <thead style={{ position: "sticky", top: "0" }}>
                <tr>
                  {data != "" &&
                    allProperties.map((columnName) => (
                      (hideNds && columnName.startsWith('nds')) ? null :( <th
                        className={`tabhead ${
                          columnName.length > 13
                            ? "widthdata200"
                            : "widthdata150"
                        }`}
                        key={columnName}
                      >
                        {columnName.toUpperCase()}
                      </th>)
                    
                     
                    ))}
                </tr>
              </thead>

              <tbody>
                {filter != "" &&
                 tabData.length>0 ? (
                  filter.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {/* {filter.map((row, index) => ( */}

                      <tr key={rowIndex}>
                        {allProperties.map((propty) => {
                          if  (hideNds && propty.startsWith('nds')){
                             return null;
                          }
                          else if (propty === "deviceType") {
                            return (
                              <td key={propty}>
                                <input
                                  type="text"
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  className="edit_field"
                                  disabled={!enableEdit}
                                  defaultValue={row[propty]}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,

                                      row,

                                      rowIndex,

                                      propty,

                                      "c"
                                    )
                                  }
                                />
                              </td>
                            );
                          } else if (propty === "remarks") {
                            return (
                              <td key={propty}>
                                <textarea
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  defaultValue={row[propty]}
                                  disabled={!enableEdit}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,
                                      row,
                                      rowIndex,
                                      propty,
                                      "e"
                                    )
                                  }
                                  style={{"width":"100%"}}
                                  // onChange={handleField}
                                />
                              </td>
                            );
                          } else {
                            return <td key={propty}>{row[propty]}</td>;
                          }
                        })}
                      </tr>

                      {/* ))} */}
                    </React.Fragment>
                  ))
                 ):(
                  <>
                  <tr>
                  <td colSpan="12">
                      Data not available
                      </td>
                    <td colSpan="12">
                      Data not available
                      </td>
                      <td colSpan="12">
                      Data not available
                      </td>
                    </tr>
                    </>
                 )
                  }
              </tbody>
            </>
          ) : (
            <>
              {
                // fdhToggle ? (

                //   <div

                //     style={{

                //       display: "inline",

                //       alignItems: "center",

                //       fontWeight: "bolder",

                //       margin: "40px",

                //     }}

                //   >

                //     Please Select Splitter

                //   </div>

                // ) :

                !data.loading ? (
                  <div
                    style={{
                      // display: "inline",

                      alignItems: "center",

                      fontSize: "17px",

                      color: "#011e60",

                      fontWeight: "bolder",

                      marginTop: "50px",
                    }}
                  >
                    <>
                      {!data.errorreview == "" ? (
                        <>{data.errorreview}</>
                      ) : (
                        <>Please Select Status</>
                      )}
                    </>
                  </div>
                ) : (
                  <Contentloading />
                )
              }
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default Fdhdatatab;
