import React from "react";
import success from "../assets/success_msg.png";
import "../activation.css";

import StepProgressBar from "../StepProgress/step-progressbar";
import { Button, Dialog } from "@mui/material";

const Success = ({ onClose, successInpop, update, vs_prog, showSuccessOk }) => {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  return (
    <Dialog open={true}>
      <div style={{ maxWidth: "450px" }}>
        <div className="msg_head" style={{ paddingBottom: "0px" }}>
          <img src={success} alt="success" className="succes_img" />
          <div
            className="text-center"
            style={{ fontSize: "18px", fontWeight: "600", padding: "5px" }}
          >
            Activated Successfully
          </div>
          {vs_prog && vs_prog[0] && (
            <div>
              <StepProgressBar totalData={vs_prog} updateData={update} />
            </div>
          )}
          {successInpop && successInpop !== "" && (
            <div style={{ fontSize: "12px" }} className="mob-margin">
              {successInpop ? successInpop : ""}
            </div>
          )}
        </div>
        {showSuccessOk || showSuccessOk === undefined ? (
          <div className="px-1 pt-2 pb-2 text-center">
            <Button
              size="small"
              variant="contained"
              className="bg-lumen"
              onClick={() => onClose()}
              style={{ width: "50%" }}
            >
              Ok
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Dialog>
  );
};

export default Success;
