import React, { useState, useEffect, useReducer, useRef } from "react";
import Contentloading from "./FDHContentloading";
import "./css/MSTdatatab.css";
import axios from "axios";
import * as XLSX from "xlsx";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import ModalWhite from "../ModalWhite/ModalFDH";
import Modalerror from "../ModalError/ErrorModal";
import { API_KEY, BASE_API_URL } from "../environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload , faImage, faL} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import ModalFopReviewCase from "../ModalWhite/ModalFopReviewCase";
import ModalImageUpload from "../ModalWhite/ModalImage";
import ModalReviewConfirm from "../ModalWhite/ModalReviewConfirm";
import ModalDelete from "../ModalWhite/ModalDelete";
import {  headersImage } from "../headers";
const initialState = [];
const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
  headers: {
    "apikey": process.env.REACT_APP_API_KEY,
    "sessionId" : sessionId,
    "encryptedId":encryptedId
  }
}
const FDHdatatab = (data) => {
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  const [mstlevelreason, setmstlevelreason] = useState("");
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // const [isChecked , setIschecked] = useState(false);
  const[showloader,setshowloader] = useState(false);
  const [showmodalimg, setShowmodalimage] = useState(false);
  const [showLevelConfirm, setshowLevelConfirm] = useState(false);
  var [imageBase64, setImageBase64]= useState("");
  const [status, setStatus] = useState("Pending");
  const [showmodaldel, setModaldelete] = useState(false);
  var [viewimageupload,setactiveviewimage] = useState(false);
  var [imageURLdata, setImageURL] = useState({});
  var username = sessionStorage.getItem("Name");
  const Cuid = sessionStorage.getItem("encryptedKey");
  const[mstlevelissue,setmstlevelissue]= useState("");
  const [postData, setPostData] = useState([]);
  const[message,setMessage]= useState();
  const navigate = useNavigate();
  const [tabData, setTabData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [getIndex, setIndex] = useState("");
  const [showdelaudit, setshowdeleteaudit] = useState(false);
  const[modalcase,setmodalcase]= useState(false);
  const [fdhToggle, setfdhToggle] = useState(false);
  const [filter, setfilter] = useState([]);
  const [enableEdit, setenableEdit] = useState(false);
  const[errorMessageCase,setErrorMessageCase] = useState("");
  const[caseId,setcaseId]= useState("");
  const [allProperties, setAllproperties] = useState([]);
  const role = sessionStorage.getItem("Role");
  const [saveToggle, setSaveToggle] = useState(false);
  const [o2Updated, setO2Updated] = useState(false);
  const [ndsUpdated, setNdsUpdated] = useState(false);
  const [hideNds, setHideNds] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputcomments, setInputComments] = useState("");
  const sessionId = sessionStorage.getItem("userId");
  const tableRef = useRef(null);
  // const [cmsStand, setCmsStand] = useState([]);
  useEffect(() => {
      setTabData(data.nodata);  
  }, [data.nodata]);
  useEffect(() => {
    if (data.tdata == "") {

    }
  }, []);

  useEffect(() => {
    checkaccess();
    setO2Updated(false);
    setNdsUpdated(false);
    setSelectedOption("");
    setInputComments("");
    if (data.tdata != "") {
      console.log(data.tdata);
      setAllproperties(Object.keys(data.tdata[0]));
      setfdhToggle(true);
      getImageUpload(data.tdata)
      setTabData(data.tdata);
      setfilter(data.tdata);
      handleChange();
      setInputComments(data.reviewcomments.faComment);
      // setCmsStand(() => {
      //   const convertedData = data.tdata.reduce((acc, item) => {
      //     const { id, cmsStand, splitterLeg } = item;
      //     if (!acc[id]) {
      //       acc[id] = {};
      //     }

      //     if (!acc[id][splitterLeg]) {
      //       acc[id][splitterLeg] = { cmsStand };
      //     }

      //     return acc;
      //   }, {});

      //   return convertedData;
      // });
    }

    if (data.loading == true) {
      setfdhToggle(false);
    }

    if (data.tdata == "") {
      setfilter([]);
    }
  }, [data.tdata]);
  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setshowdeleteaudit(true);
        break;
      case "Field Operations":
      case "SRO":
      case "Cable Technician":
      case "Structure Load":
        setenableEdit(true);
        break;
      case "Facility Support":
      case "View Only":
        setenableEdit(false);
        break;
      case "Field Technician":
        setenableEdit(true);
        break;
      case "Engineer":
        navigate("/");
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   console.log(cmsStand);
  // }, [cmsStand]);

  const cancelData = () => {
    setPostData([]);
    var arrdata = data.tdata;
    arrdata.map((d, rowIndex) => {
      const cms = document.getElementById(
        "deviceType" + "-" + d.id + "-" + rowIndex
      );
      cms.value = "";
      const executiveCommands = document.getElementById(
        "remarks" + "-" + d.id + "-" + rowIndex
      );
      executiveCommands.value = "";
    });
    setSaveToggle(false);
  };
  const handleChangedropdown = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    setmstlevelissue(selectedValue);
    // Clear input value when a new option is selected
    setInputValue("");
    setMessage("");
  };
  const handleInputChange = (e) => {
    const uppercaseValue = e.target.value.replace(/[^A-Z=0-9\s]/g, "");
    setInputValue(uppercaseValue);
  };
  const handleInputComments = (e) => {
    const comments = e.target.value;
    setInputComments(comments);
  };
  const handleCheckboxChange = (e) => {
    const selectedvalue = e.target.value;
    if (selectedvalue === "O2 Updated") {
      setO2Updated(selectedvalue);
      setNdsUpdated("");
    } else if (selectedvalue === "Nds Updated") {
      setO2Updated("");
      setNdsUpdated(selectedvalue);
    }
  };
  const handleCaseClose = ( e,
    row,
    rowIndex,
    ) => {
    if(row.action !== ""  ){
      setMessage("");
     if(row.action !== "Fiber Recovery Referral" && row.action !== "Central Office Referral"){
    setshowloader(true);
    var payload;
    if(row.action === "Engineer Referral"){
    payload=  {
      "Action": "Update Owner",
      "caseId": row.issueDevice,
    } 
  }else{
    payload=  {
      "Action": "Update Status",
			"caseId": row.issueDevice,
			"Status": "Closed", 
			"Reason": row.action
    } 
  }
    fetch(process.env.REACT_APP_BASE_API_SF_URL, {
      method: "POST", // or 'GET' depending on your API endpoint
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((datas) => {
        setshowloader(false);
        setmodalcase(true);
        var jsonString = datas;
        setErrorMessageCase(jsonString.Message);
        setcaseId(jsonString.CaseNumber);
      })
    .catch((error) => {
      console.log(error,"check");
      setshowloader(false);
      if (error.response) {
        if (error.response.status === 404) {
          setShowerror(true);
        }
        if (
          error.response.status === 400 ||
          error.response.status === 502 ||
          error.response.status === 500
        ) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }else{
    setMessage("Case update for this action are not available");
  }
  } else {
    setMessage("Please select Action");
  }
}
const handleLevelClose = () => {
  // if(selectedOption !== ""){
    // if(selectedOption !== "Fiber Recovery Referral" && selectedOption !== "Central Office Referral"){
  setMessage("");
  setshowloader(true);
  var payload =  {
    "Action": "Update Status",
    "caseId": data.reviewdata.faIssueDevice,
    "Status": "Closed",
    "Reason": "Closing a parent case",
}
  // if(selectedOption === "Engineer Referral"){
  //   payload=  {
  //     "Action": "Update Owner",
  //     "caseId": data.reviewdata.mstIssueDevice,
  //   } 
  // } else {
  //   payload=  {
	// 		"Action": "Update Status",
	// 		"caseId": data.reviewdata.mstIssueDevice,
	// 		"Status": "Closed",
	// 		"Reason": selectedOption,
  // }
  // }
  fetch(process.env.REACT_APP_BASE_API_SF_URL, {
    method: "POST", // or 'GET' depending on your API endpoint
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((datas) => {
      setshowloader(false);
      setmodalcase(true);
      var jsonString = datas;
      setErrorMessageCase(jsonString.Message);
      setcaseId(jsonString.CaseNumber);
      data.handlecase(true);
    })
  .catch((error) => {
    console.log(error,"check");
    setshowloader(false);
    if (error.response) {
      if (error.response.status === 404) {
        setShowerror(true);
      }
      if (
        error.response.status === 400 ||
        error.response.status === 502 ||
        error.response.status === 500
      ) {
        setShowerror(true);
      }
    } else {
      setShowerror(true);
    }
  });
// }else{
//   setMessage("Case update for this action are not available");
// }
// } else {
//   setMessage("Please select Action");
// }
}
  const saveData = (value) => {
    var updatedData;
    const propertiesToRemove = ["splitterLegStatus", "rowIndex", "id"];
    if (postData.length > 0) {
      updatedData = postData.map((item) => {
        const newItem = { ...item };
        propertiesToRemove.forEach((prop) => delete newItem[prop]);
        return newItem;
      });
    } else {
      updatedData = [null];
    }

    console.log(updatedData);

    // const transformedData = updatedData.reduce((acc, curr) => {
    //   const { id, ...rest } = curr;
    //   if (!acc[id]) {
    //     acc[id] = [];
    //   }

    //   acc[id].push(rest);
    //   console.log(acc)
    //   return acc;
    // }, {});

    // console.log(transformedData);
    // const o2user = o2Updated ? username : "";
    // const ndsuser = ndsUpdated ? username : "";
    var fdhname = data.reviewdata.deviceName
      ? data.reviewdata.deviceName
      : "NA";
    var commentspost = inputcomments === null ? "" : inputcomments;
    const apiUrl =
      process.env.REACT_APP_BASE_API_URL +
      "/postSplitterView?" +
      "fdhName=" +
      fdhname +
      "&user=" +
      username +
      "&faComment=" +
      commentspost +
      "&faStatus=";

    axios
      .post(apiUrl, updatedData, headers)
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          if (inputValue !== "") {
            data.handleStatus("PENDING", "mstpass");
            setInputValue("");
          } else {
            data.handleItemClick(null, null, "save");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };
  
  const resolveFiberCut = (value) => {
    handleLevelClose();
    var updatedData;
    const propertiesToRemove = ["splitterLegStatus", "rowIndex", "id"];
    if (postData.length > 0) {
      updatedData = postData.map((item) => {
        const newItem = { ...item };
        propertiesToRemove.forEach((prop) => delete newItem[prop]);
        return newItem;
      });
    } else {
      updatedData = [];
    }

    console.log(updatedData);

    // const transformedData = updatedData.reduce((acc, curr) => {
    //   const { id, ...rest } = curr;
    //   if (!acc[id]) {
    //     acc[id] = [];
    //   }

    //   acc[id].push(rest);
    //   console.log(acc)
    //   return acc;
    // }, {});

    // console.log(transformedData);
    // const o2user = o2Updated ? username : "";
    // const ndsuser = ndsUpdated ? username : "";
    var fdhname = data.reviewdata.deviceName
      ? data.reviewdata.deviceName
      : "NA";
    var commentspost = inputcomments === null ? "" : inputcomments;
    const apiUrl =
      process.env.REACT_APP_BASE_API_URL +
      "/postSplitterView?" +
      "fdhName=" +
      fdhname +
      "&user=" +
      username +
      "&faComment=" +
      commentspost +
      "&faStatus=Resolved" +
      "&deviceType=FDH" ;

    axios
      .post(apiUrl, updatedData, headers)
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          if (inputValue !== "") {
            data.handleStatus("PENDING", "mstpass");
            setInputValue("");
          } else {
            data.handleItemClick(null, null, "save");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };


  // const handleField = (e, row, rowIndex, property, from) => {
  //   let newData = {
  //     ...row,
  //     rowIndex: rowIndex,
  //     cmsStand: document.getElementById(
  //       "CMS_STRAND" + "-" + row.id + "-" + rowIndex
  //     ).value,

  //     executiveCommands: e.target.value,
  //   };

  //   setSaveToggle(true);
  //   console.log(newData);
  //   setTimeout(
  //     setPostData((prevstate) => {
  //       let bool = false;
  //       prevstate.map((item, i) => {
  //         if (
  //           i === rowIndex &&
  //           item.id === row.id &&
  //           row.splitterLeg === item.splitterLeg
  //         ) {
  //           bool = true;
  //         }
  //       });

  //       console.log(bool);
  //       if (bool) {
  //         return [
  //           ...prevstate.slice(0, rowIndex),
  //           newData,
  //           ...prevstate.slice(rowIndex + 1),
  //         ];
  //       } else {
  //         return [...prevstate, newData];
  //       }
  //     }),
  //     100
  //   );
  // };

  // console.log(comments, "comments");

  useEffect(() => {
    console.log(postData);
  }, [postData]);
  // const handlecheckbox = (event)=>{
  //   setIschecked(!isChecked);
  // }
  const handleChangeCms = (e, row, rowIndex, property, from) => {
    setErrorMessage("");
    setMessage("");
    const value = e.target.value;
    console.log(row);
    setSaveToggle(true);
    let newData;
    var data = "";

    row[property] = e.target.value;
    if (from == "e") {
      newData = {
        ...row,
        rowIndex: rowIndex,
        comment: e.target.value,
        // remarks: document.getElementById(
        //   "remarks" + "-" + row.id + "-" + rowIndex
        // ).value,
      };
    }
    // else if (from == "e") {
    //   newData = {
    //     ...row,
    //     rowIndex: rowIndex,
    //     faReason: document.getElementById(
    //       "faReason" + "-" + row.id + "-" + rowIndex
    //     ).value,

    //     remarks: e.target.value,
    //   };
    // }
    let bool = false;
    // newData[property] = e.target.value;
    setTimeout(
      setPostData((prevstate) => {
        const existingEntry = prevstate.findIndex(
          (entry) => entry.id === row.id && row.olt === entry.olt
        );

        if (existingEntry !== -1) {
          return [
            ...prevstate.slice(0, existingEntry),

            newData,

            ...prevstate.slice(existingEntry + 1),
          ];
        } else {
          return [...prevstate, newData];
        }
      }),

      100
    );

    // if (from == "e") {
    //   data = row[property];
    //   const remarks = document.getElementById(
    //     "remarks" + "-" + row.id + "-" + rowIndex
    //   );
    //   remarks.value = data;
    //   setErrorMessage(
    //     "Please Enter Valid Input, Special Characters are not allowed"
    //   );
    // } else if (from == "c") {
    //   data = row[property];
    //   const device = document.getElementById(
    //     "deviceType" + "-" + row.id + "-" + rowIndex
    //   );
    //   device.value = data;
    //   setErrorMessage(
    //     "Please Enter Valid Input, Special Characters are not allowed"
    //   );
    // }

    console.log(newData);
  };

  // console.log(cmsData, "cmsdtaa");

  const handleDataFromChild = () => {
    setModaldelete(false)
    setWhitemod(false);
  };

  const includesWithNullCheck = (inputString, targetSubstring) => {
    return (
      typeof inputString === "string" &&
      typeof targetSubstring === "string" &&
      (inputString.includes(targetSubstring) || inputString === targetSubstring)
    );
  };

  const handleChange = (e) => {
    const filteredData = data.tdata;
    console.log(filteredData);
    setfilter([...filteredData]);
    // console.log(e.target.value);
    // console.log(data.tdata);
    // setIndex(e.target.value);

    // if (e.target.value != "0") {
    //   const filteredData = data.tdata.filter(
    //     (item) => item.id == e.target.value
    //   );
    //   setfilter([...filteredData]);
    // }
  };

  useEffect(() => console.log(filter), [filter]);
  const togglePopover = () => {
    // setIsPopoverOpen(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  //excel new code
  const exportToExcel = () => {
    const datas = data.tdata;
    const capital = (obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toUpperCase()] = obj[key];
      }
      return newObj;
    };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "FOP_FDH_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  const handleNdsHide = () => {
    setHideNds(!hideNds);
  };
  const handleCloseCase =()=>{
    setmodalcase(false);
  }
  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const handleImageClose = () => {
    setShowmodalimage(false);
  };
  const handleImageBase = (data) => {
      setImageBase64(data)
  };
  const handleImagePass = () => {
    setShowmodalimage(false);
  };
  const getImageUpload = (data) => {
    axios.get(
      process.env.REACT_APP_BASE_API_IMAGE_URL +"/get?fileName=" + data[0].mst , headersImage
    )
    .then(response => {  
      const promiseResult = response.data[0].imageBase64;
      setactiveviewimage(true);
      setImageURL(promiseResult);  
    })  
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };
  const handleDelete = () =>{
    setshowLevelConfirm(true);
  }
  const handlecloseLevelmodal = () => {
    setshowLevelConfirm(false);
  };
  const handleLevelconfirm = () => {
    handlecalldelete()
    setshowLevelConfirm(false);
  };
  const handlecalldelete = ()=>{
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/deleteAuditData?'+ '&deviceType=mst' + '&mst=' +  data.reviewdata.deviceName;
    axios
    .delete(apiUrl, headers)
    .then((response) => {
      setModaldelete(true);
      setPostData([]);
      setfilter([]);
      setAllproperties([])
      data.handleclear('clear');
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          setShowerror(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }
  
  return (
    <>
     {showLevelConfirm && (
        <ModalReviewConfirm
          closePopUps={handlecloseLevelmodal}
          confirmModules={handleLevelconfirm}
        />
      )}
     {showmodalimg && <ModalImageUpload data={data.tdata[0].mst}
     imagerole = {viewimageupload}
     imageurl = {imageURLdata}
    onDataFromChild={handleImagePass}
        closemodissue={handleImageClose}  
        imgbase64 = {handleImageBase}
      />}
       {showloader && <Loader />}
      {showwhitemodal && <ModalWhite onDataFromChild={handleDataFromChild} />}
      {showmodaldel && <ModalDelete  onDataFromChild={handleDataFromChild} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
     {modalcase && (
        <ModalFopReviewCase errormsg={errorMessageCase} close={handleCloseCase} caseId={caseId} />
      )}
      {errorMessage && (
        <div className="d-flex justify-content-center">
          <span style={{ color: "red" }}>{errorMessage}</span>
        </div>
      )}
       <span style={{ color: "red" }}>{message}</span>
      <div className="dropDown_container dropDown_container_mob mob-flex-wrap">
        <div className="dropDown_1 dropDown_12 dropdown-mob-fop">
          <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
          <div className="mst-status-level range-select-mob  mst-status-level-mob">
              { filter.length > 0 && enableEdit && (
                <><div className="fdh-comment-flex set-flex-direc">
                  <div className="issue-fdh-field">
                  <div>
                    FDH: &nbsp;{" "}
                    <span>
                      {" "}
                      <b>{data.reviewdata.deviceName}</b>
                    </span>
                  </div>
                  <div>
                    FDH Issue: &nbsp;{" "}
                    <span>
                      {" "}
                      <b>{data.reviewdata.fdhWise}</b>
                    </span>
                  </div>
                  {data.reviewdata.fiberCutRange && (
                     <div>
                     Fibercut Range: &nbsp;{" "}
                     <span>
                       {" "}
                       <b>{data.reviewdata.fiberCutRange}</b>
                     </span>
                   </div>
                  )}
                   {data.reviewdata.fdhWise === "Fiber Cut" && (
                     <div>
                      Case Number: &nbsp;{" "}
                     <span>
                       {" "}
                       <b>{data.reviewdata.faIssueDevice}</b>
                     </span>
                   </div>
                  )}
                 
                   {data.reviewdata.fdhWise === "Fiber Cut" && (
                    data.mstCase.Status === "Closed" ? (
                      <>
                    </>
                    ) : (
                    <>
                      <div>
                        Case Status: {data.mstCase.Status}&nbsp; <span></span>
                      </div>
                    </>
                    )  
                  )}
                 
                  </div>
                  <div className="comments">
                
              </div>
              <div className="">
              
            </div>
            <div className="mob-dropDown_2 tab-margin-button-set-fop">
              
              <button onClick={exportToExcel} className="excelbuttonfdh">
                Export{" "}
                <span className="hidden-mob">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </button>
            </div>
            <div className="mob-dropDown_2 tab-margin-button-set-fop">
            <button
                    className="excelbuttonfdh"
                    id="saveBtn"
                    onClick={saveData}
                  >
                    Save
                  </button></div>
            
              </div>
                  {/* <div>
                    MST Level Issue: &nbsp;{" "}
                    <span>
                      {data.reviewdata.mstWise ? data.reviewdata.mstWise : "NA"}
                    </span>
                  </div>
                  {data.reviewdata.mstWise && (
                    <>
                      <div>
                        Case No: {data.mstCase.CaseNumber}&nbsp; <span></span>
                      </div>
                    </>
                  )}
                   {data.reviewdata.mstWise && (
                    <>
                      <div>
                        Case Status: {data.mstCase.Status}&nbsp; <span></span>
                      </div>
                    </>
                  )} */}
                </>
              )}
            </div>
          </div>
          </div>
          {/* <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
            {data.reviewdata.fdhWise === "Fiber Cut"  && (
              <>
                <div className="range-select-fop range-select-fop-mob">
                  <label className="reason-name">Action</label>
                  <select
                    className="range-options range-select-mobile"
                    id="options"
                    value={selectedOption}
                    onChange={handleChangedropdown}
                  >
                    <option value="">Select</option>
                    <option value="Engineer Referral">Engineer Referral</option>
                    <option value="Fiber Recovery Referral">Fiber Recovery Referral</option>
                    <option value="Central Office Referral">Central Office Referral</option>
                    <option value="MST Found / Placed">
                    MST Found / Placed
                    </option>
                    <option value="Splicing Completed">Splicing Completed</option>
                    <option value="Light Issue Resolved">Light Issue Resolved</option>
                    <option value="MST Replaced">MST Replaced </option>
                    <option value="Light Issue Resolved">MST Access Resolved</option>               
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
            <div>
              {data.reviewdata.fdhWise === "Fiber Cut"  && enableEdit && (
                <button
                  className="excelbutton-update"
                  id="saveBtn"
                  onClick={handleLevelClose}
                >
                  Update Status
                </button>
              )}
            </div>
          </div> */}
        </div>

        {filter.length > 0 && (
          <>
            {enableEdit && (
              <>
                {/* <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
                  <button className="cancelBtn" onClick={cancelData}>
                    Cancel
                  </button>
                </div> */}
                {/* <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
          {filter.length > 0 && showdelaudit && (
            <button className="excelbutton" onClick={handleDelete}>
              Delete
            </button>
          )}
        </div> */}
                {/* <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set">
                  <button
                    className="excelbutton"
                    id="saveBtn"
                    onClick={saveData}
                  >
                    Save
                  </button>
                </div> */}
              </>
            )}

            
            { viewimageupload === true && (
              <>
              <div className="mob-dropDown_2 tab-margin-button-set-fop">
            <button className="excelbutton" onClick={handleImageShow}>
              View &nbsp;
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faImage} />
              </span>
            </button>
            </div></>
            )}
            
            
          </>
        )}
     

      <div className="scrollable-tbody-container" width="85%">
        <table className="tabletab scrollable-table" ref={tableRef}>
          {data.tdata != "" && filter != "" ? (
            <>
              <thead style={{ position: "sticky", top: "0" }}>
                <tr>
                  {data != "" &&
                    allProperties.map((columnName) =>
                      columnName === "optiTap" ? null : hideNds &&
                        columnName.startsWith("nds") ? null : (
                        <th
                          className={`tabhead ${
                            columnName.length > 13
                              ? "widthdata200"
                              : "widthdata150"
                          }`}
                          key={columnName}
                          style={{'width':'19rem'}}
                        >
                          {columnName.toUpperCase()}
                        </th>
                      )
                    )}
                  {/* <th className={`tabhead widthdata200`}>Update Status</th> */}
                </tr>
              </thead>

              <tbody>
                {filter != "" &&  tabData.length>0 ? (
                  filter.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      <tr key={rowIndex}>
                        {allProperties.map((propty) => {
                          if (propty === "optiTap") {
                            return null;
                          } else if (hideNds && propty.startsWith("nds")) {
                            return null;
                          } else {
                            return <td key={propty}>{row[propty]}</td>;
                          }
                        })}
                        {/* <td >
                          {
                          row.issueDevice !== "" ? (
                              <>
                               {enableEdit && <button onClick={(e) =>
                                    handleCaseClose(
                                      e,
                                      row,
                                      rowIndex,
                                    )
                                  }>Update</button>}
                              </>
                          ) : (
                            <>
                            </>
                          )
                          }
                        </td> */}
                      </tr>

                      {/* ))} */}
                    </React.Fragment>
                  ) )
                  ):(
                    <>
                    <tr>
                    <td colSpan="12">
                        Data not available
                        </td>
                      <td colSpan="12">
                        Data not available
                        </td>
                        <td colSpan="12">
                        Data not available
                        </td>
                      </tr>
                      </>
                   )
                  
                  }
              </tbody>
            </>
          ) : (
            <>
              {!data.loading ? (
                <div
                  style={{
                    // display: "inline",

                    alignItems: "center",

                    fontSize: "17px",

                    color: "#011e60",

                    fontWeight: "bolder",

                    marginTop: "50px",
                  }}
                >
                  <>
                    {!data.errorreview == "" ? (
                      <>{data.errorreview}</>
                    ) : (
                      <>Data not found in FDH</>
                    )}
                  </>
                </div>
              ) : (
                <Contentloading />
              )}
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default FDHdatatab;
