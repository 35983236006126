import "./App.css";
import "./Search.css";
import "./Searchaudit.css";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import FDHnearby from "./assets/svg/Hub.svg";
import Bellicon from "./assets/svg/Bell.svg";
import Usericon from "./assets/svg/Account.svg";
import Lumenlogo from "./assets/Lumen Logo White.png";
import Location from "./assets/svg/Location.svg";
import FeedbackIcon from "./assets/feedbackIcon.png";
import FeedReviewIcon from "./assets/feedbackReviewIcon.png"
import Statistics from "./assets/svg/Statistics.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  faSearch,
  faLocationDot,
  faComment,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Topbar from "./topbar";
import axios from "axios";
import Modalerror from "./ModalError/ErrorModal";
import { API_KEY, BASE_API_URL } from "./environment";
// import { headers } from "./headers";
import ModalFeedback from "./ModalWhite/ModalFeedback";
import FeedbackReview from "./Feedback/FeedbackReview";

function SearchFdhMst(props) {
const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const navigate = useNavigate();
  const storedName = sessionStorage.getItem("Name");
  const Cuid = sessionStorage.getItem("encryptedKey");
  
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [wirecentfdh, setwirecentfdh] = useState("");
  const [wirecentFDHlist, setWirecentFDHList] = useState("");
  const [resMessage, setresMessage] = useState("No data available");
  const [resMessageDTN, setresMessageDTN] = useState("No data available");
  const [resMessagemst, setresMessagemst] = useState("No data available");
  const [inputValue, setInputValue] = useState("");
  const [inputAddress, setInputAddress] = useState("");
  const [inputCableName, setInputCableName] = useState("");
  const role = sessionStorage.getItem("Role");
  const [wirecentmst, setwirecentmst] = useState("");
  const [mstaddress, setmstaddress] = useState("");
  const [OntInputvalue, setOntInputvalue] = useState("");
  const [dtnInputvalue, setDtnInputvalue] = useState("");
  const [wirecentMSTlist, setWirecentMSTList] = useState("");
  const [mstaddresslist, setmstAddresslist] = useState("");
  const [inputValueMst, setInputValueMst] = useState("");
  const [inputValueMstAddress, setInputValueMstAddress] = useState("");
  const [toggleErrModal, setToggleErrModal] = useState(false);
  const [activemst, setactiveMST] = useState(false);
  const [access, setaccessuser] = useState({
    accessfdhaudit: true,
    accessmstaudit: true,
    accessontaudit: true,
    accessfdhreview: true,
    accessfieldops: true,
    accessmstreview: true,
    accessontreview: true,
    accessmstinstall: true,
    accesssuper: false,
    accessfeedreview: false,
    accessaddress:false,
    accessactivation: false
  });
  const[isHovered, setIsHovered] = useState(false);
  const[isFeedHovered, setIsFeedHovered] = useState(false);
  const [isAddHovered, setIsAddHovered] = useState(false);
  const [activeont, setactiveONT] = useState(false);
  const [activedtn, setactiveDTN] = useState(false);
  const [activefdh, setactiveFDH] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [filteredFdhAddSuggestions, setFilteredFdhAddSuggestions] = useState(
    []
  );
  const [filteredFdhCableSuggestions, setFilteredFdhCableSuggestions] =
    useState([]);
  const [errmessage, setErrorMessage] = useState(false);
  const [msterrmessage, setmstErrorMessage] = useState(false);
  const [filteredMSTSuggestions, setFilteredMSTSuggestions] = useState([]);
  const [filteredONTSuggestions, setFilteredONTSuggestions] = useState([]);
  const [filteredDTNSuggestions, setFilteredDTNSuggestions] = useState([]);

  const callLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const callAccess = () => {
    navigate("/access");
  };
  const handleErrModal = () => {
    setToggleErrModal(false);
  };
 
  const handlefeedreview = () => {
    navigate("/feedbackreview");
  };

  const handleaddaddress = () => {
    navigate("/addressadd/NA/NA");
  };

  useEffect(() => {
    if (storedName == null) {
      navigate("/");
    }
    switch (role) {
      case "Super Admin":
        setaccessuser({ ...access, accesssuper: true, accessaddress: true, accessfeedreview:true,
           accessactivation:true });
        break;
      case "Field Technician":
        setaccessuser({ ...access, accessontreview: false });

        break;
      case "Field Operations":

      case "Facility Support":

      case "SRO":

      case "Structure Load":
        setaccessuser({
          ...access,
          accessfdhreview: true,
          accessmstreview: true,
          accessontreview: false,
          accessmstinstall: true,
        });
        break;
      case "Cable Technician":
        setaccessuser({
          ...access,
          accessfdhaudit: true,
          accessmstaudit: true,
          accessontaudit: true,
          accessfdhreview: true,
          accessmstreview: true,
          accessontreview: false,
          accessmstinstall: true,
          accessfieldops: true,
        });
        break;
      case "Engineer":
        setaccessuser({
          accessfdhaudit: false,
          accessmstaudit: true,
          accessontaudit: false,
          accessfdhreview: false,
          accessmstreview: true,
          accessontreview: false,
          accessmstinstall: true,
          accessfieldops: false,
        });
        break;
      case "BSW Order Desk":
        setaccessuser({
          accessfdhaudit: false,
          accessmstaudit: true,
          accessontaudit: false,
          accessfdhreview: false,
          accessmstreview: false,
          accessontreview: false,
          accessmstinstall: true,
          accessfieldops: false,
        });
        break;
        case "Address Add":
          setaccessuser({
            accessfdhaudit: false,
            accessmstaudit: false,
            accessontaudit: false,
            accessfdhreview: false,
            accessmstreview: false,
            accessontreview: false,
            accessmstinstall: false,
            accessfieldops: false,
            accessaddress: true
          })

      default:
        break;
    }
  }, []);

  const focusWire = () => {
    document.getElementById("fdhwire").focus();
  };
  const focusWireMST = () => {
    document.getElementById("mstwire").focus();
  };
  const focusFDH = () => {
    document.getElementById("fdhInput").focus();
  };
  const focusMST = () => {
    document.getElementById("mstInput").focus();
  };
  const focusMSTAddress = () => {
    document.getElementById("mstInputAddress").focus();
  };
  const focusFDHAddress = () => {
    document.getElementById("fdhAddress").focus();
  };
  const focusFDHCableName = () => {
    document.getElementById("fdhCableName").focus();
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    setInputAddress("");
    setInputCableName("");
    setErrorMessage("");
    var wire = "";
    if (activefdh === true) {
      wire = "&wirecenter=" + wirecentfdh;
    } else {
      wire = "";
    }

    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(inputValue)) {
      if (inputValue.length >= 2) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getFdh?startWith=" +
              inputValue +
              wire,
            headers
          )
          .then((response) => {
            const data = response.data;
            setFilteredSuggestions(data);
            console.log(filteredSuggestions, "sugg");
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
                setFilteredSuggestions([]);
              }
            } else {
              setToggleErrModal(true);
              setFilteredSuggestions([]);
            }
          });
      } else {
        setFilteredSuggestions([]);
      }
    } else {
      setInputValue("");
      setFilteredSuggestions([]);
      setErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };
  const handleChangeFdhAddress = (e) => {
    const inputValue = e.target.value;
    setInputAddress(inputValue);
    setInputValue("");
    setInputCableName("");
    setErrorMessage("");
    var wire = "";
    if (activefdh === true) {
      wire = "&wirecenter=" + wirecentfdh;
    } else {
      wire = "";
    }

    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(inputValue)) {
      if (inputValue.length >= 2) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getFdh?startWith=" +
              "&address=" +
              inputValue +
              wire,
            headers
          )
          .then((response) => {
            const data = response.data;
            setFilteredFdhAddSuggestions(data);
            console.log(filteredFdhAddSuggestions, "sugg");
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
                setFilteredFdhAddSuggestions([]);
              }
            } else {
              setToggleErrModal(true);
              setFilteredFdhAddSuggestions([]);
            }
          });
      } else {
        setFilteredFdhAddSuggestions([]);
      }
    } else {
      setInputAddress("");
      setFilteredFdhAddSuggestions([]);
      setErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };
  const handleChangeFdhCable = (e) => {
    const inputValue = e.target.value;
    setInputCableName(inputValue);
    setInputAddress("");
    setInputValue("");
    setwirecentfdh("");
    setErrorMessage("");
    // var wire = "";
    // if (activefdh === true) {
    //   wire = "&wirecenter=" + wirecentfdh;
    // } else {
    //   wire = "";
    // }

    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(inputValue)) {
      if (inputValue.length >= 2) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getDistrCblName?cableNameSW=" +
              inputValue,
            headers
          )
          .then((response) => {
            const data = response.data;
            setFilteredFdhCableSuggestions(data);
            console.log(filteredFdhCableSuggestions, "sugg");
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
                setFilteredFdhCableSuggestions([]);
              }
            } else {
              setToggleErrModal(true);
              setFilteredFdhCableSuggestions([]);
            }
          });
      } else {
        setFilteredFdhCableSuggestions([]);
      }
    } else {
      setInputCableName("");
      setFilteredFdhCableSuggestions([]);
      setErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };

  const navigateReport = () => {
    navigate("/report");
    console.log("sdd");
  };

  const handleChangeMST = (e) => {
    const inputValue = e.target.value;
    setInputValueMst(inputValue);
    setmstaddress("");
    console.log(inputValue);
    setmstErrorMessage("");
    var wire = "";
    if (activemst === true) {
      wire = "&wirecenter=" + wirecentmst;
    }
    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(inputValue)) {
      if (inputValue.length >= 2) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getMst?mst=" +
              inputValue +
              wire,
            headers
          )
          .then((response) => {
            const data = response.data;
            setFilteredMSTSuggestions(data);
            console.log(filteredSuggestions, "sugg");
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
                setFilteredMSTSuggestions([]);
              }
            } else {
              setToggleErrModal(true);
              setFilteredMSTSuggestions([]);
            }
          });
      } else {
        setFilteredMSTSuggestions([]);
      }
    } else {
      setInputValueMst("");
      setFilteredMSTSuggestions([]);
      setmstErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };
  const handleChangeMSTAddress = (e) => {
    const mstaddressvalue = e.target.value;
    setmstaddress(mstaddressvalue);
    console.log(mstaddressvalue);
    setInputValueMst("");
    setwirecentmst("");
    setFilteredMSTSuggestions([]);
    setInputValueMstAddress("");
    setmstErrorMessage("");
    setFilteredMSTSuggestions([]);
   
    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(mstaddressvalue)) {
      if (mstaddressvalue.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getServiceAddress?serviceAddress=" + mstaddressvalue, headers
          )
          .then((response) => {
            const data = response.data;
            setmstAddresslist(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setWirecentMSTList([]);
      }
    } else {
      setmstaddress("");
      setmstAddresslist([]);
      // setWirecentMSTList([]);
      setmstErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };


  const handleSelectSuggestion = (suggestion) => {
    setInputValue(suggestion.fdh);
    setactiveFDH(false);
    var fdhAddress = suggestion.fdhAddress ? suggestion.fdhAddress : "NA";
    var latitude = suggestion.latitude ? suggestion.latitude : "NA";
    var longitude = suggestion.longitude ? suggestion.longitude : "NA";
    var fdhportCount = suggestion.fdhPortCount ? suggestion.fdhPortCount : "NA";
    navigate(
      "/main/" +
        suggestion.fdh +
        "/" +
        latitude +
        "/" +
        longitude +
        "/" +
        fdhAddress +
        "/" +
        fdhportCount
    );
  };
  const handleSelectAddressSuggestion = (suggestion) => {
    setInputAddress(suggestion.fdh);
    setactiveFDH(false);
    var fdhAddress = suggestion.fdhAddress ? suggestion.fdhAddress : "NA";
    var latitude = suggestion.latitude ? suggestion.latitude : "NA";
    var longitude = suggestion.longitude ? suggestion.longitude : "NA";
    var fdhportCount = suggestion.fdhPortCount ? suggestion.fdhPortCount : "NA";
    navigate(
      "/main/" +
        suggestion.fdh +
        "/" +
        latitude +
        "/" +
        longitude +
        "/" +
        fdhAddress +
        "/" +
        fdhportCount
    );
  };
  const handleSelectCableSuggestion = (suggestion) => {
    const fdhname = suggestion.FDH;
    setInputCableName(suggestion.CABLE_NAME);
    setactiveFDH(false);
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFdh?startWith=" +
          fdhname  ,
        headers
      )
      .then((response) => {
        const data = response.data;
        console.log("jncjns", data);
        var fdhAddress = data.fdhAddress ? data.fdhAddress : "NA";
        var latitude = data.latitude ? data.latitude : "NA";
        var longitude = data.longitude ? data.longitude : "NA";
        var fdhportCount = data.fdhPortCount ? data.fdhPortCount : "NA";
        navigate(
          "/main/" +
            fdhname +
            "/" +
            latitude +
            "/" +
            longitude +
            "/" +
            fdhAddress +
            "/" +
            fdhportCount
        );
      })
      .catch((e) => {
        if (e.response) {
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
          }
        } else {
          setToggleErrModal(true);
        }
      });
  };
  const handleSelectmst = (suggestion) => {
    var inputstring = suggestion.mst;

    var fdhstring = suggestion.fdh ? suggestion.fdh : "NA";
    setactiveMST(false);
    var mstPortCount = suggestion.mstPortCount ? suggestion.mstPortCount : "NA";
    setInputValueMst(inputstring);
    navigate("/mstmain/" + inputstring + "/" + fdhstring + "/" + mstPortCount);
  };
  const handleWirecentFDH = (e) => {
    const mstinputValue = e.target.value;
    setwirecentfdh(mstinputValue);
    setFilteredSuggestions([]);
    setInputValue("");
    setErrorMessage("");
    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(mstinputValue)) {
      if (mstinputValue.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getWirecenter?fdhWirecenter=" +
              mstinputValue ,
            headers
          )
          .then((response) => {
            const data = response.data;
            setWirecentFDHList(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setWirecentFDHList([]);
      }
    } else {
      setwirecentfdh("");
      setWirecentFDHList([]);
      setErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };
  const handleWirecentMST = (e) => {
    const mstinputValue = e.target.value;
    setwirecentmst(mstinputValue);
    setFilteredMSTSuggestions([]);
    setInputValueMst("");
    setmstaddress("");
    setmstErrorMessage("");
    setFilteredMSTSuggestions([]);
    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(mstinputValue)) {
      if (mstinputValue.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getWirecenter?mstWirecenter=" +
              mstinputValue ,
            headers
          )
          .then((response) => {
            const data = response.data;
            setWirecentMSTList(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setWirecentMSTList([]);
      }
    } else {
      setwirecentmst("");
      setWirecentMSTList([]);
      setmstErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };

  const handleSearchMSTTp = (e) => {
    const ontval = e.target.value;
    setOntInputvalue(ontval);
    setresMessagemst("Loading");
    setFilteredONTSuggestions([]);
    setFilteredDTNSuggestions([]);
    setDtnInputvalue("");
    if (/^[a-zA-Z0-9\s=\/,.-]*$/.test(ontval)) {
      if (ontval.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getOnt?mstTp=" +
              ontval ,
            headers
          )
          .then((response) => {
            const data = response.data;
            setFilteredONTSuggestions(data);
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status === 404) {
                setresMessagemst("No data available");
              }
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setFilteredONTSuggestions([]);
      }
    } else {
      setOntInputvalue("");
      setFilteredONTSuggestions([]);
      setmstErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };
  const handleSearchDTN = (e) => {
    const dtnval = e.target.value;
    console.log(setDtnInputvalue);
    setDtnInputvalue(dtnval);
    setresMessageDTN("Loading");
    setOntInputvalue("");
    setFilteredONTSuggestions([]);
    if (/^[0-9]+$/.test(dtnval)) {
      if (dtnval.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getOnt?dtn=" +
              dtnval,
            headers
          )
          .then((response) => {
            const data = response.data;
            setFilteredDTNSuggestions([]);

            setFilteredDTNSuggestions(response.data);
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status === 404) {
                setresMessageDTN("No data available");
                setFilteredDTNSuggestions([]);
              }
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setFilteredDTNSuggestions([]);
      }
    } else {
      setDtnInputvalue("");
      setFilteredDTNSuggestions([]);
      setmstErrorMessage(
        "Please Enter Valid Input, Special Characters and Alphabets are not allowed"
      );
    }
  };

  const handlemstreview = () => {
    navigate("/mstreview");
  };
  const handleFieldops = () => {
    navigate("/fopreview");
  };
  const handleConduitField = () => {
    navigate("/conduit");
  };
  const handlereview = () => {
    navigate("/review");
  };
  const handleOpsreview = () => {
    navigate("/fdhfopreview");
  };

  const handleontreview = () => {
    navigate("/ontreview");
  };
  const handleLbview = () => {
    window.location.href = "https://www.landbasesystems.net/CLCO.html";
  };

  const handleWireSelectSuggestion = (suggestion) => {
    var inputstring = suggestion;
    setwirecentfdh(inputstring);
    setactiveFDH(true);
    setWirecentFDHList([]);
  };
  const handleMSTSelectSuggestion = (suggestion) => {
    var inputstring = suggestion;
    setwirecentmst(inputstring);
    setactiveMST(true);
    setWirecentMSTList([]);
  };
  
 const handleSelectmstaddress = (suggestion) => {
  var inputstring = suggestion.mst;
  setactiveMST(false);
  setmstaddress(suggestion.customerAddress);
  axios
  .get(process.env.REACT_APP_BASE_API_URL + "/getMst?mst=" + inputstring  , headers)
  .then((response) => {
    const data = response.data;
    var fdhstring = data.fdh ? data.fdh : "NA";
    var mstPortCount = data.mstPortCount ? data.mstPortCount : "NA";
    navigate("/mstmain/" + inputstring + "/" + fdhstring + "/" + mstPortCount);
  })
  .catch((e) => {
    if (e.response) {
      if (
        e.response.status === 400 ||
        e.response.status === 502 ||
        e.response.status === 500
      ) {
        
      }
    } else {
      setToggleErrModal(true);
      
    }
  });
 
};
  const handlemsttpSelectSuggestion = (suggestion) => {
    var mststring = suggestion;
    setOntInputvalue(mststring);
    setactiveONT(true);
    setFilteredONTSuggestions([]);
    navigate("/ontmain/" + "NA" + "/" + mststring);
  };
  const handledtnSelectSuggestion = (suggestion) => {
    var dtnstring = suggestion;
    setOntInputvalue(dtnstring);
    setactiveONT(true);
    setFilteredONTSuggestions([]);
    navigate("/ontmain/" + dtnstring + "/" + "NA" + "/NA");
  };
  const [activeTab, setactiveTab] = useState(false);
  const [activeontTab, setactiveontTab] = useState(false);
  const [showdrop, setShowDropDown] = useState(false);

  const changeTab = (value) => {
    if (value == "FDH") {
      setactiveTab(true);
      setactiveontTab(false);
    } else if (value == "MST") {
      setactiveTab(false);
      setactiveontTab(false);
    } else {
      setactiveTab(false);
      setactiveontTab(true);
    }
  };
  const handleLogout = () => {
    setShowDropDown(!showdrop);
  };
  const handleFeedbackClick = () => {
    setShowFeedbackModal(true);
  };
  const handleFeedbackClose = () => {
    setShowFeedbackModal(false);
  };

  return (
    <>
      <div className="search-cont">
        <div className="row">
          <div className="col-lg-1 margin-nav hidden-mob"></div>
          <div className="col-lg-5 margin-nav mob-col-10 tab-col-10">
            <div className="">
              <img
                className="logocontent tab-lumen-img mob-lumen-img"
                src={Lumenlogo}
              ></img>
            </div>
          </div>
          <div className="col-lg-4 margin-nav hidden-mob hidden-tab"></div>
          <div className="col-lg-1 margin-nav mob-col-2 tab-col-2">
            <div className="d-flex flex-row mob-user-icon tab-user-icon">
            {/* {access.accesssuper && ( */}
              {access.accesssuper && <div className="feedback_rev">
                    <div
                      onClick={handlefeedreview}
                      onMouseEnter={()=> setIsHovered(true)}
                      onMouseLeave={()=> setIsHovered(false)}
                      className="feedback_message_icon_rev"
                    >
                      <img
                        className="feedIcnRev"
                        src={FeedReviewIcon}
                        alt=""
                      ></img>
                      {isHovered && <div className="hover-text-feed">Feedback Review</div>}
                    </div>
                  </div>}
              

              <div className="feedback">
                <div
                  onClick={handleFeedbackClick}
                  onMouseEnter={()=> setIsFeedHovered(true)}
                      onMouseLeave={()=> setIsFeedHovered(false)}
                  className="feedback_message_icon"
                >
                  <img className="feedIcn" src={FeedbackIcon} alt=""></img>
                  {isFeedHovered && <div className="hover-text-feed">Feedback</div>}
                </div>
                
                {showFeedbackModal && (
                  <ModalFeedback onClose={handleFeedbackClose} />
                )}
              </div>
              {
                access.accessaddress && 
                            <div className="address_add">
                
                   
                   <div
                  onMouseEnter={() => setIsAddHovered(true)}
                  onMouseLeave={() => setIsAddHovered(false)}
                  onClick={handleaddaddress}
                  className="address_add_icon_search"
                >
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="addaddress"
                  />

                  {isAddHovered &&  (
                    <div className="hover-text-feed">Address Add</div>
                  )}
                </div>
                
                
              </div>
                }

              <div
                className="mob-user-icon-main tab-user-icon-main"
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
              >
                <img className="user-i-mob" src={Usericon}></img>

                {showdrop && (
                  <div className="dropdown mob-dropdown">
                    <div
                      className="dropdown-content mob-dropdown-content"
                      style={{ color: "black" }}
                    >
                      {role == "Super Admin" && (
                        <>
                          <a onClick={callAccess}>Manage Access</a>
                        </>
                      )}

                      <a onClick={callLogout}>Log out</a>
                    </div>
                  </div>
                )}
              </div>
              <div></div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mb-1 mob-welc-cont">
          <div className="d-flex flex-column content-text mob-subwel-cont tab-subwel-cont">
            <div className="title-wel mob-title-wel">Welcome &nbsp;</div>
            <div className="title-name">
              <b>{storedName}</b>
            </div>
            <div className="title-name">{role}</div>
          </div>
        </div>
        <div className="card-tab mob-card tabl-card">
          <div className="d-flex justify-content-around">
            {access.accessfdhaudit && (
              <div
                onClick={() => changeTab("FDH")}
                style={{ cursor: "pointer" }}
                className={
                  activeTab
                    ? "box-active mob-box tab-box"
                    : "box mob-box tab-box"
                }
              >
                FDH
              </div>
            )}

            {access.accessmstaudit && (
              <div
                onClick={() => changeTab("MST")}
                style={{ cursor: "pointer" }}
                className={
                  !activeTab && !activeontTab
                    ? "box-active mob-box tab-box"
                    : "box mob-box tab-box"
                }
              >
                MST
              </div>
            )}
            {access.accessontaudit && (
              <div
                onClick={() => changeTab("ONT")}
                style={{ cursor: "pointer" }}
                className={
                  activeontTab
                    ? "box-active mob-box tab-box"
                    : "box mob-box tab-box"
                }
              >
                ONT
              </div>
            )}
          </div>
        </div>
        {activeTab && (
          <>
            <div className="search-tab mt-4 mob-search-area tab-search-area ">
              <div className="d-flex justify-content-around">
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusWire}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="wirecentinp mob-wirecentinp"
                    placeholder="Filter FDH CLLI by WireCenter "
                    id="fdhwire"
                    autoComplete="off"
                    value={wirecentfdh}
                    onChange={handleWirecentFDH}
                  ></input>
                  {wirecentfdh && wirecentFDHlist?.length > 0 ? (
                    <ul className="wire-search mob-wire-search tab-wire-search">
                      <span>
                        {wirecentFDHlist.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() =>
                              handleWireSelectSuggestion(suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </span>
                    </ul>
                  ) : !activefdh &&
                    (wirecentfdh != null || wirecentfdh != "") ? (
                    <>
                      {!activefdh && wirecentfdh && (
                        <ul className="fdh-search">
                          <>
                            <span>
                              <li>No data available</li>
                            </span>
                          </>
                        </ul>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="borderline mob-borderline tab-borderline"></div>
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusFDHAddress}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="fdhinp mob-fdhinp"
                    placeholder="Search by FDH Physical Address "
                    value={inputAddress}
                    autoComplete="off"
                    id="fdhAddress"
                    onChange={handleChangeFdhAddress}
                  ></input>
                  <>
                    {inputAddress && filteredFdhAddSuggestions?.length > 0 ? (
                      <ul className="fdh-search mob-fdh-search tab-fdh-search">
                        <span>
                          {filteredFdhAddSuggestions.map((suggestion) => (
                            <li
                              key={suggestion.fdhAddress}
                              onClick={() =>
                                handleSelectAddressSuggestion(suggestion)
                              }
                            >
                              {suggestion.fdhAddress}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : inputAddress &&
                      (inputAddress != null || inputAddress != "") ? (
                      <>
                        <ul className="fdh-search">
                          {inputAddress && (
                            <>
                              <span>
                                <li>No data Available</li>
                              </span>
                            </>
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
                <div className="borderline mob-borderline tab-borderline"></div>
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusFDH}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="fdhinp mob-fdhinp"
                    placeholder="Search by FDH CLLI"
                    value={inputValue}
                    autoComplete="off"
                    id="fdhInput"
                    onChange={handleChange}
                  ></input>
                  <>
                    {inputValue && filteredSuggestions?.length > 0 ? (
                      <ul className="fdh-search mob-fdh-search tab-fdh-search">
                        <span>
                          {filteredSuggestions.map((suggestion) => (
                            <li
                              key={suggestion.fdh}
                              onClick={() => handleSelectSuggestion(suggestion)}
                            >
                              {suggestion.fdh}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : inputValue &&
                      (inputValue != null || inputValue != "") ? (
                      <>
                        <ul className="fdh-search">
                          {inputValue && (
                            <>
                              <span>
                                <li>No data Available</li>
                              </span>
                            </>
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
                <div className="borderline mob-borderline tab-borderline"></div>
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusFDHCableName}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="fdhinp mob-fdhinp"
                    placeholder="Search by Distribution Cable"
                    value={inputCableName}
                    autoComplete="off"
                    id="fdhCableName"
                    onChange={handleChangeFdhCable}
                  ></input>
                  <>
                    {inputCableName &&
                    filteredFdhCableSuggestions?.length > 0 ? (
                      <ul className="fdh-search mob-fdh-search tab-fdh-search">
                        <span>
                          {filteredFdhCableSuggestions.map((suggestion) => (
                            <li
                              key={suggestion.CABLE_NAME}
                              onClick={() =>
                                handleSelectCableSuggestion(suggestion)
                              }
                            >
                              {suggestion.CABLE_NAME}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : inputCableName &&
                      (inputCableName != null || inputCableName != "") ? (
                      <>
                        <ul className="fdh-search">
                          {inputCableName && (
                            <>
                              <span>
                                <li>No data Available</li>
                              </span>
                            </>
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>
            </div>

            {errmessage && (
              <div className="d-flex mt-2 justify-content-center">
                <span style={{ color: "red" }}>{errmessage}</span>
              </div>
            )}

            <div className="sub-tab mt-4">
              <div className="d-flex justify-content-around">
                {access.accessfdhreview && (
                  <div
                    className="box-Contone"
                    onClick={handlereview}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={FDHnearby} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">Review FDH</span>
                      </div>
                    </div>
                  </div>
                )}
                {access.accessfieldops && (
                  <div
                    className="box-Contone"
                    onClick={handleOpsreview}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={FDHnearby} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">Field OPS Review</span>
                      </div>
                    </div>
                  </div>
                )}
                  <div className="box-Contone">
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={Location} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">LB Viewer Mobile</span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </>
        )}

        {!activeTab && !activeontTab && (
          <>
            <div className="search-tab mt-4 mob-search-area tab-search-area">
              <div className="d-flex justify-content-around">
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusWireMST}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="wirecentinp mob-wirecentinp"
                    placeholder="Filter MST name by WireCenter"
                    value={wirecentmst}
                    autoComplete="off"
                    id="mstwire"
                    onChange={handleWirecentMST}
                  ></input>
                  {wirecentmst && wirecentMSTlist?.length > 0 ? (
                    <ul className="wire-search mob-wire-search tab-wire-search">
                      <span>
                        {wirecentMSTlist.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() =>
                              handleMSTSelectSuggestion(suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </span>
                    </ul>
                  ) : !activemst &&
                    (wirecentmst != null || wirecentmst != "") ? (
                    <>
                      {!activemst && wirecentmst && (
                        <ul className="fdh-search">
                          <>
                            <span>
                              <li>No data available</li>
                            </span>
                          </>
                        </ul>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="borderline mob-borderline tab-borderline"></div>
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusMST}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="fdhinp mob-fdhinp"
                    placeholder="Search by MST name"
                    id="mstInput"
                    autoComplete="off"
                    value={inputValueMst}
                    onChange={handleChangeMST}
                  ></input>

                  <>
                    {inputValueMst && filteredMSTSuggestions?.length > 0 ? (
                      <ul className="fdh-search mob-fdh-search tab-fdh-search">
                        <span>
                          {filteredMSTSuggestions.map((suggestion) => (
                            <li
                              key={suggestion.mst}
                              onClick={() => handleSelectmst(suggestion)}
                            >
                              {suggestion.mst}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : inputValueMst &&
                      (inputValueMst != null || inputValueMst != "") ? (
                      <>
                        <ul className="fdh-search">
                          {inputValueMst && (
                            <>
                              <span>
                                <li>No data available</li>
                              </span>
                            </>
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
                <div className="borderline mob-borderline tab-borderline"></div>
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusMSTAddress}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="fdhinp mob-fdhinp"
                    placeholder="Search by Service Address "
                    id="mstInputAddress"
                    autoComplete="off"
                    value={mstaddress}
                    onChange={handleChangeMSTAddress}
                  ></input>

                  <>
                    {mstaddress && mstaddresslist?.length > 0 ? (
                      <ul className="wire-search mob-wire-search tab-wire-search">
                        <span>
                          {mstaddresslist.map((suggestion) => (
                            <li
                              key={suggestion.customerAddress}
                              onClick={() => handleSelectmstaddress(suggestion)}
                            >
                              {suggestion.customerAddress}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : !activemst &&
                      (mstaddress != null || mstaddress != "") ? (
                      <>
                        {!activemst && mstaddress && (
                          <ul className="fdh-search">
                            <>
                              <span>
                                <li>No data available</li>
                              </span>
                            </>
                          </ul>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>
            </div>
            {msterrmessage && (
              <div className="d-flex mt-2 justify-content-center">
                <span style={{ color: "red" }}>{msterrmessage}</span>
              </div>
            )}

            <div className="sub-tab mt-4">
              <div className="d-flex justify-content-around flexwrap">
                {access.accessmstreview && (
                  <div
                    className="box-Contone"
                    onClick={handlemstreview}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={FDHnearby} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">Review MST</span>
                      </div>
                    </div>
                  </div>
                )}
                {access.accessfieldops && (
                  <div
                    className="box-Contone"
                    onClick={handleFieldops}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={FDHnearby} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">Field OPS Review</span>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="box-Contone"
                  onClick={handleConduitField}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex flex-column">
                    <div>
                      <img className="searchImg" src={FDHnearby} alt=""></img>
                    </div>
                    <div>
                      <span className="navtitle">Conduit Extension Review</span>
                    </div>
                  </div>
                </div>
                <div
                  className="box-Contone"
                  onClick={() => navigateReport()}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex flex-column">
                    <div>
                      <img className="searchImg" src={Statistics} alt=""></img>
                    </div>
                    <div>
                      <span className="navtitle">Installation Report</span>
                    </div>
                  </div>
                </div>{" "}
                  <div className="box-Contone">
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={Location} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">LB Viewer Mobile</span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </>
        )}

        {activeontTab && (
          <>
            <div className="search-tab mt-4 mob-search-area tab-search-area">
              <div className="d-flex justify-content-around">
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusWireMST}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="wirecentinp mob-wirecentinp"
                    placeholder="Search MST TP "
                    value={OntInputvalue}
                    autoComplete="off"
                    id="mstwire"
                    onChange={handleSearchMSTTp}
                  ></input>
                  {OntInputvalue && filteredONTSuggestions?.length > 0 ? (
                    <ul className="wire-search mob-wire-search tab-wire-search">
                      <span>
                        {filteredONTSuggestions.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() =>
                              handlemsttpSelectSuggestion(suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </span>
                    </ul>
                  ) : !activeont &&
                    (OntInputvalue != null || OntInputvalue != "") ? (
                    <>
                      {!activeont && OntInputvalue && (
                        <ul className="fdh-search">
                          <>
                            <span>
                              <li>{resMessagemst}</li>
                            </span>
                          </>
                        </ul>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="borderline mob-borderline tab-borderline"></div>
                <div
                  className="boxInp mob-boxInp tab-boxInp"
                  style={{ cursor: "pointer" }}
                  onClick={focusMST}
                >
                  <div class="search-container mob-search-cont">
                    <div class="search-icon mob-search-icon">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <input
                    className="fdhinp mob-fdhinp"
                    placeholder="Search DTN"
                    maxLength={12}
                    id="mstInput"
                    autoComplete="off"
                    value={dtnInputvalue}
                    onChange={handleSearchDTN}
                  ></input>

                  <>
                    {dtnInputvalue && filteredDTNSuggestions?.length > 0 ? (
                      <ul className="fdh-search mob-fdh-search tab-fdh-search">
                        <span>
                          {filteredDTNSuggestions.map((suggestion, i) => (
                            <li
                              key={i}
                              onClick={() =>
                                handledtnSelectSuggestion(suggestion)
                              }
                            >
                              {suggestion}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : dtnInputvalue &&
                      (dtnInputvalue != null || dtnInputvalue != "") ? (
                      <>
                        <ul className="fdh-search">
                          {dtnInputvalue && (
                            <>
                              <span>
                                <li>{resMessageDTN}</li>
                              </span>
                            </>
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>
            </div>
            {msterrmessage && (
              <div className="d-flex mt-2 justify-content-center">
                <span style={{ color: "red" }}>{msterrmessage}</span>
              </div>
            )}
            <div className="sub-tab mt-4">
              <div className="d-flex justify-content-around">
                {access.accessontreview && (
                  <div
                    className="box-Contone"
                    onClick={handleontreview}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={FDHnearby} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">Review ONT</span>
                      </div>
                    </div>
                  </div>
                )}

                  <div className="box-Contone">
                    <div className="d-flex flex-column">
                      <div>
                        <img className="searchImg" src={Location} alt=""></img>
                      </div>
                      <div>
                        <span className="navtitle">LB Viewer Mobile</span>
                      </div>
                    </div>
                  </div>
              
              </div>
            </div>
          </>
        )}
      </div>

      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
    </>
  );
}

export default SearchFdhMst;
