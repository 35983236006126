import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Modaloptitap.css";

const Modaloptitap = ({ close, allowdata, onDatafromOpti }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  console.log(onDatafromOpti);
  //   const closeFunction = () => {
  //     props.action();
  //   };
  const handleClickCancel = () => {
    const dataToSend = false;
    close(dataToSend);
  };
  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = { value: selectedValue, status: false };

    // Call the function received from the parent with the data
    onDatafromOpti(dataToSend);
  };
  const handleClickitem = () => {
    const dataToSend = {
      value: "",
      status: false,
    };
    onDatafromOpti(dataToSend);
  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modals">
              <div className="d-flex justify-content-end">
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickitem}
                />
              </div>

              {allowdata == false ? (
                <>
                  <div
                    style={{
                      color: "#000",
                      padding: "30px 40px",
                      fontSize: "20px",
                    }}
                  >
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/> */}
                    Please Select the MST Splitter Port Size
                  </div>

                  <div className="radio-container-optitap">
                    <label className="radio-label-optitap">
                      <input
                        type="radio"
                        value="2"
                        checked={selectedValue === "2"}
                        onChange={handleRadioChange}
                      />
                      <span className="radio-value-optitap">2</span>
                    </label>
                    <label className="radio-label-optitap">
                      <input
                        type="radio"
                        value="4"
                        checked={selectedValue === "4"}
                        onChange={handleRadioChange}
                      />
                      <span className="radio-value-optitap">4</span>
                    </label>
                    <label className="radio-label-optitap">
                      <input
                        type="radio"
                        value="8"
                        checked={selectedValue === "8"}
                        onChange={handleRadioChange}
                      />
                      <span className="radio-value-optitap">8</span>
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      color: "#000",
                      padding: "30px 40px",
                      fontSize: "20px",
                    }}
                  >
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/> */}
                    Please select the full audit checkbox and fill out the
                    Onfield Information
                  </div>
                </>
              )}
            </div>
            {allowdata == false ? (
              <div className="modal-bottom">
                <button className="alert-bottoms" onClick={handleClick}>
                  OK
                </button>
              </div>
            ) : (
              <div className="modal-bottom">
                <button className="alert-bottoms" onClick={handleClickCancel}>
                  OK
                </button>
              </div>
            )}
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modaloptitap;
