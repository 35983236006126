import React, { useEffect } from "react";
import Topbar from "../topbar";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import "./AddressSearch.css";
import AddressAddImg from "../assets/AddressAdd.png";
import SearchAddImg from "../assets/SearchAddress.png";
import { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Modalerror from "../ModalError/ErrorModal";
import axios from "axios";
import ModalSuccessAdd from "../ModalWhite/ModalAddresssuccess";


const AddressSearch = () => {
const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  var stateList = [
    { value: "AZ", label: "AZ" },
    { value: "CO", label: "CO" },
    { value: "FL", label: "FL" },
    { value: "IA", label: "IA" },
    { value: "ID", label: "ID" },
    { value: "IN", label: "IN" },
    { value: "MN", label: "MN" },
    { value: "MT", label: "MT" },
    { value: "ND", label: "ND" },
    { value: "NE", label: "NE" },
    { value: "NM", label: "NM" },
    { value: "NV", label: "NV" },
    { value: "OR", label: "OR" },
    { value: "SD", label: "SD" },
    { value: "UT", label: "UT" },
    { value: "WA", label: "WA" },
    { value: "WI", label: "WI" },
    { value: "WY", label: "WY" },
  ];
  var preDirectionalList = [
    { value: "N", label: "N" },
    { value: "S", label: "S" },
    { value: "E", label: "E" },
    { value: "W", label: "W" },
  ];
  var postDirectionalList = [
    { value: "NW", label: "NW" },
    { value: "NE", label: "NE" },
    { value: "SE", label: "SE" },
    { value: "SW", label: "SW" },
  ];
  var udifList = [
    { value: "Engineer", label: "Engineer" },
    { value: "NDS Job ID", label: "NDS Job ID" },
    { value: "NDS Address ID", label: "NDS Address ID" },
    { value: "SiteTracker ID", label: "SiteTracker ID" },
    { value: "Property ID", label: "Property ID" },
    { value: "RU SiteID", label: "RU SiteID" },
    { value: "Work Type", label: "Work Type" },
    { value: "Address Status", label: "Address Status" },
    { value: "Address Validated", label: "Address Validated" },
    { value: "Address Migrated", label: "Address Migrated" },
    { value: "Fiber_Drop_installed", label: "Fiber Drop installed" },
    { value: "Loc_global_Id", label: "Loc global Id" },
  ];
  var [statekeyname, setstatekeyname] = useState();
  var [communitykeyname, setCommunityKeyname] = useState();
  var [addressalias, setaddressalias] = useState();
  var [sendmessage, setsendmessage] = useState('');
  var [showmessageaddress, setshowmessageaddress] = useState(false);
  var [streetkeyname, setStreetkeyname] = useState();
  var [streetsuffixkeyname, setStreetsuffixkeyname] = useState();
  var [latitudeOption, setLatitudeOption] = useState();
  var [udifkeyname, setUdifkeyname] = useState();
  var [accessOSG, setAccessOSG] = useState("");
  const [zipcodekeyname, setZipcodekeyname] = useState();
  const[predirectionkeyname, setPredirectionkeyname] = useState();
  const [preDirectionalListitem, setpreDirectionallistitem] = useState();
  const [postDirectionalListitem, setpostDirectionallistitem] = useState();
  const[postdirectionkeyname, setPostdirectionkeyname] = useState();
  const[udifspeckeyname, setUdifspeckeyname] = useState([]);
  const[houseNumber, sethouseNumber] = useState();
  const[houseSuffix, sethouseSuffix] = useState();
  const[latitude, setLatitude] = useState("");
  const[longitude, setLongitude] = useState("");
  const[longitudeOption,setLongitudeOption] = useState();
  const[locationname, setLocation] = useState();
  const [toggleErrModal, setToggleErrModal] = useState(false);

  const [community, setCommunity] = useState([]);
  const [street, setStreet] = useState([]);
  const [streetSuffix, setStreetSuffix] = useState([]);
  const [zipcode, Setzipcode] = useState([]);
  const [udifVal, setUdifVal] = useState([]);
  const [finaludif, setfinalUdif] = useState([]);
  const [centralOfficeList, setCentralOfficeList] = useState("");
  const [central, setCentral] = useState("");
  const [mstList, setMstList] = useState("");
  const [mst, setMst] = useState("");
  const { mstname } = useParams();
  const { centralofc } = useParams();
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [errorMsg, setErrorMsg] = useState("");

useEffect(()=>{
  if(mstname != 'NA'){
    setMst(mstname);
    setCentral(centralofc)
  }
  oauthtoken();
},[])

  const oauthtoken = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_API_SAK_URL + "/oauth/token?grant_type=client_credentials",
      headers: {
        Authorization: `Basic dW40dnk5bEV1eE9mSjVKZTBHR0U2RXExU3NkZG9BcUM6Zmx5V0JDTGZEQkJuRG5iYQ==`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setAccessOSG(data.data.access_token);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
          setToggleErrModal(true);
        }
      });
  };
  const handleErrModal = () => {
    setToggleErrModal(false);
  };
  const handleSuccModal = () => {
    setshowmessageaddress(false);
  };

  const handleState = (selected) => {
    const statenamevalue = selected.value;
    console.log(selected.value);
    setstatekeyname(selected);
    setCommunityKeyname(null);
    setStreetkeyname(null);
    setStreetsuffixkeyname(null);
    setZipcodekeyname(null);
    setUdifkeyname(null);
    setUdifspeckeyname(null);

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item,
          label: item,
        }));
        setCommunity(convertedData);
        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            setToggleErrModal(true);
            setCommunity([]);
          }
        } else {
          setCommunity([]);
        }
      });
  };
  const handleCommunity = (selected) => {
    const communitynamevalue = selected.value;
    setCommunityKeyname(selected);
    console.log(selected.value);
    const statenamevalue = statekeyname.value;
    setStreetkeyname(null);
    setStreetsuffixkeyname(null);
    setZipcodekeyname(null);
    setUdifkeyname(null);
    setUdifspeckeyname(null);

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue +
        "&community=" +
        communitynamevalue,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item,
          label: item,
        }));
        setStreet(convertedData);
        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            setToggleErrModal(true);
            setStreet([]);
          }
        } else {
          setStreet([]);
        }
      });
  };
  const handleStreetName = (selected) => {
    const streetnamevalue = selected.value;
    setStreetkeyname(selected);
    console.log(selected.value);
    const statenamevalue = statekeyname.value;
    const communitynamevalue = communitykeyname.value;

    setStreetsuffixkeyname(null);
    setZipcodekeyname(null);
    setUdifkeyname(null);
    setUdifspeckeyname(null);

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue +
        "&community=" +
        communitynamevalue +
        "&streetName=" +
        streetnamevalue,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item,
          label: item,
        }));
        setStreetSuffix(convertedData);
        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            setToggleErrModal(true);
            setStreetSuffix([]);
          }
        } else {
          setStreetSuffix([]);
        }
      });




      fetch(
        process.env.REACT_APP_BASE_API_URL +
          "/getAddress/centralOffice?state=" +
          statenamevalue +
          "&community=" +
          communitynamevalue +
          "&streetName=" +
          streetnamevalue,
        headers
      )
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          // const data = response.data;
          // const resdata = data;
          setCentralOfficeList(data);
          // console.log(resdata, "sugg");
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            if (error.status === 400 || error.status === 404) {
              console.log("error");
              setToggleErrModal(true);
              setCentralOfficeList([]);
            }
          } else {
            setCentralOfficeList([]);
          }
        });
  };
  const handleStreetSuffix = (selected) => {
    const streetsuffixnamevalue = selected.value;
    setStreetsuffixkeyname(selected);
    console.log(selected.value);
    const statenamevalue = statekeyname.value;
    const communitynamevalue = communitykeyname.value;
    const streetnamevalue = streetkeyname.value;
    setZipcodekeyname(null);

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue +
        "&community=" +
        communitynamevalue +
        "&streetName=" +
        streetnamevalue +
        "&streetSuffix=" +
        streetsuffixnamevalue,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item,
          label: item,
        }));
        Setzipcode(convertedData);
        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            setToggleErrModal(true);
            Setzipcode([]);
          }
        } else {
          Setzipcode([]);
        }
      });
      handleprefixlist(streetsuffixnamevalue);
      handlepostprefixlist(streetsuffixnamevalue);
  };
  
  const handleprefixlist = (streetsuffixnamevalue) => {
    const statenamevalue = statekeyname.value;
    const communitynamevalue = communitykeyname.value;
    const streetnamevalue = streetkeyname.value;

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue +
        "&community=" +
        communitynamevalue +
        "&streetName=" +
        streetnamevalue +
        "&streetSuffix=" +
        streetsuffixnamevalue + 
        "&dirCode=preDir"
        ,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item,
          label: item,
        }));
        setpreDirectionallistitem(convertedData);
       
        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            setToggleErrModal(true);
            Setzipcode([]);
          }
        } else {
          Setzipcode([]);
        }
      });
  };
  const handlepostprefixlist = (streetsuffixnamevalue) => {
    const statenamevalue = statekeyname.value;
    const communitynamevalue = communitykeyname.value;
    const streetnamevalue = streetkeyname.value;

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue +
        "&community=" +
        communitynamevalue +
        "&streetName=" +
        streetnamevalue +
        "&streetSuffix=" +
        streetsuffixnamevalue + 
        "&dirCode=postDir"
        ,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item,
          label: item,
        }));
        setpostDirectionallistitem(convertedData);
       
        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            setToggleErrModal(true);
            Setzipcode([]);
          }
        } else {
          Setzipcode([]);
        }
      });
  };
  
  const handleZipCode = (selected) => {
    const zipcodenamevalue = selected.value;
    setZipcodekeyname(selected);
  };
  const handlePostdirection = (selected) => {
    const postdirectionnamevalue = selected.value;
    setPostdirectionkeyname(selected);
  };
  const handlePredirection = (selected) => {
    const predirectionnamevalue = selected.value;
    setPredirectionkeyname(selected);
  };
  const handleUdifSpec = (selected) => {
    console.log(selected)
    setUdifspeckeyname(selected);
    
    const convertedData = selected.map((item) => ({
      key: item.value,
      value: item.label,
    }));

    setfinalUdif(convertedData);
    
  };
  const handleHousenumber  = (event) =>{
    sethouseNumber(event.target.value.toUpperCase());
  }
  const handleLocation  = (event) =>{
    console.log(event.target.value)
    setLocation(event.target.value.toUpperCase());
  }

  const handleHouseSuffix = (event)=>{
    sethouseSuffix(event.target.value.toUpperCase());
  }
  const handleLatitude = (e) => {
    const value = e.target.value;
    if(/^-?\d*\.?\d*$/.test(value)){
      setLatitude(value);
    }
  };

 const handleLongitude = (e) =>{
  const value = e.target.value;
  if(/^-?\d*\.?\d*$/.test(value)){
    setLongitude(value);
  }
 }

  const handleaddressalias = (event)=>{
    setaddressalias(event.target.value.toUpperCase());
  }
  const handleUdif = (selected) => {
    
    console.log(selected);
    setUdifkeyname(selected);
    const concatenatedKeys = selected.map(item => item.value).join(',');
    console.log(concatenatedKeys);
    const udifnamevalue = selected.value;
    const statenamevalue = statekeyname?.value;
    const communitynamevalue = communitykeyname?.value;
    const streetnamevalue = streetkeyname?.value;
    setUdifspeckeyname(null);

    

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddress?state=" +
        statenamevalue +
        "&community=" +
        communitynamevalue +
        "&streetName=" +
        streetnamevalue +
        "&udifKey=" +
        concatenatedKeys,
      headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        console.log(data)
        // const data = response.data;
        // const resdata = data;
        const convertedData = data.map((item) => ({
          value: item.KEY,
          label: item.VALUE,
        }));

        setUdifVal(convertedData);

        // console.log(resdata, "sugg");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          if (error.status === 400 || error.status === 404) {
            console.log("error");
            // setToggleErrModal(true);
            setUdifVal([]);
          }
        } else {
          setUdifVal([]);
        }
      });
  };

  const handleCentraloffice = (e) => {
    const centralofficevalue = e.target.value.toUpperCase();
    setCentral(centralofficevalue);
   console.log(centralofficevalue,"check",e.value)
    if (centralofficevalue.length >= 1) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            "/getAddress?state=" + statekeyname?.value  + "&centralOffice=" + 
            centralofficevalue,
          headers
        )
        .then((response) => {
          const data = response.data;
          console.log(data, "tata");
          setCentralOfficeList(data);
        })
        .catch((e) => {
          if (e.response) {
            if (
              e.response.status === 400 ||  e.response.status === 404 
            ) {
              setCentralOfficeList([]);
            }
            if (
              e.response.status === 502 ||
              e.response.status === 500
            ) {
              setToggleErrModal(true);
            }
          } else {
            
          }
        });
    } else {
      setCentralOfficeList([]);
    }
  };

  const handleSelectCentral = (suggestion) => {
    console.log(suggestion,"jel")
    var inputstring = suggestion.toUpperCase();
    setCentral(inputstring);
    setCentralOfficeList([]);
    // handleMst(inputstring);
  };

  const handleMst = (e) => {
    const mstvalue = e.target.value;
    setMst(mstvalue);
    const centralofficeval = (centralofc == 'NA')  ? central : centralofc ;

    if (mstvalue.length >= 1) {
      axios.get(process.env.REACT_APP_BASE_API_URL + "/getAddress/centralOffice" + "?mst=" +
            mstvalue + '&centralOffice=' + centralofficeval,
          headers
        )
        .then((response) => {
          const data = response.data;
          console.log(data, "mst");
          setMstList(data);
        })
        .catch((e) => {
          if (e.response) {
            if (
              e.response.status === 400 ||
              e.response.status === 502 ||
              e.response.status === 500
            ) {
              setToggleErrModal(true);
            }
          } else {
            // setToggleErrModal(true);
          }
        });
    } else {
      setMstList([]);
    }
  };

  const handleSelectMst = (suggestion) => {
    var inputstring = suggestion;
    setMst(inputstring);
    setMstList([]);
    // handleMst(inputstring);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    if (
      !statekeyname ||
      !communitykeyname ||
      !streetkeyname ||
      !mst ||
      !central /* Add conditions for other dropdowns */
    ) {
      setErrorMsg(
        "Please ensure all required fields (*) are completed before proceeding."
      );
      return; // Prevent form submission if fields are not valid
    }else if((latitude !== "" && longitude == "") || (longitude !== "" && latitude == "")){
      setErrorMsg(
        "Please ensure Lat & Long is entered"
      );
      return;
   } else {
      const data = {
        "address": {
        "houseNumber": houseNumber,
        "houseSuffix": houseSuffix,
        "streetName": streetkeyname?.value,
        "streetSuffix": streetsuffixkeyname?.value,
        "location": locationname,
        "postalZip": zipcodekeyname?.value,
        "preDirectional":predirectionkeyname?.value,
        "postDirectional":postdirectionkeyname?.value,
        "community": communitykeyname?.value,
        "provinceState": statekeyname?.value
        },
        "centralOffice": central,
        "addressAliasId": addressalias,
        "latitude": latitude,
        "longitude": longitude,
        "udifs": finaludif,
        "fibreAccessPointName": mst
        }
      axios
      .post(
        process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/addresses/accesspoints", 
      data,
      {
        headers :{
           "Content-Type": "application/json",
            Authorization: `Bearer ${accessOSG}`,
         }
       },
      )
      .then((response) => {
        const data = response.data;
        setshowmessageaddress(true);
        setsendmessage('Successfully Updated')
       console.log(data)
      })
      .catch((e) => {
        if (e.response) {
          if (
            e.response.status === 400 ||
            e.response.status === 404
          ) {
            console.log(e.response.data.message)
            setsendmessage(e.response.data.message)
            setshowmessageaddress(true)
          }
          if (
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        }
      });

    }
    // Clear form error if all fields are valid
    setErrorMsg("");
    // Proceed with form submission or other actions
  };

  const customFormatOptionLabel = ({ label }) => {
    return label.toUpperCase();
  };
  const handleInputChange = (inputValue) => {
    return inputValue.toUpperCase();
  };
  return (
    <div>
      <Topbar />
      <div className="row">
        <div className="col-2 mob-col-12"></div>
        <div className="second-nav-address">
          <div className="content-one-address">
            <div className="hidden-mob-feed">
              <Link to="/search">
                <FontAwesomeIcon
                  className="ico-nav mb-2 p-3 mob-padding-5"
                  style={{ color: "#011E60" }}
                  icon={faHome}
                />
              </Link>
            </div>
            <h6 className="feed_head">Address Add</h6>
          </div>
        </div>
      </div>
      <div className="add_outer_box_flex">
        <div className="add_outer_box">
          <div className="search_header_flex">
            <div className="search_add_img_flex">
              <img className="search_subs_img" src={SearchAddImg} alt=""></img>
            </div>
            <div className="search_subs_head">Search By Subscriber Address</div>
          </div>
          <div className="search_subs_feed">
            Locating and identifying subscriber addresses using detailed
            components such as central office, house number, directional
            indicators, street name, location, community and state.
          </div>
          <div className="add_address_img_fle">
            <div>
              {errorMsg && <div className="error-message-add">{errorMsg}</div>}
              <div className="add_detail_flex">
                <div>
                  <div className="req_field_flex">
                    <div>State</div>
                    <div className="required_field_add">*</div>
                  </div>
                  <div className="search_det_inp">
                    <Select
                      options={stateList}
                      onChange={handleState}
                      value={statekeyname}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      className="select_drop_add"
                      placeholder="Select State"
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>Community</div>
                    <div className="required_field_add">*</div>
                  </div>

                  <div className="search_det_inp">
                    <Select
                      options={community}
                      onChange={handleCommunity}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      value={communitykeyname}
                      className="select_drop_add"
                      placeholder="Select Community"
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>Street Name</div>
                    <div className="required_field_add">*</div>
                  </div>

                  <div className="search_det_inp">
                    <Select
                      options={street}
                      onChange={handleStreetName}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      value={streetkeyname}
                      className="select_drop_add"
                      placeholder="Select Street Name"
                    />
                  </div>
                </div>
              </div>
              <div className="add_detail_flex">
                <div>
                  <div className="req_field_flex">
                    <div>Street Suffix</div>
                  </div>

                  <div className="search_det_inp">
                    <Select
                      options={streetSuffix}
                      onChange={handleStreetSuffix}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      value={streetsuffixkeyname}
                      className="select_drop_add"
                      placeholder="Select Street Suffix"
                    />
                  </div>
                </div>

                <div>
                  <div className="req_field_flex">
                    <div>Pre-Directional</div>
                  </div>

                  <div className="search_det_inp">
                    <Select
                      options={preDirectionalListitem}
                        onChange={handlePredirection}
                        formatOptionLabel={customFormatOptionLabel}
                       onInputChange={handleInputChange}
                        value={predirectionkeyname}
                      className="select_drop_add"
                      placeholder="Select Pre-Directional"
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>Post-Directional</div>
                  </div>

                  <div className="search_det_inp">
                    <Select
                      options={postDirectionalListitem}
                        onChange={handlePostdirection}
                        formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                        value={postdirectionkeyname}
                      className="select_drop_add"
                      placeholder="Select Post-Directional"
                    />
                  </div>
                </div>
              </div>
              <div className="add_detail_flex">
                <div>
                  <div className="req_field_flex">
                    <div>House Number</div>
                    <div className="required_field_add">*</div>
                  </div>

                  <div className="search_det_inp">
                    <input
                      className="select_inp_add"
                      type="text"
                      value={houseNumber}
                      placeholder="Enter House Number"
                      onChange={handleHousenumber}
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>House Suffix</div>
                  </div>

                  <div className="search_det_inp">
                    <input
                      className="select_inp_add"
                      value={houseSuffix}
                      type="text"
                      onChange={handleHouseSuffix}
                      placeholder="Enter House Suffix"
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>Location</div>
                  </div>

                  <div className="search_det_inp">
                    <input
                      className="select_inp_add"
                      type="text"
                      placeholder="Enter Location"
                      value={locationname}
                      onChange={handleLocation}
                    />
                  </div>
                </div>
              </div>
              <div className="add_detail_flex">
              <div>
                  <div className="req_field_flex">
                    <div>Latitude</div>
                  </div>

                  <div className="search_det_inp">
                  <input
                      className="select_inp_add"
                      value={latitude}
                      type="text"
                      onChange={handleLatitude}
                      maxLength={10}
                      placeholder="Enter Latitude"
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>Longitude</div>
                  </div>

                  <div className="search_det_inp">
                     <input
                      className="select_inp_add"
                      value={longitude}
                      type="text"
                      maxLength={11}
                      onChange={handleLongitude}
                      placeholder="Enter Longitude"
                    />
                  </div>
                </div>
                <div>
                  <div>Zip Code</div>
                  <div className="search_det_inp">
                    <Select
                      options={zipcode}
                      onChange={handleZipCode}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      value={zipcodekeyname}
                      className="select_drop_add"
                      placeholder="Select Zip Code"
                    />
                  </div>
                </div>
                {/* <div>
                  <div>UDIF Attribute</div>
                  <div className="search_det_inp">
                    <Select
                    isMulti
                      options={udifList}
                      onChange={handleUdif}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      value={udifkeyname}
                      className="select_drop_add"
                      placeholder="Select UDIF Attribute"
                    />
                  </div>
                </div>
                <div>
                  <div>UDIF Specification</div>
                  <div className="search_det_inp">
                    <Select
                    isMulti
                      options={udifVal}
                      onChange={handleUdifSpec}
                      value={udifspeckeyname}
                      formatOptionLabel={customFormatOptionLabel}
                      onInputChange={handleInputChange}
                      className="select_drop_add"
                      placeholder="Select UDIF Specification"
                    />
                  </div>
                </div> */}
              </div>
              <div className="add_detail_flex">
              <div>
                  <div className="req_field_flex">
                    <div>Address AliasID</div>
                  </div>

                  <div className="search_det_inp">
                    <input
                      className="select_inp_add"
                      value={addressalias}
                      type="text"
                      onChange={handleaddressalias}
                      placeholder="Enter Address aliasId"
                    />
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>Central Office</div>
                    <div className="required_field_add">*</div>
                  </div>

                  <div className="search_det_inp">
                    <div>
                      <input
                        className="select_inp_add"
                        placeholder="Search Central Office"
                        value={central}
                        autoComplete="off"
                        id="centraloffice"
                        onChange={handleCentraloffice}
                      ></input>
                      {centralOfficeList?.length > 0 ? (
                        <ul className="wirecenterreview mob-wire-search tab-wire-search">
                          <span>
                            {centralOfficeList.map((suggestion) => (
                              <li
                                key={suggestion}
                                onClick={() => handleSelectCentral(suggestion)}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </span>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="req_field_flex">
                    <div>MST</div>
                    <div className="required_field_add">*</div>
                  </div>

                  <div className="search_det_inp">
                    <div>
                      <input
                        className="select_inp_add"
                        placeholder="Search MST"
                        value={mst}
                        autoComplete="off"
                        disabled={!central}
                        id="mstname"
                        onChange={handleMst}
                      ></input>
                      {mstList?.length > 0 ? (
                        <ul className="wirecenterreview mob-wire-search tab-wire-search">
                          <span>
                            {mstList.map((suggestion) => (
                              <li
                                key={suggestion}
                                onClick={() => handleSelectMst(suggestion)}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </span>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add_add_img">
              <img className="add_img_address" src={AddressAddImg} alt=""></img>
            </div>
          </div>
          <div>
            <button className="add_search_btn" onClick={handleSubmit}>
              Add Address
            </button>
          </div>
        </div>
      </div>
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
      {
        showmessageaddress &&  <ModalSuccessAdd send={sendmessage} onDataFromChild={handleSuccModal} ></ModalSuccessAdd>
      }
     
    </div>
  );
};

export default AddressSearch;