import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalCasa.css";
import { API_KEY,BASE_API_URL} from "../environment";
import axios from "axios";
import { faSearch} from "@fortawesome/free-solid-svg-icons";
const Modalcasa = ({ close,add,sendAddress}) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const handleadd = () => {
    const dataToSend = false;
    add(dataToSend);
    sendAddress(inputValue);
  };

  const handleClickCancel = () => {
    const dataToSend = false;
    close(dataToSend)
  };
  const handleChange=(e)=>{
    const inputValue =e.target.value;               
    setInputValue(inputValue);
   }
   const handleSelectSuggestion =(suggestion)=>{
    setInputValue(suggestion.full_address_txt);
    setFilteredSuggestions([]);
    // console.log(suggestion,"name");
    // var fdhAddress = suggestion.fdhAddress?suggestion.fdhAddress:'NA';
    // var latitude = suggestion.latitude?suggestion.latitude:'NA';
    // var longitude = suggestion.longitude?suggestion.longitude:'NA';
    // var fdhportCount = suggestion.fdhPortCount?suggestion.fdhPortCount:'NA'
    // nav("/main/" + suggestion.fdh + '/' + latitude + '/' + longitude + '/' + fdhAddress + '/' + fdhportCount);
   }
  const handleClearClick = () => {
    setInputValue('');
 setFilteredSuggestions([]);
  };
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal">
              <div className="m-header">
                <span>
                  <b>CASA Serial No</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: "#001f6a", height: "25px", width: "25px",cursor:"pointer" }}
                  onClick={handleClickCancel}
                />
              </div>
              <div className="modalcontent">
                <div className="m-radios-casa">
                <div class="search-container-pages">
                  <div className="s-icon-one">
                       <FontAwesomeIcon icon={faSearch} />
                  </div>

                  <input type="text" className="search-inputs" placeholder="Enter Casa Serial"  value={inputValue} onChange={handleChange}/>
                 {inputValue ? (<span className="clear-buttons-address" onClick={handleClearClick}>
                              &times;
                            </span>):( <span></span>)}
                </div>
                </div>
              </div>
              
                <button className="alert-bottom" onClick={handleadd}>
                  Update CASA Serial No
                </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modalcasa;
