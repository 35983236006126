import React, { useState, useEffect, useReducer, useRef } from "react";
import Contentloading from "./MSTContentloading";
import "./css/MSTdatatab.css";
import axios from "axios";
import * as XLSX from "xlsx";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import ModalWhite from "../ModalWhite/ModalFDH";
import Modalerror from "../ModalError/ErrorModal";
import { API_KEY, BASE_API_URL } from "../environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faImage } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ModalImageUpload from "../ModalWhite/ModalImage";
import ModalReviewConfirm from "../ModalWhite/ModalReviewConfirm";
import ModalDelete from "../ModalWhite/ModalDelete";
import { headersImage } from "../headers";
const initialState = [];

const MSTdatatab = (data) => {
 const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // const [isChecked , setIschecked] = useState(false);
  const [status, setStatus] = useState("Pending");
  var username = sessionStorage.getItem("Name");
  const Cuid = sessionStorage.getItem("encryptedKey");
  var [enableEdit, setenableEdit] = useState(false);
  const [postData, setPostData] = useState([]);
  const [tabData, setTabData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [getIndex, setIndex] = useState("");
  const [fdhToggle, setfdhToggle] = useState(false);
  const [filter, setfilter] = useState([]);
  var [viewimageupload,setactiveviewimage] = useState(false);
  var [imageURLdata, setImageURL] = useState({});
  const [allProperties, setAllproperties] = useState([]);
  const navigate = useNavigate();
  const role = sessionStorage.getItem("Role");
  const [saveToggle, setSaveToggle] = useState(false);
  const [showmodaldel, setModaldelete] = useState(false);
  const [showmodalimg, setShowmodalimage] = useState(false);
  const [showdelaudit, setshowdeleteaudit] = useState(false);
  const [showLevelConfirm, setshowLevelConfirm] = useState(false);
  const [o2Updated, setO2Updated] = useState(false);
  const [ndsUpdated, setNdsUpdated] = useState(false);
  const [hideNds, setHideNds] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputcomments, setInputComments] = useState("");
  var [imageBase64, setImageBase64]= useState("");
  const tableRef = useRef(null);
  // const [cmsStand, setCmsStand] = useState([]);
  useEffect(() => {
    if (data.tdata == "") {
      console.log("im here too");
    }
  }, []);

  useEffect(() => {
    checkaccess();
    setO2Updated(false);
    setNdsUpdated(false);
    setSelectedOption("");
    setInputComments("");
    if(data.activeIndex != ""){
      getImageUpload(data.activeIndex);
    }
    if (data.tdata != "") {
      console.log(data.tdata);
      setAllproperties(Object.keys(data.tdata[0]));
      setfdhToggle(true);
      setTabData(data.tdata);
      setfilter(data.tdata);
      handleChange();
      setInputComments(data.reviewcomments.reviewRemarks);
      // setCmsStand(() => {
      //   const convertedData = data.tdata.reduce((acc, item) => {
      //     const { id, cmsStand, splitterLeg } = item;
      //     if (!acc[id]) {
      //       acc[id] = {};
      //     }

      //     if (!acc[id][splitterLeg]) {
      //       acc[id][splitterLeg] = { cmsStand };
      //     }

      //     return acc;
      //   }, {});

      //   return convertedData;
      // });
    }

    if (data.loading == true) {
      setfdhToggle(false);
    }

    if (data.tdata == "") {
      setfilter([]);
    }
  }, [data.tdata]);

  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setshowdeleteaudit(true);
        break;
      case "Field Operations":
      case "Engineer":
      case "Cable Technician":
      case "Structure Load":
        setenableEdit(true);
        break;
      case "Facility Support":
      case "SRO":
      case "View Only":
        setenableEdit(false);
        break;
      case "Field Technician":
        setenableEdit(false);
        // navigate("/");
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   console.log(cmsStand);
  // }, [cmsStand]);

  const cancelData = () => {
    setPostData([]);
    var arrdata = data.tdata;
    arrdata.map((d, rowIndex) => {
      const cms = document.getElementById(
        "deviceType" + "-" + d.id + "-" + rowIndex
      );
      cms.value = "";
      const executiveCommands = document.getElementById(
        "remarks" + "-" + d.id + "-" + rowIndex
      );
      executiveCommands.value = "";
    });
    setSaveToggle(false);
  };
  const handleChangedropdown = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    // Clear input value when a new option is selected
    setInputValue("");
  };
  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const handleImageClose = () => {
    setShowmodalimage(false);
  };
  const handleImageBase = (data) => {
      setImageBase64(data)
  };
  const handleImagePass = () => {
    setShowmodalimage(false);
  };
  const handleInputChange = (e) => {
    const uppercaseValue = e.target.value.replace(/[^A-Z=0-9\s]/g, "");
    setInputValue(uppercaseValue);
  };
  const handleInputComments = (e) => {
    const comments = e.target.value;
    setInputComments(comments);
  };
  const handleCheckboxChange = (e) => {
    const selectedvalue = e.target.value;
    if (selectedvalue === "O2 Updated") {
      setO2Updated(selectedvalue);
      setNdsUpdated("");
    } else if (selectedvalue === "Nds Updated") {
      setO2Updated("");
      setNdsUpdated(selectedvalue);
    }
  };
  const saveData = (value) => {
    // var o2Ndsparam='';
    // if (o2Updated && ndsUpdated) {
    //   o2Ndsparam = 'O2 and NDS updated'
    //   } else if (o2Updated) {
    //     o2Ndsparam = 'O2 updated'
    //   } else if (ndsUpdated) {
    //     o2Ndsparam = 'NDS updated'
    //   } else {
    //     o2Ndsparam = 'Not updated'
    //   }

    var updatedData;
    const propertiesToRemove = ["splitterLegStatus", "rowIndex", "id"];
    if (postData.length > 0) {
      updatedData = postData.map((item) => {
        const newItem = { ...item };
        propertiesToRemove.forEach((prop) => delete newItem[prop]);
        return newItem;
      });
    } else {
      updatedData = [null];
    }

    console.log(updatedData);

    // const transformedData = updatedData.reduce((acc, curr) => {
    //   const { id, ...rest } = curr;
    //   if (!acc[id]) {
    //     acc[id] = [];
    //   }

    //   acc[id].push(rest);
    //   console.log(acc)
    //   return acc;
    // }, {});

    // console.log(transformedData);
    const o2user = o2Updated ? username : "";
    const ndsuser = ndsUpdated ? username : "";
    var mstname = data.reviewdata.deviceName
      ? data.reviewdata.deviceName
      : "NA";
    var commentspost = inputcomments === null ? "" : inputcomments;
    const apiUrl =
       process.env.REACT_APP_BASE_API_URL +
      "/postReviewComment?" +
      "reviewBy=" +
      o2user +
      "&reviewStatus=" +
      o2Updated +
      "&reviewDevice=MST" +
      "&reviewDeviceName=" +
      mstname +
      "&reviewType=" +
      selectedOption +
      "&reviewRemark=" +
      commentspost +
      "&ndsReviewStatus=" +
      ndsUpdated +
      "&ndsReviewBy=" +
      ndsuser +
      "&updatedMstName=" +
      inputValue;

    axios
      .post(apiUrl, updatedData, headers)
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          if (inputValue !== "") {
            data.handleStatus("PENDING", "mstpass");
            setInputValue("");
          } else {
            data.handleItemClick(null, null, "save");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };

  // const handleField = (e, row, rowIndex, property, from) => {
  //   let newData = {
  //     ...row,
  //     rowIndex: rowIndex,
  //     cmsStand: document.getElementById(
  //       "CMS_STRAND" + "-" + row.id + "-" + rowIndex
  //     ).value,

  //     executiveCommands: e.target.value,
  //   };

  //   setSaveToggle(true);
  //   console.log(newData);
  //   setTimeout(
  //     setPostData((prevstate) => {
  //       let bool = false;
  //       prevstate.map((item, i) => {
  //         if (
  //           i === rowIndex &&
  //           item.id === row.id &&
  //           row.splitterLeg === item.splitterLeg
  //         ) {
  //           bool = true;
  //         }
  //       });

  //       console.log(bool);
  //       if (bool) {
  //         return [
  //           ...prevstate.slice(0, rowIndex),
  //           newData,
  //           ...prevstate.slice(rowIndex + 1),
  //         ];
  //       } else {
  //         return [...prevstate, newData];
  //       }
  //     }),
  //     100
  //   );
  // };

  // console.log(comments, "comments");

  useEffect(() => {
    console.log(postData);
  }, [postData]);
  // const handlecheckbox = (event)=>{
  //   setIschecked(!isChecked);
  // }
  const handleChangeCms = (e, row, rowIndex, property, from) => {
    setErrorMessage("");
    const value = e.target.value;
    console.log(row);
    setSaveToggle(true);
    let newData;
    var data = "";
    if (/^[a-zA-Z0-9\s=]*$/.test(value)) {
      row[property] = e.target.value;
      if (from == "c") {
        newData = {
          ...row,
          rowIndex: rowIndex,
          deviceType: e.target.value,
          remarks: document.getElementById(
            "remarks" + "-" + row.id + "-" + rowIndex
          ).value,
        };
      } else if (from == "e") {
        newData = {
          ...row,
          rowIndex: rowIndex,
          deviceType: document.getElementById(
            "deviceType" + "-" + row.id + "-" + rowIndex
          ).value,

          remarks: e.target.value,
        };
      }
      let bool = false;
      // newData[property] = e.target.value;
      setTimeout(
        setPostData((prevstate) => {
          const existingEntry = prevstate.findIndex(
            (entry) => entry.id === row.id && row.MST_PORT === entry.MST_PORT
          );

          if (existingEntry !== -1) {
            return [
              ...prevstate.slice(0, existingEntry),

              newData,

              ...prevstate.slice(existingEntry + 1),
            ];
          } else {
            return [...prevstate, newData];
          }
        }),

        100
      );
    } else {
      if (from == "e") {
        data = row[property];
        const remarks = document.getElementById(
          "remarks" + "-" + row.id + "-" + rowIndex
        );
        remarks.value = data;
        setErrorMessage(
          "Please Enter Valid Input, Special Characters are not allowed"
        );
      } else if (from == "c") {
        data = row[property];
        const device = document.getElementById(
          "deviceType" + "-" + row.id + "-" + rowIndex
        );
        device.value = data;
        setErrorMessage(
          "Please Enter Valid Input, Special Characters are not allowed"
        );
      }
    }
    console.log(newData);
  };

  // console.log(cmsData, "cmsdtaa");

  const handleDataFromChild = () => {
    setWhitemod(false);
    setModaldelete(false)
  };

  const includesWithNullCheck = (inputString, targetSubstring) => {
    return (
      typeof inputString === "string" &&
      typeof targetSubstring === "string" &&
      (inputString.includes(targetSubstring) || inputString === targetSubstring)
    );
  };

  const handleChange = (e) => {
    const filteredData = data.tdata;
    console.log(filteredData);
    setfilter([...filteredData]);
    // console.log(e.target.value);
    // console.log(data.tdata);
    // setIndex(e.target.value);

    // if (e.target.value != "0") {
    //   const filteredData = data.tdata.filter(
    //     (item) => item.id == e.target.value
    //   );
    //   setfilter([...filteredData]);
    // }
  };

  useEffect(() => console.log(filter), [filter]);
  const togglePopover = () => {
    // setIsPopoverOpen(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  //excel new code
  const exportToExcel = () => {
    const datas = data.tdata;
    const capital = (obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toUpperCase()] = obj[key];
      }
      return newObj;
    };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "MST_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  const handleNdsHide = () => {
    setHideNds(!hideNds);
  };

  const getImageUpload = (data) => {
    axios.get(
      process.env.REACT_APP_BASE_API_IMAGE_URL  +"/get?fileName=" + data , headersImage
    )
    .then(response => {  
      const promiseResult = response.data[0].imageBase64;
      setactiveviewimage(true);
      setImageURL(promiseResult);  
    })  
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };
  const handleDelete = () =>{
    setshowLevelConfirm(true);
  }
  const handlecloseLevelmodal = () => {
    setshowLevelConfirm(false);
  };
  const handleLevelconfirm = () => {
    setshowLevelConfirm(false);
    handlecalldelete();
  };

console.log(data.mstLevel.mstWise,"Update mst address");
  const handlecalldelete = ()=>{
    const apiUrl =  process.env.REACT_APP_BASE_API_URL + '/deleteAuditData?'+ '&deviceType=mst' + '&mst=' +  data.reviewdata.deviceName;
    axios
    .delete(apiUrl, headers)
    .then((response) => {
      setModaldelete(true);
      setPostData([]);
      setfilter([]);
      setAllproperties([])
      data.handleItemClick('clear');
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          setShowerror(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        // setShowerror(true);
      }
    });
  }
  return (
    <>
     {showLevelConfirm && (
        <ModalReviewConfirm
          closePopUps={handlecloseLevelmodal}
          confirmModules={handleLevelconfirm}
        />
      )}
       {showmodaldel && <ModalDelete  onDataFromChild={handleDataFromChild} />}
      {showwhitemodal && <ModalWhite onDataFromChild={handleDataFromChild} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {showmodalimg && <ModalImageUpload data={data.activeIndex}
     imagerole = {viewimageupload}
     imageurl = {imageURLdata}
    onDataFromChild={handleImagePass}
        closemodissue={handleImageClose}  
        imgbase64 = {handleImageBase}
      />}

      {/* {isPopoverOpen && (
        <div className="popover-backdrop">
        <div className="popover">
          <div className="popover-content">
            <p>Please Enter the Data in Valid Field</p>
            <button onClick={togglePopover} className="popover-close-button">
              Close
            </button>
          </div>
        </div>
      </div>
      )} */}

      {errorMessage && (
        <div className="d-flex justify-content-center">
          <span style={{ color: "red" }}>{errorMessage}</span>
        </div>
      )}
      <div className="dropDown_container dropDown_container_mob mob-flex-wrap">
        <div className="dropDown_1 dropDown_12">
          {/* {filter.length > 0 && ( */}
          {data.mstLevel.mstWise==="Update MST Address"?(
            <div className="update-checkbox update-checkbox-mob">
              {/* <div class="form-check">
                <input class="m-1 reviewinp" type="checkbox"  onChange={handlecheckbox} checked={isChecked}  id="flexCheckDefault"></input>
                <label class="form-check-label" for="flexCheckDefault">
                  Completed Review
                </label>
              </div> */}
              <div class="form-check">
                <input
                  class="m-1 reviewinp"
                  type="radio"
                  value="O2 Updated"
                  onChange={handleCheckboxChange}
                  checked={o2Updated === "O2 Updated"}
                  id="flexCheckDefault"
                ></input>
                <label class="form-check-label" for="flexCheckDefault">
                  O2 Updated
                </label>
              </div>
              <div class="form-check">
                <input
                  class="m-1 reviewinp"
                  type="radio"
                  value="Nds Updated"
                  onChange={handleCheckboxChange}
                  checked={ndsUpdated === "Nds Updated"}
                  id="flexCheckDefaultone"
                ></input>
                <label class="form-check-label" for="flexCheckDefaultone">
                  Nds Updated
                </label>
              </div>
            </div>):(<>{filter.length > 0 && (
              <div className="update-checkbox update-checkbox-mob">
              {/* <div class="form-check">
                <input class="m-1 reviewinp" type="checkbox"  onChange={handlecheckbox} checked={isChecked}  id="flexCheckDefault"></input>
                <label class="form-check-label" for="flexCheckDefault">
                  Completed Review
                </label>
              </div> */}
              <div class="form-check">
                <input
                  class="m-1 reviewinp"
                  type="radio"
                  value="O2 Updated"
                  onChange={handleCheckboxChange}
                  checked={o2Updated === "O2 Updated"}
                  id="flexCheckDefault"
                ></input>
                <label class="form-check-label" for="flexCheckDefault">
                  O2 Updated
                </label>
              </div>
              <div class="form-check">
                <input
                  class="m-1 reviewinp"
                  type="radio"
                  value="Nds Updated"
                  onChange={handleCheckboxChange}
                  checked={ndsUpdated === "Nds Updated"}
                  id="flexCheckDefaultone"
                ></input>
                <label class="form-check-label" for="flexCheckDefaultone">
                  Nds Updated
                </label>
              </div>
            </div>)}</>)}
          {/* )} */}
          {/* <div>Select Splitter:</div>
          <select
            className="dropDownOption"
            id="splitterOptions"
            onChange={handleChange}
          >
            <option readonly value="0">
              Please Select{" "}
            </option>

            {data.tdata != "" &&
              data.spkeys.map((value) => {
                return <option value={value}>{value + " Splitter"}</option>;
              })}
          </select> */}
          {/* {filter.length > 0 && ( */}
            <>
           
            <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
            {filter.length > 0 && (
              <div className="range-select range-select-mob">
                <label>Range</label>
                <select
                  className="range-options range-select-mobile mob-range-wid-set"
                  id="options"
                  value={selectedOption}
                  onChange={handleChangedropdown}
                >
                  <option value="">Select</option>
                  <option value="ONT to MST">ONT to MST</option>
                  {/* <option value="Different range in the SLOC">Different range in the SLOC</option> */}
                </select>
              </div>)}
              {filter.length > 0 && selectedOption === "ONT to MST" && (
                <div className="mst-name">
                  <label>MST Name</label>
                  <input
                    className="review-mst-name"
                    type="text"
                    id="inputValue"
                    disabled={!enableEdit}
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {data.mstLevel.mstWise==="Update MST Address"?(
              <div className="comments">
                <label>Comments</label>
                <textarea
                  className="review-mst-comments"
                  id="inputcomments"
                  value={inputcomments}
                  disabled={!enableEdit}
                  onChange={handleInputComments}
                />
              </div>):(<>{filter.length > 0 && (<div className="comments">
                <label>Comments</label>
                <textarea
                  className="review-mst-comments"
                  id="inputcomments"
                  value={inputcomments}
                  disabled={!enableEdit}
                  onChange={handleInputComments}
                />
              </div>)}</>)}
              {filter.length > 0 && (
              <div class="mob-mst-toggle">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input neisltog"
                    type="checkbox"
                    onClick={handleNdsHide}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Enable Niesl
                  </label>
                </div>
              </div>)}
            </div></>
           {/* )}  */}
        </div>

                {
                  enableEdit && (
                    <>
                    <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
          {filter.length > 0 && (
            <button className="cancelBtn" onClick={cancelData}>
              Cancel
            </button>
          )}
        </div>
        <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
          {filter.length > 0 && showdelaudit && (
            <button className="excelbutton" onClick={handleDelete}>
              Delete
            </button>
          )}
        </div>
        
        <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set">
          
          {data.mstLevel.mstWise==="Update MST Address"?(<button className="excelbutton" id="saveBtn" onClick={saveData}>
              Save
            </button>):( <>{filter.length > 0 && ( 
            <button className="excelbutton" id="saveBtn" onClick={saveData}>
            Save
          </button>
           )} </>)}
        </div>
                    </>
                  )
                }
        
        {filter.length > 0 && (
          //     <DownloadTableExcel
          //     filename="users table"
          //     sheet="users"
          //     currentTableRef={tableRef.current}>
          //     <button className="excelbuttons"> Export excel </button>
          // </DownloadTableExcel>
          <div className="mob-dropDown_2 tab-margin-button-set">
            <button onClick={exportToExcel} className="excelbutton">
              Export{" "}
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faDownload} />
              </span>
            </button>
          </div>
          
        )}
        {filter.length > 0 && viewimageupload == true && (
          <div className="mob-dropDown_2 tab-margin-button-set">
            <button className="excelbutton" onClick={handleImageShow}>
              View &nbsp;
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faImage} />
              </span>
            </button>
          </div>
          
        )}

      </div>
      

      <div className="scrollable-tbody-container" width="85%">
        <table className="tabletab scrollable-table" ref={tableRef}>
          {data.tdata != "" && filter != "" ? (
            <>
              <thead style={{ position: "sticky", top: "0" }}>
                <tr>
                  {data != "" &&
                    allProperties.map((columnName) =>
                      columnName === "optiTap" ? null : hideNds &&
                        columnName.startsWith("nds") ? null : (
                        <th
                          className={`tabhead ${
                            columnName.length > 13
                              ? "widthdata200"
                              : "widthdata150"
                          }`}
                          key={columnName}
                        >
                          {columnName.toUpperCase()}
                        </th>
                      )
                    )}
                </tr>
              </thead>

              <tbody>
                {filter != "" &&
                  filter.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {/* {filter.map((row, index) => ( */}

                      <tr key={rowIndex}>
                        {allProperties.map((propty) => {
                          if (propty === "optiTap") {
                            return null;
                          } else if (hideNds && propty.startsWith("nds")) {
                            return null;
                          } else if (propty === "deviceType") {
                            return (
                              <td key={propty}>
                                <input
                                  type="text"
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  className="edit_field"
                                  defaultValue={row[propty]}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,

                                      row,

                                      rowIndex,

                                      propty,

                                      "c"
                                    )
                                  }
                                />
                              </td>
                            );
                          } else if (propty === "remarks") {
                            return (
                              <td key={propty}>
                                <textarea
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  defaultValue={row[propty]}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,
                                      row,
                                      rowIndex,
                                      propty,
                                      "e"
                                    )
                                  }
                                  style={{ width: "100%" }}
                                  // onChange={handleField}
                                />
                              </td>
                            );
                          } else {
                            return <td key={propty}>{row[propty]}</td>;
                          }
                        })}
                      </tr>

                      {/* ))} */}
                    </React.Fragment>
                  ))}
              </tbody>
            </>
          ) : (
            <>
              {!data.loading ? (
                <div
                  style={{
                    // display: "inline",

                    alignItems: "center",

                    fontSize: "17px",

                    color: "#011e60",

                    fontWeight: "bolder",

                    marginTop: "50px",
                  }}
                >
                  <>
                    {!data.errorreview == "" ? (
                      <>{data.errorreview}</>
                    ) : (
                      <>Please Select Status</>
                    )}
                  </>
                </div>
              ) : (<>{data.mstLevel.mstWise!=="Update MST Address"&& (<Contentloading />)}</>
                
              )}
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default MSTdatatab;
