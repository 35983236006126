import React from "react";
import './styling.css'

const Modalloader = () => {
  return (
    <div class="loading-area">
      <div class="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Modalloader;
