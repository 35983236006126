import React from "react";
// import "./css/MSTContentloading.css";
import "./css/ContentFop.css"

const ContentFop = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default ContentFop;
