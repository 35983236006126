import React, { useState, useEffect } from "react";

import "./css/MSTDropdown.css";

import { FaAngleDown } from "react-icons/fa";
import {BASE_API_URL} from "../environment";



const MSTDropdown = (props) => {
  
  // const [data, setData] = useState([]);

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    // fetch(BASE_API_URL + "getAuditDetailsByFDH?fdhName="+ props.FDH )
    //   .then((res) => res.json())
    //   .then((data) =>
    //   setData(data)
    //   );
  }, [props.FDH]);
  
  return (
    <>
      {/* <div
        style={{
          display: "grid",
          gridTemplateColumns: "120px 180px 10px",
          fontSize: "15px",
        }}
      >
        <div>Select Splitter:</div>

        <select className="dropDownOption">
          {data.map((value,key)=>{
            return(
              <option id="splitterOptions" value={value.o2Splitter}>{value.o2Splitter}</option>
            )
          })}
        </select>
      </div> */}
    </>
  );
};

export default MSTDropdown;
