import React, { useState, useEffect, useReducer, useRef } from "react";
import Contentloading from "./MSTContentloading";
import "./css/MSTdatatab.css";
import axios from "axios";
import * as XLSX from "xlsx";

import ModalWhite from "../ModalWhite/ModalFDH";
import Modalerror from "../ModalError/ErrorModal";
import { API_KEY, BASE_API_URL } from "../environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload , faImage, faL} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import ModalFopReviewCase from "../ModalWhite/ModalFopReviewCase";
import ModalImageUpload from "../ModalWhite/ModalImage";
import ModalReviewConfirm from "../ModalWhite/ModalReviewConfirm";
import ModalDelete from "../ModalWhite/ModalDelete";
import {  headersImage } from "../headers";
import ModalNoLightYes from "../ModalWhite/ModalNoLightYes";
const initialState = [];
const MSTdatatab = (data) => {
const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  const [mstlevelreason, setmstlevelreason] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const buttonref = useRef(null);
  
  const[showloader,setshowloader] = useState(false);
  const [showmodalimg, setShowmodalimage] = useState(false);
  const [showLevelConfirm, setshowLevelConfirm] = useState(false);
  var [imageBase64, setImageBase64]= useState("");
  const [status, setStatus] = useState("Pending");
  const [showmodaldel, setModaldelete] = useState(false);
  var [viewimageupload,setactiveviewimage] = useState(false);
  var [imageURLdata, setImageURL] = useState({});
  var username = sessionStorage.getItem("Name");
  const Cuid = sessionStorage.getItem("encryptedKey");
  const[mstlevelissue,setmstlevelissue]= useState("");
  const [postData, setPostData] = useState([]);
  const[message,setMessage]= useState();
  const navigate = useNavigate();
  var [accessOSG, setAccessOSG] = useState("");
  const [tabData, setTabData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [getIndex, setIndex] = useState("");
  const [showdelaudit, setshowdeleteaudit] = useState(false);
  const[modalcase,setmodalcase]= useState(false);
  const[saveactive,setSaveactive]= useState(false);
  const [fdhToggle, setfdhToggle] = useState(false);
  const [filter, setfilter] = useState([]);
  const [enableEdit, setenableEdit] = useState(false);
  const[errorMessageCase,setErrorMessageCase] = useState("");
  const[caseId,setcaseId]= useState("");
  const [allProperties, setAllproperties] = useState([]);
  const role = sessionStorage.getItem("Role");
  const [saveToggle, setSaveToggle] = useState(false);
  const [o2Updated, setO2Updated] = useState(false);
  const [ndsUpdated, setNdsUpdated] = useState(false);
  const [hideNds, setHideNds] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showNoLightYes, setShowNoLightYes] = useState(false);
  const [inputcomments, setInputComments] = useState("");
  const tableRef = useRef(null);

  
  useEffect(() => {
      setTabData(data.nodata);  
  }, [data.nodata]);
  useEffect(() => {
    if (data.tdata == "") {

    }
    oauthtoken();
  }, []);

  useEffect(()=>{
    if(saveactive){
saveData();
    }

  },[saveactive])

  const oauthtoken = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_API_SAK_URL + "/oauth/token?grant_type=client_credentials",
      headers: {
        Authorization: `Basic dW40dnk5bEV1eE9mSjVKZTBHR0U2RXExU3NkZG9BcUM6Zmx5V0JDTGZEQkJuRG5iYQ==`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setAccessOSG(data.data.access_token);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };

  useEffect(() => {
    checkaccess();
    setO2Updated(false);
    setNdsUpdated(false);
    setSelectedOption("");
    setInputComments("");
    if (data.tdata != "") {
      console.log(data.tdata);
      setAllproperties(Object.keys(data.tdata[0]));
      setfdhToggle(true);
      getImageUpload(data.tdata)
      setTabData(data.tdata);
      setfilter(data.tdata);
      handleChange();
      setInputComments(data.reviewcomments.faComment);
      
    }

    if (data.loading == true) {
      setfdhToggle(false);
    }

    if (data.tdata == "") {
      setfilter([]);
    }
  }, [data.tdata]);
  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setshowdeleteaudit(true);
        break;
      case "Field Operations":
      case "SRO":
      case "Cable Technician":
      case "Structure Load":
        setenableEdit(true);
        break;
      case "Facility Support":
      case "View Only":
        setenableEdit(false);
        break;
      case "Field Technician":
        setenableEdit(true);
        break;
      case "Engineer":
        navigate("/");
        break;
      default:
        break;
    }
  };


  const cancelData = () => {
    setPostData([]);
    var arrdata = data.tdata;
    arrdata.map((d, rowIndex) => {
      const cms = document.getElementById(
        "deviceType" + "-" + d.id + "-" + rowIndex
      );
      cms.value = "";
      const executiveCommands = document.getElementById(
        "comment" + "-" + d.id + "-" + rowIndex
      );
      executiveCommands.value = "";
    });
    setSaveToggle(false);
  };
  const handleChangedropdown = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    setmstlevelissue(selectedValue);
    // Clear input value when a new option is selected
    setInputValue("");
    setMessage("");
  };
  const handleInputChange = (e) => {
    const uppercaseValue = e.target.value.replace(/[^A-Z=0-9\s]/g, "");
    setInputValue(uppercaseValue);
  };
  const handleInputComments = (e) => {
    const comments = e.target.value;
    setInputComments(comments);
  };
  const handleCheckboxChange = (e) => {
    const selectedvalue = e.target.value;
    if (selectedvalue === "O2 Updated") {
      setO2Updated(selectedvalue);
      setNdsUpdated("");
    } else if (selectedvalue === "Nds Updated") {
      setO2Updated("");
      setNdsUpdated(selectedvalue);
    }
  };
  const handleCaseClose = ( e,
    row,
    rowIndex,
    ) => {
    if(row.action !== "Fiber Recovery Referral" && row.action !== "Central Office Referral" && row.action !== "Engineer Referral")
    {
      let updatedpayload =  postData.map(item => ({
        ...item,sftrue: true
     })    
       );
       setPostData(updatedpayload);
    }

    if(row.action !== ""  ){
      setMessage("");
     if(row.action !== "Fiber Recovery Referral" && row.action !== "Central Office Referral"){
    setshowloader(true);
    var payload;
    if(row.action === "Engineer Referral"){
    payload=  {
      "Action": "Update Owner",
      "caseId": row["case/device"],
    } 
  }else{
    payload=  {
      "Action": "Update Status",
			"caseId":  row["case/device"],
			"Status": "Closed", 
			"Reason": row.action
    } 
  }
    fetch(process.env.REACT_APP_BASE_API_SF_URL, {
      method: "POST", // or 'GET' depending on your API endpoint
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((datas) => {
        setshowloader(false);
        setmodalcase(true);
        var jsonString = datas;
        const status = jsonString.Status;
        setErrorMessageCase(jsonString.Message);
        setcaseId(jsonString.CaseNumber);
        if(row.action !== "Engineer Referral"){
        setTimeout( ()=>{ 
          portdefectivemst(row)
        },[2000])
       }
       setSaveactive(true);
      
      })
    .catch((error) => {
      setshowloader(false);
      if (error.response) {
        if (error.response.status === 404) {
          setShowerror(true);
        }
        if (
          error.response.status === 400 ||
          error.response.status === 502 ||
          error.response.status === 500
        ) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }else{
    setMessage("Case update for this action are not available");
  }
  } else {
    setMessage("Please select Action");
  }
}
const handleLevelClose = () => {
  if(selectedOption !== ""){
    if(selectedOption !== "Fiber Recovery Referral" && selectedOption !== "Central Office Referral"){
  setMessage("");
  setshowloader(true);
  var payload;
  if(selectedOption === "Engineer Referral"){
    payload=  {
      "Action": "Update Owner",
      "caseId": data.reviewdata.faIssueDevice,
    } 
  } else {
    payload=  {
			"Action": "Update Status",
			"caseId": data.reviewdata.faIssueDevice,
			"Status": "Closed",
			"Reason": selectedOption,
  }
  }
  fetch(process.env.REACT_APP_BASE_API_SF_URL, {
    method: "POST", // or 'GET' depending on your API endpoint
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((datas) => {
      setshowloader(false);
      setmodalcase(true);
      var jsonString = datas;
      setErrorMessageCase(jsonString.Message);
      setcaseId(jsonString.CaseNumber);
      data.handlecase(true);
    })
  .catch((error) => {
    console.log(error,"check");
    setshowloader(false);
    if (error.response) {
      if (error.response.status === 404) {
        setShowerror(true);
      }
      if (
        error.response.status === 400 ||
        error.response.status === 502 ||
        error.response.status === 500
      ) {
        setShowerror(true);
      }
    } else {
      setShowerror(true);
    }
  });
}else{
  setMessage("Case update for this action are not available");
}
} else {
  setMessage("Please select Action");
}
}

const portdefectivemst = (row) => {
  const data = {
    status: "DEFECTIVE",
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessOSG}`,
  };

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(data),
  };
  const optionsget = {
    headers: headers,
  };
  var mstName = row.mst
  var value = row.mstPortWise
  var mstPort = row.terminalPort
   if(row.distriCableStrand){
    axios.get(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/accesspoints/" + mstName 
    , optionsget )
        .then((response) => {
          if (response.status == 204 || response.status == 207 || response.status == 200) {
            var networkname = response.data;
            var distinguishnetworks = networkname.networks
              var dnvalue  = distinguishnetworks[row.terminalPort - 1]
            var finalnetworkname = dnvalue.networkDN;
            if(finalnetworkname){
              updateportdefect(finalnetworkname, value, row.distriCableStrand, row);
              
            }
          }
        })
        .catch((error) => {
          if (error) {
            if (error.response.status === 404 || error.response.status === 400) {
              
            }
            if (error.response.status === 500) {
              
            }
            if (error.response.status === 502) {
              setShowerror(true);
            }
          } 
        });
   }else{
     
    updateportdefectresour(value, mstName,mstPort)
   }
    
  
};

const updateportdefect = (networkname, value,fdhOpPort,row)=>{
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessOSG}`,
  };
  console.log(fdhOpPort,'ss')
  if(fdhOpPort){
    const data = {
      status: "AVAILABLE",
      description: value,
    };
  
  
    axios.put(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/resources/" +
          networkname +
          "?identifierType=NETWORK",
      data,
      {
        headers :{
           "Content-Type": "application/json",
            Authorization: `Bearer ${accessOSG}`,
         }
       },
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            setShowNoLightYes(true);
            
            setModalMsg("Port status restored successfully");
            
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            if (error.response.status === 404 || error.response.status === 400) {
              setShowNoLightYes(true);
            
             setModalMsg(error.response.data.message);
            }
            if (error.response.status === 502 || error.response.status === 500) {
              setShowerror(true);
            }
          } else {
          }
        });
  } 
  else{
    const data = {
      status: "AVAILABLE",
      description: value,
    };
    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    };
    fetch(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/resources/" +
          row.mst?row.mst:'' +
          "%2fTP=00" +
          row.terminalPort?row.terminalPort:'' +
          "/?identifierType=RESOURCE",
        options
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            setShowNoLightYes(true);
            
            setModalMsg("Port status restored successfully");
          }
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
  
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            if (error.status === 404 || error.status === 400) {
              setShowNoLightYes(true);
           
             setModalMsg(error.statusText);
            }
            if (error.status === 502 || error.status === 500) {
              setShowerror(true);
            }
          } else {
            
          }
        });

  }
 
}

const updateportdefectresour = (value,mstName,mstPort)=>{
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessOSG}`,
  };
    const data = {
      status: "AVAILABLE",
      description: value,
    };
    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    };
    fetch(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/resources/" +
          mstName +
          "%2fTP=00" +
          mstPort +
          "/?identifierType=RESOURCE",
        options
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            setShowNoLightYes(true);
            setModalMsg("Port status restored successfully");
          }
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
  
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            if (error.status === 404 || error.status === 400) {
              setShowNoLightYes(true);
             setModalMsg(error.statusText);
            }
            if (error.status === 502 || error.status === 500) {
              setShowerror(true);
            }
          } else {
            
          }
        });
}

const resolveissue = (value) =>{
  let updatedpayload =  postData.map(item => ({
    ...item,isstrue: true
 })    
   );
   setPostData(updatedpayload);
   setTimeout( ()=>{ 
    buttonref.current.click();
  },[1000])

}
  const saveData = (value) => {
    console.log(postData,'check')

    var updatedData;
    const propertiesToRemove = ["splitterLegStatus", "rowIndex", "id"];
    if (postData.length > 0) {
      updatedData = postData.map((item) => {
        const newItem = { ...item };
        propertiesToRemove.forEach((prop) => delete newItem[prop]);
        return newItem;
      });
    } else {
      updatedData = [null];
    }

    console.log(updatedData);
    let finalpayload=updatedData.map(item =>{
      if(item?.sftrue == true){
        return { ...item, status:'Closed'}
      }else if(item?.isstrue == true){
        return { ...item, status:'Resolved'}
      }else{
        return item
      }
    })
    console.log(finalpayload)

    

    

    console.log(data.mstCase.Status,"checkdata");
    const o2user = o2Updated ? username : "";
    const ndsuser = ndsUpdated ? username : "";
    var mstname = data.reviewdata.deviceName
      ? data.reviewdata.deviceName
      : "NA";
    var commentspost = inputcomments === null ? "" : inputcomments;
    var mstcasestatus = data.mstCase.Status ? data.mstCase.Status : "" ;
      
    axios
      .post(process.env.REACT_APP_BASE_API_URL +
        "/postFieldOperationsReview?" +
        "deviceName=" +
        mstname +
        "&user=" +
        username +
        "&faReason=" +
        selectedOption +
        "&faStatus=" +
        mstcasestatus +    
        "&faComment=" +
        commentspost +
        "&deviceType=MST" 
  , finalpayload, headers)
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          if (inputValue !== "") {
            data.handleStatus("PENDING", "mstpass");
            setInputValue("");
          } else {
            data.handleItemClick(null, null, "save");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
        }
      });
      setSaveactive(false);
  };
  const resolveFiberCut = (value) => {
    var updatedData;
    const propertiesToRemove = ["splitterLegStatus", "rowIndex", "id"];
    if (postData.length > 0) {
      updatedData = postData.map((item) => {
        const newItem = { ...item };
        propertiesToRemove.forEach((prop) => delete newItem[prop]);
        return newItem;
      });
    } else {
      updatedData = [null];
    }

    console.log(updatedData);
    let finalpayload = updatedData.map((item) => {
      if (item?.sftrue == true) {
        return { ...item, status: "Closed" };
      } else {
        return item;
      }
    });

   

    console.log(data.mstCase.Status, "checkdata");
    const o2user = o2Updated ? username : "";
    const ndsuser = ndsUpdated ? username : "";
    var mstname = data.reviewdata.deviceName
      ? data.reviewdata.deviceName
      : "NA";
    
    var commentspost = inputcomments === null ? "" : inputcomments;

    const updatedStatus = data.mstCase.Status === undefined ? 'resolved' : data.mstCase.Status;

    const apiUrl =
      process.env.REACT_APP_BASE_API_URL +
      "/postFieldOperationsReview?" +
      "deviceName=" +
      mstname +
      "&user=" +
      username +
      "&faReason=" +
      selectedOption +
      "&faStatus=" +
      updatedStatus ? updatedStatus : "" +
      "&faComment=" +
      commentspost +
      "&deviceType=MST" ;

    axios
      .post(apiUrl, finalpayload,headers)
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          if (inputValue !== "") {
            data.handleStatus("PENDING", "mstpass");
            setInputValue("");
          } else {
            data.handleItemClick(null, null, "save");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };

  

  useEffect(() => {
    console.log(postData);
  }, [postData]);
  const handleChangeCms = (e, row, rowIndex, property, from) => {
    setErrorMessage("");
    setMessage("");
    const value = e.target.value;
    console.log(row);
    setSaveToggle(true);
    let newData;
    var data = "";
   
    row[property] = e.target.value;
    if (from == "c") {
      newData = {
        ...row,
        rowIndex: rowIndex,
        action: e.target.value,
        comment: document.getElementById(
          "comment" + "-" + row.id + "-" + rowIndex
        ).value,
      };
    }
    else if (from == "e") {
      newData = {
        ...row,
        rowIndex: rowIndex,
        action: document.getElementById(
          "action" + "-" + row.id + "-" + rowIndex
        ).value,

        comment: e.target.value,
      };
    }
    let bool = false;

    setTimeout(
      setPostData((prevstate) => {
        const existingEntry = prevstate.findIndex(
          (entry) => entry.id === row.id && row.MST_PORT === entry.MST_PORT
        );

        if (existingEntry !== -1) {
          return [
            ...prevstate.slice(0, existingEntry),

            newData,

            ...prevstate.slice(existingEntry + 1),
          ];
        } else {
          return [...prevstate, newData];
        }
      }),

      100
    );

  };

  

  const handleDataFromChild = () => {
    setModaldelete(false)
    setWhitemod(false);
  };

  const includesWithNullCheck = (inputString, targetSubstring) => {
    return (
      typeof inputString === "string" &&
      typeof targetSubstring === "string" &&
      (inputString.includes(targetSubstring) || inputString === targetSubstring)
    );
  };

  const handleChange = (e) => {
    const filteredData = data.tdata;
    console.log(filteredData);
    setfilter([...filteredData]);
    
  };

  useEffect(() => console.log(filter), [filter]);
  const togglePopover = () => {
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  
  const exportToExcel = () => {
    const datas = data.tdata;
    const capital = (obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toUpperCase()] = obj[key];
      }
      return newObj;
    };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "MST_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  const handleNdsHide = () => {
    setHideNds(!hideNds);
  };
  const handleCloseCase =()=>{
    setmodalcase(false);
  }
  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const handleImageClose = () => {
    setShowmodalimage(false);
  };
  const handleImageBase = (data) => {
      setImageBase64(data)
  };
  const handleImagePass = () => {
    setShowmodalimage(false);
  };
  const getImageUpload = (data) => {
    axios.get(
      process.env.REACT_APP_BASE_API_IMAGE_URL +"/get?fileName=" + data[0].mst , headersImage
    )
    .then(response => {  
      const promiseResult = response.data[0].imageBase64;
      setactiveviewimage(true);
      setImageURL(promiseResult);  
    })  
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };
  const handleDelete = () =>{
    setshowLevelConfirm(true);
  }
  const handlecloseLevelmodal = () => {
    setshowLevelConfirm(false);
  };
  const handlemodalcloseyes = () => {
    setShowNoLightYes(false);
  };
  const handleLevelconfirm = () => {
    handlecalldelete()
    setshowLevelConfirm(false);
  };
  const handlecalldelete = ()=>{
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/deleteAuditData?' + '&deviceType=mst' + '&mst=' +  data.reviewdata.deviceName;
    axios
    .delete(apiUrl, headers)
    .then((response) => {
      setModaldelete(true);
      setPostData([]);
      setfilter([]);
      setAllproperties([])
      data.handleclear('clear');
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          setShowerror(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }
  
  return (
    <>
     {showLevelConfirm && (
        <ModalReviewConfirm
          closePopUps={handlecloseLevelmodal}
          confirmModules={handleLevelconfirm}
        />
      )}
     {showmodalimg && <ModalImageUpload data={data.tdata[0].mst}
     imagerole = {viewimageupload}
     imageurl = {imageURLdata}
    onDataFromChild={handleImagePass}
        closemodissue={handleImageClose}  
        imgbase64 = {handleImageBase}
      />}
       {showloader && <Loader />}
      {showwhitemodal && <ModalWhite onDataFromChild={handleDataFromChild} />}
      {showmodaldel && <ModalDelete  onDataFromChild={handleDataFromChild} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {showNoLightYes && (
        <ModalNoLightYes
          onDataFromChild={handlemodalcloseyes}
          modalMsg={modalMsg}
        />
      )}
     {modalcase && (
        <ModalFopReviewCase errormsg={errorMessageCase} close={handleCloseCase} caseId={caseId} />
      )}
      {errorMessage && (
        <div className="d-flex justify-content-center">
          <span style={{ color: "red" }}>{errorMessage}</span>
        </div>
      )}
       <span style={{ color: "red" }}>{message}</span>
      <div className="dropDown_container dropDown_container_mob mob-flex-wrap">
        <div className="dropDown_1 dropDown_12 dropdown-mob-fop">
          <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
            <div className="mst-status-level range-select-mob  mst-status-level-mob">
              {data.reviewdata.deviceName !== "NA" && filter.length > 0 && (
                <>
                  <div>
                    MST: &nbsp;{" "}
                    <span>
                      {" "}
                      <b>{data.reviewdata.deviceName}</b>
                    </span>
                  </div>
                  <div>
                    MST Level Issue: &nbsp;{" "}
                    <span>
                      {data.reviewdata.mstWise ? data.reviewdata.mstWise : "NA"}
                    </span>
                  </div>
                  {data.reviewdata.mstWise && (
                    data.mstCase.Status === "Closed" ? (
                      <>
                    </>
                    ) : (
                    <>
                      <div>
                        Case No: {data.mstCase.CaseNumber}&nbsp; <span></span>
                      </div>
                    </>
                    )
                   
                  )}
                   {data.reviewdata.mstWise && (
                    <>
                      <div>
                        Case Status: {data.mstCase.Status}&nbsp; <span></span>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
            {data.reviewdata.mstWise && (
              <>
                <div className="range-select-fop range-select-fop-mob mob-range-wid-set">
                  <label className="reason-name">Action</label>
                  <select
                    className="range-options range-select-mobile"
                    id="options"
                    value={selectedOption}
                    onChange={handleChangedropdown}
                  >
                    <option value="">Select</option>
                    <option value="Engineer Referral">Engineer Referral</option>
                    <option value="Fiber Recovery Referral">Fiber Recovery Referral</option>
                    <option value="Central Office Referral">Central Office Referral</option>
                    <option value="MST Found / Placed">
                    MST Found / Placed
                    </option>
                    <option value="Splicing Completed">Splicing Completed</option>
                    <option value="Light Issue Resolved">Light Issue Resolved</option>
                    <option value="MST Replaced">MST Replaced </option>
                    <option value="Light Issue Resolved">MST Access Resolved</option>               
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center range-container range-container-mob range-container-tab">
            <div>
              {data.reviewdata.mstWise && enableEdit && (
                <button
                  className="excelbutton-update"
                  id="saveBtn"
                  onClick={handleLevelClose}
                >
                  Update Status
                </button>
              )}
            </div>
            <div className="comments">
            {filter.length > 0 && enableEdit && (
              <>
                <label>Comments</label>
                <textarea
                  className="review-mst-comments"
                  id="inputcomments"
                  value={inputcomments}
                  disabled={!enableEdit}
                  onChange={handleInputComments}
                /></>)}
              </div>
              {/* <div className="">
              {data.reviewdata.mstWise === "Fiber Cut" && data.mstCase.Status === undefined && (
                <>
                  <button className="resolveFiberBtn" onClick={resolveFiberCut}>
                    Resolve fiber cut
                  </button>
                </>
              )}
            </div> */}
            
          </div>
          
        </div>

        {filter.length > 0 && (
          <>
          
            {enableEdit && (
              <>
                 <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
          {filter.length > 0 && showdelaudit && (
            <button className="excelbutton" onClick={handleDelete}>
              Delete
            </button>
          )}
        </div>
              
                <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set">
                  <button
                    ref={buttonref}
                    className="excelbutton"
                    id="saveBtn"
                    onClick={saveData}
                  >
                    Save
                  </button>
                </div>
              </>
            )}

            <div className="mob-dropDown_2 tab-margin-button-set-fop">
              <button onClick={exportToExcel} className="excelbutton">
                Export{" "}
                <span className="hidden-mob">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </button>
            </div>
            {viewimageupload === true && (
              <>
              <div className="mob-dropDown_2 tab-margin-button-set-fop">
            <button className="excelbutton" onClick={handleImageShow}>
              View &nbsp;
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faImage} />
              </span>
            </button>
            </div></>
            )}
            
            
          </>
        )}
      </div>

      <div className="scrollable-tbody-container" width="85%">
        <table className="tabletab scrollable-table" ref={tableRef}>
          {data.tdata != "" && filter != "" ? (
            <>
              <thead style={{ position: "sticky", top: "0" }}>
                <tr>
                  {data != "" &&
                    allProperties.map((columnName) =>
                      columnName === "optiTap" ? null : hideNds &&
                        columnName.startsWith("nds") ? null : (
                        <th
                          className={`tabhead ${
                            columnName.length > 13
                              ? "widthdata200"
                              : "widthdata150"
                          }`}
                          key={columnName}
                        >
                          {columnName.toUpperCase()}
                        </th>
                      )
                    )}
                  <th className={`tabhead widthdata200`}>Update Status</th>
                </tr>
                
              </thead>

              <tbody>
                {filter != "" &&  tabData.length>0 ? (
                  filter.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      

                      <tr key={rowIndex}>
                        {allProperties.map((propty) => {
                          if (propty === "optiTap") {
                            return null;
                          } else if (hideNds && propty.startsWith("nds")) {
                            return null;
                          } else if (propty === "action") {
                            return (
                              <td key={propty}>
                                <select
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  className="edit_field"
                                  disabled={!enableEdit}
                                  defaultValue={row[propty]}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,

                                      row,

                                      rowIndex,

                                      propty,

                                      "c"
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Engineer Referral">
                                  Engineer Referral
                                  </option>
                                  <option  value="Fiber Recovery Referral">Fiber Recovery Referral</option>
                                  <option  value="Central Office Referral">Central Office Referral</option>
                                  <option value="MST Found / Placed">
                                     MST Found / Placed
                                  </option>
                                  <option value="Splicing Completed">
                                    Splicing Completed
                                  </option>
                                  <option value="Light Issue Resolved">
                                  Light Issue Resolved
                                  </option>
                                </select>
                              </td>
                            );
                          } else if (propty === "comment") {
                            return (
                              <td key={propty}>
                                <textarea
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  defaultValue={row[propty]}
                                  disabled={!enableEdit}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,
                                      row,
                                      rowIndex,
                                      propty,
                                      "e"
                                    )
                                  }
                                  style={{ width: "100%" }}
                                
                                />
                              </td>
                            );
                          } else {
                            return <td key={propty}>{row[propty]}</td>;
                          }
                        })}
                        <td >
                          {
                              ( row["mstPortWise"] !== ""  && row["case/device"] == "" && row["comment"] !=='' ) && 
                              <>
                              <button onClick={resolveissue} >Resolve Issue</button>

                              </>
                                
                          }

                          {
                           row["case/device"] !== ""   ? (
                              <>
                               {enableEdit && <button onClick={(e) =>
                                    handleCaseClose(
                                      e,
                                      row,
                                      rowIndex,
                                    )
                                  }>Update</button>}
                              </>
                          ) : (
                            <>
                            </>
                          )
                          }
                        </td>
                      </tr>

                    
                    </React.Fragment>
                  ) )
                  ):(
                    <>
                    <tr>
                    <td colSpan="12">
                        Data not available
                        </td>
                      <td colSpan="12">
                        Data not available
                        </td>
                        <td colSpan="12">
                        Data not available
                        </td>
                      </tr>
                      </>
                   )
                  
                  }
              </tbody>
            </>
          ) : (
            <>
              {!data.loading ? (
                <div
                  style={{
                    

                    alignItems: "center",

                    fontSize: "17px",

                    color: "#011e60",

                    fontWeight: "bolder",

                    marginTop: "50px",
                  }}
                >
                  <>
                    {!data.errorreview == "" ? (
                      <>{data.errorreview}</>
                    ) : (
                      <>Data not found in MST TP</>
                    )}
                  </>
                </div>
              ) : (
                <Contentloading />
              )}
            </>
          )}
          
        </table>
      </div>
    </>
  );
};

export default MSTdatatab;
