import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./ModalAddresssuccess.css";

const ModalSuccessAdd = (props) => {
  console.log(props);
  //   const closeFunction = () => {
  //     props.action();
  //   };

  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;

    // Call the function received from the parent with the data
    props.onDataFromChild(dataToSend);
  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            {props.send == "Successfully Updated" ? (
              <>
                <div className="modals">
                  <div
                    style={{
                      color: "#000",
                      padding: "30px 40px",
                      fontSize: "20px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{
                        color: "#001f6a",
                        margin: "10px",
                        height: "40px",
                        width: "40px",
                      }}
                    />{" "}
                    <br />
                    {props.send}
                  </div>
                  {/* <Modalalert /> */}
                </div>
                <div className="modal-bottom">
                  <button className="alert-bottoms" onClick={handleClick}>
                    OK
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="modals">
                  <div
                    style={{
                      color: "#000",
                      padding: "30px 40px",
                      fontSize: "20px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{
                        color: "#ee2222",
                        margin: "10px",
                        height: "40px",
                        width: "40px",
                      }}
                    />{" "}
                    <br />
                    <b>{props.send}</b>
                  </div>
                </div>
                <div className="modal-bottom">
                  <button
                    className="alert-bottoms-onterror"
                    onClick={handleClick}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalSuccessAdd;