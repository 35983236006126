// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .popover-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .popover-backdrop {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.89;
    height: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popover {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .popover-content {
    padding: 10px;
  }
  
  .popover-close-button {
    background-color: #EE2222;
    border: none;
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/popover.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;IACtB,wCAAwC;IACxC,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".popover-container {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  .popover-button {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .popover-backdrop {\n    z-index: 5;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    opacity: 0.89;\n    height: 100%;\n    background: black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .popover {\n    background-color: #fff;\n    border: 1px solid #ccc;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n    z-index: 1;\n  }\n  \n  .popover-content {\n    padding: 10px;\n  }\n  \n  .popover-close-button {\n    background-color: #EE2222;\n    border: none;\n    padding: 5px 10px;\n    margin-top: 10px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
