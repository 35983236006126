import React from "react";
import { useNavigate } from 'react-router-dom';
import Topbar from "../topbar";
import "./PageNotFound.css";
import NotFoundImg from "../assets/404.png";

const PageNotFound = () => {
  // const storedName = sessionStorage.getItem("Name");
    const navigate = useNavigate();

  const handleButtonClick = () => {
    // if (storedName == null) {
    //   navigate('/');
    // } else {
    //   navigate('/search');
    // }
    navigate('/search');
  };
  return (
    <div>
      <Topbar />
      <div className="notfound_content">
        <div className="notfound_error_cont">
        <div>
          <img className="not_found_img" src={NotFoundImg} alt=""></img>
        </div>
        <div className="error_404_cont">OOPS! 404 - PAGE NOT FOUND</div>
        <div className="error_404_cont2">This page you are looking for might have been</div>
        <div className="error_404_cont2">removed or temporarily unavailable</div>
        <div className="notfound_home"><button className="notfound_home_button" onClick={handleButtonClick}>Go To Home</button></div>
      </div>
      </div>
    </div>
  );
};

export default PageNotFound;
