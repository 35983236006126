import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faExclamationCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./ModalOntError.css";
import { useNavigate } from "react-router-dom";

const ModalOntError = ({ close,onDataFromChild }) => {
  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    // Call the function received from the parent with the data
    close(dataToSend);
  };
 
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modals">
            
              <div
                style={{
                  color: "#000",
                  padding: "30px 40px",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon icon={faExclamationCircle} style={{color: "#ee2222",margin:"10px", height:"40px",width:"40px"}} /> <br/>
                <b>Unable to change the port</b> 
              </div>
            </div>
            <div className="modal-bottom">
              <button className="alert-bottoms-onterror" onClick={handleClick}>Close</button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalOntError;
