import React, { useState, useEffect } from "react";
import {
  
  faDownload,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link} from "react-router-dom";
import Topbar from "../topbar";
import "./FeedbackReview.css";
import ReviewedFeedback from "./ReviewedFeedback";
import axios from "axios";
import Modalerror from "../ModalError/ErrorModal";
import FeedbackTab from "../FeedbackTab";
import Loader from "../Loader/Loader";
import ModalFeedback from "../ModalWhite/Modalsuccessfeedback";

const FeedbackReview = () => {
  const sessionId = sessionStorage.getItem("userId");
  const name = sessionStorage.getItem("Name");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const data = [
    {
      CUID: "AD32344",
      ROLE: "ADMIN",
      NAME: "COMPLETED",
    },
    {
      CUID: "AD32344",
      ROLE: "ADMIN",
      NAME: "COMPLETED",
    }
  
  ];

  const [buttonStates, setButtonStates] = useState(
    Array(data.length).fill("Open")
  );
  
  const [showObj, setshowObj] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [showloader, setshowloader] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showsuccModal, setsuccModal] = useState(false);
  const [showReviewedFeed, setShowReviewedFeed] = useState(false);
  
  const handleRevFeedClick = (row) => {
    setshowObj(row)
    setShowReviewedFeed(true);
  };
  const handleRevFeedClose = () => {
    setShowReviewedFeed(false);
  };
  const [showerrmodal, setShowerror] = useState(false);
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const [feedRevData, setFeedRevData] = useState([]);

  useEffect(() => {  
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFeedbacks/review",
        headers
      );
      const data = response.data;

      setFeedRevData(data); 
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }
  };
  const handleSuccData = (data) => {
    setsuccModal(false);
  };
  const exportToExcel = () => {
    const datas = feedRevData;
    const capital = (obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toUpperCase()] = obj[key];
      }
      return newObj;
    };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "FEEDBACK_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  

  const handleDropdownChange = (index, value) => {
    const newRows = feedRevData.map((row, i) => 
      i === index ? { ...row, action: value } : row
    );
    setFeedRevData(newRows);
  };
  const handleCommentChange = (index, value) => {
    const newRows = feedRevData.map((row, i) => 
      i === index ? { ...row, comments: value } : row
    );
    setFeedRevData(newRows);
  };
  const handleSave = async (row) => {
     console.log(row);
        setshowloader(true);
        const valuedata = [null];
        const savedata = {
          id: row.id,
          cuid: row.cuid, 
          name: row.name, 
          role:row.role,  
          issueFaced: row.issueFaced,  
          infoProvided: row.infoProvided, 
          missingFeature:row.missingFeature, 
          additionalFeedback: row.additionalFeedback, 
          feedbackTime: row.feedbackTime,  
          action: row.action,  
          comments: row.comments,  
          actionBy: name, 
          actionTime: "" 
        }
        try {
          const responseone = await axios.post(
            process.env.REACT_APP_BASE_API_URL +
              "/postFeedbackReview" ,
            savedata,
            headers
          );
          var datas = responseone.data;
          if (datas == "Success") {
            setshowloader(false);
            setsuccModal(true);
            fetchData();
          } 
        } catch (error) {
          console.error("Error fetching data:", error);
          setshowloader(false);
          if (error.response) {
            if (error.response.status === 400) {
              setIsPopoverOpen(true);
            }
            if (error.response.status === 502 || error.response.status === 500) {
              setShowerror(true);
            }
          } else {
            setShowerror(true);
          }
        }
  };
  const togglePopover = () => {
    setIsPopoverOpen(false);
  };

  return (
    <div>
      <Topbar />
      <FeedbackTab/>
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError}/>}
      {showsuccModal && <ModalFeedback onDataFromChild={handleSuccData} />}
      {showloader && <Loader />}
      {isPopoverOpen && (
        <div className="popover-backdrop pop-feedback-backdrop">
          <div className="popover pop-feedback">
            <div className="popover-content">
              <p>Please Choose Action</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="second-nav-rev">
        <div className="col-2 mob-col-12">
          <div className="content-one-rev">

            <div className="hidden-mob-feed">
              <Link to="/search">
                <FontAwesomeIcon
                  className="ico-nav mb-2 p-3 mob-padding-5"
                  style={{ color: "#011E60" }}
                  icon={faHome}
                />
              </Link>
            </div>
            <h6 className="feed_head">Feedback Review</h6>
          </div>
          </div>
        </div>
      </div>
      <div>
        <div className="feed_rev_text"> 
        <div className="">
          {" "}
          Feedback Review Page, Where You Can Review And Manage All Customer
          Feedback To Ensure Continuous Improvement Of Our Products And
          Services.
        </div>
        <div className="excel-button-feedback">
        <button onClick={exportToExcel} id="exp_v" className="excelbuttonfeedback">
                Export{" "}
                <span className="hidden-mob">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </button>
          </div>
          </div>
          <div className="mob-overflow-scroll tab-feed">
          <table className="custom-table">
            <thead className="custom-thead">
              <tr className="table-row-rev">
                {/* Dynamically create table headers from data keys */}
                {Object.keys(data[0]).map((key) => (
                  <th key={key} className="custom-th">
                    {key}
                  </th>
                ))}
                <th className="custom-th">Feedback Time</th>
                <th className="custom-th">Form Review</th>
                <th className="custom-th">Action</th>
                <th className="custom-th">Comments</th>
                <th className="custom-th">Action By</th>
                <th className="custom-th">Action Time</th>
                <th className="custom-th">Save</th>
              </tr>
            </thead>
            <tbody className="custom-tbody">
              {/* Map through data to create table rows */}
              {feedRevData.map((row,rowIndex) => (
                <tr key={rowIndex} className="table-row-rev">
                  {/* {Object.values(row).map((value, i) => (
                    <td key={i} className="custom-td">
                      {value}
                    </td>
                  ))} */}
                  <td>{row.cuid}</td>
                  <td>{row.role}</td>
                  <td>{row.name}</td>
                  <td>{row.feedbackTime}</td>
                  <td className="custom-td">
                    <button
                      onClick={()=>handleRevFeedClick(row)}
                      className="savebuttonfeedback"
                    >
                      Open
                      {/* {buttonStates[index]} */}
                    </button>
                  </td>
                  <td>
                          {/* {isEditing ? ( */}
                          <select
                          className="feedback-dropdown"
                        value={row.action || ''}
                       onChange={(e) => handleDropdownChange(rowIndex, e.target.value)}
                             >
                              <option value="">Select</option>
                              <option value="In Progress">In Progress</option>
                              <option value="Completed">Completed</option>
                              <option value="No Action Required">No Action Required</option>
                            </select>
                        
                        </td>
                        <td>
                        <textarea
                         type="text"
                          value={row.comments || ''}
                          onChange={(e) => handleCommentChange(rowIndex, e.target.value)}
                        />
                      </td>
                  <td>{row.actionBy}</td>
                  <td>{row.actionTime}</td>
                  <td>
                  <button
                onClick={() => handleSave(row)}
                className="savebuttonfeedback">
                Save
                    </button>
                  </td>
                </tr>
              ))}
               {showReviewedFeed&& (
                  <ReviewedFeedback onClose={handleRevFeedClose} dataFeed={showObj}/>
                )}
            </tbody>
          </table>
     
        </div>
      </div>
      
    </div>
  );
};

export default FeedbackReview;
