import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./ModalTpIssueConfirm.css";

const ModalTpWorkingIssue = ({ yespop, message, closepop, mstTpIssue,legstatus}) => {
  const issue = mstTpIssue;
  const handleYes = () => {
    const dataToSend = false;
    yespop(dataToSend, issue);
  };
  const handleClose = () => {
    const dataToSend = false;
    closepop(dataToSend);
  };
  return (
    <>
      {ReactDOM.createPortal(

        <div id="modal-containerconfirm">

            {
                legstatus == "WORKING" ? (<>
                <div className="modalc-background">
            {mstTpIssue == "No Light" ||
            mstTpIssue == "Low Light" ||
            mstTpIssue == "Broken Port" 
           ? (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    {/* {mstTpIssue == "Port in use" ? (
                      <span>
                        Reporting this issue will present the option to move the
                        order to different facilities - Do you wish to continue?
                      </span>
                    ) : ( */}
                      <span>
                      Reporting this issue will be tracked via Salesforce case, 
                      to change facilities also, please call QF - Facility Support - Do you wish to continue?
                      </span>
                    {/* )} */}
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) :  (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    <span>
                    To change facilities on a working customer please contact QF- Facility Support
                    </span>
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    
                    <span>
                      <button className="ok-Tp-button" onClick={handleClose}>
                        OK
                      </button>
                    </span>
                  </div>
                </div>
              </>
            )  }
          </div>
                </>) :
                (
                    <>
                    <div className="modalc-background">
            {mstTpIssue == "No Light" ||
            mstTpIssue == "Low Light" ||
            mstTpIssue == "Broken Port" ||
            mstTpIssue == "Port in use" 
           ? (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    {mstTpIssue == "Port in use" ? (
                      <span>
                       Reporting this issue will suspend this port in inventory, please populate the OnField Address where port is in use. Do you wish to continue? 
                      </span>
                    ) : (
                      <span>
                        Reporting this issue will be tracked via Salesforce case, and mark this port as suspended in the inventory system. Do you wish to continue?
                      </span>
                   )}
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            )  : (
              <>
                <div className="modal-issue">
                  <div className="issue-title">
                    <span>
                    Reporting this issue will be tracked via Salesforce case, 
                    and mark this port as suspended in the inventory system. Do you wish to continue? 
                    </span>
                  </div>
                </div>
                <div className="modalc-bottom">
                  <div className="">
                    <span>
                      <button className="yes-button" onClick={handleYes}>
                        Yes
                      </button>
                    </span>
                    <span>
                      <button className="no-button" onClick={handleClose}>
                        No
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) }
          </div>
                    </>
                )
            }
          
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalTpWorkingIssue;
