// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directactivation-manatoryfield{
    font-size: 12px;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px
}
.directactivation-mobilecontiner{
    margin-top: 10px
}
.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 0px !important;
}
.directactivation-discovermsg{
    font-size: 10px;
                  color: red;
                  height: 15px;
                  text-align: center
}
.activate-search .MuiFormControl-root .MuiInputBase-root input{
    padding: 9px 15px;
  }
.directactivation-connectivity{
    height: 100px; text-align: center; display: flex
}
.directactivation-connectivity-msg{
    margin: auto; font-size: 18px; font-weight: 700
}`, "",{"version":3,"sources":["webpack://./src/directactivation.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,oBAAoB;IACpB;AACJ;AACA;IACI;AACJ;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,eAAe;kBACD,UAAU;kBACV,YAAY;kBACZ;AAClB;AACA;IACI,iBAAiB;EACnB;AACF;IACI,aAAa,EAAE,kBAAkB,EAAE;AACvC;AACA;IACI,YAAY,EAAE,eAAe,EAAE;AACnC","sourcesContent":[".directactivation-manatoryfield{\n    font-size: 12px;\n    width: 100%;\n    padding-bottom: 10px;\n    margin-bottom: 10px\n}\n.directactivation-mobilecontiner{\n    margin-top: 10px\n}\n.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root{\n    padding: 0px !important;\n}\n.directactivation-discovermsg{\n    font-size: 10px;\n                  color: red;\n                  height: 15px;\n                  text-align: center\n}\n.activate-search .MuiFormControl-root .MuiInputBase-root input{\n    padding: 9px 15px;\n  }\n.directactivation-connectivity{\n    height: 100px; text-align: center; display: flex\n}\n.directactivation-connectivity-msg{\n    margin: auto; font-size: 18px; font-weight: 700\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
