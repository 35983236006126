// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar-fdhview {
  position: relative;
  display: inline-block;
  margin-top: 15px;
  font-family: 'Poppins';
  margin-left: 20px;
}

.search-input-icon-fdhview::placeholder {
  font-size: small;
}

.search-input-icon-fdhview {
  padding: 8px 28px 8px 10px;
  border: 1px solid #ccc;
  font-family: 'Poppins';
  border-radius: 5px;
  outline: none;
  width: 150px;
  margin-bottom: 2px;
  border-color: black;
  border-width: 1.5px;
  font-size: small;
}

.searchicon-fdhview {
  color: #38c6f4;
  position: absolute;
  top: 50%;
  right: 8px;
  /* left: 5px; */
  transform: translateY(-50%);
  pointer-events: none;
}
.marg-align{
  margin-right: 20px;
}
.searchresultfdh{
  border: solid 1px #c1c1c1;
    padding: 6px;
    width: 13rem;
    border-radius: 4px;
    font-family: 'Poppins';
    outline: none;
}

.form-date{
  border: solid 1px #c1c1c1;
  padding: 6px;
  width: 13rem;
  border-radius: 4px;
  font-family: 'Poppins';
  outline: none;
}

.filter-rev{
  padding: 1px 2px 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Review/css/search.css"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,2BAA2B;EAC3B,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,yBAAyB;IACvB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');\n.search-bar-fdhview {\n  position: relative;\n  display: inline-block;\n  margin-top: 15px;\n  font-family: 'Poppins';\n  margin-left: 20px;\n}\n\n.search-input-icon-fdhview::placeholder {\n  font-size: small;\n}\n\n.search-input-icon-fdhview {\n  padding: 8px 28px 8px 10px;\n  border: 1px solid #ccc;\n  font-family: 'Poppins';\n  border-radius: 5px;\n  outline: none;\n  width: 150px;\n  margin-bottom: 2px;\n  border-color: black;\n  border-width: 1.5px;\n  font-size: small;\n}\n\n.searchicon-fdhview {\n  color: #38c6f4;\n  position: absolute;\n  top: 50%;\n  right: 8px;\n  /* left: 5px; */\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n.marg-align{\n  margin-right: 20px;\n}\n.searchresultfdh{\n  border: solid 1px #c1c1c1;\n    padding: 6px;\n    width: 13rem;\n    border-radius: 4px;\n    font-family: 'Poppins';\n    outline: none;\n}\n\n.form-date{\n  border: solid 1px #c1c1c1;\n  padding: 6px;\n  width: 13rem;\n  border-radius: 4px;\n  font-family: 'Poppins';\n  outline: none;\n}\n\n.filter-rev{\n  padding: 1px 2px 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
