import React from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import { useState, useEffect, useMemo } from "react";
import FdhviewHeader from "./FdhviewHeader";
import Fdhdatatab from "./Fdhdatatab";
// import MyComponent from "./Mycomponent";
import Modalblack from "../ModalBlack/Modal";
import Modalerror from "../ModalError/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./css/fdhview.css";
import "./css/search.css";
import { Modal } from "bootstrap";
import Contentloading from "./Contentloading";
import { API_KEY,BASE_API_URL } from "../environment";
import axios from "axios";
import Modalsplitter from "../ModalWhite/ModalSplitter";


// const fdhinfo = [
//   // "CPCRFLJ0353",
//   // "CPCRFLJ0405",
//   // "CPCRFLJ0391",
//   // "CPCRFLJ0392",
//   // "CPCRFLJ0351",
//   // "CPCRFLJ0390",
//   // "CPCRFLJ0356",
//   // "CPCRFLJ0395",
//   // "CPCRFLJ0357",
//   // "CPCRFLJ0403",
//   // "CPCRFLJ0349",
//   // "CPCRFLJ0393",
//   // "CPCRFLJ0350",
//   // "NFMYFLJ0004",
//   // "NFMYFLJ0063",
//   // "CPCRFLJ0386",
//   // "NFMYFLJ0064",
//   // "CPCRFLJ0387",
//   // "CPCRFLJ0404",
//   // "CPCRFLJ0388",
//   // "CPCRFLJ0397",
//   // "CPCRFLJ0355",
//   // "CPCRFLJ0351",
//   // "CPCRFLJ0344",
//   // "CPCRFLJ0352",
//   // "CPCRFLJ0396",
//   // "CPCRFLJ0389",
//   // "CPCRFLJ0345",
//   // "CPCRFLJ0399",
//   // "CPCRFLJ0398",
//   // "CPCRFLJ0385",
//   // "NFMYFLJ0065",
//   // "NFMYFLJ0066",
//   // "NFMYFLJ0034",
// ];
const statuslist = [{ status: "COMPLETED" }, { status: "PENDING" }];
// const stateList = [
//   { state: "WA" },
//   { state: "IN" },
//   { state: "MN" },
//   { state: "NM" },
//   { state: "OR" },
//   { state: "FL" },
//   { state: "UT" },
//   { state: "MT" },
//   { state: "CO" },
//   { state: "AZ" },
//   { state: "NV" },
//   { state: "NE" },
//   { state: "ID" },
//   { state: "ND" },
//   { state: "WY" },
//   { state: "SD" },
//   { state: "WI" },
// ];
const stateList = [
  { value: "AZ",  label: "AZ" },
{ value: "CO",  label: "CO" },
{ value: "FL",  label: "FL" },
{ value: "IA",  label: "IA" },
{ value: "ID",  label: "ID" },
{ value: "IN",  label: "IN" },
{ value: "MN",  label: "MN" },
{ value: "MT",  label: "MT" },
{ value: "ND",  label: "ND" },
{ value: "NE",  label: "NE" },
{ value: "NM",  label: "NM" },
{ value: "NV",  label: "NV" },
{ value: "OR",  label: "OR" },
{ value: "SD",  label: "SD" },
{ value: "UT",  label: "UT" },
{ value: "WA",  label: "WA" },
{ value: "WI",  label: "WI" },
{ value: "WY",  label: "WY" },
];
const fdhinfo = [
  {
    FDH: "ME=CPCRFLCC CPCRFLJ0393",
  },
  {
    FDH: "ME=CPCRFLCC CPCRFLJ0395",
  },
  {
    FDH: "ME=CPCRFLCJ CPCRFLJ0399",
  },
  {
    FDH: "ME=CPCRFLCJ CPCRFLJ0403",
  },
  {
    FDH: "ME=CPCRFLCJ CPCRFLJ0405",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0344",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0345",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0349",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0350",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0351",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0352",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0353",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0355",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0356",
  },
  {
    FDH: "ME=CPCRFLCN CPCRFLJ0357",
  },
  {
    FDH: "ME=CPCRFLCR CPCRFLJ0385",
  },
  {
    FDH: "ME=CPCRFLCR CPCRFLJ0386",
  },
  {
    FDH: "ME=CPCRFLCR CPCRFLJ0387",
  },
  {
    FDH: "ME=CPCRFLCR CPCRFLJ0388",
  },
  {
    FDH: "ME=CPCRFLCR CPCRFLJ0389",
  },
  {
    FDH: "ME=CPCRFLCV CPCRFLJ0390",
  },
  {
    FDH: "ME=CPCRFLCV CPCRFLJ0391",
  },
  {
    FDH: "ME=CPCRFLCV CPCRFLJ0392",
  },
  {
    FDH: "ME=CPCRFLDI CPCRFLJ0397",
  },
  {
    FDH: "ME=CPCRFLDI CPCRFLJ0398",
  },
  {
    FDH: "ME=CPCRFLFF CPCRFLJ0404",
  },
  {
    FDH: "ME=CPCRFLXB CPCRFLJ0396",
  },
  {
    FDH: "ME=NFMYFLXB NFMYFLJ0004",
  },
  {
    FDH: "ME=NFMYFLXB NFMYFLJ0063",
  },
  {
    FDH: "ME=NFMYFLXB NFMYFLJ0064",
  },
  {
    FDH: "ME=NFMYFLXB NFMYFLJ0065",
  },
  {
    FDH: "ME=NFMYFLXB NFMYFLJ0066",
  },
  {
    FDH: "ME=NFMYFLXH NFMYFLJ0034",
  },
];
const Fdhview = (props) => {
  
  console.log("check fdh", props);
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
  const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  const [activeButton, setactiveButton] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleErrModal, setToggleErrModal] = useState(false);

  const toggleButton = (buttonId) => {
    setactiveButton(activeButton === buttonId ? null : buttonId);
  };
   const [startDate, setStartDate] = useState();
   const [endDate, setEndDate] = useState();
   
   const [formatstartDate, setformatStartDate] = useState('');
   const [formatendDate, setformatEndDate] = useState('');
   const Cuid = sessionStorage.getItem("encryptedKey");
  const [dataFDH, setDataFDH] = useState([]);
  const [dataWirecenter, setdataWirecenter] = useState([]);
  const [tableheader, settableheader] = useState([]);
  const [activeIndex, setActiveIndex] = useState('');
  const [selectedIndex, setIndex] = useState("");
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [splitterKeys, setsplitterKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [showloading, setShowloading] = useState(true);
  const [filteredData12, setfilteredData12] = useState([]);
  var [reviewstatus, setHeaderreview] = useState("");
  const [errorreview, seterrorMst] = useState();
  var [enablewirecenter, setEnableWire] = useState(false);
  var [statevalue, setstatevalue] = useState(null);
  var [statename, setstatename] = useState({ label:'', value:''});
  var [statekeyname, setstatekeyname] = useState();
  const [wirecent, setwirecent] = useState("");
  const[selectedStateValue,setselectedStateValue] = useState("");
   const [wirecentlist, setWirecentList] = useState("");
  var [wirecentervalue, setWireValue] = useState(null);
  var [setmatchfilt, setMatchingFilt] = useState("");
  var [reviewValue, setreviewValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  var [enablesplitterview, setSplitterview] = useState(false);
  const [splitterViewData, setSplitterViewData] = useState([]);
  const[dataerror,setDataError]= useState(false);
  const[nodata,setNoData]=useState(["1","2"]);
  useEffect(() => {
    setToggleModal(true);
    // setNoData();
    // callFDHList();
    // handlewirecenter();
  }, []);
  useEffect(()=>{
    if(formatstartDate || formatendDate){
      callDatefilter();
   }
  },[formatstartDate,formatendDate])
  // useEffect(()=>{
  //   callFDHList();
  //   },[statevalue, wirecentervalue])
  useEffect(() => {
    if (props.fdhList) {
      // setShowloading(false)
      setfilteredData(props.fdhList);
      setfilteredData12(props.fdhList);
      console.log("checking 11");
    } else {
      console.log(dataFDH);
    }
  }, [props.fdhList]);
  const fetchDataSplit = async (fdhNameval) => {
    setSplitterViewData([]);
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_API_URL +"/getSplitterView?fdhName="+
      fdhNameval, headers);
      const data = response.data;

      setSplitterViewData(data); // Assuming your API response is an array of objects
    } catch (error) {
      console.log(error.response, "jk");
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setToggleErrModal(true);
        }
      } else {
        setToggleErrModal(true);
      }
    }
  }
  const datetimeString = reviewstatus.reviewTime; // 24-hour format

  // Convert the date and time into desired formats
  const formattedDate = new Date(datetimeString?datetimeString:'NA').toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedTime = new Date(datetimeString?datetimeString:'NA').toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });
  const fetchreview = (value, match) => {
   var num=["1","2"];
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getFinalAuditReportByFDH?fdh=" +
        value +
        "&onfieldComparison=" +
        match , headers
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          if (data.status === "404") {
            setLoadingToggle(false);
            seterrorMst(data.message);
            setNoData([]);
          }
          if (data.status === "502") {
            setToggleErrModal(true);
          }
        } else {
          setNoData(num);
          settableheader(() => {
            const arrayOfObjects = Object.keys(data)
              .map((key) => {
                const id = parseInt(key); // Convert the key to an integer as required
                return data[key].map((item) => ({ id, ...item }));
              })
              .flat();

            return arrayOfObjects;
          });
          setsplitterKeys(Object.keys(data));
          setLoadingToggle(false);
          setToggleModal(false);
        }
      })
      // .then((data) => {
      //   settableheader(() => {
      //     const arrayOfObjects = Object.keys(data)
      //       .map((key) => {
      //         const id = parseInt(key); // Convert the key to an integer as required
      //         return data[key].map((item) => ({ id, ...item }));
      //       })
      //       .flat();

      //     return arrayOfObjects;
      //   });
      //   setsplitterKeys(Object.keys(data));
      //   setLoadingToggle(false);
      //   setToggleModal(false);

      // })
      .catch((e) => {
        if (e.response) {
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
          setToggleErrModal(true);
        }
      });
  };

  const handlematchingfilt = (event) => {
    setMatchingFilt(event.target.value);
    fetchreview(reviewValue, event.target.value);
  };
  const handleSplitClose = (data) => {
    setSplitterview(data);
    
  };
  const handleshowsplit = (data) =>{
    setSplitterview(true)
  }
  const handleStartDateChange = (date)=>{
    const inpDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    const formattedDate = inpDate.toISOString().split('T')[0];
    setStartDate(date);
    setformatStartDate(formattedDate);
    console.log(formattedDate,'dateitem')
    // callDatefilter();
    
  }
  const callDatefilter = ()=>{
    console.log(formatstartDate)
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      fdhWise:"NA",
      fdhComments:"NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA"
    }

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceType=FDH" +
        "&auditStatus=" +
        statevalue +
        "&state=" +
        statename+
        "&wirecenter=" +
        wirecent +
        "&dateFrom=" + formatstartDate +
        "&dateTo=" + formatendDate +
        "&reviewPage=archieve", headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        setfilteredData(data);
        setfilteredData12(data);
      }).catch((error) => {
        console.log(error);
        if (error) {
          if(error.status == 400 || error.status == 404){
            setfilteredData([]);
            setfilteredData12([]);
            settableheader([]);
            setHeaderreview(val);
            props.onChildDataChange(val);
          }
          if (
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
            console.log(error.status);
          }
        } else {
        }
      });
  }
  const handleEndDateChange = (date)=>{
    const inpDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    const formattedDate = inpDate.toISOString().split('T')[0];
    setEndDate(date);
    setformatEndDate(formattedDate);
    console.log(formattedDate,'enditem');
    // callDatefilter();
  }
  // const handlewirecenter = (data) => {
  //   if (data !== "0") {
  //     fetch(process.env.REACT_APP_BASE_API_URL + "getFdh?startWith=ME=" + "&state=" + data  + process.env.REACT_APP_API_KEY)
  //       .then((response) => {
  //         console.log(response);
  //         if (!response.ok) {
  //           throw response;
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         setdataWirecenter(data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         if (error) {
  //           if (
  //             error.status === 400 ||
  //             error.status === 502 ||
  //             error.status === 500
  //           ) {
  //             setToggleErrModal(true);
  //           }
  //         } else {
  //         }
  //       });
  //   }
  // };

  //CPCRFLJ0392
  const handleItemClick = (event, val,passedData) => {
    var deviceData=event?.target?.textContent ? event.target.textContent : selectedIndex
    setActiveIndex(deviceData);
    setIndex(deviceData);
    setreviewValue(deviceData);
    setLoadingToggle(true);
    fetchDataSplit(deviceData);
    if(passedData !== 'save'){
      settableheader([]);
      setsplitterKeys([]);
      }
    fetch(process.env.REACT_APP_BASE_API_URL + "/getAuditReviewInfo?deviceName=" + deviceData  +  "&reviewPage=archieve", headers)
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setHeaderreview(data[0]);
          props.onChildDataChange(data[0]);
        }
      })
      .catch((error) => {
        setHeaderreview("");
        props.onChildDataChange("");
        console.log(error);
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
        }
      });
    if(passedData !== 'save'){
      fetchreview(deviceData);
    }
  };
  console.log(dataWirecenter);
  const handleErrModal = () => {
    setToggleErrModal(false);
  };

  // const handleState = (event) => {
  //   var datavalue = event.target.value;
  //   console.log(datavalue);
  //   setstatevalue(datavalue);
  //   setdataWirecenter([]);
  //   if(datavalue != '0'){

  //    fetch(
  //     process.env.REACT_APP_BASE_API_URL + "getFdh?startWith=ME=" + '&state='+ datavalue
  //    )

  //    .then(response => {
  //     console.log(response)
  //     if (!response.ok) {
  //       throw response;
  //     }
  //     return response.json();
  //   })
  //      .then((data) => {
  //      setfilteredData(data);
  //      setfilteredData12(data)
  //      })
  //      .catch((error) => {
  //       console.log(error);
  //       if (error) {
  //         if (
  //           error.status === 400 ||
  //           error.status === 502 ||
  //           error.status === 500
  //         ) {
  //           setToggleErrModal(true);
  //         }
  //       } else {

  //       }
  //     });
  //     // callFDHList(param);
  //     setEnableWire(true);
  //     handlewirecenter(datavalue);

  //   }
  //   else{
  //     var param =  '&state=' + datavalue + '&wireCenter=' + wirecentervalue
  //     // callFDHList(param);
  //     setfilteredData([]);
  //     setfilteredData12([])
  //   }
  // };

  //status filter
  const handleStatus = (event) => {
    var datavalue = event.target.value;
    console.log(datavalue);
    setSelectedDate("");
    setInputValue('');
    setstatekeyname([]);
    setstatename('');
    setwirecent("");
    setInputValue("");
    setStartDate("");
    setEndDate("");
    settableheader([]);
    setstatevalue(datavalue);
    setdataWirecenter([]);
    setLoadingToggle(false);
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      fdhWise:"NA",
      fdhComments:"NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA"
    }
    setHeaderreview(val);
    props.onChildDataChange(val);
    if (datavalue != "0") {
      // /getAuditReviewInfo?deviceType=MST&auditStatus=PENDING&deviceName=ME=

      fetch(
        process.env.REACT_APP_BASE_API_URL +
          "/getAuditReviewInfo?deviceType=FDH" +
          "&auditStatus=" +
          datavalue +
          "&deviceName=" + "&reviewPage=archieve" , headers
      )
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          setfilteredData(data);
          setfilteredData12(data);
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            if (
              error.status === 400 ||
              error.status === 502 ||
              error.status === 500
            ) {
              setToggleErrModal(true);
            }
          } else {
          }
        });
      // callFDHList(param);
      setEnableWire(true);
    }
    else{
      setfilteredData([]);
      setfilteredData12([]);
      setEnableWire(false);
    }
  };

  //typeahead
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    // if (inputValue.length >= 2) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            "/getAuditReviewInfo?deviceType=FDH" +
            "&auditStatus=" +
            statevalue +
            "&state=" +
            statename+
           "&wirecenter=" +
            wirecent +
            "&deviceName=" +
            inputValue  + "&reviewPage=archieve", headers
        )
        .then((response) => {
          const data = response.data;
          setfilteredData(data);
          setfilteredData12(data);
        }).catch((error)=>{
          if(error.response.status == 400 || error.response.status == 404){
            console.log(error.response.status);
            setfilteredData([]);
          setfilteredData12([]);
          }else{
            setToggleErrModal(true)
          }
        });
    //}
  };

  //date filter
  const getdateval = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
    const datevalue = event.target.value;
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      auditTime: "NA",
      fdhWise:"NA",
      fdhComments:"NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA"
    }
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceType=FDH" +
        "&auditStatus=" +
        statevalue +
        "&dateFrom=" + formatstartDate +
        "&dateTo=" + formatendDate  + "&reviewPage=archieve", headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        setfilteredData(data);
        setfilteredData12(data);
      }).catch((error) => {
        console.log(error);
        if (error) {
          if(error.status == 400 || error.status == 404){
            setfilteredData([]);
            setfilteredData12([]);
            settableheader([]);
            setHeaderreview(val);
            props.onChildDataChange(val);
          }
          if (
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
            console.log(error.status);
          }
        } else {
        }
      });
  };


  const handleState = (event) =>{
    const statenamevalue = event.value;
    console.log(event.value)
    setstatekeyname(event);
    setstatename(statenamevalue);

    setwirecent("");
    setInputValue("");
    setStartDate("");
    setEndDate("");
    // if(wirevalue !== '0'){
      fetch(
        process.env.REACT_APP_BASE_API_URL + "/getAuditReviewInfo?" + '&auditStatus='+ statevalue + 
         "&reviewPage=archieve", headers
       )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              settableheader([]);
              setsplitterKeys([]);
              setfilteredData12([]);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }
  const handleSelectWire = (suggestion) => {
    var inputstring = suggestion;
    setwirecent(inputstring);
    setWirecentList([]);
    handlewirecenter(inputstring);
  };
  const handlewirecenter = (event) =>{
    
    const wirecentername = event;
    // if(wirevalue !== '0'){
      setInputValue("");
      setStartDate("");
      setEndDate("");
      fetch(
        process.env.REACT_APP_BASE_API_URL + "/getAuditReviewInfo?" + '&auditStatus='+ statevalue + '&deviceType=FDH' + '&state=' + statename + '&wireCenter=' + wirecentername 
        +  "&reviewPage=archieve", headers
       )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              setfilteredData12([]);
              settableheader([]);
              setsplitterKeys([]);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }
  const handleWirecent = (e) => {
    const wirecentervaluefdh = e.target.value;
    setwirecent(wirecentervaluefdh);
    // setFilteredSuggestions([]);
    // setFilteredMSTSuggestions([]);
      if (wirecentervaluefdh.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getWirecenter?fdhWirecenter=" +
              wirecentervaluefdh +
              "&state="+
              statename
              , headers
          )
          .then((response) => {
            const data = response.data;
            setWirecentList(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setWirecentList([]);
      }
  };
  const handleSearchChange = (e) => {
    console.log(e.target.value);
    setSearchTerm(e.target.value);
    // setActiveIndex(false);
    console.log();
    const filteredDataup = filteredData12?.filter((fdh) =>
      fdh.fdh.toLowerCase().includes(e.target.value.toLowerCase())
    );
    console.log(filteredDataup);
    setfilteredData(filteredDataup);
  };
  

  return (
    <>
      <div className="d-flex justify-content-between box-fdh pb-1 mob-col-12">
        <div>
        <div className="d-flex flex-row filtertags mob-col-12 mob-flex-wrap tab-flex-wraps">
          <div className="mob-col-12 mb-nomargin  marg-align" >
            <select
              className="filter-select mob-filtertag"
              onChange={handleStatus}
            >
              <option value="0">Select Status</option>

              {statuslist.map((status) => (
                <option key={status} value={status.status}>
                  {status.status}
                </option>
              ))}
            </select>
          </div>
          
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
          <Select
          options={stateList}
          onChange={handleState}
          value={statekeyname}
          className="searchresultfdhselect"
          placeholder="Select State"
          isMulti={false} // Set to true if you want to allow multiple selections
          />
          )}
        </div>
        <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
               <div>
                  <input
                    className="searchresultfdh"
                    placeholder="Search Wirecenter"
                    value={wirecent}
                    autoComplete="off"
                    id="wirecenter"
                    onChange={handleWirecent}
                  ></input>
                  {wirecentlist?.length > 0 ? (
                    <ul className="wirecenterreview mob-wire-search tab-wire-search">
                      <span>
                        {wirecentlist.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() => handleSelectWire(suggestion)}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </span>
                    </ul>
                  ) : 
                  (
                    <></>
                  )
                  }
                </div>
            )}
            </div>
         
          <div className="mob-col-6 marg-align mob-no-margin mob-margin-top-set">
            {enablewirecenter && (
              <div className="d-flex flex-row datepicker">
                
                <DatePicker selected={startDate} selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-date"
              placeholderText="From Date"
              onChange={handleStartDateChange} />
              
            
                </div>
          // <div className="d-flex flex-column outerdate datewid">
          //         {/* <div>Date</div> */}
          //         <div className="mob-aligen-right">
          //           <input
          //             type="date"
          //             id="dateinpt"
          //             value={selectedDate}
          //             onChange={getdateval}
          //             className="form-date"
          //           ></input>
          //         </div>
          //    </div>
            )}
          </div>
          <div className="mob-col-6 marg-align mob-no-margin mob-margin-top-set">
            {enablewirecenter && (
              <div className="d-flex flex-row datepicker">
              
                
                <DatePicker selected={endDate} selectsEnd
               startDate={startDate}
              endDate={endDate}
              placeholderText="To Date"
              className="form-date"
              minDate={startDate}
              onChange={handleEndDateChange} />
                
                </div>
          // <div className="d-flex flex-column outerdate datewid">
          //         {/* <div>Date</div> */}
          //         <div className="mob-aligen-right">
          //           <input
          //             type="date"
          //             id="dateinpt"
          //             value={selectedDate}
          //             onChange={getdateval}
          //             className="form-date"
          //           ></input>
          //         </div>
          //    </div>
            )}
          </div>
          
         
        </div>
        {enablewirecenter && (
        <div className="d-flex flex-row filtertags mob-col-12 mob-flex-wrap tab-flex-wraps ">
           <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
              <div>
                <input
                  type="text"
                  value={inputValue}
                  placeholder="Search FDH"
                  style={{ margin: "0" }}
                  className="searchresultfdh"
                  onChange={handleChange}
                ></input>
              </div>
            )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
            
            {enablewirecenter && (
              <div className="d-flex flex-column outerdate datewid">
                      <div className="mob-aligen-right  review-text-head">
                       Review By
                      </div>
                      <div className="mob-aligen-right review-text-content">
                       {reviewstatus.reviewBy?reviewstatus.reviewBy:'NA'}
                      </div>
                 </div>
              )} 
              </div>
              <div className="mob-col-6 review-font marg-align mob-no-margin">
              {enablewirecenter && (
              <div className="d-flex flex-column outerdate datewid">
                      {/* <div>Date</div> */}
                      <div className="mob-aligen-right  review-text-head">
                       Review Status
                      </div>
                      <div className="mob-aligen-right review-text-content">
                       {reviewstatus.reviewStatus?reviewstatus.reviewStatus:'NA'}
                      </div>
                 </div>
              )}
              </div>
              <div className="mob-col-6 review-font marg-align mob-no-margin">
          <div className="d-flex flex-column outerdate datewid">
                  {/* <div>Date</div> */}
                  <div className="mob-aligen-right review-text-head">
                   Review Date & Time
                  </div>
                  <div className="mob-aligen-right review-text-content">
                  {
                        formattedDate === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedDate} </span>
                        )
                      }
                  &nbsp;
                     {
                        formattedTime === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedTime} CST</span>
                        )
                      }
                  </div>
             </div>
          
          </div>
              <div className="mob-col-6 review-font marg-align mob-no-margin">
              {enablewirecenter && (
              <div className="d-flex flex-column outerdate datewid">
                      {/* <div>Date</div> */}
                      <div className="mob-aligen-right review-text-head">
                       Issues
                      </div>
                      <div className="mob-aligen-right review-text-content">
                       {reviewstatus.fdhWise?reviewstatus.fdhWise:'NA'}
                      </div>
                 </div>
              )}
              </div>
        
         
         
        </div>
        )}
        {enablewirecenter && (
        <div className="d-flex flex-row filtertags mob-col-12 mob-flex-wrap tab-flex-wraps ">
           <div className="mob-col-6 marg-align mob-no-margin">
           {enablewirecenter && (
              <div className="d-flex flex-column outerdate datewid">
                      <div className="mob-aligen-right review-text-head">
                      Comments
                      </div>
                      <div className="mob-aligen-right review-text-content">
                       {reviewstatus.fdhComments?reviewstatus.fdhComments:'NA'}
                      </div>
                 </div>
              )} 
          </div>
        </div>
        )}
        </div>
      </div>

      
      <div className="fdhview mob-row tab-row">
        <div
          className="scrollable-table-container mob-col-4"
          style={{ width: "10%" }}
        >
          <FdhviewHeader tabhead={{ tabhead: "1" }} />

          <div
            className="scrollable-tbody-container"
            width="10%"
            style={{ height: "350px" }}
          >
            <table className="scrollable-table">
              <tbody>
                {/* {dataFDH.map((item, index) => (
                  <tr className="trfix" key={index}>
                    <td
                      key={index}
                      className={`button ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => handleItemClick(index)}
                    >
                      {item.fdhName.substring(12, 24)}
                      {activeButton === activeIndex && (
                        <span className="arrow"></span>
                      )}
                    </td>
                  </tr>
                ))} */}
               {filteredData.length > 0 ?(
                <>
                {filteredData?.map((item, index) => (
                  <tr className="trfix" key={index}>
                       <td
                      key={index}
                      className={`button ${
                        item.deviceName == activeIndex ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleItemClick(e, item)}
                    >
                      {item.deviceName}
                    </td>
                   
                  </tr>
                ))}</>
               ):(
                <tr>
                <td>No data</td>
                </tr>
               )}
                
                {/* {
                 showloading && (
                  <div>
                    <Contentloading/>
                  </div>
                 )
                } */}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="scrollable-table-container fdhdetailstab mob-col-8 "
          style={{ height: "350px", width: "85%" }}
        >
                <div className="mob-width-100  mb-2 mb-nomargin2">
                  {
                    activeIndex !=='' && (
                      <>
 {/* <button className="btn btn-primary" onClick={handleshowsplit} style={{'cursor':'pointer', 'width':'135px', 'font-size':'11px', 'border':'none','background':'#011E60'}}>View Splitter Issues</button> */}
                      </>
                    )
                  }
                   
                  </div>
            {tableheader.length > 0 && (
              <>
              <div className="d-flex justify-content-start filter-rev">
                <div className="d-flex flex-row mob-width-100">
                  <div className="mob-width-100 mb-nomargin2">
                    <select
                      class="filter-select mob-filtertag"
                      style={{ width: "10rem" }}
                      onChange={handlematchingfilt}
                    >
                      <option value="">All Filter</option>
                      <option value="match">Matching</option>
                      <option value="mismatch">Mismatching</option>
                    </select>
                  </div>
                  
                </div>
                </div>
              </>
            )}
         
          {/* <FdhviewHeader tabhead={{tabhead:"2",activeIndex:selectedIndex}} /> */}
          {enablesplitterview && (
        <Modalsplitter
          closemodissue={handleSplitClose}
          dataSplitter={splitterViewData}
          responseData=''
          issue='view'
        />
      )}

          <Fdhdatatab
            tdata={tableheader}
            spkeys={splitterKeys}
            reviewdata={reviewstatus}
            errorreview={errorreview}
            loading={loadingToggle}
            nodata={nodata}
            activeIndex = {activeIndex}
            handleItemClick={handleItemClick}
          />
        </div>
      </div>
      {/* {toggleModal && <Modalblack />} */}
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
    </>
  );
};

export default Fdhview;
