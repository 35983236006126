import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalAddressOnt.css";
import { API_KEY, BASE_API_URL } from "../environment";
import axios from "axios";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const ModalAddressOnt = ({ closeont, addont, sendAddressont }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const handleadd = () => {
    const dataToSend = false;
    addont(dataToSend);
    sendAddressont(inputValue);
  };

  const handleClickCancel = () => {
    const dataToSend = false;
    closeont(dataToSend);
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    if (inputValue.length >= 2) {
      axios
        .get(
          "https://centurylink-prod.apigee.net/v1-O2/typeAhead?addressesStartsWith=" +
            inputValue +
            "&maxResults=10&key=74rtvsz2HLMGSYKpBtu4JUbkEtabhps8"
        )
        .then((response) => {
          const data = response.data;
          setFilteredSuggestions(data.lstAddresses);
        })
        .catch((e) => {});
    }
  };
  const handleSelectSuggestion = (suggestion) => {
    setInputValue(suggestion.full_address_txt);
    setFilteredSuggestions([]);
  };
  const handleClearClick = () => {
    setInputValue("");
    setFilteredSuggestions([]);
  };
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal">
              <div className="m-header">
                <span>
                  <b>Select Address</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
              <div className="modalcontent">
                <div className="m-radios">
                  <div class="search-container-pages">
                    <div className="s-icon-one">
                      <FontAwesomeIcon icon={faSearch} />
                    </div>

                    <input
                      type="text"
                      className="search-inputs"
                      placeholder="Search Address"
                      value={inputValue}
                      onChange={handleChange}
                    />
                    {inputValue ? (
                      <span
                        className="clear-buttons-address"
                        onClick={handleClearClick}
                      >
                        &times;
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  {filteredSuggestions?.length > 0 && (
                    <ul className="list-search-address mob-ont-address">
                      <span>
                        {filteredSuggestions.map((suggestion) => (
                          <li
                            key={suggestion.full_address_txt}
                            onClick={() => handleSelectSuggestion(suggestion)}
                          >
                            {suggestion.full_address_txt}
                          </li>
                        ))}
                      </span>
                    </ul>
                  )}
                </div>
              </div>

              <button className="alert-bottom" onClick={handleadd}>
                UPDATE ADDRESS
              </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalAddressOnt;
