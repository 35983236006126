// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-containerwhite {
    position: fixed;
    display: table;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;  
    .modal-background {
      display: table-cell;
      background: rgba(0, 0, 0, 0.8);
      text-align: center;
      vertical-align: middle;
      .modals {
        background: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        height: auto;
        width: 30%;
        display: inline-block;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0px; 
        border-bottom-left-radius: 0px;
        font-weight: 300;
        position: relative;   
  
      }
      @media all and (min-width: 320px) and (max-width: 768px) {
        .modals{
          width: 80% !important;
        }
      }
    }
    .alert-bottoms-home{
      /* line-height: 25px; */
      /* font-weight: bold; */
      /* margin-bottom: 15px; */
      padding: 10px;
      width: 15%;
      /* border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px; */
      border: 2px #001F6A;
      font-size: 18px;
      background-color: #001F6A;
      color: white;
    }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/ModalWhite/modalOnt.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,YAAY;IACZ,WAAW;IACX,MAAM;IACN,OAAO;IACP,UAAU;IACV;MACE,mBAAmB;MACnB,8BAA8B;MAC9B,kBAAkB;MAClB,sBAAsB;MACtB;QACE,iBAAiB;QACjB,2CAA2C;QAC3C,YAAY;QACZ,UAAU;QACV,qBAAqB;QACrB,4BAA4B;QAC5B,6BAA6B;QAC7B,+BAA+B;QAC/B,8BAA8B;QAC9B,gBAAgB;QAChB,kBAAkB;;MAEpB;MACA;QACE;UACE,qBAAqB;QACvB;MACF;IACF;IACA;MACE,uBAAuB;MACvB,uBAAuB;MACvB,yBAAyB;MACzB,aAAa;MACb,UAAU;MACV;wCACkC;MAClC,mBAAmB;MACnB,eAAe;MACf,yBAAyB;MACzB,YAAY;IACd;EACF","sourcesContent":["#modal-containerwhite {\n    position: fixed;\n    display: table;\n    height: 100%;\n    width: 100%;\n    top: 0;\n    left: 0;\n    z-index: 5;  \n    .modal-background {\n      display: table-cell;\n      background: rgba(0, 0, 0, 0.8);\n      text-align: center;\n      vertical-align: middle;\n      .modals {\n        background: white;\n        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;\n        height: auto;\n        width: 30%;\n        display: inline-block;\n        border-top-left-radius: 10px;\n        border-top-right-radius: 10px;\n        border-bottom-right-radius: 0px; \n        border-bottom-left-radius: 0px;\n        font-weight: 300;\n        position: relative;   \n  \n      }\n      @media all and (min-width: 320px) and (max-width: 768px) {\n        .modals{\n          width: 80% !important;\n        }\n      }\n    }\n    .alert-bottoms-home{\n      /* line-height: 25px; */\n      /* font-weight: bold; */\n      /* margin-bottom: 15px; */\n      padding: 10px;\n      width: 15%;\n      /* border-bottom-right-radius: 10px;\n      border-bottom-left-radius: 10px; */\n      border: 2px #001F6A;\n      font-size: 18px;\n      background-color: #001F6A;\n      color: white;\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
