import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./modalOnt.css";
import { useNavigate } from "react-router-dom";

const modalOnt = ({ close,onDataFromChild }) => {
  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    // Call the function received from the parent with the data
    close(dataToSend);
  };
  const handleConfirm = ()=>{
    // Call the function received from the parent with the data
    onDataFromChild(false);
  }

  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modals">
            
              <div
                style={{
                  color: "#000",
                  padding: "30px 40px",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
                 Are you sure want to change the port?
              </div>
            </div>
            <div className="modal-bottom">
            <button className="alert-bottoms-home" onClick={handleConfirm}>Confirm</button>
              <button className="alert-bottoms-home" onClick={handleClick}>Close</button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default modalOnt;
