import React, { useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./ModalSplitter.css";
import axios from "axios";
import { Camera } from "react-cam";
import ErrorModal from "../ModalError/ErrorModal";
import { API_KEY ,BASE_API_URL} from "../environment";
import Loader from "../Loader/Loader";
import ModalFDH from "./ModalFDH";
const initialState = [];

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_ISSUE':
      var newState = state;
      const findIssue = state.findIndex(issue =>  issue && (issue.slot === action.row.slot));
      if (findIssue >= 0){
       newState[findIssue].issue =  action.issue ;
      return newState;
      } else {
        const newIssue = { slot: action.row.slot, issue: action.issue}
        newState.push(newIssue);
        return newState;
      }
  }
}

const Modalsplitter = (props) => {

  const datasplit = props.dataSplitter;
  const datavalue = props.responseData;
  const viewmode = props.issue;
  
  console.log(props,"check");
  const auditStatus = props.statusModal;
  const [showerrmodal, setShowerror] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showloader, setshowloader] = useState(false);
  const [data, dispatch] = useReducer(reducer, initialState);
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showissueview, setshowissueview] = useState(0);
  const handleIssueChange = (index, issue, row) => {
    dispatch({ type: 'UPDATE_ISSUE', index, issue ,row});
  };

  useEffect(()=>{
    if(viewmode == 'view'){
setshowissueview(1)
    }else{
      setshowissueview(0)
    }
  },[])

  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const Cuid = sessionStorage.getItem("encryptedKey");


  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    props.closemodissue(dataToSend);
  };

  const handleDataFromError = () => {
    setShowerror(false);
  };
  var username = sessionStorage.getItem("Name");
  
  const handleSend = async () => {
 const status = auditStatus ?  auditStatus : "Pending";
 setshowloader(true);
    try {
      const responseone = await axios.post(
        process.env.REACT_APP_BASE_API_URL +
          "/postSplitterView?status=" + status +
          "&user=" +
          username +
          "&fdhName=" +
          datavalue.fdh +
          "&wirecenter=" +
          datavalue.wireCenter+
          "&state=" +
          datavalue.state ,data,headers
      );

      var datas = responseone.data;
      if (datas == "Success") {
        setshowloader(false);
        setWhitemod(true);
      }
    } catch (error) {
      setshowloader(false);
     
      if (error.response) {
        if (error.response.status === 400) {
          setIsPopoverOpen(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }
  };
  const togglePopover = () => {
    setIsPopoverOpen(false);
  };
  const handleDataFromChild = () => {
    setWhitemod(false);
  };
  return (
    <>
    {showwhitemodal && <ModalFDH onDataFromChild={handleDataFromChild} />}
     {isPopoverOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Select Action</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {showloader && <Loader />}
      {showerrmodal && <ErrorModal onDataFromError={handleDataFromError} />}
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal modal-spliter">
              <div className="m-header splitter_view_header">
                <span>
                  <>Splitter view</>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
              <div style={{width:'100%'}}>
                <div className="modalcontent">
                  <div className="modal_view_size" style={{ height: "20rem" }}>
                    {
                      viewmode == 'view' ? (
                        <>
                        
                        </>
                      ):(
                        <>
                        <div className=" mb-2 splitter_button_send">
                    { datasplit.length > 0 ? (
                      <button
                      className="but-dark mob-but-fil"
                      style={{ width: "10rem" }}
                      onClick={handleSend}
                    >
                      Send
                    </button>
                    ):(
                      <></>
                    )}
                     

                    </div>
                        </>
                      )
                    }
                    
                    <div className="mob-overflow-scroll scroll_table" style={{"overflow":"scroll"}}  >  
                    <table className="table table-bordered">
                      <thead className="thead-lightfdh">
                        <tr>
                          <th>OLT</th>
                          <th>Feeder Cable</th>
                          <th>Slot</th>
                          <th>Splitter</th>
                          <th>Leg count</th>
                          {/* <th>Distribution Strand</th> */}
                          <th>Issue</th>
                          {/* <th>OnField Distribution Strand</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {datasplit.length > 0 ? (
                        <>
                          {datasplit.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {" "}
                              {/* Using index as key */}
                              <td>{row.olt}</td>
                              <td>{row.feederCbl}</td>
                              <td>{row.slot}</td>
                              <td>{row.splitter}</td>
                              <td>{row.legCount}</td>
                              <td>
                                { showissueview > 0 ? (<>
                                  {row.issue || ''}
                                </>
                                ):(
                                  <>
                                  <select className="table-input-splitter-view form-control-splitter-view" 
                                  defaultValue={row.issue || ''}
                                  onChange={(e) => handleIssueChange(rowIndex, e.target.value,row)}
                                >
                                  <option value="">Select</option>
                                  <option value="Wrong Light">Wrong Light</option>
                                  <option value="No Light">No Light</option>
                                  <option value="Incorrect Splitter Count">
                                    Incorrect Splitter Count
                                  </option>
                                  <option value="Splitter Missing">
                                    Splitter Missing
                                  </option>
                                </select>
                                  </>
                                )}
                                
                              </td>
                              {/* <td><input className="form-control table-input"></input></td> */}
                            </tr>
                          ))}
                       </> ):(
                          <>
                          <tr>
                            <td colSpan={6}>Loading Data....</td>
                          </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modalsplitter;
