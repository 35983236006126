// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-area {
	display: grid;
	place-items: center;
	/* height: 100vh; */
    padding: 30px;
}
.loader div {
	height: 10px;
	width: 10px;
    
	border-radius: 50%;
	transform: scale(0);
	animation: animate 1.5s ease-in-out infinite;
	display: inline-block;
	margin: .5rem;
}
.loader div:nth-child(0) {
	animation-delay: 0s;
}
.loader div:nth-child(1) {
	animation-delay: 0.2s;
}
.loader div:nth-child(2) {
	animation-delay: 0.4s;
}
.loader div:nth-child(3) {
	animation-delay: 0.6s;
}
.loader div:nth-child(4) {
	animation-delay: 0.8s;
}
.loader div:nth-child(5) {
	animation-delay: 1s;
}
.loader div:nth-child(6) {
	animation-delay: 1.2s;
}
.loader div:nth-child(7) {
	animation-delay: 1.4s;
}
@keyframes animate {
	0%, 100% {
		transform: scale(0.2);
		background-color: #BD0036;
	}
	40% {
		transform: scale(1);
		background-color: #F25330;
	}
	50% {
		transform: scale(1);
		background-color: #F2B900;
	}
}
`, "",{"version":3,"sources":["webpack://./src/ModalBlack/styling.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,mBAAmB;IAChB,aAAa;AACjB;AACA;CACC,YAAY;CACZ,WAAW;;CAEX,kBAAkB;CAClB,mBAAmB;CACnB,4CAA4C;CAC5C,qBAAqB;CACrB,aAAa;AACd;AACA;CACC,mBAAmB;AACpB;AACA;CACC,qBAAqB;AACtB;AACA;CACC,qBAAqB;AACtB;AACA;CACC,qBAAqB;AACtB;AACA;CACC,qBAAqB;AACtB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,qBAAqB;AACtB;AACA;CACC,qBAAqB;AACtB;AACA;CACC;EACC,qBAAqB;EACrB,yBAAyB;CAC1B;CACA;EACC,mBAAmB;EACnB,yBAAyB;CAC1B;CACA;EACC,mBAAmB;EACnB,yBAAyB;CAC1B;AACD","sourcesContent":[".loading-area {\n\tdisplay: grid;\n\tplace-items: center;\n\t/* height: 100vh; */\n    padding: 30px;\n}\n.loader div {\n\theight: 10px;\n\twidth: 10px;\n    \n\tborder-radius: 50%;\n\ttransform: scale(0);\n\tanimation: animate 1.5s ease-in-out infinite;\n\tdisplay: inline-block;\n\tmargin: .5rem;\n}\n.loader div:nth-child(0) {\n\tanimation-delay: 0s;\n}\n.loader div:nth-child(1) {\n\tanimation-delay: 0.2s;\n}\n.loader div:nth-child(2) {\n\tanimation-delay: 0.4s;\n}\n.loader div:nth-child(3) {\n\tanimation-delay: 0.6s;\n}\n.loader div:nth-child(4) {\n\tanimation-delay: 0.8s;\n}\n.loader div:nth-child(5) {\n\tanimation-delay: 1s;\n}\n.loader div:nth-child(6) {\n\tanimation-delay: 1.2s;\n}\n.loader div:nth-child(7) {\n\tanimation-delay: 1.4s;\n}\n@keyframes animate {\n\t0%, 100% {\n\t\ttransform: scale(0.2);\n\t\tbackground-color: #BD0036;\n\t}\n\t40% {\n\t\ttransform: scale(1);\n\t\tbackground-color: #F25330;\n\t}\n\t50% {\n\t\ttransform: scale(1);\n\t\tbackground-color: #F2B900;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
