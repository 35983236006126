import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./ModalImage.css";
import ImgIco from "../assets/upload.png";
import axios from "axios";
import DragDropFile from "../Dragdrop";
import { Camera } from "react-cam";
import { BASE_API_URL } from "../environment";
import ErrorModal from "../ModalError/ErrorModal";
import { headersImage } from "../headers";

const ModalImageUpload = (props ) => {
  console.log(props,'csf')
  const [selectedValue, setSelectedvalue] = useState("");
  const [showcamera, setcameravisible] = useState("");
  const [showerrmodal, setShowerror] = useState(false);
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [imagSize, setImageSize] = useState("");
  const [fileupload, setfileupload] = useState({});
  const [customFileName, setcustomFileName] = useState("");
  console.log(props)
  function capture(imgSrc) {
      
    setSelectedvalue(imgSrc);
    setcameravisible(false);
    console.log(imgSrc);
  }
  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    props.closemodissue(dataToSend);
  };
  function handleFile(files) {
    var facility = props.data;
    console.log(props)
    
    if(props.id){
      var facilityport = facility;
      var mb =  files.size / 1048576;
      var parsevalue = parseFloat(mb.toFixed(2))
   setImageSize(parsevalue)
       if (files.size > 4 * 1024 * 1024) { // Check if image size exceeds 5 MB
         compressImage(files); // Compress the image if size exceeds 5 MB
       }else{
       if(files){
         const reader =  new FileReader();
         reader.onloadend = ()=>{
           setSelectedvalue(reader.result);
         }
         reader.readAsDataURL(files);
       }
       const formData = new FormData();
       // Customize the name of the file here
       const customFileName = `${facilityport}.${files.name.split('.').pop()}`;
       console.log(customFileName,'che')
       setcustomFileName(customFileName)
         formData.append('image', files, customFileName);
         setfileupload(formData)
       setSelectedvalue(URL.createObjectURL(files));
       console.log(files)
     }
    }
    else{
      var mb =  files.size / 1048576;
      var parsevalue = parseFloat(mb.toFixed(2))
   setImageSize(parsevalue)
       if (files.size > 4 * 1024 * 1024) { // Check if image size exceeds 5 MB
         compressImage(files); // Compress the image if size exceeds 5 MB
       }else{
       if(files){
         const reader =  new FileReader();
         reader.onloadend = ()=>{
           setSelectedvalue(reader.result);
         }
         reader.readAsDataURL(files);
       }
       const formData = new FormData();
       // Customize the name of the file here
       const customFileName = `${facility}.${files.name.split('.').pop()}`;
       setcustomFileName(customFileName)
         formData.append('image', files, customFileName);
         setfileupload(formData)
       setSelectedvalue(URL.createObjectURL(files));
       console.log(files)
     }
    }
  
    // alert("Number of files: " + files.length);
  }
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);
  const cam = useRef(null);
  // handle drag events
  const handleDrag = function (e) {
    // e.preventDefault();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const handleDelete = () => {
    setSelectedvalue(null);
  };

  const uploadImage = () => {
    var typeValue = props.type;
    fetch(
      process.env.REACT_APP_BASE_API_IMAGE_URL + '/upload?deviceType=' + typeValue , {
          method: 'POST',
          body: fileupload,
          headers: {
            "apikey": process.env.REACT_APP_API_IMAGE_KEY,
          }
        })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // alert('eew')
        //   console.log(data);
          props.imgbase64(selectedValue)
          props.closemodissue(false);
           // fetchDataNds();
      })
      .catch((error) => {

        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });

 }

  const compressImage = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxWidth = 800; // Max width for the resized image
        const maxHeight = 600; // Max height for the resized image
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        const compressedImageData = canvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed
        setSelectedvalue(compressedImageData);
      };
    };
    reader.readAsDataURL(file);
  };

  const handledeleteimg = ()=>{
    var imageName = props.imageName;
    axios
    .delete(
      process.env.REACT_APP_BASE_API_IMAGE_URL + '/deleteImage?imageName=' + imageName,
      headersImage
    )
    .then((response) => {
      console.log(response);
      props.closemodissue(false);
      // const promiseResult = response.data[0].imageBase64;
      // setactiveviewimage(true);
      // setImageURL(promiseResult);
    })
    .catch((error) => {
      if (error) {
        if (
          error.status === 400 ||
          error.status === 502 ||
          error.status === 404 ||
          error.status === 500
        ) {
          // setactiveviewimage(false);
          
        }
      } else {
      }
    });
  }
  const cameraactive = () => {
    setcameravisible(!showcamera);
  };
  // triggers when file is dropped
  const handleDrop = function (e) {
    // e.preventDefault();
    // e.stopPropagation();
    setDragActive(false);

    handleFile(e.dataTransfer.files[0]);
  };
  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };
  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  return (
    <>
 {showerrmodal && <ErrorModal onDataFromError={handleDataFromError} />}
      {ReactDOM.createPortal(
        
        <div id="modal-containerwhite">
          <div className="modal-background">
          {props.imagerole == true ? (
            <div className="modal-image mob-mod-set">
              <div className="m-header">
                <span>
                  <>{props.imagerole == true ? 'View Image': 'Upload File'}</>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
                <>
                <div className="modalcontent">
                  {
                    (props.mode == 'audit' && props.role == 'Super Admin') && (
                      <div className="d-flex justify-content-end">
                      <FontAwesomeIcon style={{'color':'red','cursor':'pointer'}} icon={faTrash}onClick={handledeleteimg} ></FontAwesomeIcon>
                     </div>
                    )
                  }
                 
                  {
                    props.imageurl && (
                      <img  className="mob-img-set img-size" src={`data:image/png;base64,${props.imageurl}`} ></img>
                    )
                  }
              </div>
              </>
              </div>
              ):(

                <div className="modal mob-mod-set">
                <div className="m-header">
                  <span>
                    <>{props.imagerole == true ? 'View Image': 'Upload File'}</>
                  </span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      color: "#001f6a",
                      height: "25px",
                      width: "25px",
                      cursor: "pointer",
                    }}
                    onClick={handleClickCancel}
                  />
                </div>
                <>
                <div className="modalcontent">
                {showcamera && (
                  <>
                    <Camera
                      showFocus={true}
                      front={false}
                      capture={capture}
                      ref={cam}
                      width="100%"
                      height="50%"
                      focusWidth="100%"
                      focusHeight="50%"
                      btnColor="white"
                    />
                    <br></br>
                  </>
                )}

                {/* <form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  // onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={false}
                    onChange={handleChange}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? "drag-active" : ""}
                  >
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <img src={ImgIco} />
                      <div className="d-flex flex-column">
                        <div style={{ "font-weight": "bold" }}>
                          Drag and Drop
                        </div>
                        <div>
                          <span>Upload only JPEG, JPG, PNG</span>
                        </div>
                      </div>
                      <b></b>
                    </div>
                  </label>
                 
                 
                </form> */}
                <div className="mt-2">
                  {!showcamera && (
                    <>
                    <div className="d-flex justify-content-center">
                      <div>  <span>Image upload limited to 4 MB If it exceeds the limit, it will automatically compress. </span></div>
                    </div>
                      <div className="d-flex justify-content-center mt-2">
                        <div>
                        
                          <input
                            type="file"
                            id="actualbtn"
                            accept="image/jpeg, image/jpg, image/png, image/heic"
                            hidden
                            multiple={false}
                            onChange={handleChange}
                          />
                          <label for="actualbtn" className="img-upload-btn">
                            Select Image
                          </label>
                          {/* <button onChange={handleChange} className="img-upload-btn">
                      Upload
                    </button> */}
                        </div>
                     
                      </div>
                      <div className="d-flex justify-content-center mt-1">
                      <span>Accept only JPG, JPEG, PNG, HEIC Format</span>
                      </div>
                    </>
                  )}
                </div>
                {showcamera && (
                  <>
                    <button
                      className="img-upload-btn"
                      onClick={(img) => cam.current.capture(img)}
                    >
                      Capture
                    </button>
                  </>
                )}

                {selectedValue && (
                  <div className="loaded-item mt-2">
                    <div className="d-flex flex-row main-cont-set-img">
                      <div className="imgside mob-display-hide">
                        <img className="img-box" src={selectedValue}></img>
                      </div>
                      <div className="contentside mob-wid-set">
                        <div className="d-flex flex-row">
                          <div
                            className="d-flex flex-column img-wid-set"
                          >
                            <div className="img-title mt-1">
                              <span>
                                <b>{customFileName?customFileName:'NA'}</b>
                              </span>
                            </div>
                            <div className="img-loading mt-1">
                              <span></span>
                            </div>
                            <div className="upload-font">
                              <span>Uploaded</span>
                            </div>
                            <div className=" mt-1">
                              <span>{imagSize} MB</span>
                            </div>
                          </div>
                          <div className="fontside">
                            <FontAwesomeIcon
                              onClick={handleDelete}
                              icon={faTrash}
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <button className="img-upload-btn" onClick={uploadImage} >Submit</button>
                    </div>
                  </div>
                )}
              </div></>
              </div>
              )}
            </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalImageUpload;