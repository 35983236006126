import React, { useState } from "react";
import { useEffect } from "react";
import { Outlet, Link, useNavigate,useLocation } from "react-router-dom";
import ModalPage from "./ModalWhite/modalpage";
import "./App.css";
import "./ReviewtabMst.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/Lumen Logo White.png";
import mstreview from "./assets/mstreview.png";
import field from "./assets/fieldoperation.png";

function ReviewtabFdh(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const navigate = useNavigate();
  const storedName = sessionStorage.getItem("Name");
  const role = sessionStorage.getItem("Role");
  const [pageactive, setPageactive] = useState(false);
  const [showpagemodal, setPageModal] = useState(false);
  const [showdrop, setDropdown] = useState(false);

  const history = useLocation();
  const currentpath = history.pathname;
  console.log(currentpath.pathname);


  useEffect(() => {
    if (storedName == null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (props.data == true) {
      setPageactive(props.data);
    } else {
      setPageactive(false);
    }
    // setpagesavePop(p)
  }, [props.data]);

  const onpagenav = () => {
    if (pageactive == true) {
      setPageModal(true);
    }
    if (pageactive == false) {
      navigate("/search");
    }
  };

  const handleLogout = () => {
    // sessionStorage.clear();
    // navigate('/');
    setDropdown(!showdrop);
  };
  const handlechangepath = (value)=>{
    navigate(value)
  }
  const callLogout = () =>{
    sessionStorage.clear();
    navigate('/')
  }
  const callAccess = () =>{
    navigate('/access')
  }
  const handleDataFromPage = () => {
    setPageModal(false);
  };
  return (
    <>
      <div className="review-tab">
        <div className="d-flex flex-row">
            <div onClick={()=>{handlechangepath('/review')}} className= { currentpath == '/review' ? 'section-tab-review reviewtab-active' : 'section-tab-review' }>
              <span ><img  src={mstreview} className="review-icon-size"/></span>&nbsp;&nbsp;<span>FDH Review</span>
            </div>
            <div onClick={()=>{handlechangepath('/archivefdh')}} className={ currentpath == '/archivefdh' ? 'section-tab-review reviewtab-active' : 'section-tab-review' }>
            <span ><img  src={field} className="review-icon-size"/></span>&nbsp;&nbsp;<span>Archive</span>
            </div>
        </div>
      </div>
    </>
  );
}

export default ReviewtabFdh;
