import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './Main';
import Search from './Search';
import Login from './Login/Login';
import ReviewMain from './Review/reviewMain';
import MstMain from './MstMain';
import MSTReviewMain from './MstReview/MSTReviewMain';
import MSTFieldOPMain from './MstFOPReview/MSTFieldOPMain';
import MSTConduitMain from './ConduitExtentionReview/MSTConduitMain';
import ReportMst from './report_mst';
import SearchFdhMst from './Search-fdh-mst';
import ManageAccess from './ManageAccess';
import OntMain from './OntMain';
import OntSwap from './ontswap';
import ONTReviewMain from './OntReview/ONTReviewMain';
import FDHFOPMain from './FDHFOPReview/FDHFieldOPMain';
import FeedbackReview from './Feedback/FeedbackReview';
import ReviewMainFdh from './ReviewArchiveFdh/reviewMain';
import ReviewMainMst from './MstReviewArchive/MSTReviewMain';
import FopArchiveFdh from './FDHFOPReviewArchive/FDHFieldOPMain';
import MSTFOPMain from './MstFOPReviewArchive/MSTFieldOPMain';
import AddressSearch from './AddressAdd/AddressSearch';
import ArchiveFeedbackReview from './Feedback/ArchiveFeedbackReview';
import PageNotFound from './NetworkError/PageNotFound';
function Router() {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/main/:fdhName/:latitude/:longitude/:address/:strand" element={<Main />}>  </Route>
      <Route path="/mstmain/:mstName/:fdhstring/:mstportCount" element={<MstMain />}>  </Route>
      <Route path="/ontmain/:dtnName/:mstTpName/:Tpport" element={<OntMain />}>  </Route>
      {/* <Route path="/search" element={<Search />}>  </Route> */}
      <Route path="/access" element={<ManageAccess />}>  </Route>
      <Route path="/search" element={<SearchFdhMst />}>  </Route>
      <Route path="/mstreview" element={<MSTReviewMain />}>  </Route>
      <Route path="/fopreview" element={<MSTFieldOPMain />}>  </Route>
      <Route path="/fdhfopreview" element={<FDHFOPMain />}>  </Route>
      <Route path="/conduit" element={<MSTConduitMain />}>  </Route>
      <Route path="/ontreview" element={<ONTReviewMain />}>  </Route>
      <Route path="/archivefdh" element={<ReviewMainFdh />}>  </Route>
      <Route path="/archivemst" element={<ReviewMainMst/>}>  </Route>
      <Route path="/archivefopmst" element={<MSTFOPMain/>}>  </Route>
      <Route path="/archivefopfdh" element={<FopArchiveFdh/>}>  </Route>
      <Route path="/review" element={<ReviewMain />}>  </Route>
      <Route path="/report" element={<ReportMst />}>  </Route>
      <Route path="/ontswap" element={<OntSwap />}>  </Route>
      <Route path="/feedbackreview" element={<FeedbackReview/>}>  </Route>
      <Route path="/archivefeedback" element={<ArchiveFeedbackReview/>}>  </Route>
      <Route path="/addressadd/:mstname/:centralofc" element={<AddressSearch/>}></Route>
      <Route path="*" element={<PageNotFound/>}></Route>
      <Route path="/" element={<Login />}>
        
        {/* <Route index element={<Search />} /> */}
      
      </Route>
    </Routes>
  </BrowserRouter>
  )
}

export default Router;




