import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import "./ModalFDH.css";

const ModalDefective = ({ closeModal,messageError}) => {
 
  const handleClick = () => {
    const dataToSend = false;
    closeModal(dataToSend);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
          <div className="modals">   
            <div
              style={{
                color: "#000",
                padding: "30px 40px",
                fontSize: "20px",
              }}
            >
              <FontAwesomeIcon icon={faExclamationCircle} style={{color: "#ee2222",margin:"10px", height:"40px",width:"40px"}} /> <br/>
              <b>Unable To Update Defective Status</b> 

              <div style={{fontSize:"14px"}}> <b>{messageError}</b></div>
            </div>
          </div>
          <div className="modal-bottom">
            <button className="alert-bottoms-onterror" onClick={handleClick}>Close</button>
          </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalDefective;
