import React from "react";
import ReactDOM from "react-dom";
import "./modal.css";
import Modalloader from "./Modalloader";
import Modalalert from "./Modalalert";


const Modal = (props) => {
  const closeFunction = () => {
    props.action();
  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerblack">
          <div className="modal-backgroundblack">
            <div className="modal">
              {/* <div className="modal-header">                
              </div> */}
              <Modalalert />
              <Modalloader />
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modal;
