import React, { useState, useEffect, useReducer, useRef } from "react";
import Contentloading from "./ONTContentloading";
import "./css/ONTdatatab.css";
import axios from "axios";
import * as XLSX from "xlsx";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import ModalWhite from "../ModalWhite/ModalFDH";
import Modalerror from "../ModalError/ErrorModal";
import { API_KEY, BASE_API_URL } from "../environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faImage } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ModalImageUpload from "../ModalWhite/ModalImage";
import ModalReviewConfirm from "../ModalWhite/ModalReviewConfirm";
import ModalDelete from "../ModalWhite/ModalDelete";
import {  headersImage } from "../headers";
const initialState = [];

const ONTdatatab = (data) => {
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [status, setStatus] = useState("Pending");
  var username = sessionStorage.getItem("Name");
  const Cuid = sessionStorage.getItem("cuid");
  const navigate = useNavigate();
  var [enableEdit, setenableEdit] = useState(false);
  const [postData, setPostData] = useState([]);
  const role = sessionStorage.getItem("Role");
  const [tabData, setTabData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [getIndex, setIndex] = useState("");
  const [fdhToggle, setfdhToggle] = useState(false);
  const [filter, setfilter] = useState([]);
  const [allProperties, setAllproperties] = useState([]);
  const [saveToggle, setSaveToggle] = useState(false);
  const [o2Updated, setO2Updated] = useState(false);
  const [ndsUpdated, setNdsUpdated] = useState(false);
  const [showdelaudit, setshowdeleteaudit] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showmodaldel, setModaldelete] = useState(false);
  var [imageBase64, setImageBase64]= useState("");
  var [viewimageupload,setactiveviewimage] = useState(false);
  var [imageURLdata, setImageURL] = useState({});
  const [showmodalimg, setShowmodalimage] = useState(false);
  const [showLevelConfirm, setshowLevelConfirm] = useState(false);
  const [inputcomments, setInputComments] = useState("");
  const tableRef = useRef(null);
  // const [cmsStand, setCmsStand] = useState([]);

  useEffect(() => {
    checkaccess();
    if (data.tdata == "") {
      console.log("im here too");
    }
  }, []);

  useEffect(() => {
    setO2Updated(false);
    setNdsUpdated(false);
    setSelectedOption("");
    setInputComments("");
    if (data.tdata != "") {
      console.log(data.tdata);
      setAllproperties(Object.keys(data.tdata[0]));
      setfdhToggle(true);
      setTabData(data.tdata);
      getImageUpload(data.tdata)
      setfilter(data.tdata);
      handleChange();
      setInputComments(data.reviewcomments.reviewRemarks);
      // setCmsStand(() => {
      //   const convertedData = data.tdata.reduce((acc, item) => {
      //     const { id, cmsStand, splitterLeg } = item;
      //     if (!acc[id]) {
      //       acc[id] = {};
      //     }

      //     if (!acc[id][splitterLeg]) {
      //       acc[id][splitterLeg] = { cmsStand };
      //     }

      //     return acc;
      //   }, {});

      //   return convertedData;
      // });
    }

    if (data.loading == true) {
      setfdhToggle(false);
    }

    if (data.tdata == "") {
      setfilter([]);
    }
  }, [data.tdata]);
  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const handleImageClose = () => {
    setShowmodalimage(false);
  };
  const handleImageBase = (data) => {
      setImageBase64(data)
  };
  const handleImagePass = () => {
    setShowmodalimage(false);
  };

  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setshowdeleteaudit(true);
        break;
      case "Structure Load":
        setenableEdit(true);
        break;
      case "View Only":
        setenableEdit(false);
        break;
      case "Field Technician":
      case "Facility Support":
      case "SRO":
      case "Field Operations":
      case "Engineer":
        navigate("/");
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   console.log(cmsStand);
  // }, [cmsStand]);

  const cancelData = () => {
    setPostData([]);
    var arrdata = data.tdata;
    arrdata.map((d, rowIndex) => {
      const comments = document.getElementById(
        "comments" + "-" + d.id + "-" + rowIndex
      );
      comments.value = "";
    });
    setSaveToggle(false);
  };
  const handleChangedropdown = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    // Clear input value when a new option is selected
    setInputValue("");
  };
  const getImageUpload = (data) => {
    axios.get(
      process.env.REACT_APP_BASE_API_IMAGE_URL +"/get?fileName=" + data[0].ont , headersImage
    )
    .then(response => {  
      const promiseResult = response.data[0].imageBase64;
      setactiveviewimage(true);
      setImageURL(promiseResult);  
    })  
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };
  const handleInputChange = (e) => {
    const uppercaseValue = e.target.value.replace(/[^A-Z=0-9\s]/g, "");
    setInputValue(uppercaseValue);
  };
  const handleInputComments = (e) => {
    const comments = e.target.value;
    setInputComments(comments);
  };
  const handleCheckboxChange = (e) => {
    const selectedvalue = e.target.value;
    if (selectedvalue === "O2 Updated") {
      setO2Updated(selectedvalue);
      setNdsUpdated("");
    } else if (selectedvalue === "Nds Updated") {
      setO2Updated("");
      setNdsUpdated(selectedvalue);
    }
  };
  const saveData = (value) => {
    var updatedData;
    // if(postData.length>0){
    //   updatedData = postData.map((item) => {
    //     const newItem = { ...item };
    //     propertiesToRemove.forEach((prop) => delete newItem[prop]);
    //     return newItem;
    //   });
    // }else{
    //    updatedData=[null];
    // }

    console.log(postData);
    axios
      .post(
        process.env.REACT_APP_BASE_API_URL +
          "/postOntReviewDtls?" ,
        postData, headers
      )
      .then((response) => {
        console.log("Successfully-> " + JSON.stringify(response));
        setWhitemod(true);
        if (response.data == "Success") {
          setPostData([]);
          data.handleItemClick(null, null, "save");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setShowerror(true)
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true)
          }
        } else {
          setShowerror(true)
        }
      });
  };

  useEffect(() => {
    console.log(postData);
  }, [postData]);

  const handleChangeCms = (e, row, rowIndex, property, from) => {
    setErrorMessage("");
    const value = e.target.value;
    console.log(row);
    setSaveToggle(true);
    let newData;
    var data = "";
    if (/^[a-zA-Z0-9\s=]*$/.test(value)) {
      row[property] = e.target.value;
      if (from == "e") {
        newData = {
          ...row,
          rowIndex: rowIndex,
          deviceType: e.target.value,
          comments: document.getElementById(
            "comments" + "-" + row.id + "-" + rowIndex
          ).value,
          reviewBy: username,
        };
      } else if (from == "stat") {
        if (e.target.value !== "0") {
          newData = {
            ...row,
            rowIndex: rowIndex,
            // comments: e.target.value,
            reviewStatus: document.getElementById(
              "reviewStatus" + "-" + row.id + "-" + rowIndex
            ).value,
            reviewBy: username,
          };
        }
      }

      let bool = false;
      // newData[property] = e.target.value;
      setTimeout(
        setPostData((prevstate) => {
          const existingEntry = prevstate.findIndex(
            (entry) => entry.id === row.id
          );

          if (existingEntry !== -1) {
            return [
              ...prevstate.slice(0, existingEntry),

              newData,

              ...prevstate.slice(existingEntry + 1),
            ];
          } else {
            return [...prevstate, newData];
          }
        }),

        100
      );
    } else {
      if (from == "e") {
        data = row[property];
        const remarks = document.getElementById(
          "remarks" + "-" + row.id + "-" + rowIndex
        );
        remarks.value = data;
        setErrorMessage(
          "Please Enter Valid Input, Special Characters are not allowed"
        );
      } else if (from == "stat") {
        data = row[property];
        const device = document.getElementById(
          "reviewStatus" + "-" + row.id + "-" + rowIndex
        );
        device.value = data;
      }
    }
    console.log(newData);
  };

  // console.log(cmsData, "cmsdtaa");

  const handleDataFromChild = () => {
    setModaldelete(false)
    setWhitemod(false);
  };

  const includesWithNullCheck = (inputString, targetSubstring) => {
    return (
      typeof inputString === "string" &&
      typeof targetSubstring === "string" &&
      (inputString.includes(targetSubstring) || inputString === targetSubstring)
    );
  };

  const handleChange = (e) => {
    const filteredData = data.tdata;
    console.log(filteredData);
    setfilter([...filteredData]);
    // console.log(e.target.value);
    // console.log(data.tdata);
    // setIndex(e.target.value);

    // if (e.target.value != "0") {
    //   const filteredData = data.tdata.filter(
    //     (item) => item.id == e.target.value
    //   );
    //   setfilter([...filteredData]);
    // }
  };

  useEffect(() => console.log(filter), [filter]);
  const togglePopover = () => {
    // setIsPopoverOpen(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  //excel new code
  const exportToExcel = () => {
    const datas = data.tdata;
    const capital = (obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toUpperCase()] = obj[key];
      }
      return newObj;
    };
    const finaldata = datas.map(capital);
    const ws = XLSX.utils.json_to_sheet(finaldata);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "ONT_REVIEW.xlsx";

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.href = url;

    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
  };
  const handleDelete = () =>{
    setshowLevelConfirm(true);
  }
  const handlecloseLevelmodal = () => {
    setshowLevelConfirm(false);
  };
  const handleLevelconfirm = () => {
    setshowLevelConfirm(false);
    handlecalldelete()
  };
  
  const handlecalldelete = ()=>{
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/deleteAuditData?'  + '&deviceType=ont' + '&ont=' +  data.tdata[0].ont ;
    axios
    .delete(apiUrl, headers)
    .then((response) => {
      setModaldelete(true);
      setPostData([]);
      setfilter([]);
      setAllproperties([])
      data.handleclear('clear');
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          setShowerror(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    });
  }
  return (
    <>
     {showLevelConfirm && (
        <ModalReviewConfirm
          closePopUps={handlecloseLevelmodal}
          confirmModules={handleLevelconfirm}
        />
      )}
       {showmodaldel && <ModalDelete  onDataFromChild={handleDataFromChild} />}
     {showmodalimg && <ModalImageUpload data={data.tdata[0].ont}
     imagerole = {viewimageupload}
     imageurl = {imageURLdata}
    onDataFromChild={handleImagePass}
        closemodissue={handleImageClose}  
        imgbase64 = {handleImageBase}
      />}
      {showwhitemodal && <ModalWhite onDataFromChild={handleDataFromChild} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {/* {isPopoverOpen && (
        <div className="popover-backdrop">
        <div className="popover">
          <div className="popover-content">
            <p>Please Enter the Data in Valid Field</p>
            <button onClick={togglePopover} className="popover-close-button">
              Close
            </button>
          </div>
        </div>
      </div>
      )} */}
      {errorMessage && (
        <div className="d-flex justify-content-center">
          <span style={{ color: "red" }}>{errorMessage}</span>
        </div>
      )}
      <div className="dropDown_container dropDown_container_mob mob-flex-wrap">
        <div className="dropDown_1 dropDown_12">
          {/* {filter.length > 0 && (
            <div className="update-checkbox update-checkbox-mob">
              <div class="form-check">
                <input class="m-1 reviewinp" type="radio"  value='O2 Updated' onChange={handleCheckboxChange} checked={o2Updated === 'O2 Updated'}  id="flexCheckDefault"></input>
                <label class="form-check-label" for="flexCheckDefault">
                  O2 Updated
                </label>
              </div>
              <div class="form-check">
                <input class="m-1 reviewinp" type="radio" value='Nds Updated' onChange={handleCheckboxChange} checked={ndsUpdated === 'Nds Updated'}  id="flexCheckDefaultone"></input>
                <label class="form-check-label" for="flexCheckDefaultone">
                 Nds Updated
                </label>
              </div>
            </div>
        )}
         
          {filter.length > 0 && (
          <div className="d-flex justify-content-center range-container range-container-mob">
              <div className="range-select range-select-mob">
              <label>Range</label>
                        <select className="range-options range-select-mobile" id="options" value={selectedOption} onChange={handleChangedropdown}>
                        <option value="">Select</option>
                        <option value="ONT to MST">ONT to MST</option>
                        </select>
                        </div>
          {(selectedOption === "ONT to MST" 
            ) && (
              <div className="mst-name">
                <label >MST Name</label>
                <input
                className="review-mst-name"
                type="text"
                id="inputValue"
                value={inputValue}
                onChange={handleInputChange}
              />
             </div>
           )}
           <div className="comments">
                <label>Comments</label>
                <textarea
                className="review-mst-comments"
                id="inputcomments"
                value={inputcomments}
               onChange={handleInputComments}
              />
             </div>
        </div>
          )} */}
        </div>

        {enableEdit && (
          <>
            <div className="dropDown_2 mob-dropDown_2">
              {filter.length > 0 && (
                <button className="cancelBtn" onClick={cancelData}>
                  Cancel
                </button>
              )}
            </div>
            <div className="dropDown_2 mob-dropDown_2 tab-margin-button-set tab-margin-left-set">
          {filter.length > 0 &&  showdelaudit &&(
            <button className="excelbutton" onClick={handleDelete}>
              Delete
            </button>
          )}
        </div>
            <div className="dropDown_2 mob-dropDown_2">
              {filter.length > 0 && (
                <button className="excelbutton" id="saveBtn" onClick={saveData}>
                  Save
                </button>
              )}
            </div>
          </>
        )}
        {filter.length > 0 && (
          //     <DownloadTableExcel
          //     filename="users table"
          //     sheet="users"
          //     currentTableRef={tableRef.current}>
          //     <button className="excelbuttons"> Export excel </button>
          // </DownloadTableExcel>
          <div className="mob-dropDown_2">
            <button onClick={exportToExcel} className="excelbutton">
              Export{" "}
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faDownload} />
              </span>
            </button>
          </div>
        )}
        { viewimageupload == true && (
          <div className="mob-dropDown_2 tab-margin-button-set">
            <button className="excelbutton" onClick={handleImageShow}>
              View &nbsp;
              <span className="hidden-mob">
                <FontAwesomeIcon icon={faImage} />
              </span>
            </button>
          </div>
          
        )}
      </div>

      <div className="scrollable-tbody-container" width="85%">
        <table className="tabletab scrollable-table textbrk" ref={tableRef}>
          {data.tdata != "" && filter != "" ? (
            <>
              <thead style={{ position: "sticky", top: "0" }}>
                <tr>
                  {data != "" &&
                    allProperties.map((columnName) => (
                      <th
                        className={`tabhead ${
                          columnName.length > 13
                            ? "widthdata200"
                            : "widthdata150"
                        }`}
                        key={columnName}
                      >
                        {columnName.toUpperCase()}
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody>
                {filter != "" &&
                  filter.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {/* {filter.map((row, index) => ( */}

                      <tr key={rowIndex}>
                        {allProperties.map((propty) => {
                          if (propty === "comments") {
                            return (
                              <td key={propty}>
                                <textarea
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  defaultValue={row[propty]}
                                  disabled={!enableEdit}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,
                                      row,
                                      rowIndex,
                                      propty,
                                      "e"
                                    )
                                  }
                                  style={{ width: "100%" }}
                                  // onChange={handleField}
                                />
                              </td>
                            );
                          } else if (propty === "reviewStatus") {
                            console.log(row[propty]);
                            return (
                              <td key={propty}>
                                <select
                                  className="reviewstat"
                                  disabled={!enableEdit}
                                  defaultValue={row[propty]}
                                  id={`${propty}-${row.id}-${rowIndex}`}
                                  onChange={(e) =>
                                    handleChangeCms(
                                      e,
                                      row,
                                      rowIndex,
                                      propty,
                                      "stat"
                                    )
                                  }
                                >
                                  <option value="0">Select Status</option>
                                  <option value="O2 UPDATED">O2 Updated</option>
                                  <option value="NDS UPDATED">
                                    NDS Updated
                                  </option>
                                  <option value="O2 NDS UPDATED">
                                    O2 &NDS Updated
                                  </option>
                                </select>
                              </td>
                            );
                          } else {
                            return <td key={propty}>{row[propty]}</td>;
                          }
                        })}
                      </tr>

                      {/* ))} */}
                    </React.Fragment>
                  ))}
              </tbody>
            </>
          ) : (
            <>
              {!data.loading ? (
                <div
                  style={{
                    // display: "inline",

                    alignItems: "center",

                    fontSize: "17px",

                    color: "#011e60",

                    fontWeight: "bolder",

                    marginTop: "50px",
                  }}
                >
                  <>
                    {!data.errorreview == "" ? (
                      <>{data.errorreview}</>
                    ) : (
                      <>Please Select Status</>
                    )}
                  </>
                </div>
              ) : (
                <Contentloading />
              )}
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default ONTdatatab;
