import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalFeedback.css";
import axios from "axios";
import feedbackImg from "../assets/feedbackImg.png";


const ModalFeedback = ({onClose}) => {
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
 const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  var username = sessionStorage.getItem("Name");
  var cuID = sessionStorage.getItem("cuid");
  const Cuid = sessionStorage.getItem("encryptedKey");
  const role = sessionStorage.getItem("Role");
  const [showfeed, setshowfeed] = useState(true);

  // const handleSendFeedback = async () => {

  //   const Issue = answers.issueFaced;
  //   const Info = answers.infoProvided;

  // setshowloader(true);
  // try {
  //   const responseone = await axios.post(
  //     process.env.REACT_APP_BASE_API_URL +
  //       "&user=" +
  //       username +
  //       "&role="+role+
  //       "&issueFaced=" +
  //       Issue +
  //       "&infoProvided=" +
  //       Info +
  //       "&additionalFeedback=" +
  //       additionalFeedback ,
  //       headers
  //   );

  //   var datas = responseone;
  //  if (datas == "Success") {
  //    setshowloader(false);
  //    setWhitemod(true);
  //  }
  //   if (datas.status === 200) {
  //     alert("Feedback submitted successfully!");
  //   } else {
  //     alert("Failed to submit feedback. Please try again.");
  //   }
  // } catch (error) {
  //   console.error("Error submitting feedback:", error);
  //   alert("An error occurred. Please try again.");
  // }
  //  }
  //   catch (error) {
  //    setshowloader(false);

  //    if (error.response) {
  //      if (error.response.status === 400) {
  //        setIsPopoverOpen(true);
  //      }
  //      if (error.response.status === 502 || error.response.status === 500) {
  //        setShowerror(true);
  //      }
  //    } else {
  //      setShowerror(true);
  //    }
  //  }
  // };

  const handleSendFeedback = () => {
    const Issue = answers.issueFaced;
    const Info = answers.infoProvided;
    const postData={
      "cuid":cuID,
      "name":username,
      "role":role,
      "issueFaced": Issue,
      "infoProvided": Info,
      "additionalFeedback": additionalFeedback
    }
   
    const apiUrl =
      process.env.REACT_APP_BASE_API_URL +
      "/postFeedback?";
      
    axios
      .post(apiUrl,postData, headers)
      .then((response) => {
        console.log(response, "Hel");
        console.log("Successfully-> " + JSON.stringify(response));
        // setWhitemod(true);
        // if (response.data == "Success") {
        //   setPostData([]);
        //   if (inputValue !== "") {
        //     data.handleStatus("PENDING", "mstpass");
        //     setInputValue("");
        //   } else {
        //     data.handleItemClick(null, null, "save");
        //   }
        // }
        if (response.status === 200) {
          setshowfeed(false);
          setTimeout(()=>{
                setshowfeed(true);
                onClose();
          },2000)
        } 
      })
      .catch((error) => {
        // if (error.response) {
        //   if (error.response.status === 400) {
        //     setShowerror(true);
        //   }
        //   if (error.response.status === 502 || error.response.status === 500) {
        //     setShowerror(true);
        //   }
        // } else {
        //   setShowerror(true);
        // }
      });
  };

  const [answers, setAnswers] = useState({
    issueFaced: "",
    infoProvided: "",
    additionalFeedback: "",
  });

  const [additionalFeedback, setAdditionalFeedback] = useState("");

  const handleAnswerChange = (question, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: answer,
    }));
  };

  const handleAdditionalFeedbackChange = (e) => {
    setAdditionalFeedback(e.target.value);
  };

  return (
    <>
      {ReactDOM.createPortal(
        
        <div id="modal-containerwhite-feed">
          <div className="modal-background-feed">
          
          {
            showfeed == true ?
            <>
             <div className="modal-feed">
              <div className="m-header-feed">
                <span>
                  <b>Send Us Your Feedback!</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={onClose}
                />
              </div>

              <div className="feed-total-cont">
                <div className="feed-back-img-dash">
                  <img className="feedImg" src={feedbackImg} alt=""></img>
                </div>

                <div>
                  <div className="modalcontent-feed">
                    <div className="feed-question-set">
                      <div className="feed-back-qest">
                        Did You Face Any Issue Accessing The Tool?
                      </div>
                      <div className="feed-options-set">
                        <button
                          onClick={() =>
                            handleAnswerChange("issueFaced", "Yes")
                          }
                          className={
                            answers.issueFaced === "Yes" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          Yes
                        </button>
                        <button
                          onClick={() =>
                            handleAnswerChange("issueFaced", "Partially")
                          }
                          className={
                            answers.issueFaced === "Partially" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          Partially
                        </button>
                        <button
                          onClick={() => handleAnswerChange("issueFaced", "No")}
                          className={
                            answers.issueFaced === "No" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          No
                        </button>
                      </div>
                    </div>

                    <div className="feed-question-set">
                      <div className="feed-back-qest">
                        Was All Information You Were Seeking Provided?
                      </div>
                      <div className="feed-options-set">
                        <button
                          onClick={() =>
                            handleAnswerChange("infoProvided", "Yes")
                          }
                          className={
                            answers.infoProvided === "Yes" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          Yes
                        </button>
                        <button
                          onClick={() =>
                            handleAnswerChange("infoProvided", "Partially")
                          }
                          className={
                            answers.infoProvided === "Partially"
                              ? "selected"
                              : ""
                          }
                          // className="single-feed-inp"
                        >
                          Partially
                        </button>
                        <button
                          onClick={() =>
                            handleAnswerChange("infoProvided", "No")
                          }
                          className={
                            answers.infoProvided === "No" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          No
                        </button>
                      </div>
                    </div>

                    <div className="feed-question-set comments-section">
                      <div className="feed-back-qest">
                        Please Provide Any Additional Feedback Or Question You
                        May Have :
                      </div>
                      <textarea
                        value={additionalFeedback}
                        onChange={handleAdditionalFeedbackChange}
                      />
                    </div>
                  </div>
                  <div className="submit-btn-feed">
                    <button
                      type="submit"
                      className="submit-feed-bottom"
                      onClick={handleSendFeedback}
                    >
                      Send
                    </button>
                  </div>
                 
                </div>
              </div>
           </div>
            </>:

            <>
              <div className="modal-feed-thanks">
              {/* <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    float:'right',
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={onClose}
                /> */}
             <div className="m-header-thanks">
                <span>
                  <b>Thanks for Your Feedback!</b>
                </span>
               
              </div>
              </div>
            </>
          }
          </div>

          </div>
      ,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalFeedback;
