import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import DatePicker from 'react-datepicker';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import MSTviewHeader from "./MSTviewHeader";
import MSTdatatab from "./MSTdatatab";

import Modalblack from "../ModalBlack/Modal";
import Modalerror from "../ModalError/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLocationDot ,faCircle} from "@fortawesome/free-solid-svg-icons";
import "./css/MSTview.css";
import "./css/search.css";
import { Modal } from "bootstrap";
import Contentloading from "./MSTContentloading";
import {API_KEY, BASE_API_URL } from "../environment";
import axios from "axios";
import { text } from "@fortawesome/fontawesome-svg-core";



var statuslist = [{ status: "COMPLETED" }, { status: "PENDING" }];
var stateList = [
  { value: "AZ",  label: "AZ" },
{ value: "CO",  label: "CO" },
{ value: "FL",  label: "FL" },
{ value: "IA",  label: "IA" },
{ value: "ID",  label: "ID" },
{ value: "IN",  label: "IN" },
{ value: "MN",  label: "MN" },
{ value: "MT",  label: "MT" },
{ value: "ND",  label: "ND" },
{ value: "NE",  label: "NE" },
{ value: "NM",  label: "NM" },
{ value: "NV",  label: "NV" },
{ value: "OR",  label: "OR" },
{ value: "SD",  label: "SD" },
{ value: "UT",  label: "UT" },
{ value: "WA",  label: "WA" },
{ value: "WI",  label: "WI" },
{ value: "WY",  label: "WY" },
];
const MSTview = (props) => {
  console.log("check fdh", props);
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [activeButton, setactiveButton] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleErrModal, setToggleErrModal] = useState(false);

  const toggleButton = (buttonId) => {
    setactiveButton(activeButton === buttonId ? null : buttonId);
  };
  const [mstinputValue, setMstInputValue] = useState("");
  const [mstfilteredSuggestions, setmstFilteredSuggestions] = useState([]);
  var [statename, setstatename] = useState({ label:'', value:''});
  var [statekeyname, setstatekeyname] = useState();
  const [wirecent, setwirecent] = useState("");
  const[mstReviewStatus,setMstReviewStatus] = useState("");
   const [wirecentlist, setWirecentList] = useState("");
  const [dataFDH, setDataFDH] = useState([]);
  const [tableheader, settableheader] = useState([]);
  const[mstLevel,SetMstLevel]= useState(null);
  const[passData,setPassData]=useState(false);
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [errorreview, seterrorMst] = useState();
  const [activeIndex, setActiveIndex] = useState(false);
  const [selectedIndex, setIndex] = useState("");
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [splitterKeys, setsplitterKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [showloading, setShowloading] = useState(true);
  const [filteredData12, setfilteredData12] = useState([]);
  var [reviewstatus, setHeaderreview] = useState("");
  var [wirecentervalue, setWireValue] = useState(null);
  const [showerrmodal, setShowerror] = useState(false);
  var [statevalue, setstatevalue] = useState(null);
  var [enablewirecenter, setEnableWire] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueFdh, setInputValueFdh] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const[dataComments,setDataComments]=useState('');
  const [startDate, setStartDate] = useState();
   const [endDate, setEndDate] = useState();
   const [formatstartDate, setformatStartDate] = useState('');
   const [formatendDate, setformatEndDate] = useState('');
  const [dataWirecenter, setdataWirecenter] = useState([]);
  useEffect(() => {
    setToggleModal(true);
    // callFDHList();
  }, []);
  useEffect(()=>{
    if(formatstartDate || formatendDate){
      callDatefilter();
   }
      },[formatstartDate,formatendDate])
  useEffect(() => {
    if (props.fdhList) {
      // setShowloading(false)
      setfilteredData(props.fdhList);
      console.log("checking 11");
    } else {
      console.log(dataFDH);
    }
  }, [props.fdhList]);
  const datetimeString = reviewstatus.reviewTime; 
  const ndsdatetime = reviewstatus.ndsReviewTime;
    const formattedDate = new Date(datetimeString?datetimeString:'NA').toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  
    const formattedTime = new Date(datetimeString?datetimeString:'NA').toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    const ndsformattedDate = new Date(ndsdatetime?ndsdatetime:'NA').toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  
    const ndsformattedTime = new Date(ndsdatetime?ndsdatetime:'NA').toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });

    

  const handleStatus = (event,passedmst) => {
    setwirecent("");
    setstatename("");
    setstatekeyname([]);
    setInputValue("");
    setInputValueFdh("");
    setStartDate("");
    setEndDate("");
    if(passedmst == 'mstpass'){
      settableheader([]);
      setsplitterKeys([]);
      setfilteredData([]);
      setfilteredData12([]);
      setLoadingToggle(false);
      statuslist = [{ status: "COMPLETED" }, { status: "PENDING" }];
      setEnableWire(false);
      document.getElementById("statusValue").selectedIndex = 0;
      const val = {
        deviceName: "NA",
        deviceType: "NA",
        auditStatus: "NA",
        auditBy: "NA",
        auditTime: "NA",
        reviewStatus: "NA",
        reviewBy: "NA",
        reviewTime: "NA",
        auditType:"NA",
        mstWise:"NA",
        strandRange :"NA",
        comments : "NA",
        reviewRemarks:"NA",
        auditDropType:"NA",
        ndsReviewStatus : "NA",
        ndsReviewBy: "NA",
        ndsReviewTime:"NA"
      }
      props.onChildDataChange(val);
      setHeaderreview(val);
      }else{
        var datavalue = event?.target?.value ? event.target.value : "PENDING";
        setSelectedDate("");
    setInputValue('');
    setInputValueFdh('');
    settableheader([]);
    setstatevalue(datavalue);
    setdataWirecenter([]);
    setLoadingToggle(false);
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA",
      mstWise:"NA",
      strandRange :"NA",
      comments : "NA",
      reviewRemarks:"NA",
      auditDropType:"NA",
      ndsReviewStatus : "NA",
      ndsReviewBy: "NA",
      ndsReviewTime:"NA"
    }
    props.onChildDataChange(val);
    setHeaderreview(val);
        if (datavalue != "0") {
          
    
          fetch(
             process.env.REACT_APP_BASE_API_URL +
              "/getAuditReviewInfo?deviceType=MST" +
              "&auditStatus="+
              datavalue +
              "&deviceName=", headers
          )
            .then((response) => {
              console.log(response);
              if (!response.ok) {
                throw response;
              }
              return response.json();
            })
            .then((data) => {
              console.log("check",data)
              setfilteredData(data);
              setfilteredData12(data);
            })
            .catch((error) => {
              console.log(error);
              if (error) {
                if (
                  error.status === 400 ||
                  error.status === 502 ||
                  error.status === 500
                ) {
                  setToggleErrModal(true);
                }
              } else {
              }
            });
          // callFDHList(param);
          setEnableWire(true);
        }else{
          setfilteredData([]);
          setfilteredData12([]);
          setEnableWire(false);
        }
      } 
  };

  //typeahead
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    // if (inputValue.length >= 2) {
      axios
        .get(
           process.env.REACT_APP_BASE_API_URL +
            "/getAuditReviewInfo?deviceType=MST" +
            "&auditStatus=" +
            statevalue +
            "&state=" +
            statename+
           "&wirecenter=" +
            wirecent +
            "&deviceName=" +
            inputValue , headers
        )
        .then((response) => {
          const data = response.data;
          setfilteredData(data);
          setfilteredData12(data);
        })
        .catch((error) => {
          if (error.response.status == 400 || error.response.status == 404) {
            setfilteredData([]);
            setfilteredData12([]);
          } else {
            setToggleErrModal(true);
          }
        });
    //}
  };
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activefdh, setactiveFDH] = useState(false);
  const [wirecentfdh, setwirecentfdh] = useState("");
  const [errmessage, setErrorMessage] = useState(false);
  const focusFDH = () => {
    document.getElementById("fdhInput").focus();
  };
  const handleSelectSuggestion = (suggestion) => {
    const fdhName = suggestion.fdh;
    setInputValueFdh(fdhName);
    setwirecent("");
    setInputValue("");
    setStartDate("");
    setEndDate("");
    setFilteredSuggestions([]);
      fetch(
         process.env.REACT_APP_BASE_API_URL + "/getMstListByFdh?fdh=" + fdhName + '&auditedMst=YES' , headers
       )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
              setfilteredData12([]);
            }
          } else {
            setToggleErrModal(true);
          }
        });
  };
  const navigate = useNavigate();
  const handleChangeFdh = (e) => {
    const inputValueFdh = e.target.value;
    setInputValueFdh(inputValueFdh);
    if (/^[a-zA-Z0-9\s=]*$/.test(inputValueFdh)) {
      if (inputValueFdh.length >= 2) {
        axios
          .get( process.env.REACT_APP_BASE_API_URL + "/getFdh?startWith=" + inputValueFdh , headers)
          .then((response) => {
            const data = response.data;
            setFilteredSuggestions(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                setToggleErrModal(true);
                setFilteredSuggestions([]);
              }
            } else {
              setToggleErrModal(true);
              setFilteredSuggestions([]);
            }
          });
      } else {
        setFilteredSuggestions([]);
      }
    } else {
      setInputValueFdh("");
      setFilteredSuggestions([]);
      setErrorMessage(
        "Please Enter Valid Input, Special Characters are not allowed"
      );
    }
  };
  const handleStartDateChange = (date)=>{
    const inpDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    const formattedDate = inpDate.toISOString().split('T')[0];
    setStartDate(date);
    setformatStartDate(formattedDate);
    console.log(formattedDate,'dateitem')
    // callDatefilter();
    
  }
  const callDatefilter = ()=>{
    console.log(formatstartDate)
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA"
    }
    fetch(
       process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceType=MST" +
        "&auditStatus=" +
        statevalue +
        "&state=" +
        statename+
        "&wirecenter=" +
        wirecent +
        "&dateFrom=" + formatstartDate +
        "&dateTo=" + formatendDate , headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        setfilteredData(data);
        setfilteredData12(data);
      }).catch((error) => {
        console.log(error);
        if (error) {
          if(error.status == 400 || error.status == 404){
            setfilteredData([]);
            setfilteredData12([]);
            settableheader([]);
            setHeaderreview(val);
            props.onChildDataChange(val);
          }
          if (
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
            console.log(error.status);
          }
        } else {
        }
      });
  }
  const handleEndDateChange = (date)=>{
    const inpDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    const formattedDate = inpDate.toISOString().split('T')[0];
    setEndDate(date);
    setformatEndDate(formattedDate);
    console.log(formattedDate,'enditem');
    // callDatefilter();
  }
  //date filter
  const getdateval = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
    const datevalue = event.target.value;
    fetch(
       process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceType=MST" +
        "&auditStatus=" +
        statevalue +
        "&dateFrom=" + formatstartDate +
        "&dateTo=" + formatendDate , headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        setfilteredData(data);
        setfilteredData12(data);
      })
      .catch((error) => {

        if (error) {
          if(error.status === 404 || error.status === 400){
            setfilteredData([]);
            setfilteredData12([])
          }
          if (
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
        }
      });
  };

  

  const handleclear = (clear)=>{
    setLoadingToggle(false)
  }
  const handleItemClick = (event, val,passedData) => {
    var deviceData=event?.target?.textContent ? event.target.textContent : selectedIndex
    setActiveIndex(deviceData);
    setIndex(deviceData);
    setLoadingToggle(true);
    if(passedData !== 'save'){
    settableheader([]);
    setsplitterKeys([]);
    
    }
    fetch(
       process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        deviceData , headers
    ).then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then((data) => {
        console.log(data, "arr");
        if (data) {
          setPassData(true);
          SetMstLevel(data[0]);
          setDataComments(data[0]);
          setHeaderreview(data[0]);
          props.onChildDataChange(data[0]);
        }
      })
      .catch((e) => {
        setLoadingToggle(false)
        if (e.response) {
          if(e.response.status === 404){
            setHeaderreview(val);
          }
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        }
      });
      if(passedData !== 'save'){
    fetch(
       process.env.REACT_APP_BASE_API_URL + "/getFinalAuditReportByMST/mstReview?mst=" + event.target.textContent , headers
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          if (data.status === "404") {
            setLoadingToggle(false);
            seterrorMst(data.message);
          }
          if (data.status === "502") {
            setToggleErrModal(true);
          }
        } else {
          settableheader(() => {
            const arrayOfObjects = Object.keys(data)
              .map((key) => {
                const id = parseInt(key); // Convert the key to an integer as required
                return data[key]?.map((item) => ({ id, ...item }));
              })
              .flat();

            return arrayOfObjects;
          });
          setsplitterKeys(Object.keys(data));
          setLoadingToggle(false);
          setToggleModal(false);
        }
      })

      .catch((e) => {
        if (e.response) {
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
          setToggleErrModal(true);
        }
      });
    }
  };

  const handleErrModal = () => {
    setToggleErrModal(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const handleState = (selected) =>{
    const statenamevalue = selected.value;
    console.log(selected.value)
    setstatename(statenamevalue);
    setstatekeyname(selected);
    setwirecent("");
    setInputValue("");
    setInputValueFdh("");
    setStartDate("");
    setEndDate("");
    // if(wirevalue !== '0'){
      fetch(
         process.env.REACT_APP_BASE_API_URL + "/getAuditReviewInfo?" + '&auditStatus='+ statevalue + '&deviceType=MST' + '&state=' + statenamevalue , headers
       )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
              setfilteredData12([]);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }
 
  const handleSelectWire = (suggestion) => {
    var inputstring = suggestion;
    setwirecent(inputstring);
    setWirecentList([]);
    handlewirecenter(inputstring);
  };
  const handlewirecenter = (event) =>{
    
    const wirecentername = event;
    // if(wirevalue !== '0'){
      setInputValue("");
      setInputValueFdh("");
      setStartDate("");
      setEndDate("");
      fetch(
         process.env.REACT_APP_BASE_API_URL + "/getAuditReviewInfo?" + '&auditStatus='+ statevalue + '&deviceType=MST' + '&state=' + statename + '&wireCenter=' + wirecentername 
    , headers)
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              setfilteredData12([]);
              settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }
  const handleWirecent = (e) => {
    const wirecentervaluefdh = e.target.value;
    setwirecent(wirecentervaluefdh);
    
      if (wirecentervaluefdh.length >= 1) {
        axios
          .get(
             process.env.REACT_APP_BASE_API_URL +
              "/getWirecenter?fdhWirecenter=" +
              wirecentervaluefdh +
              "&state="+
              statename
              , headers
          )
          .then((response) => {
            const data = response.data;
            setWirecentList(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setWirecentList([]);
      }
  };
  

  const Statusupdate = (item) => {
    let style={};
    if(item.o2ReviewStatus === 'O2 UPDATED' && item.ndsReviewStatus === 'NDS UPDATED'){
      style = { borderLeft: '5px solid #7E8DD2 '}
    } else if(item.o2ReviewStatus === 'O2 UPDATED'){
      style = {  borderLeft: '5px solid #503296'}
    } else if(item.ndsReviewStatus === 'NDS UPDATED'){
      style = {  borderLeft: '5px solid #38C6F4'}
    } else {
      style = {  borderLeft: '5px solid #03b94c'}
    }
    return style;
  };
  const handlestatuscolor = (item) =>{
    if(activeIndex == item.deviceName && (item.o2ReviewStatus === 'O2 UPDATED' && item.ndsReviewStatus === 'NDS UPDATED')){
      return 'both-updateded'
    } else if(activeIndex == item.deviceName && (item.o2ReviewStatus === 'O2 UPDATED')){
      return 'oupdated'
    } else if(activeIndex == item.deviceName && (item.ndsReviewStatus === 'NDS UPDATED')){
      return 'ndupdateded'
    } else if (activeIndex == item.deviceName){
      return 'nothingupdated'
    }else{
      return ''
    }
  };
  const handleMstStatus = (e) =>{
    const mststatusval = e.target.value;
    setMstReviewStatus(mststatusval);
    setstatename("");
    setstatekeyname("");
    setwirecent("");
    setInputValue("");
    setInputValueFdh("");
    setStartDate("");
    setEndDate("");
      fetch(
        process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceType=MST" +
        "&auditStatus=" +
        statevalue +
        "&state=" +
        statename+
        "&wirecenter=" +
        wirecent +
        "&dateFrom=" + formatstartDate +
        "&dateTo=" + formatendDate +
        "&auditedTypeStatus=" + mststatusval , headers
       )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
              setfilteredData12([]);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }
  return (
    <>
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="d-flex justify-content-between box-fdh pb-1 status-p ">
        <div>
        
       
        <div className="d-flex flex-row filtertags mob-col-12 mob-flex-wrap  tab-flex-wraps">
          <div className="mob-col-12 review-font mb-nomargin marg-align" >
            <select
              className="filter-select mob-filtertag "
              id="statusValue"
              onChange={handleStatus}
            >
              <option value="0">Select Status</option>

              {statuslist.map((status) => (
                <option key={status} value={status.status}>
                  {status.status}
                </option>
              ))}
            </select>
          </div>
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
          <Select
          options={stateList}
          onChange={handleState}
          value={statekeyname}
          className="searchresultfdhselect"
          placeholder="Select State"
          isMulti={false} // Set to true if you want to allow multiple selections
          />
          )}
        </div>
        <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
               <div>
                  <input
                    className="searchresultfdh"
                    placeholder="Search Wirecenter"
                    value={wirecent}
                    autoComplete="off"
                    id="wirecenter"
                    onChange={handleWirecent}
                  ></input>
                  {wirecentlist?.length > 0 ? (
                    <ul className="wirecenterreview mob-wire-search tab-wire-search">
                      <span>
                        {wirecentlist.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() => handleSelectWire(suggestion)}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </span>
                    </ul>
                  ) : 
                  (
                    <></>
                  )
                  }
                </div>
            )}
            </div>
            
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="d-flex flex-row datepicker">
               
                <DatePicker selected={startDate} selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-date"
              placeholderText="From Date"
              onChange={handleStartDateChange} />
              
                
                
                </div>
            )}
          </div>
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="d-flex flex-row datepicker">
              
               
                <DatePicker selected={endDate} selectsEnd
               startDate={startDate}
              endDate={endDate}
              placeholderText="To Date"
              className="form-date"
              minDate={startDate}
              onChange={handleEndDateChange} />
                
                </div>
            )}
          </div>
         
        </div>
        {enablewirecenter && (
        <div className="d-flex  flex-row filtertags mob-col-12 mob-flex-wrap review-comment tab-tag tab-flex-wraps">
           <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="mob-search-fop">
                  <select value={mstReviewStatus} onChange={handleMstStatus}  className="searchresultfdh">
                    <option value="0">Select Status</option>
                    <option value="O2 Updated">O2 Updated</option>
                    <option value="Nds Updated">Nds Updated</option>
                    <option value="Not Updated">Not Updated</option>
                  </select>
              </div>
            )}
          </div>
           <div className="mob-col-6 review-font marg-align mob-no-margin mob-review-mar-set">
            {enablewirecenter && (
              <div>
                <input
                  type="text"
                  value={inputValue}
                  style={{ margin: "0" }}
                  className="searchresultfdh"
                  placeholder="Search MST"
                  onChange={handleChange}
                ></input>
              </div>
            )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin mst_review_fdh mob-review-mar-set">
          {enablewirecenter && (
              <div style={{ cursor: "pointer" }}
               onClick={focusFDH}>
                <input
                  type="text"
                  value={inputValueFdh}
                  style={{ margin: "0" }}
                  className="searchresultfdh"
                  placeholder="Search FDH"
                  autoComplete="off"
                    id="fdhInput"
                  onChange={handleChangeFdh}
                ></input>
                <>
                    {inputValueFdh && filteredSuggestions?.length > 0 ? (
                      <ul className="fdh-search-mst-review mob-fdh-search-mst-review tab-fdh-search mob-search-input-set-wid">
                        <span>
                          {filteredSuggestions.map((suggestion) => (
                            
                            <li
                              key={suggestion.fdh}
                              onClick={() => handleSelectSuggestion(suggestion)}
                            >
                              {suggestion.fdh}
                            </li>
                          ))}
                        </span>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </>
              </div>
            )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin mob-review-mar-set">
          {enablewirecenter && (
          <div className="d-flex flex-column outerdate datewid">
                  {/* <div>Date</div> */}
                  <div className="review-text-head">
                  O2 Review By
                  </div>
                  <div className="review-text-content">
                   {reviewstatus.reviewBy?reviewstatus.reviewBy:'NA'}
                  </div>
             </div>
          )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin mob-review-mar-set">
          {enablewirecenter && (
          <div className="d-flex flex-column outerdate datewid">
                  {/* <div>Date</div> */}
                  <div className="review-text-head">
                  O2 Review Status
                  </div>
                  <div className="review-text-content">
                   {reviewstatus.reviewStatus?reviewstatus.reviewStatus:'NA'}
                  </div>
             </div>
          )}
          </div>
        </div>
        )}
        {enablewirecenter && (
        <div className="d-flex  flex-row filtertags mob-col-12 mob-flex-wrap review-comment tab-tag tab-flex-wraps">
            <div className="mob-col-6 review-font marg-align mob-no-margin ">
          <div className="d-flex flex-column outerdate datewid">
                  <div className="review-text-head">
                   O2 Review Date & Time 
                  </div>
                  <div className="review-text-content">
                  {
                        formattedDate === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedDate} </span>
                        )
                      }
                  &nbsp;
                     {
                        formattedTime === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedTime} CST</span>
                        )
                      }
                  </div>
             </div>
          </div> 
          <div className="mob-col-6 review-font marg-align mob-no-margin ">
          <div className="d-flex flex-column outerdate datewid">
                  <div className="review-text-head">
                  Nds  Review By
                  </div>
                  <div className="review-text-content">
                   {reviewstatus.ndsReviewBy?reviewstatus.ndsReviewBy:'NA'}
                  </div>
             </div>
          {/* )} */}
          </div>
           <div className="mob-col-6 review-font marg-align mob-no-margin">
          {/* {enablewirecenter && ( */}
          <div className="d-flex flex-column outerdate datewid">
                  {/* <div>Date</div> */}
                  <div className="review-text-head">
                   Nds Review Status
                  </div>
                  <div className="review-text-content">
                   {reviewstatus.ndsReviewStatus?reviewstatus.ndsReviewStatus:'NA'}
                  </div>
             </div>
          {/* )} */}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
          {/* {enablewirecenter && ( */}
          <div className="d-flex flex-column outerdate datewid">
                  {/* <div>Date</div> */}
                  <div className="review-text-head">
                   Nds Review Date & Time 
                  </div>
                  <div className="review-text-content">
                  {
                        ndsformattedDate === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{ndsformattedDate} </span>
                        )
                      }
                  &nbsp;
                     {
                        ndsformattedTime === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{ndsformattedTime} CST</span>
                        )
                      }
                  </div>
             </div>
          {/* )} */}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
          <div className="d-flex flex-column outerdate datewid">
               { (reviewstatus.mstWise == "Disaster" || reviewstatus.mstWise == "No Light" || reviewstatus.mstWise == "Missing MST" ||
                reviewstatus.mstWise == "No Mst Access-Trees" || reviewstatus.mstWise == "No Mst Access-Private Property" || reviewstatus.mstWise == "No Mst Access-Safety Concerns" || reviewstatus.mstWise == "No Mst Access-Other Resident" 
               ) ? <>
               </> :
                <>
                <div className="review-text-head">
                   Mst Level 
                  </div>
               <div className="review-text-content">
                   {reviewstatus.mstWise?reviewstatus.mstWise:'NA'}
                  </div>
               </>}   
    
             </div>
          </div>    
        </div>
         )}
        {enablewirecenter && (
        <div className="d-flex  flex-row filtertags mob-col-12 mob-flex-wrap review-comment tab-tag tab-flex-wraps">
           <div className="mob-col-6 review-font marg-align mob-no-margin">
          <div className="d-flex flex-column outerdate datewid">
                  <div className="review-text-head">
                  Audit Comments
                  </div>
                  <div className="review-text-content">
                   {reviewstatus.comments?reviewstatus.comments:'NA'}
                  </div>
             </div>
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
          <div className="d-flex flex-column outerdate datewid">
                  <div className="review-text-head">
                  MST Placement
                  </div>
                  <div className="review-text-content">
                   {reviewstatus.auditDropType?reviewstatus.auditDropType:'NA'}
                  </div>
             </div>
          </div>
          </div>
         )}
        </div>

       
       
      </div>

      
      <div className="fdhview mob-row tab-row">
      <div className="col-5 mob-col-12 block-in-mobile">
              <div className="legend-content mt-3">
                <span className="o2updated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                 O2 Updated
                </span>

                <span className="ndsupdated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Nds Updated
                </span>

                <span className="ondupdated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  O2 & Nds Updated
                </span>
                <span className="notupdated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Not Updated
                </span>
              </div>
            </div>
        <div
          className="scrollable-table-container mob-col-4"
          style={{ width: "10%" }}
        >

          <MSTviewHeader tabhead={{ tabhead: "1" }} />
    
          <div
            className="scrollable-tbody-container mob-body-height"
            width="10%"
            style={{ height: "350px" }}
          >
            <table className="scrollable-table">
              <tbody>
                
               {
                filteredData.length > 0 ? (
                  <>
                   {filteredData?.map((item, index) => (
                  <tr style={Statusupdate(item)} key={index}>
                    <td
                      key={index}
                      className={ handlestatuscolor(item)}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleItemClick(e, item)}
                    >
                      {item.deviceName}
                    </td>
                  </tr>
                ))}
                  </>
                ):(
                  <>
                  <tr>
                    <td>No data</td>
                  </tr>
                  </>
                )
               }
                {showloading && <div>{/* <Contentloading/> */}</div>}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="scrollable-table-container fdhdetailstab mob-col-8"
          style={{ height: "350px", width: "85%" }}
        >
          {/* <FdhviewHeader tabhead={{tabhead:"2",activeIndex:selectedIndex}} /> */}

          {passData && 
          <MSTdatatab
            mstLevel={mstLevel}
            tdata={tableheader}
            spkeys={splitterKeys}
            errorreview={errorreview}
            reviewdata={reviewstatus}
            loading={loadingToggle}
            reviewcomments={dataComments}
            handleItemClick={handleItemClick}
            handleclear={handleclear}
            handleStatus={handleStatus}
            activeIndex={activeIndex}
          />}
        </div>
      </div>
      {/* {toggleModal && <Modalblack />} */}
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
    </>
  );
};

export default MSTview;
