import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import "./ModalFeedback.css";
// import "./FeedbackReview.css"
import "./ReviewedFeedback.css"
import axios from "axios";
import feedbackImg from "../assets/feedbackImg.png";
// import { useEffect } from "react";



const ReviewedFeedback = (props) => {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
 const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}

  //   const handleClickCancel = () => {
  // Data you want to send to the parent
  //     const dataToSend = false;
  //     closemodissue(dataToSend);
  //   };

  const datafeedrev = props.dataFeed;
  console.log(datafeedrev)
  
  const [answers, setAnswers] = useState({
    issueFaced: datafeedrev.issueFaced,
    infoProvided: datafeedrev.infoProvided,
    additionalFeedback:datafeedrev.additionalFeedback,
  });
  console.log(answers)

  const [additionalFeedback, setAdditionalFeedback] = useState(datafeedrev.additionalFeedback || "NA");

  const handleAnswerChange = (question, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: answer,
    }));
  };

  const handleAdditionalFeedbackChange = (e) => {
    setAdditionalFeedback(e.target.value);
  };
const handleClose = ()=>{
  props.onClose()
}
 
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite-feed-revfeed">
          <div className="modal-background-feed-revfeed">
            <div className="modal-feed-revfeed">
              <div className="m-header-feed-revfeed">
                <span>
                  <b>Feedback Review</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>

              

                <div>
                  <div className="modalcontent-feed-revfeed">
                    <div className="feed-question-set-revfeed">
                      <div className="feed-back-qest-revfeed">
                        Did You Face Any Issue Accessing The Tool?
                      </div>
                      <div className="feed-options-set-revfeed">
                        <button
                        disabled
                          onClick={() =>
                            handleAnswerChange("issueFaced", "Yes")
                          }
                          className={
                            answers.issueFaced === "Yes" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          Yes
                        </button>
                        <button
                        disabled
                          onClick={() =>
                            handleAnswerChange("issueFaced", "Partially")
                          }
                          className={
                            answers.issueFaced === "Partially" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          Partially
                        </button>
                        <button
                        disabled
                          onClick={() => handleAnswerChange("issueFaced", "No")}
                          className={
                            answers.issueFaced === "No" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          No
                        </button>
                      </div>
                    </div>

                    <div className="feed-question-set-revfeed">
                      <div className="feed-back-qest-revfeed">
                        Was All Information You Were Seeking Provided?
                      </div>
                      <div className="feed-options-set-revfeed">
                        <button
                        disabled
                          onClick={() =>
                            handleAnswerChange("infoProvided", "Yes")
                          }
                          className={
                            answers.infoProvided === "Yes" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          Yes
                        </button>
                        <button
                        disabled
                          onClick={() =>
                            handleAnswerChange("infoProvided", "Partially")
                          }
                          className={
                            answers.infoProvided === "Partially"
                              ? "selected"
                              : ""
                          }
                          // className="single-feed-inp"
                        >
                          Partially
                        </button>
                        <button
                        disabled
                          onClick={() =>
                            handleAnswerChange("infoProvided", "No")
                          }
                          className={
                            answers.infoProvided === "No" ? "selected" : ""
                          }
                          // className="single-feed-inp"
                        >
                          No
                        </button>
                      </div>
                    </div>

                    <div className="feed-question-set comments-section-revfeed">
                      <div className="feed-back-qest-revfeed">
                        Please Provide Any Additional Feedback Or Question You
                        May Have :
                      </div>
                      <textarea
                      disabled
                        value={answers.additionalFeedback}
                        onChange={handleAdditionalFeedbackChange}
                      />
                    </div>
                  </div>
                  {/* <div className="submit-btn-feed-revfeed">
                    <button
                      type="submit"
                      className="submit-feed-bottom-revfeed"
                      onClick={handleSendFeedback}
                    >
                      Send
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ReviewedFeedback;
