import React, { useEffect, useReducer, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import "./fdhtable.css";
import "./Editable.css";
import "./msttable.css";
import "./popover.css";
import "./Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ModalSuccess from "./ModalWhite/Modalsuccess";
import ModalOptitap from "./ModalWhite/Modaloptitap";
import { API_KEY, BASE_API_URL } from "./environment";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faSearch,
  faDotCircle,
  faCircle,
  faFilter,
  faNewspaper,
  faPlus,
  faMinus,
  faTrash,
  faUpload,
  faEyeDropper,
  faEye,
  faRotateRight,
  faCheck,
  faLocationArrow,
  faCircleInfo,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import ModalBlack from "./ModalBlack/Modal";
import ModalWhite from "./ModalWhite/Modal";
import ModalMstIssue from "./ModalWhite/ModalMstIssue";
import Modalerror from "./ModalError/ErrorModal";
import Modalconfirm from "./ModalConfirm/ConfirmModal";
import Modalviewaddress from "./ModalWhite/Modalviewadd";
import { FaCommentsDollar } from "react-icons/fa";
import Loader from "./Loader/Loader";
import Modaladdress from "./ModalWhite/Modaladdress";
import Modalcasa from "./ModalWhite/Modalcasa";
import ModalNoLight from "./ModalWhite/ModalNoLight";
import ModalImageUpload from "./ModalWhite/ModalImage";
import ModalNoLightConfirm from "./ModalWhite/ModalNoLightConfirm";
import ModalNoLightYes from "./ModalWhite/ModalNoLightYes";
import ModalLevelConfirm from "./ModalWhite/ModalLevelConfirm";
import ModalRestorePort from "./ModalWhite/ModalRestorePort";
import ModalDefective from "./ModalWhite/ModalDefective";
import ModalImageUploadPort from "./ModalWhite/ModalImageport";
import { faDownload , faImage, faL} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import ModalTpIssueConfirm from "./ModalWhite/ModalTpIssueConfirm";
import ModalTpWorkingIssue from "./ModalWhite/ModalTpWorkingIssue";
import ModalAuditInfo from "./ModalWhite/ModalAuditInfo";

const PAGE_SIZE = 25; //
const initialState = [];
var isSpliterMatching = false;
var isLegMatching = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_ENTRY":
      
      console.log(action.payload);
      const logger = [...state, action.payload];
      console.log(logger);
      const entryIndex = state.findIndex((entry) => {
        const ent = entry.staticId ? entry.staticId : entry.mstPort;
        const stats = action.payload.staticId
          ? action.payload.staticId
          : action.payload.mstPort;
        if (ent === stats) {
          return entry;
        }
      });
      console.log(entryIndex);
      
      if (entryIndex !== -1) {
        // Update the user object using the spread operator
        return [
          ...state.slice(0, entryIndex),
          action.payload,
          ...state.slice(entryIndex + 1),
        ];
      } else {
        return [...state, action.payload];
      }
  

    case "EMPTY":
      return initialState;
    case "EDIT_ENTRY_LEG":
      return state.map((entry) =>
        entry.id === action.payload.id ? { ...entry, ...action.payload } : entry
      );
    case "DELETE_ITEM":
      const itemId = action.payload;
      console.log(itemId);
      console.log(state);
      const updatedItems = state.filter((item) => item.staticId !== itemId);
      return updatedItems;
    default:
      return state;
  }
};
function MstTable({
  wireState,
  onPagesaveData,
  onPageStatus,
  onPageReviewtime,
}) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
const headersImage = {
  headers: {
    "apikey": process.env.REACT_APP_API_IMAGE_KEY,
  }
}
  
  var resultArrayNds;
  var resultData;
  var resultArrayo2;
  console.log(BASE_API_URL, "sss");
  const [addressIndex, setAddressIndex] = useState("");
  const[showRestorePortModal,setShowRestorePortModal] =useState(false);
  const[checkCasaSerial,setCheckCasaSerial]=useState("");
  const [casaIndex, setcasaIndex] = useState("");
  const [childcase,setChildCase]= useState("");
  const [savedata, dispatch] = useReducer(reducer, initialState);
  const [overallData, setoverallData] = useState([]);
  const[caseLevelSuccess,setCaseLevelSuccess] = useState(false);
  const[casepayload,setCasePayload] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [fdhnames, setfdhnames] = useState("");
  const [resetarraylist, setResetarray] = useState([]);
var [apidatalist, setapidatalist] = useState([]);
  var [dtnauthinfo, setdtnauthinfo] = useState({
    SessionStatus: "",
    StartTime: "",
    StopTime: "",
    WGName: "",
    DSLAMNode:"",
    status:"",
    dtn:""
  });
  const[portSelectedDefect,setPortSelectedDefect] = useState("");
  const[portdefectPortvalue,setportdefectPortvalue]= useState(false);
  const [originalValueSplit, setOriginSplitval] = useState("");
  const[auditInfo,setAuditInfo] = useState([]);
  const[auditInfoPopup,setAuditInfoPopup]=useState(false);
  const Cuid = sessionStorage.getItem("encryptedKey");
  const[accessBSW,setaccessBSW] = useState(false);
  const TechCuid = sessionStorage.getItem("cuid");
  const [originalValueLeg, setOriginLegval] = useState("");
  const [sfDtn, setSFDtn] = useState ([]);
  const [mstwise, setmstwise] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [mstCaseNo, setMstCaseIdNo] = useState("");
  const [mstCaseNomst, setMstCaseIdNomst] = useState("");
  var [neisl, setNeisl] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [mstAvailablePort, setMstAvailablePort] = useState("");
  const [mstportaliasid, setMstportaliasid] = useState("");
  const [totalnumberofItems, setTotalNumber] = useState([]);
  const [status, setCheckStatus] = useState("Pending");
  let globalIndexOffset = 0;
  const [showNoLightConfirm, setShowNoLightConfirm] = useState(false);
  const [casaInitialIndex,setCasaInitialIndex] = useState();
  const [showLevelConfirm, setshowLevelConfirm] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [currentDtn, setCurrentDtn] = useState("");
  const [dataIssue, SetdataIssue] = useState({
    data: "",
    rowval: "",
    indexval: "",
  });
  const nav = useNavigate();
  const [showNoLightYes, setShowNoLightYes] = useState(false);
  const[showModalDefective,setShowModalDefective] = useState(false);
  const[messageError,setMessageError] = useState("");
  const [showblackmodal, setBlackmod] = useState(false);
  const [showmodalimg, setShowmodalimage] = useState(false);
  const [showmodalimgport, setShowmodalimageport] = useState(false);
  const [imageportId, setImageportId] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverNOpen, setIsPopoverNOpen] = useState(false);
  const [isPopoverStdOpen, setIsPopoverStdOpen] = useState(false);
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showsuccModal, setsuccModal] = useState(false);
  const [showpopupopti, setPopupOpti] = useState(false);
  const [rowIndexval, setrowIndexval] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  var [audittypeval, setAudittype] = useState("static");
  const [showmodalconfirm, setShowmodalconfirm] = useState(false);
  const [showmodaladdress, setShowmodalAddress] = useState(false);
  const [showmodalissue, setShowmodalIssue] = useState(false);
  const [mstpopissue, setMstPopIssue] = useState("");
  const[mstTpPopup, setMstTpPopup] = useState(false);
  const[mstTpIssue,setmstTpIssue] = useState("");
  const [showmodaladdressUpdate, setShowmodalAddressUpdate] = useState(false);
  const [showmodalcasaUpdate, setShowmodalcasaUpdate] = useState(false);
  var [imageName, setImageName] = useState('');
  var [isChecked, setIsChecked] = useState(false);
  var [splittervalue, setSplittervalue] = useState({});
  var [legvalue, setLegvalue] = useState([]);
  const [showloader, setshowloader] = useState(false);
  const [enablemstlev, setenablemstlev] = useState(false);
  var [arrayrow, setArraysplit] = useState([]);
  var [isCheckedvalid, setIsCheckedvalid] = useState(false);
  var [legarrayrow, setArrayLeg] = useState([]);
  const [modalnolight, setModalNoLight] = useState(false);
  const [selectedMstchange, SetSelectedMstchange] = useState("");
  const [errorMessageLight, setErrorMessageLight] = useState();
  const [fdhfilter, setfdhFilteredSuggestions] = useState();
  const [fdhopvalue, setfdhopvalue] = useState(null);
  const [apiData, setApiData] = useState(null);

  const [isFilter, setisFilter] = useState(false);
  const navigate = useNavigate();
 
  const statusOptions = ["AVAILABLE", "WORKING", "PENDING"];
  
  const [filter, setFilter] = useState({ idRange: "", splitter_status: "" });

  var [splitterMatch, setSplitterMatch] = useState("");
  var [splitterLegMatch, setSplitterLegMatch] = useState("");
  var [remark, setRemark] = useState("");
  const [isPageSave, setisPageSave] = useState(false);
  var [legvalue, setLegvalue] = useState("");
  var [addresslist, setAddressList] = useState([]);
  var [availableportlist, setAvailableportlist] = useState([]);
  var [availableMSTlist, setAvailableMSTlist] = useState([]);
  var [viewimageupload, setactiveviewimage] = useState(false);
  var [viewimageuploadport, setactiveviewimageport] = useState(false);
  var [activemstlevel, setactivemstlevel] = useState(false);
  const[addressAlias,setAddressAlias] = useState("");
  const[dropPlacement,setDropPlacement] = useState ();
  const { fdhName } = useParams();
  const { mstName } = useParams();
  console.log(fdhName);
  var resultString = "";
  const { jobNumber } = useParams();
  const { latitude } = useParams();
  const { longitude } = useParams();
  var [fdhNamevalue, setFDHname] = useState("");
  var [imageBase64, setImageBase64] = useState("");
  var [enableEdit, setenableEdit] = useState(false);
  var [mstLevelDrop, setMstlevelDrop] = useState("");
  const role = sessionStorage.getItem("Role");
  var [imageURLdata, setImageURL] = useState({});
  const[mstTpWorPop,setMstTpWorPop] = useState(false);
  var [activeauto, setactiveauto] = useState(false);
  var [mstCaseId, setMstCaseId] = useState("");
  const popupRef = useRef(null);
  const popupRefInfo = useRef(null);
  var [statusport, setstatusport] = useState("");
  var [issueport, setissueport] = useState("");
  const[issuepayload, setIssuepayload] = useState({});
  var [loadingpop, setloadingpop] = useState("Loading...");
  var [checkfilter, setcheckfilter] = useState([]);
  var username = sessionStorage.getItem("Name");
  var [accessOSG, setAccessOSG] = useState("");
  var [imageList, setImagelist] = useState([]);
  var [allowoptichange, setallowOptichange] = useState(false);
  const[optiRow,setOptiRow]= useState();
  const[optiIndex,setoptiIndex]= useState();
  const[issueIndex,setIssueIndex]= useState();
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedIndexDtn, setClickedIndexDtn] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverInfo, setPopoverInfo] = useState(false);
  const [formData, setFormData] = useState({
    comments: "",
    auditDropType: "",
    
  });
  const [errors, setErrors] = useState({
    comments: "",
    drop: "",
  });
  var cuid = sessionStorage.getItem("encryptedKey");
  useEffect(() => {
    if (activeauto) {
      autoSave();
    }
  }, [activeauto]);
  useEffect(()=>{
    if(portdefectPortvalue){
      portdefectivemst("suspend");
    }
  },[portdefectPortvalue]);
  useEffect(() =>{
    if(resetarraylist.length > 0){
      setoverallData(resetarraylist)
    }
    
   
  },[resetarraylist])

  useEffect(() => {
    checkaccess();
    handleAuditData();
    console.log(fdhName, "as");
    dispatch({ type: "EMPTY" });
    setSplittervalue([]);
    setLegvalue([]);
    setoverallData([]);
    console.log(checkfilter);
    fetchDatamst();
    deviceStatus(mstName);
    getFDHListportmst();
    getavailableportmst();
    getCommentsdrop();
    oauthtoken();
    console.log(imageList)
  }, [mstName,imageList]);
  useEffect(()=>{
    if(caseLevelSuccess == true && casepayload == true){
      fetchDatamst();
    }
      },[caseLevelSuccess,casepayload]);
  useEffect(() => {
    getImageUpload();
  }, [viewimageupload]);

  useEffect(() => {
    getAddresslist();
  }, [showmodaladdress]);

  useEffect(() => {
    if (savedata.length > 0) {
      console.log("working");
      setisPageSave(true);
      onPagesaveData(true);
    } else {
      setisPageSave(false);
      onPagesaveData(false);
    }
  }, [savedata]);

  useEffect(() => {
    const handlebeforeload = (e) => {
      e.preventDefault();
      e.returnValue = "HHSD";
    };
    window.addEventListener("beforeunload", handlebeforeload);

    return () => {
      window.removeEventListener("beforeunload", handlebeforeload);
    };
  }, [savedata]);

  const oauthtoken = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_API_SAK_URL + "/oauth/token?grant_type=client_credentials",
      headers: {
        Authorization: `Basic dW40dnk5bEV1eE9mSjVKZTBHR0U2RXExU3NkZG9BcUM6Zmx5V0JDTGZEQkJuRG5iYQ==`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setAccessOSG(data.data.access_token);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };
  const handleAuditData = ()  =>{  
    axios
    .get(
      process.env.REACT_APP_BASE_API_URL +
        "/getFdtComments?deviceName=" +
        mstName,
      headers
    )
    .then((response) => {
      const data = response.data;
      setAuditInfo(data);
     console.log(data,"auditdata");
    })
    .catch((error) => {
      if (error) {
        if (
          error.status === 400 ||
          error.status === 502 ||
          error.status === 404 ||
          error.status === 500
        ) {
            setAuditInfo([]);
        }
      } else {
      }
    });

  }
  const handleAuditInfo =()=>{
    setAuditInfoPopup(true);
  }


    const handleCloseAudit = (data) =>{
    setAuditInfoPopup(data);
  }
  const getImageUpload = () => {
    const isreview = "NO";
    axios.get(
      process.env.REACT_APP_BASE_API_IMAGE_URL + "/get?fileName=" +
        mstName+"&isReviewed=" + isreview,  headersImage
    )
    .then(response => {  
      // const promiseResult = response.data[0].imageBase64;
      var promiseResult = response.data;
      console.log(promiseResult)
      const deviceLevel = promiseResult.filter(image => image.imageLevel === 'Device Level');
      console.log(deviceLevel)
      if(deviceLevel.length>0){
        setImageURL(deviceLevel[0].imageBase64?deviceLevel[0].imageBase64:'');
        setImageName(deviceLevel[0].imageName?deviceLevel[0].imageName:'');
        var imgdevicerole = deviceLevel.length>0 ? true: false;
      setactiveviewimage(imgdevicerole);
      setImageBase64('data:image/png;base64,' + deviceLevel[0].imageBase64?deviceLevel[0].imageBase64:'')
      }
      var portlevel = response.data;
      var portLevelImages = portlevel.filter(image => image.imageLevel === 'Port Level');
      if(portLevelImages.length > 0){
        setImagelist(portLevelImages);
      
        var imgportrole = portLevelImages.length>0 ? true: false
        setactiveviewimageport(imgportrole);
  
        
        console.log(promiseResult,
          'd'
        )   
      }
    
     
    })  
      .catch((error) => {
        // setImagelist([]);
        if (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            setactiveviewimage(false);
            setactiveviewimageport(false);
            setImagelist([])
            setImageURL('')
          }
        } else {
        }
      });
  };
  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setaccessBSW(true);
          break;
          case "Field Activation":
      case "Field Operations":
      case "Facility Support":
      case "Field Technician":
      case "Cable Technician":
      case "Structure Load":
        setenableEdit(true);
        break;
      case "BSW Order Desk":
        setenableEdit(true);
        setaccessBSW(true);
          break;
      case "SRO":
      case "View Only":
      case "Engineer":
        setenableEdit(false);
    }
  };

  const handleDtnInfo = () =>{
    setPopoverInfo(true);
  }
  
  const getdtnauthenticate = (item, row,key) => {
    setloadingpop('Loading...')
    setdtnauthinfo({
      SessionStatus: "",
      StartTime: "",
      StopTime: "",
      WGName: "",
      DSLAMNode:"",
      status:"",
      dtn:""
    })
    
    var index = row.id;
      setPopoverVisible(true);
      fetch(
        process.env.REACT_APP_BASE_API_URL +
          "/lars/authentication/" +
          item +
          "?", headers
      )
        .then((response) => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setdtnauthinfo(data);
            setdtnauthinfo(prevData => ({ ...prevData, dtn: item }));
          }
        })
        .catch((error) => {
          if (error) {
            if (error.status === 404 ||  error.status === 400) {
              const data = {
                SessionStatus: "",
    StartTime: "",
    StopTime: "",
    WGName: "",
    DSLAMNode:"",
    status:"failed",
    dtn:""
              }
              setdtnauthinfo(data);
              setloadingpop('Failed to Authenticate')
            }
            if (
             
              error.status === 502 ||
              error.status === 500
            ) {
              setShowerror(true);
            }
          } else {
          }
        });
    setClickedIndex(index);
    setClickedIndexDtn(key);
  };
  const navigateOnt = (item) =>{
    navigate("/ontmain/" + item + "/NA" + "/NA");
  }
  const getavailableportmst = () => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getMstPortAvailableList?mst=" +
        mstName , headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setAvailableportlist(data);
        }
      })
      .catch((error) => {
        if (error) {
          if (error.status === 404) {
            setAvailableportlist([]);
          }
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });
  };
  const getFDHListportmst = () => {
    axios
    .get( process.env.REACT_APP_BASE_API_URL + "/getFdhByMst?mst=" + mstName , headers)
    .then((response) => {
      const data = response.data;
      if(data.status === "400"){
        
        setfdhFilteredSuggestions([]);

      }else{
        var datafdh = data[0]?data[0]:'NA';
       getmstlist(datafdh)
      }
    })
  
      .catch((error) => {
        setfdhFilteredSuggestions([]);
        if (error.response) {
          if (error.reponse.status === 404) {
            setfdhFilteredSuggestions([]);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };

  const getmstlist = (fdhvalue) => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getMstListByFdh?fdh=" +
        fdhvalue , headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((response) => {
        console.log(response);
        if (response) {
          const data = response;
          if (data.message === "No Records Found for the FDH") {
            setAvailableMSTlist([]);
          } else {
            console.log("valu", data);
            setAvailableMSTlist(data);
          }
        }
      })
      .catch((error) => {
        if (error) {
          if (error.status === 404) {
            setAvailableMSTlist([]);
          }
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });
  };
  const getAddresslist = () => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddressLinkedWith?mst=" +
        mstName, headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setAddressList(data);
        }
      })
      .catch((error) => {
        if (error) {
          if (error.status === 404) {
            setAddressList([]);
          }
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setAddressList([]);
            setShowerror(true);
          }
        } else {
        }
      });
  };
  const getCommentsdrop = () => {
    const datacm2 = {
       comments: "",
      auditDropType: "",     
    };
    setmstwise("");
   setFormData(datacm2);
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        mstName , headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          // console.log(data[0].mstWise
          //   ,"check");20
          const datacm = {
            comments: data[0].comments,
            auditDropType: data[0].auditDropType,     
          };
          setFormData(datacm);
        }
      })
      .catch((error) => {
        const datacm2 = {
          comments: "",
          auditDropType: "",     
        };
        setFormData(datacm2);
        if (error) {
          if (error.status === 404) {
            setAddressList([]);

          }
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });
  };
  const deviceStatus = (fdhName) => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        fdhName, headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if(data && data[0] && data[0].mstWise){
          setmstwise(data[0].mstWise);
          }

          if (data[0].auditStatus == "COMPLETED") {
            setIsChecked(true);
            onPageReviewtime(data[0].auditTime);
            onPageStatus(data[0].auditStatus);
          } else if (data[0].auditStatus == "PENDING") {
            onPageStatus(data[0].auditStatus);
            onPageReviewtime(data[0].auditTime);
          } else {
          }
        }
      })
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            onPageStatus("Not Audited");
            onPageReviewtime(null);
          }
        } else {
        }
      });
  };

  const handleShow = () => {
    setNeisl(!neisl);
  };

  const fetchDatamst = () => {
    const mstsend = selectedMstchange ? selectedMstchange : mstName;
    setBlackmod(true);
    const responsetwo = axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/getMstSpliterDtls?mst=" +
          mstsend, headers
      )
      .then((data) => {
        var dataso2 = data;
        let mergedDataMap = {};
        var datas = dataso2.data;
        setApiData(datas);
        console.log(datas);
        resultData = Object.entries(datas).map(([key, value]) => ({
          id: key,
          ...value[0],
        }));
        console.log(resultData);

        const transformArray = (resultData) => {
          return resultData.map(item => {
            const { onFieldSplitter, onFieldSplitterLeg, ...rest } = item;
            return {
              ...rest,
              onfieldSplitter: onFieldSplitter,
              onfieldSplitterLeg: onFieldSplitterLeg
            };
          });
        };
        var transformedArray = transformArray(resultData);
        setapidatalist(transformedArray);

        resultData?.forEach((item) => {
          mergedDataMap[item.id] = {
            ...mergedDataMap[item.id],
            ...item,
          };
        });

        let mergedDataArray = Object.values(mergedDataMap).map(
          (item, index) => ({
            id: index,
            ndsDistCable: item.ndsDistributionCableStrand || null,
            o2fdhOpPort: item.distCblStd || null,
            o2mstName: item.mst || null,
            mstPort: item.mstPort || null,

            splitter_leg: item.leg || null,
            splitter: item.splitterSlot || null,

            
            ndsapPort: item.ndsApPort || null,

            ndsSplitterLeg: item.ndsSplitterLeg || null,
            ndsSplitter: item.ndsSplitter || null,
            mstAddress: item.mstAddress,
            fdhName: item.fdh || null,
            LEG_STATUS: item.legStatus[0] || null,
            onfieldStrand: item.onfieldStrand || null,
            onfieldSplitter: item.onFieldSplitter || null,
            onfieldSplitterLeg: item.onFieldSplitterLeg || null,
            onFieldComparison: item.onFieldComparison || null,
            DROP_TYPE: item.dropType || null,
            casaSerial: item.issueDevice || null,
            MST_PORT_WISE: item.mstPortWise || null,
            updatedMstAddress: item.updatedMstAddress || null,
            OptiTapCheck: item.OptiTapCheck || null,
            optiTap: item.optiTap || null,
            optiblock: false,
            dtn: item?.dtn,
            addressAliasId: item?.addressAliasId
          })
        );

        // mergedDataArray.sort((a,b) => a.mstPort.localeCompare(b.mstPort))
        console.log(imageList);
        const mergeArrays = () => {
          return mergedDataArray.map(port => {
            const image = imageList.find(img => img.imageName.includes(`TP_${port.mstPort}`));
            return {
              ...port,
              imageName: image ? image.imageName : '',
              imageType: image ? image.imageType : '',
              imageLevel: image ? image.imageLevel : '',
              imageBase64: image ? image.imageBase64 : ''
            };
          });
        };
        var merged = mergeArrays();
        console.log(merged)
        // setoverallData(merged);
       
        merged.sort((a,b) =>{
          let numA = parseInt(a.mstPort.match(/\d+/g)?.[0] || 0,10);
          let numB = parseInt(b.mstPort.match(/\d+/g)?.[0] || 0,10);
          let charA = (a.mstPort.match(/[A-Za-z]/g) || []).join('');
          let charB = (b.mstPort.match(/[A-Za-z]/g)|| []).join('');
          if(numA == numB){
            return charA.localeCompare(charB);
          }else{
            return numA-numB;
          }})
        setoverallData(merged);
        setcheckfilter(mergedDataArray);
        setfdhnames(mergedDataArray[0].fdhName);
        console.log(mergedDataArray[0].fdhName,"fdhname");
        setBlackmod(false);
        var arr = []
    for (let index = 0; index < mergedDataArray.length; index++) {
      const status = mergedDataArray[index]['LEG_STATUS'];
      if(status == 'PENDING' || status == 'WORKING' ){
        if(mergedDataArray[index]['dtn']){
          var loopdtn = mergedDataArray[index]['dtn'];
          loopdtn.map((item, index)=>{
          })
        arr.push(mergedDataArray[index]['dtn'])
        }
      }
      console.log(arr,"checkdata");   
    }
    const flateArray = arr.flatMap(array=> array);
    setSFDtn(flateArray);
    console.log(flateArray,"cheata");
      })
      .catch((error) => {
        setBlackmod(false);
        setoverallData([]);
        if (error.response) {
          if (error.response.status === 404) {
            setoverallData([]);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
      
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    console.log(newPage);
    console.log(totalPages);
    console.log(filteredData);
  };

  const handleViewMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleAddresspopup = () => {
    setShowmodalAddress(!showmodaladdress);
  };
  const handleMstIssueClose = (data) => {
    setShowmodalIssue(data);
  };
  const handleAddresspopupOpen = (rowIndex) => {
    setShowmodalAddressUpdate(true);
    setAddressIndex(rowIndex);
  };
  const handleCasapopupOpen = (rowIndex) => {
    setShowmodalcasaUpdate(true);
    setcasaIndex(rowIndex);
  };
  const handleAddAddressUpdate = (data) => {
    setShowmodalAddressUpdate(false);
    filteredData[addressIndex]["updatedMstAddress"] = data;
    console.log(filteredData[addressIndex]["updatedMstAddress"]);
  };
  const handleAddCasaUpdate = (data) => {
    setShowmodalcasaUpdate(false);
    filteredData[casaIndex]["casaSerial"] = data;
    console.log(filteredData[casaIndex]["casaSerial"]);
    const datass = {
      mst: mstName,
      fdh: fdhnames,
      mstPort: filteredData[casaIndex]["mstPort"] || "",
      staticId: filteredData[casaIndex]["idval"] || "",
      distCblStd: filteredData[casaIndex]["o2fdhOpPort"] || "",
      splitterSlot: filteredData[casaIndex]["splitter"] || "",
      leg: filteredData[casaIndex]["splitter_leg"] || "",
      ndsSplitter: filteredData[casaIndex]["ndsSplitter"] || "",
      ndsSplitterLeg: filteredData[casaIndex]["ndsSplitterLeg"] || "",
      onfieldSplitter: filteredData[casaIndex]["onfieldSplitter"] || "",
      onfieldSplitterLeg: filteredData[casaIndex]["onfieldSplitterLeg"] || "",
      onfieldStrand: filteredData[casaIndex]["onfieldStrand"] || "",
      onFieldComparison: filteredData[casaIndex]["onFieldComparison"] || "",
      casaSerial: filteredData[casaIndex]["casaSerial"] || "",
      legStatus: [filteredData[casaIndex]["LEG_STATUS"]] || "",
      dropType: filteredData[casaIndex]["DROP_TYPE"] || "",
      mstPortWise: filteredData[casaIndex]["MST_PORT_WISE"] || "",
      updatedMstAddress: filteredData[casaIndex]["updatedMstAddress"] || "",
      mstAddress: filteredData[casaIndex]["mstAddress"] || "",
      optiTap: filteredData[casaIndex]["optiTap"] || "",
      dtn: filteredData[casaIndex]["dtn"] || "",
    };
    console.log(datass);
    handleAdd(datass);
  };

  const handleResetdata = (rowIndex) => {
    const updatedData = [...overallData];
    const originalRowData = apidatalist[rowIndex];
  
    console.log("Row Index:", rowIndex);
    console.log("Original Row Data:", originalRowData);
    console.log("Current Row Data:", updatedData[rowIndex]);
  
    const isAlphanumeric = /[a-zA-Z]/.test(updatedData[rowIndex].mstPort);
    console.log("Is Alphanumeric:", isAlphanumeric);
  
    if (isAlphanumeric) {
      // Extract the base numeric part of the alphanumeric identifier
      const baseNumericValue = updatedData[rowIndex].mstPort.match(/^\d+/)[0];
  
      // Find all rows with the same base numeric value
      const alphanumericRows = updatedData.filter((row, index) =>
        row.mstPort.startsWith(baseNumericValue) &&
        row.mstPort !== baseNumericValue &&
        index !== rowIndex
      );
  
      // Reset the first alphanumeric row to the base numeric value
      const firstAlphanumericIndex = updatedData.findIndex(
        row => row.mstPort === updatedData[rowIndex].mstPort
      );
  
      // Reset properties to their original state
      const propertiesToReset = [
        "onfieldSplitter",
        "onfieldSplitterLeg",
        "onfieldStrand",
        "MST_PORT_WISE",
        "LEG_STATUS",
        "imageBase64"
      ];
  
      const resetRow = { ...updatedData[firstAlphanumericIndex], mstPort: baseNumericValue };
  
      propertiesToReset.forEach(key => {
        console.log(`Resetting property ${key} from ${resetRow[key]} to ${originalRowData[key] || null}`);
        resetRow[key] = originalRowData[key] || "";
      });
  
      // Reset optiTap explicitly after resetting other properties
      resetRow.optiTap = null;
      // resetRow.LEG_STATUS = originalRowData.legStatus ? originalRowData.legStatus[0] : "";
  
      updatedData[firstAlphanumericIndex] = resetRow;
  
      // Remove all subsequent alphanumeric rows
      alphanumericRows.forEach(row => {
        const index = updatedData.findIndex(r => r.mstPort === row.mstPort);
        updatedData.splice(index, 1);
      });
  
      console.log("Updated Data After Alphanumeric Reset:", updatedData);
      setoverallData(updatedData);
    } else {
      // For numeric reset, reset specific properties
      const resetProperties = [
        "onFieldComparison",
        "onfieldSplitter",
        "onfieldSplitterLeg",
        "onfieldStrand",
        "updatedMstAddress",
        "MST_PORT_WISE",
        // "LEG_STATUS"
      ];
  
      const newItem = { ...updatedData[rowIndex] };
  
      resetProperties.forEach(key => {
        console.log(`Resetting property ${key} from ${newItem[key]} to ${originalRowData[key] || null}`);
        newItem[key] = originalRowData[key] || ""; // Reset the property to the original value
      });
  
      
  
      console.log("New Item After Reset:", newItem);
  
      updatedData[rowIndex] = newItem;
      console.log("Updated Data After Numeric Reset:", updatedData);
      setoverallData(updatedData);
    }
  };
  const handleAddressClose = (data) => {
    setShowmodalAddress(data);
  };
  const handleClickOutside = (e) =>{
    if(popupRef.current && !popupRef.current.contains(e.target)){
      setPopoverVisible(false);
      setPopoverInfo(false);
      setClickedIndex(null);
    }
  }

  useEffect(()=>{
    if(popoverVisible){
      document.addEventListener('mousedown',handleClickOutside);
    }
    if(popoverInfo){
      document.addEventListener('mousedown',handleClickOutside);
    }
    return () =>{
      document.removeEventListener('mousedown',handleClickOutside);
    }
  },[popoverVisible,popoverInfo])
  const handleAddressCloseupdate = (data) => {
    setShowmodalAddressUpdate(data);
  };
  const handlCloseoptipop = (data) => {
    setPopupOpti(data);
  };
  const handleCasaCloseupdate = (data) => {
    setShowmodalcasaUpdate(data);
  };
  const [showDropdown, setShowDropDown] = useState(false);
  const [numRowsToAdd, setNumRowsToAdd] = useState(1);

  const toggleDropdown = () => {
    setShowDropDown(!showDropdown);
  };

  const handleClick = (itemId, value) => {
    console.log(itemId, "id");
    const updatedItems = [...overallData];
    updatedItems.map((item, index) => {
      console.log(item, index);
      if (item.idval === itemId) {
        updatedItems.splice(index, 1);
        setoverallData(updatedItems);
        dispatch({ type: "DELETE_ITEM", payload: itemId });
      }
    });
  };

  const toggleDropAdd = () => {
    const lastindex = overallData.length + 1;
    console.log(lastindex);
    const newRows = Array.from({ length: 1 }, (_, index) => ({
      mstPort: "",
      idval: lastindex,
      idEdit: true,
    }));
    console.log(newRows, "kh");
    setoverallData((prevData) => [...prevData, ...newRows]);
  };

 

  const addRows = () => {
  
    const lastId =
      overallData.length > 0
        ? Math.max(...overallData.map((row) => row.mstPort))
        : 0;
    console.log(lastId);
    const newRows = Array.from({ length: numRowsToAdd }, (_, index) => ({
      mstPort: lastId + index + 1,
    }));
    console.log(newRows);
    setoverallData((prevData) => [...prevData, ...newRows]);
    
  };

  const incrementCounter = () => {
    setNumRowsToAdd(numRowsToAdd + 1);
  };

  const decrementCounter = () => {
    if (numRowsToAdd > 1) {
      setNumRowsToAdd(numRowsToAdd - 1);
    }
  };

  const handleCheckbox = () => {
    setIsCheckedvalid(!isCheckedvalid);
  };

  const listClassNames = {
    AVAILABLE: "class-list1",
    PENDING: "class-list2",
    WORKING: "class-list3",
    "LEGACY USER": "class-list4",
    "LEGACY AVAILABLE": "class-list4",
    "LEGACY PENDING": "class-list4",
    "LEGACY WORKING": "class-list4",
    "SUSPENDED": "class-list5",
    "DEFECTIVE": "class-list5",
     "LEGACY SUSPENDED": "class-list5",
    "LEGACY DEFECTIVE": "class-list5",
  };

  const Statusupdate = (splitter_status) => {
    return listClassNames[splitter_status] || "";
  };

  const handleSave = async () => {
    setIsEditing(true);
    if (checkfilter === overallData) {
    }
    const newErrors = {
      comments: "",
      auditDropType: "",
    };

    // Basic validation

    if (formData.comments === "") {
      newErrors.comments = "Comments field is required";
    }
    if (formData.auditDropType === "") {
      newErrors.auditDropType = "Mst Placement  is required";
    }
    setErrors(newErrors);
    setIsPopoverNOpen(false);
    setIsPopoverStdOpen(false);
    var totalcount = 0;
    var state = false;
    var stand = false;

    if (Object.values(newErrors).every((error) => error === "")) {
      // Perform further actions here, e.g., submit the form data
      for (let index = 0; index < filteredData.length; index++) {
        const element = filteredData[index];

        if (
          (element.onfieldStrand == null ||
            element.onfieldStrand == "" ||
            element.onfieldSplitter == null ||
            element.onfieldSplitter == "" ||
            element.onfieldSplitterLeg == null ||
            element.onfieldSplitterLeg == "") &&
          isCheckedvalid
        ) {
          setIsPopoverNOpen(true);
          setIsPopoverStdOpen(false);
         
          state = true;
        }
        if (
          (element.mstPort == null || element.mstPort == "") &&
          isCheckedvalid
        ) {
          setIsPopoverNOpen(false);
          setIsPopoverStdOpen(true);
          
          stand = true;
          break;
        }
      }
      setErrors(newErrors);
      if (state == false && stand == false) {
        var auditstatus = "Completed";
        // if (isChecked === true) {
        //   auditstatus = "Completed";
        // } else {
        //   auditstatus = "Completed";
        // }
        setshowloader(true);
        const valuedata = [null];
        try {
          const responseone = await axios.post(
            process.env.REACT_APP_BASE_API_URL +
              "/postMstFieldAuditDetails" +
              "?status=" +
              auditstatus +
              "&user=" +
              username +
              "%2F" +
              cuid +
              "&auditType=" +
              audittypeval +
              "&mstWise=" +
              mstwise +
              "&mstIssueDevice=" +
              mstCaseNomst +
              "&comments=" +
              formData.comments +
              "&dropType=" +
              formData.auditDropType +
              "&deviceName=" +
              mstName +
              "&wirecenter=" +
              wireState.wireCenter +
              "&state=" +
              wireState.state ,
            savedata.length > 0 ? savedata : valuedata,
            headers
          );

          var datas = responseone.data;

          if (datas == "Success") {
            handleAuditData();
            setshowloader(false);
            setsuccModal(true);
            setIsChecked(false);
            deviceStatus(mstName);

            var list = overallData.map((item) => {
              console.log(item);
              if (item && item.idEdit) {
                item.idEdit = false;
              }
              return item;
            });
            setoverallData(list);
            dispatch({ type: "EMPTY", payload: [] });
            console.log(savedata);
            setCasePayload(true);
             
          
          } else {
          }
          console.log(datas);
        } catch (error) {
          console.error("Error fetching data:", error);
          setshowloader(false);
          if (error.response) {
            if (error.response.status === 400) {
              setIsPopoverOpen(true);
            }
            if (
              error.response.status === 502 ||
              error.response.status === 500
            ) {
              setShowerror(true);
            }
          } else {
            setShowerror(true);
          }
        }
      }
    }
  };

  const autoSave = async () => {
    var auditstatus = "Completed";
    const valuedata = [null];
    try {
      const responseone = await axios.post(
        process.env.REACT_APP_BASE_API_URL +
          "/postMstFieldAuditDetails" +
          "?status=" +
          auditstatus +
          "&user=" +
          username +
          "%2F" +
          cuid +
          "&auditType=" +
          audittypeval +
          "&mstWise=" +
          mstwise +
          "&mstIssueDevice=" +
          mstCaseNomst +
          "&comments=" +
          formData.comments +
          "&dropType=" +
          formData.auditDropType +
          "&deviceName=" +
          mstName +
          "&wirecenter=" +
          wireState.wireCenter +
          "&state=" +
          wireState.state ,
        savedata.length > 0 ? savedata : valuedata,
        headers
      );

      var datas = responseone.data;

      if (datas == "Success") {
        fetchDatamst();
        handleAuditData();
        setIsChecked(false);
        setactiveauto(false);
        deviceStatus(mstName);
        var list = overallData.map((item) => {
          console.log(item);
          if (item && item.idEdit) {
            item.idEdit = false;
          }
          return item;
        });
        setoverallData(list);
        dispatch({ type: "EMPTY", payload: [] });
        console.log(savedata);
        
      } else {
      }
      console.log(datas);
    } catch (error) {
      console.error("Error fetching data:", error);
      setshowloader(false);
      if (error.response) {
        if (error.response.status === 400) {
          setIsPopoverOpen(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }
  };
  const handleFilter = async () => {
    setisFilter(!isFilter);
  };
 

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchTerm);
    setCurrentPage(1); // Reset to the first page when searching
  };

  console.log(overallData);
  var ss = overallData.length;
  console.log(ss);

  const totalPages = Math.ceil(overallData.length / PAGE_SIZE);

  const visibleData = overallData.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );
  var splitterArray;
  var legArray;
  var originalSplitval;
  var originalLegval;
  
  const handledtnNavigate = (item, key) => {
    if (item !== "") {
      navigate("/ontmain/" + item + "/NA" + "/NA");
    }
  };
  const handleInputChangeSplit = (
    event,
    rowIndex,
    row,
    index,
    from,
    column
  ) => {
    if (event.target.value == "") {
      filteredData[index][column] = event.target.value;
    }
    const patternnumber = /^[0-9]*$/;
    const pattern = /^(?:[0-9]+|N+)$/;
    setErrorMessage("");
    setportdefectPortvalue(false);
    setrowIndexval(index)
    setfdhopvalue(row);
    var imgbase = row.imageBase64;
    console.log(row.imageBase64,'sd')
    setImageBase64('data:image/png;base64,' + row.imageBase64);

    if (
      (column === "onfieldSplitter" ||
        column === "onfieldStrand" ||
        column === "onfieldSplitterLeg") &&
      !pattern.test(event.target.value)
    ) {
      if (event.target.value && event.target.value.endsWith("N")) {
        var repvalue = "N";
      } else {
        var repvalue = event.target.value.replace(
          /[^0-9N+]|^N|[^N][0-9]*N+/g,
          ""
        );
      }
      if (event.target.value == "") {
        filteredData[index]["onFieldComparison"] = "";
      }
      setErrorMessage(
        "Please Enter only UPPERCASE 'N' and digits, Special characters and Alphanumeric not allowed"
      );
      if (from == "s") {
        document.getElementById("split-" + index).value = repvalue;
      } else if (from == "l") {
        document.getElementById("leg-" + index).value = repvalue;
      } else if (from == "st") {
        document.getElementById("stand-" + index).value = repvalue;
      } else {
        document.getElementById("updatedMstAddress-" + index).value = repvalue;
      }
    } else if (column === "mstPort") {
      if (!patternnumber.test(event.target.value)) {
        document.getElementById("id-" + index).value =
          filteredData[index][column];
        setErrorMessage(
          "Please Enter only Numbers, Special characters and Alphanumeric not allowed"
        );
      } else {
        filteredData[index][column] = event.target.value;
        document.getElementById("id-" + index).value = event.target.value;
      }
    } else {
      const { name, value } = event.target;
      console.log(value);
      var spliter = "";
      var leg = "";
      setArraysplit(row);
      if (event.target.value.endsWith("N")) {
        filteredData[index][column] = "N";
        if (from == "s") {
          document.getElementById("split-" + index).value = "N";
        } else if (from == "l") {
          document.getElementById("leg-" + index).value = "N";
        } else if (from == "st") {
          document.getElementById("stand-" + index).value = "N";
        } else {
          document.getElementById("updatedMstAddress-" + index).value = value;
        }
      } else {
        filteredData[index][column] = event.target.value;
      }
      var letter = "N";
      if (
        (row.o2fdhOpPort ? row.o2fdhOpPort : letter) ===
          (row.ndsDistCable ? row.ndsDistCable : row.onfieldStrand) &&
        (row.ndsDistCable ? row.ndsDistCable : row.onfieldStrand) ===
          row.onfieldStrand &&
        (row.splitter ? row.splitter : letter) ===
          (row.ndsSplitter ? row.ndsSplitter : row.onfieldSplitter) &&
        (row.ndsSplitter ? row.ndsSplitter : row.onfieldSplitter) ===
          row.onfieldSplitter &&
        (row.splitter_leg ? row.splitter_leg : letter) ===
          (row.ndsSplitterLeg ? row.ndsSplitterLeg : row.onfieldSplitterLeg) &&
        (row.ndsSplitterLeg ? row.ndsSplitterLeg : row.onfieldSplitterLeg) ===
          row.onfieldSplitterLeg
      ) {
        filteredData[index]["onFieldComparison"] = "Matching";
      } else {
        filteredData[index]["onFieldComparison"] = "Mismatching";
      }
      if (
        value != "" &&
        (row.onfieldStrand == null ||
          row.onfieldSplitter == null ||
          row.onfieldSplitterLeg == null)
      ) {
        filteredData[index]["onFieldComparison"] = "";
      }

      if (from == "s") {
        spliter = value;
        leg = document.getElementById("leg-" + index).value;
        setSplittervalue((prevInputValues) => ({
          ...prevInputValues,
          [rowIndex]: { ...prevInputValues[rowIndex], [name]: value },
        }));
        console.log(parseInt(row.splitter));
        console.log(parseInt(row.ndsSplitter));
        console.log(parseInt(value));
      } else {
        leg = value;
        spliter = document.getElementById("split-" + index).value;
        setLegvalue((prevInputValues) => ({
          ...prevInputValues,
          [rowIndex]: { ...prevInputValues[rowIndex], [name]: value },
        }));
        console.log(parseInt(row.splitter_leg));
        console.log(parseInt(row.ndsSplitterLeg));
        console.log(parseInt(value));
        
      }
      
      console.log(isLegMatching);
      console.log(isSpliterMatching);
      if (from == "standid") {
        filteredData[index][column] = event.target.value;
      }
      if (from == "AD") {
        filteredData[index][column] = event.target.value;
      }
      if (from == "d" && column == "MST_PORT_WISE") {
        if (
          from == "d" &&
          column == "MST_PORT_WISE" &&
          event.target.value == "Casa Device"
        ) {
          if (event.target.value == "Casa Device") {
            setCasaInitialIndex(index);
            // handleCasapopupOpen(index);
            setmstTpIssue(row.MST_PORT_WISE);
           
            setMstTpPopup(true);
          }
        } else if (from == "d" && column == "MST_PORT_WISE" &&
          event.target.value == "Restore to Available"
        ) {
          setShowRestorePortModal(true);
          console.log(row.MST_PORT_WISE,"handleinputmstwise" )
          
          setissueport(row.MST_PORT_WISE?row.MST_PORT_WISE:'')
          if(row.casaSerial){
            console.log("case serial",row.casaSerial)
            setCheckCasaSerial("yescasa");

          }else{
            console.log("not present")
            setCheckCasaSerial("nocasa")
          }

        }
        // else {  
        //   filteredData[index]["casaSerial"] = "";
        // }
        if(event.target.value == 'Conduit Extension'){
          if(!viewimageupload){
            setShowmodalimage(true);
          }
        }
      }
      if(from == "d" && column == "MST_PORT_WISE" && event.target.value == "YES") {

        setstatusport(row.LEG_STATUS?row.LEG_STATUS:'')
        setmstTpIssue(row.MST_PORT_WISE);
        setMstTpPopup(true);
        for (let index = 0; index < filteredData.length; index++) {
          const element = filteredData[index];
  
          if (
            (element.onfieldStrand == null ||
              element.onfieldStrand == "" ||
              element.onfieldSplitter == null ||
              element.onfieldSplitter == "" ||
              element.onfieldSplitterLeg == null ||
              element.onfieldSplitterLeg == "") || 
             !isCheckedvalid
          ) {
            setOptiRow(row);
            setoptiIndex(rowIndex);
            setallowOptichange(true);
            // setPopupOpti(true);
            // updateRowsopti(2,row.mstPort,row,rowIndex);
           
          }else{
            setallowOptichange(false)
            // setPopupOpti(true);
            // updateRowsopti(2,row.mstPort,row,rowIndex);
          }
        
        }
        
        
      }
      if (
        from == "d" &&
        column == "MST_PORT_WISE" &&
        (event.target.value == "No Light" ||
          event.target.value == "Low Light" ||
          event.target.value == "Not Spliced" ||
          event.target.value == "Broken Port" ||
         event.target.value == "Port in use")
      ) {
        setOptiRow(row);
        setIssueIndex(index);

        setmstTpIssue(row.MST_PORT_WISE);
        setAddressAlias(row.addressAliasId);
        setPortSelectedDefect(row.mstPort);
        setDropPlacement("");
        setstatusport(row.LEG_STATUS?row.LEG_STATUS:'')
        setissueport(row.MST_PORT_WISE?row.MST_PORT_WISE:'')
        const dataforisse = {
          DTN: row.dtn[0],
          Action: "Create Case",
          Issue: row.MST_PORT_WISE,
          Subject: `FDT/${row.MST_PORT_WISE}`,
          wireCenter: wireState.wireCenter,
          MSTName: mstName,
          FDHName: fdhnames,
          DistributionStrand: row.o2fdhOpPort?row.o2fdhOpPort:'NA',
          SplitterSlot: row.splitter,
          TechCuid: TechCuid,
          SplitterLeg: row.splitter_leg,
        };
        setIssuepayload(dataforisse);
        if(row.LEG_STATUS == 'AVAILABLE' ||  row.LEG_STATUS == 'WORKING' || row.LEG_STATUS == 'LEGACY USER'){

          setMstTpWorPop(true);
          //  createCase(dataforisse,row,index);
          //  setportdefectPortvalue(true);
        } else{

       setMstTpPopup(true);
        // setShowNoLightConfirm(true);
        SetdataIssue({
          data: dataforisse,
          rowval: row,
          indexval: index,
        });
        setCurrentDtn(row.dtn[0]); 
      }  
      }
      if (
        from == "d" &&
        column == "MST_PORT_WISE" &&
        (event.target.value == "Drop Placement" )
      ) {

        setmstTpIssue(row.MST_PORT_WISE);
        if(row.LEG_STATUS === "PENDING"){
          setMstTpPopup(true);
        } else if(row.LEG_STATUS === "WORKING"){
           setMstTpWorPop(true);
        }
        setDropPlacement("Drop Placement");
        setAddressAlias(row.addressAliasId);
        setPortSelectedDefect(row.mstPort);
        setfdhopvalue(row);
        setstatusport(row.LEG_STATUS?row.LEG_STATUS:'')
        setissueport(row.MST_PORT_WISE?row.MST_PORT_WISE:'')
        // setShowNoLightConfirm(true);
        setCurrentDtn(row.dtn[0]);   
      }

      if (
        from == "d" &&
        column == "MST_PORT_WISE" &&
        event.target.value == "YES"
      ) {
        setstatusport(row.LEG_STATUS?row.LEG_STATUS:'')
        
        setmstTpIssue(row.MST_PORT_WISE);
        setMstTpPopup(true);
        if (event.target.value == "YES") {
          filteredData[index]["OptiTapCheck"] = "YES";
        } else {
          filteredData[index]["OptiTapCheck"] = "NO";
        }
        return false;
      }
      
      console.log(row, "row");
      const data = {
        mst: mstName,
        fdh: fdhnames,
        mstPort: row.mstPort,
        staticId: row.idval ? row.idval : null,
        distCblStd: row.o2fdhOpPort,
        splitterSlot: row.splitter,
        leg: row.splitter_leg,
        ndsSplitter: row.ndsSplitter,
        ndsSplitterLeg: row.ndsSplitterLeg,
        onfieldSplitter: row.onfieldSplitter,
        onfieldSplitterLeg: row.onfieldSplitterLeg,
        onfieldStrand: row.onfieldStrand,
        onFieldComparison: row.onFieldComparison,
        issueDevice: row.casaSerial,
        legStatus: [row.LEG_STATUS],
        dropType: row.DROP_TYPE,
        mstPortWise: row.MST_PORT_WISE,
        updatedMstAddress: row.updatedMstAddress,
        mstAddress: row?.mstAddress,
        optiTap: row.optiTap ? row.optiTap : null,
        dtn: row?.dtn,
      };
      console.log(data);
      handleAdd(data);
    }
  };
  const handleTpYes = (data,issuename) =>{
     setMstTpPopup(data);
     if((issuename == "No Light" ||
      issuename == "Low Light" ||
      issuename == "Not Spliced" ||
      issuename == "Broken Port" ||
      issuename == "Port in use")){
        setShowNoLightConfirm(true);
      }else if (issuename === "Drop Placement"){
        setShowNoLightConfirm(true);
      }else if(issuename === "Casa Device"){
      handleCasapopupOpen(casaInitialIndex);
     } else {
      updateRowsopti(2,optiRow.mstPort,optiRow,optiIndex);
     }
  }
  const handleTpWorkYes = (data,issuename) =>{
     setMstTpWorPop(data);
     createCase(issuepayload,optiRow,issueIndex);
     setportdefectPortvalue(true);
  }
  const handleTpNo = (data) =>{
    setMstTpPopup(data);
 }
 const handlecloseworkingpopup=(data)=>{
  setMstTpWorPop(data);

 }
  const createMstCase = (payload) => {
    setshowloader(true);

    fetch(process.env.REACT_APP_BASE_API_SF_URL, {
      method: "POST", // or 'GET' depending on your API endpoint
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setshowloader(false);
        // Handle the response data here
        var jsonString = data;
        const caseId = jsonString.caseId;
        const caseNumber = jsonString.caseNumber;
        if(jsonString?.ChildrenCases){
        setChildCase(jsonString.ChildrenCases);
        const mapped = filteredData.map(item => {
          const foundCase = jsonString.ChildrenCases.find(c => c.DTN === item.dtn[0]);
          console.log(foundCase,'212')
          return { ...item, casaSerial: foundCase ? foundCase.caseNumber : null , MST_PORT_WISE: foundCase ? payload.Issue :  null };
        });
        for (let index = 0; index < mapped.length; index++) {
          const data = {
            mst: mstName,
            fdh: fdhnames,
            mstPort: mapped[index].mstPort,
            distCblStd: mapped[index].o2fdhOpPort ,
            ndsSplitter: mapped[index].ndsSplitter,
            ndsSplitterLeg: mapped[index].ndsSplitterLeg,
            optiblock: mapped[index].OptiTapCheck,
            o2fdhOpPort: mapped[index].o2fdhOpPort ,
            splitter: mapped[index].splitter,
            leg: mapped[index].splitter_leg,
            onfieldStrand: mapped[index].onfieldStrand,
            onfieldSplitter: mapped[index].onfieldSplitter,
            onfieldSplitterLeg: mapped[index].onfieldSplitterLeg,
            mstPortWise: mapped[index].MST_PORT_WISE ,
            issueDevice:mapped[index].casaSerial,
            mstAddress: mapped[index].mstAddress,
            updatedMstAddress: mapped[index].updatedMstAddress,
            dtn: mapped[index].dtn ,
            legStatus: [mapped[index].LEG_STATUS],
            dropType: mapped[index].DROP_TYPE,
            onFieldComparison: mapped[index].onFieldComparison,
            optiTap: mapped[index].optiTap,
        }
        handleAdd(data);
      console.log(data,"payloadcheck") ; 
        }
        handleSave();
        console.log(mapped,'dtncheck')
        setoverallData(mapped);
        setCaseLevelSuccess(true);
      }    
   
        setCaseId(caseId);
        setMstCaseId(caseId);
        setMstCaseIdNomst(caseNumber);
        setMstCaseIdNo(caseNumber);
        setactiveauto(true);
        setErrorMessageLight(jsonString.Message);
        setModalNoLight(true);
        if (viewimageupload == true) {
          console.log(imageURLdata,"createmstcase")
          const mstlevelImg ={
            imageBase64:imageURLdata,
          }
          createCaseImg(caseId, mstlevelImg);  
        }
        setTimeout(() => {
          setModalNoLight(false);
        }, 5000);
      })
      .catch((error) => {
        setshowloader(false);
        if (error.response) {
          if (error.response.status === 404) {
            setShowerror(true);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };
  const createCase = (payload, row, index, value) => {  
    console.log(payload);
    const datainput = {
      DTN: value == 'yes' ? '' : payload.DTN,
      Action: "Create Case",
      Issue: payload.Issue,
      Subject: payload.Subject,
      wireCenter: wireState.wireCenter,
      MSTName: mstName,
      FDHName: fdhnames,
      DistributionStrand: payload.DistributionStrand?payload.DistributionStrand:'NA',
      SplitterSlot: payload.SplitterSlot,
      TechCuid: TechCuid,
      SplitterLeg: payload.SplitterLeg,
    }
    setshowloader(true);
    console.log(activeauto);
    setChildCase('');
    fetch(process.env.REACT_APP_BASE_API_SF_URL, {
      method: "POST", // or 'GET' depending on your API endpoint
      body: JSON.stringify(datainput),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        
        setshowloader(false);
        // Handle the response data here
        var jsonString = data;
        const caseId = jsonString.caseId;
        const caseNumber = jsonString.caseNumber;

        setCaseId(caseId);
        setMstCaseId(caseNumber);
        setMstCaseIdNo(caseNumber);
        filteredData[index]["casaSerial"] = caseNumber;
        if (viewimageupload == true || viewimageuploadport == true) {
          createCaseImg(caseId, row);  
        }
        const datas = {
          mst: mstName,
          fdh: fdhnames,
          mstPort: row.mstPort,
          staticId: row.idval ? row.idval : null,
          distCblStd: row.o2fdhOpPort,
          splitterSlot: row.splitter,
          leg: row.splitter_leg,
          ndsSplitter: row.ndsSplitter,
          ndsSplitterLeg: row.ndsSplitterLeg,
          onfieldSplitter: row.onfieldSplitter,
          onfieldSplitterLeg: row.onfieldSplitterLeg,
          onfieldStrand: row.onfieldStrand,
          onFieldComparison: row.onFieldComparison,
          issueDevice: row.casaSerial,
          legStatus: [row.LEG_STATUS],
          dropType: row.DROP_TYPE,
          mstPortWise: row.MST_PORT_WISE,
          updatedMstAddress: row.updatedMstAddress,
          mstAddress: row?.mstAddress,
          optiTap: row.optiTap ? row.optiTap : null,
          
          dtn: row?.dtn,
        };
        createCaseSuccess(row);
        handleAdd(datas);
        setactiveauto(true);
        setErrorMessageLight(jsonString.Message);
        setModalNoLight(true);
        setTimeout(() => {
          setModalNoLight(false);
        }, 5000);
      })
      .catch((error) => {
        setshowloader(false);
        if (error.response) {
          if (error.response.status === 404) {
            setShowerror(true);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };

  const createCaseImg = (caseId, img) => {
    console.log(img.imageBase64)
    var imgdvc = 'data:image/png;base64,' +  img.imageBase64;
    console.log(imgdvc)
    var image = imgdvc? imgdvc.split(",")[1]: imageBase64.split(",")[1];
    console.log(image,"myimage")
    const payload = {
      Action: "Upload Images",
      VersionDataList: [image],
      caseId: caseId,
    };
    if(image && image != ""){
    fetch(process.env.REACT_APP_BASE_API_SF_URL, {
      method: "POST", // or 'GET' depending on your API endpoint
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setshowloader(false);
        // Handle the response data here
        var jsonString = data;
        const caseId = jsonString.caseId;
        const caseNumber = jsonString.caseNumber;
        setCaseId(caseId);
        setMstCaseId(caseNumber);
        setactiveauto(true);
        setErrorMessageLight(jsonString.Message);
        setModalNoLight(true);
        setTimeout(() => {
          setModalNoLight(false);
        }, 5000);
      })
      .catch((error) => {
        setshowloader(false);
        if (error.response) {
          if (error.response.status === 404) {
            setShowerror(true);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
    }
  };

  //mstwise issue save
  const handlemstwise = (event) => {
    const newErrors = {
      start: "",
      stop: "",
      comments: "",
    };
    setErrors(newErrors);
    const setvalue = event.target.value;
  
    if(setvalue){
      setactivemstlevel(true);
    }
    if(setvalue === "Incorrect fiber count"){
      setIsCheckedvalid(true);
    }else{
      setIsCheckedvalid(false);
    }
    setmstwise(setvalue);
    if (setvalue === "No Light") {
      setshowLevelConfirm(true);
    } else {
      setshowLevelConfirm(false);
    }
    if (
      setvalue === "No Mst Access" ||
      setvalue === `No Mst Access-${mstpopissue}`
    ) {
      setShowmodalIssue(true);
    } else {
      setMstPopIssue("");
      setShowmodalIssue(false);
    }
  };
 
  useEffect(() => {
      if(activemstlevel == true){
    if (
      mstwise === `No Mst Access-${mstpopissue}` ||
      mstwise === "Missing MST"  ||  mstwise === "Disaster" || mstwise === "Fiber Cut"
    ) {
      const dataMst = {
        DTN: "",
        DTNList: sfDtn,
        Action: "Create Case",
        Issue: mstwise,
        Subject: `FDT/${mstwise}`,
        wireCenter: wireState.wireCenter,
        MSTName: mstName,
        FDHName: fdhnames,
        DistributionStrand: null,
        SplitterSlot: "",
        TechCuid: TechCuid,
        SplitterLeg: "",
      };
      createMstCase(dataMst);
    }
      }
  }, [mstwise]);
  const handleAdd = (data) => {
    dispatch({ type: "ADD_ENTRY", payload: data });
  };

  const handleDataFromChild = (data) => {
    setWhitemod(false);

    isChecked = data;
    setIsChecked(isChecked);

    handleSave();
    console.log(isChecked);
  };
  const handleSuccData = (data) => {
    setsuccModal(false);
  };
  const handleOptipop = (data) =>{
    setPopupOpti(data.status);
    console.log(fdhopvalue);
    var value = data.value;
    if(value !=''){
      updateRowsopti(value, fdhopvalue.mstPort, fdhopvalue, rowIndexval);

    }
    // console.log(value, fdhopvalue.id, fdhopvalue, fdhopvalue.id)
    // var id= fdhopvalue.id + 1;
   
  }

 
  const handleauditvalue = (data, isChecked) => {
    console.log(data);
    audittypeval = data;
    setAudittype(data);
    handleDataFromChild(isChecked);
  };
  const handleMstIssuePop = (data, issueVal) => {
    setShowmodalIssue(data);
    setmstwise("No Mst Access-" + issueVal);
    setMstPopIssue(issueVal);
  };
  const handleclosemodal = (data) => {
    setWhitemod(false);
  };

  const handleConfirmData = () => {
    setShowmodalconfirm(false);
  };
  // const handleSubmit = () => {
  //   setWhitemod(true);
  // };
 

  //mouneesh- MY filter logics and code starts here

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    const numericpattern = /^[0-9-]*$/;
    if (numericpattern.test(value)) {
      setFilter({ ...filter, [name]: value });
    } else {
    }
  };

  const handleIdFilter = () => {
    let minId, maxId;

    if (filter.idRange.includes("-")) {
      [minId, maxId] = filter.idRange.split("-").map(Number);
    } else {
      minId = maxId = Number(filter.idRange);
    }

   
    if (searchTerm.length > 0) {
      const filteredData = overallData.filter((item) =>
        Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const valueString = String(value);
            return valueString.includes(searchTerm);
          }
          return false;
        })
      );
      return filteredData;
    }

    // Check if the ID range input is empty and display full data

    if (filter.idRange === "") {
      // Filter the data based on the selected status

      const filteredData = visibleData.filter(
        (row) =>
          filter.splitter_status === "" ||
          row.splitter_status === filter.splitter_status
      );

      return filteredData;
    }

    // Filter the data based on the ID range and status

    const filteredData = visibleData.filter((row) => {
      const idMatch =
        (Number.isNaN(minId) || row.mstPort >= minId) &&
        (Number.isNaN(maxId) || row.mstPort <= maxId);

     

      const name = row.mstPort !== null ? row.mstPort.toLowerCase() : "";

      // Perform the case-insensitive search
      name.includes(searchTerm.toLowerCase());

      return idMatch;
    });

    return filteredData;
  };

  const filteredData = handleIdFilter();
  const showNoRecordFoundMessage =
    filter.idRange !== "" &&
    filter.splitter_status !== "" &&
    filteredData.length === 0;

  const handleDataFromError = () => {
    setShowerror(false);
  };
  const handleRestorePortPopupClose=(data)=>{
    setShowRestorePortModal(data);
    setissueport("");

  }
  const handleclosenolightmodal = () => {
    setShowNoLightConfirm(false);
    createCase(dataIssue.data, dataIssue.rowval, dataIssue.indexval, 'No');
  setTimeout(() => {
    portdefectivemst("suspend");  
    }, 2000);
  };
  const handleRestoreApi=(data)=>{
    if (data == true){
      portdefectivemst('restore');
    }
  
  }
  const handlePortAvailable = (data) => {
    var port = data.split('_');
    setMstAvailablePort(port[0]);
    setMstportaliasid(port[1]?port[1]:'');
   
  };
  const handleselectedMst = (data) => {
    SetSelectedMstchange(data);
    console.log(data,"Mstchangedata");
  };
  const handleportChangemodal = () => {
    if(dataIssue.rowval.MST_PORT_WISE !== 'Port in use' ){ 
    createCase(dataIssue.data, dataIssue.rowval, dataIssue.indexval, 'yes');
    }
    if(mstportaliasid !== ''){
      setTimeout(()=>{
        handleleftinaddress();
      },2000)
    }
    setTimeout(() => {
      portchangemst();
    }, 5000);
  };
  const handledropPlacement = () =>{
    if(mstportaliasid !== ''){
      setTimeout(()=>{
        handleleftinaddress();
      },2000)
    }
    setTimeout(() => {
      portchangemst();
    }, 5000);
  }
  const handleleftinaddress = ()=>{
    console.log(mstportaliasid,'addressalias')
    const data =  {
      "status": "STATIC",
      "actionCode": "DELETE"
    }
    axios.put(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/addresses/" +
      mstportaliasid
           +
          "/facilities",  
      data,
      {
        headers :{
           "Content-Type": "application/json",
            Authorization: `Bearer ${accessOSG}`,
         }
       },
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            setShowNoLightYes(true);
            setShowNoLightConfirm(false);
           setModalMsg('Port Left In Successfully Updated');
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            if (error.response.status === 404 || error.response.status === 400) {
              setShowNoLightYes(true);
              setShowNoLightConfirm(false);
             setModalMsg(error.response.data.message);
            }
            if (error.response.status === 502 || error.response.status === 500) {
              setShowerror(true);
            }
          } else {
          }
        });
  }
  
  const portdefectivemst = (conditioncheck) => {
    const data = {
      status: "DEFECTIVE",
      description: issueport,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessOSG}`,
    };

    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    };
    const optionsget = {
      headers: headers,
    };
    if(fdhopvalue.o2fdhOpPort){
      axios.get(
        process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/accesspoints/" + mstName 
      , optionsget )
          .then((response) => {
            if (response.status == 204 || response.status == 207 || response.status == 200) {
              var networkname = response.data;
              var distinguishnetworks = networkname.networks
                var dnvalue  = distinguishnetworks[fdhopvalue.id]
                console.log(dnvalue, fdhopvalue.id)
              console.log(distinguishnetworks,'cminbg')
              var finalnetworkname = dnvalue.networkDN;
              if(finalnetworkname){
                updateportdefect(finalnetworkname,conditioncheck);
                fetchDatamst();
              }
            }
          })
          .catch((error) => {
            if (error) {
              if (error.response.status === 404 || error.response.status === 400) {
                setShowModalDefective(true);
                setMessageError('Unable to Complete');
              }
              if (error.response.status === 500) {
                setShowModalDefective(true);
                setMessageError(error.response.data.message);
              }
              if (error.response.status === 502) {
                setShowerror(true);
              }
            } 
          });
      
    }else{
      fetch(
        process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/resources/" +
            mstName +
            "%2fTP=00" +
            portSelectedDefect +
            "/?identifierType=RESOURCE",
          options
        )
          .then((response) => {
            if(response.status == 204 || response.status == 207 || response.status == 200){
              setShowNoLightYes(true);
                setShowNoLightConfirm(false);
              setModalMsg("Port updated as Defective");
              fetchDatamst();
            }
            if (!response.ok) {
              throw response;
            }
            return response.json();
          })
          .then((response) => {
            if (response) {
    
            }
          })
          .catch((error) => {
            console.log(error)
            if (error) {
              if (error.status === 404 || error.status === 400) {
                setShowNoLightYes(true);
                setShowNoLightConfirm(false);
              setModalMsg('TP Port does not exist');
              }
              if (error.status === 502 || error.status === 500) {
                setShowerror(true);
              }
            } else {
              
            }
          });
    }
  };

  const updateportdefect = (networkname,conditioncheck)=>{
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessOSG}`,
    };
    const data = {
      status: conditioncheck== "suspend" ?"SUSPENDED" : "AVAILABLE",
      description: issueport,
    };
  

    axios.put(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/resources/" +
          networkname +
          "?identifierType=NETWORK",
      data,
      {
        headers :{
           "Content-Type": "application/json",
            Authorization: `Bearer ${accessOSG}`,
         }
       },
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            setShowRestorePortModal(false);
            setShowNoLightYes(true);
              setShowNoLightConfirm(false);
              if(conditioncheck=="restore"){
                setModalMsg("Port Restored Successfully");
              }
              else{
            setModalMsg("Port updated as Suspended");
              }
            fetchDatamst();
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            if (error.response.status === 404 || error.response.status === 400) {
              setShowNoLightYes(true);
              setShowNoLightConfirm(false);
             setModalMsg(error.response.data.message);
            }
            if (error.response.status === 502 || error.response.status === 500) {
              setShowerror(true);
            }
          } else {
          }
        });
  }
  
  const portchangemst = () => {
    const data = {
      circuitIdentifier: currentDtn,
      deviceDN: selectedMstchange === '' ? mstName :  selectedMstchange,
      port: mstAvailablePort,
      suspended: false,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessOSG}`,
    };

    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    };

    fetch(
      
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/accesspoints/ports", options
    )
      .then((response) => {
        if(response.status == 204 || response.status == 207 || response.status == 200){
          setShowNoLightYes(true);
            setShowNoLightConfirm(false);
          setModalMsg("MST port change succesfully updated");
          if(!dropPlacement){
            portdefectivemst('suspend');
          }
          // fetchDatamst();
          if(selectedMstchange){    
          nav("/mstmain/" + selectedMstchange + "/NA/NA");
          fetchDatamst();
        }
        }
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((response) => {
          setModalMsg("MST port change succesfully updated");
      })
      .catch((error) => {
        if (error) {
          if (error.status === 404 || error.status === 400) {
            setShowNoLightYes(true);
            setShowNoLightConfirm(false);
            setModalMsg("Please contact QF Tech Assist - Facility Support to process change.");
          }
          if (error.status === 502 || error.status === 500) {
            setShowerror(true);
          }
        } else {
        }
      });
  };
  

  const createCaseSuccess = (row) => {
    var value = row;
    setShowNoLightConfirm(false);
  };

  const handlecloseLevelmodal = () => {
    setshowLevelConfirm(false);
    setShowNoLightYes(true);
    setModalMsg("Splitter has light prior to reporting");
  };
  const handlenolightmodalconfirm = () => {
    setShowNoLightConfirm(false);
    setShowNoLightYes(true);
    setModalMsg(" Please contact QF Tech Assist - Facility Support to process change.");
  };
  const handleLevelconfirm = () => {
    setshowLevelConfirm(false);
    const dataMst = {
      DTN: "",
      DTNList: sfDtn,
      Action: "Create Case",
      Issue: mstwise,
      Subject: `FDT/${mstwise}`,
      wireCenter: wireState.wireCenter,
      MSTName: mstName,
      FDHName: fdhnames,
      DistributionStrand: "",
      SplitterSlot: "",
      TechCuid: TechCuid,
      SplitterLeg: "",
    };
    createMstCase(dataMst);
  };
  const updateRowsopti = (value, id, row, rowIndex) => {
    console.log(rowIndex);
    var val = value;
    var item = row;

    if (val !== "0") {
      addRowsOpti(id, val, item, rowIndex);
    }
    const data = {
      mst: mstName,
      fdh: fdhnames,

      mstPort: filteredData[rowIndex]["mstPort"],
      staticId: row.idval ? row.idval : null,
      distCblStd: filteredData[rowIndex]["o2fdhOpPort"],
      splitterSlot: filteredData[rowIndex]["splitterSlot"],
      leg: filteredData[rowIndex]["splitter_leg"],
      ndsSplitter: filteredData[rowIndex]["ndsSplitter"],
      ndsSplitterLeg: filteredData[rowIndex]["ndsSplitter"],
      onfieldSplitter: filteredData[rowIndex]["onfieldSplitter"],
      onfieldSplitterLeg: filteredData[rowIndex]["onfieldSplitterLeg"],
      onfieldStrand: filteredData[rowIndex]["onfieldStrand"],
      issueDevice: filteredData[rowIndex]["casaSerial"],
      onFieldComparison: filteredData[rowIndex]["onFieldComparison"],
      legStatus: [filteredData[rowIndex]["LEG_STATUS"]],
      dropType: filteredData[rowIndex]["DROP_TYPE"],
      mstPortWise: filteredData[rowIndex]["MST_PORT_WISE"],
      mstAddress: filteredData[rowIndex]["mstAddress"],
      updatedMstAddress: filteredData[rowIndex]["updatedMstAddress"],
      dtn: filteredData[rowIndex]["dtn"],
      optiTap: val,
     
    };
   
  };
  const addRowsOpti = (ids, value, item, rowIndex) => {
    
    const lastId = ids;
    console.log(item);
    console.log(lastId);
    let result = ["A", "B", "C", "D", "E", "F", "G", "H"];
    const newRows = Array.from({ length: value, result }, (_, index) => ({
      mst: mstName,
      fdh: fdhnames,
      mstPort: lastId + result[index],
      distCblStd: filteredData[rowIndex]["o2fdhOpPort"],
      mstPort: lastId + result[index],
      OptiTapCheck: "NO",
      ndsSplitter: filteredData[rowIndex]["ndsSplitter"],
      ndsSplitterLeg: filteredData[rowIndex]["ndsSplitter"],
      optiblock: true,
      o2fdhOpPort: filteredData[rowIndex]["o2fdhOpPort"],
      splitter: filteredData[rowIndex]["splitter"],
      splitter_leg: filteredData[rowIndex]["splitter_leg"],
      onfieldStrand: filteredData[rowIndex]["onfieldStrand"],
      onfieldSplitter: filteredData[rowIndex]["onfieldSplitter"],
      onfieldSplitterLeg: filteredData[rowIndex]["onfieldSplitterLeg"],
      onFieldComparison:  filteredData[rowIndex]["onFieldComparison"],
      mstPortWise: filteredData[rowIndex]["MST_PORT_WISE"],
      issueDevice: filteredData[rowIndex]["casaSerial"],
      mstAddress:  filteredData[rowIndex]["mstAddress"],
      updatedMstAddress: filteredData[rowIndex]["updatedMstAddress"],
      dtn: filteredData[rowIndex]["dtn"],
      legStatus: ( filteredData[rowIndex]["LEG_STATUS"] == '' || filteredData[rowIndex]["LEG_STATUS"] == null ) ?  [""] : [ filteredData[rowIndex]["LEG_STATUS"]],
      dropType: filteredData[rowIndex]["DROP_TYPE"],
     
      optiTap: value,
    }));
    console.log(newRows);

    var val = overallData.findIndex((item) => item.mstPort == ids);
    let filtrArr = overallData.filter((item) => item.mstPort !== ids);
    let newArray = [];
    var allNewRowIndex = [];
    console.log("newRows", newRows, filtrArr.length + 1, rowIndex);
    for (let index = 0; index < filtrArr.length + 1; index++) {
      if (index == rowIndex) {
        newArray = [...newArray, ...newRows];
        
        console.log("newRows", newArray);

        newRows.forEach((ro) => {
          const NewRowIndex = newArray.findIndex(
            (item) => item.mstPort === ro.mstPort
          );
          console.log("allNewRowIndex", newRows, newArray);

          if (NewRowIndex >= 0) {
            allNewRowIndex.push(NewRowIndex);
            console.log("allNewRowIndex", allNewRowIndex);
          }
        });
        console.log("newRows", newArray);
      }
      if (index < filtrArr.length) {
        newArray.push(filtrArr[index]);
      }
    }
    console.log(filtrArr, "jjj");

    var listitem = [...newRows, ...filtrArr];
    console.log(allNewRowIndex, "jjn");
   

    setoverallData(newArray);
    if (allNewRowIndex?.length > 0) {
      allNewRowIndex.forEach((indexD) => {
        console.log("2Data", newArray[indexD]);
        handleAdd(newArray[indexD]);
      });
    }

  };
  const togglePopover = () => {
    setIsPopoverOpen(false);
    setIsPopoverNOpen(false);
    setIsPopoverStdOpen(false);
  };
  const handleInputMSTLevel = (e) => {
    const newErrors = {
      comments: "",
    };
    setErrors(newErrors);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };
  const handleSelectDrop = (event) => {
    const newErrors = {
      auditDropType: "",
    };
    setErrors(newErrors);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleNOLightClose = () => {
    setModalNoLight(false);
  };
  const handleImageShow = (id) => {
    setShowmodalimage(true);
    setImageportId(id);
  };

  const handleImageShowport = (row) => {
    setShowmodalimageport(true);
   setImageName(row.imageName?row.imageName:'')
    setImageportId(row);
  };
  const handleImageClose = () => {
    setShowmodalimage(false);
    setShowmodalimageport(false)
    getImageUpload();
  };
  const handleImageBase = (data) => {
    setImageBase64(data);
  };
  const handleImagePass = () => {
    console.log(setIsChecked);
    setShowmodalimage(false);
  };
  const handlemodalcloseyes = () => {
    setShowNoLightYes(false);
  };
  const handlemodaldefective = () =>{
    setShowModalDefective(false);
  }
  const handleclosepopup =()=>{
    setShowNoLightConfirm(false);
  }
  
  // const hideOption = true;

  const hideOption = true;
  const additionalData = {
    MSTName: wireState?wireState.mst:"",
    MSTAddress:wireState?wireState.mstAddress:"",
    FDHName:fdhnames?fdhnames:"",
    DistributionCable: wireState?wireState.cableName:"",
    MSTLeveIssue:mstwise?mstwise:"",
  }; 
  const exportToExcel = () => {
      
      if (!apiData) {
        console.error('No data available to export.');
        return;
      }
    
      const capitalizeKeys = (obj) => {
        const newObj = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key.toUpperCase()] = obj[key];
          }
        }
        return newObj;
      };
  
      // Flatten the data structure, filter out empty arrays and capitalize keys
      const flattenedData = Object.values(apiData)
        .flat()
        .filter(item => Object.keys(item).length !== 0) // Filter out empty objects
        .map(item => capitalizeKeys(item));
  
  // Convert additional data into an array of arrays format
  const additionalRows = Object.entries(additionalData).map(([key, value]) => [key, value]);
        const worksheet = XLSX.utils.aoa_to_sheet([...additionalRows, []])
    
  
  // Convert the table data into an array of arrays format
  // const tableRows = flattenedData.map(obj => Object.values(obj));
  
  // Combine additional rows with table data
  // const finalData = [...additionalRows, [], ...tableRows];
   // Empty array for spacing
      // Create a new workbook
    XLSX.utils.sheet_add_json(worksheet,flattenedData,{origin:-1})
      const wb = XLSX.utils.book_new();
      //const ws = XLSX.utils.json_to_sheet(finalData);
      
      
  
      XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");
  
      const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
  
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      const fileName = "MST_AUDIT.xls";
  
      const url = URL.createObjectURL(blob);
  
      const a = document.createElement("a");
  
      a.href = url;
  
      a.download = fileName;
  
      a.click();
  
      URL.revokeObjectURL(url);
    };

  return (
    
    <>
    
      {showmodalimg && (
        <ModalImageUpload
          data={mstName}
          id={imageportId}
          type='MST'
           mode='audit'
           role={role}
          imagerole={viewimageupload}
          imageurl={imageURLdata}
          onDataFromChild={handleImagePass}
          closemodissue={handleImageClose}
          imgbase64={handleImageBase}
          imageName={imageName}
        />
      )}
       {showmodalimgport && (
        <ModalImageUploadPort
          data={mstName}
          id={imageportId}
          type='MST'
          mode='audit'
          role={role}
          imagerole={viewimageuploadport}
          // imageurl={imageURLdata}
          onDataFromChild={handleImagePass}
          closemodissue={handleImageClose}
          imgbase64={handleImageBase}
          imageName={imageName}
        />
      )}
      {auditInfoPopup && (<ModalAuditInfo close={handleCloseAudit} auditdata={auditInfo}/>)}
      {modalnolight && (
        <ModalNoLight
          errormsg={errorMessageLight}
          close={handleNOLightClose}
          caseId={caseId}
          childcase={childcase}
          mstCaseNo={mstCaseNo}
        />
      )}
      
      {showmodalcasaUpdate && (
        <Modalcasa
          close={handleCasaCloseupdate}
          add={handleAddCasaUpdate}
          sendAddress={handleAddCasaUpdate}
        />
      )}
      {
        showpopupopti && (
          <ModalOptitap allowdata={allowoptichange}  onDatafromOpti={handleOptipop} close={handlCloseoptipop}/>
        )
      }
      {showmodaladdressUpdate && (
        <Modaladdress
          close={handleAddressCloseupdate}
          add={handleAddAddressUpdate}
          sendAddress={handleAddAddressUpdate}
        />
      )}
      {showmodaladdress && (
        <Modalviewaddress mst={mstName}  centralofc={wireState.wireCenter} lstAddress={addresslist} close={handleAddressClose} />
      )}
      {showmodalissue && (
        <ModalMstIssue
          addIssue={handleMstIssuePop}
          closemodissue={handleMstIssueClose}
        />
      )}
      {showmodalconfirm && <Modalconfirm onDataFromChild={handleConfirmData} />}
      {showwhitemodal && (
        <ModalWhite audittype={handleauditvalue} closemod={handleclosemodal} />
      )}
      {showLevelConfirm && (
        <ModalLevelConfirm
          closePopUps={handlecloseLevelmodal}
          confirmModules={handleLevelconfirm}
        />
      )}
      {showNoLightConfirm && (
        <ModalNoLightConfirm
        mstname = {mstName}
          availableaddress={availableportlist}
          selectedMstchange={handleselectedMst}
          availabelmst={availableMSTlist}
          selectedPortAvailable={handlePortAvailable}
          closemodissueNoLight={handleclosepopup}
          closePopUp={handleclosenolightmodal}
          callo2api={handleportChangemodal}
          callo2dropPlacement={handledropPlacement}
          status={statusport}
          confirmModule={handlenolightmodalconfirm}
          dropPlacement={dropPlacement}
        />
      )}
      {showNoLightYes && (
        <ModalNoLightYes
          onDataFromChild={handlemodalcloseyes}
          modalMsg={modalMsg}
        />
      )}
      {
        showModalDefective && (
          <ModalDefective
          closeModal={handlemodaldefective}
          messageError={messageError}
          />
        )
      }
      {showblackmodal && <ModalBlack />}
      {showRestorePortModal && <ModalRestorePort casaserial={checkCasaSerial} yespop={handleRestoreApi} closepop={handleRestorePortPopupClose}/>}
      {showloader && <Loader />}
      {mstTpWorPop && <ModalTpWorkingIssue  mstTpIssue={mstTpIssue} yespop={handleTpWorkYes} closepop={handlecloseworkingpopup} legstatus={statusport}/>}
      {mstTpPopup && <ModalTpIssueConfirm yespop={handleTpYes} closepop={handleTpNo} mstTpIssue={mstTpIssue} legStatus={statusport}/>}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {showsuccModal && <ModalSuccess onDataFromChild={handleSuccData} />}
      {isPopoverOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the Data in Onfield Column</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopoverNOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the N in Empty field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopoverStdOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the MST Terminal in Empty field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}

<div className="task-bar row">
  <div className="col-6">
  <div className="search-container-msttable">
          <FontAwesomeIcon icon={faSearch} className="search-icon-top" />
          <input type="text"  className="search-tasks"
           value={searchTerm}
           onChange={handleSearch}
           placeholder="Search..."
           tabIndex={0}
         
          />
        </div>
        <select
                    className="select-issues"
                    name="MST_WISE"
                    id="MST_WISE"
                    value={mstwise}
                    onChange={(event) => handlemstwise(event)}
                  >
                    <option value="">Select MST Level Issue</option>
                    <option value="No Light">No Light</option>
                    <option value={"No Mst Access"}>
                      {mstpopissue
                        ? `No Mst Access-${mstpopissue}`
                        : "No Mst Access"}
                    </option>
                    <option value="Update MST Address">
                      Update MST Address
                    </option>
                    <option value="Incorrect fiber count">
                      Incorrect Fiber Count
                    </option>
                    <option value="Insufficient labeling">
                    Insufficient labeling
                    </option>
                    <option value="Missing MST">Missing MST</option>
                    <option value="Disaster">Disaster</option>
                    <option value="Fiber Cut" hidden={hideOption}>Fiber Cut</option>
                  </select>
  </div>
<div className="col-6  right-save-content">
<div style={{ display:"flex",width: '20px', alignItems: 'center',cursor:"pointer",marginRight:"30px" }}  title="Comment Log">
              <FontAwesomeIcon icon={faPenToSquare} onClick={handleAuditInfo} style={{fontSize:"18px",color:"#001E64"}}/>
            </div>
  <div>
  <button
          onClick={exportToExcel}
          disabled={!apiData}
          className="but-dark mob-but-fil"
          >
                Export{" "}
                <span
                //  className="hidden-mob"
                 >
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </button>
  </div>
 
<button
                  onClick={handleFilter}
                  className="but-dark-outline mob-but-fil"
                >
                  <span>
                    <FontAwesomeIcon className="" ico icon={faFilter} />
                    &nbsp;Filter
                  </span>
                </button>
                {enableEdit && (
                  <button
                    onClick={handleSave}
                    className="but-dark mob-but-fil"
                  >
                    Save
                  </button>
                )}
</div>
       
      </div>
      <div className="fdhTable-outer mt-3">
          <div className="row mob-set-align" style={{alignItems:"baseline"}}>
            <div className="col-6 mob-col-12 block-in-mobile">
     
              <div className="legend-content1 mt-3">
                <span className="Available">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Available
                </span>
 
                <span className="Pending">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Pending
                </span>
 
                <span className="Working">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Working
                </span>
                <span className="Legacy">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Legacy Customer
                </span>
                <span className="Suspended_Defective">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Defective/Suspended
                </span>
              </div>
             
           
             
            </div>
            <div className="col-2 mob-col-6 tab-selectad">
              <div class="form-check form-switch">
                <button
                  className="but-dark mob-but-fil mob-mar-adjust"
                  onClick={handleImageShow}
                >
                  {viewimageupload == true ? "View Image" : "Upload Image"}
                </button>
              </div>
             
 
              </div>
              <div className="col-2 mob-col-6 tab-selectad">
              <select
                className="text-wise mob-mst-place"
                name="auditDropType"
                onChange={handleSelectDrop}
                value={formData.auditDropType}
                style={{ "font-size": "12px", padding: "9px", color: "gray" }}
              >
                <option value="">Select MST Placement</option>
                <option value="Aerial">Aerial</option>
                <option value="Buried">Buried</option>
               
              </select>
              {errors.auditDropType && (
                <p style={{ color: "red" }} className="mob-mstwise-text">
                  {errors.auditDropType}
                </p>
              )}
              </div>
    <div className="col-2 mob-col-6 comment-mst-mob" style={{display:"flex"}}>
    <textarea
                placeholder="Comment"
                name="comments"
                disabled={!enableEdit}
                value={formData.comments}
                onChange={handleInputMSTLevel}
                className="text-wise "
              ></textarea>
              {errors.comments && (
                <p style={{ color: "red" }} className="mob-mstwise-text">
                  {errors.comments}
                </p>
              )}
    </div>
          </div>
         
        </div>
      <div className="container">
        
        {isFilter && (
          <div className="filterCard arrow-top mt-3">
            <div className=" d-flex flex-column">
              <div className="filter-title">Filter By</div>
              <div className="row mt-3">
              
                <div className="col-lg-9" style={{ "text-align": "left" }}>
                  <div className="">
                    <div className="d-flex flex-column">
                      <div>
                        <span>MST Terminal</span>
                      </div>
                      <div>
                        <input
                          className="form-control select-fdh"
                          type="text"
                          name="idRange"
                          value={filter.idRange}
                          onChange={handleFilterChange}
                          placeholder="1-20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 mst-table">
          <div className="d-flex justify-content-around block-in-desktop">
            <div className="Available">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ "padding-right": "10px" }}
              />
              Available
            </div>

            <div className="Pending">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ "padding-right": "10px" }}
              />
              Pending
            </div>

            <div className="Working">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ "padding-right": "10px" }}
              />
              Working
            </div>
          </div>

          {errorMessage && (
            <div className="d-flex justify-content-center pb-2">
              <span style={{ color: "red" }} className="mob-mstwise-text">
                {errorMessage}
              </span>
            </div>
          )}
        
          <div className=" mst-table">
            <div className="d-flex justify-content-start">
              <div class="form-check m-2 form-switch">
                <input
                  class="form-check-input neisltog view-address"
                  type="checkbox"
                  checked={showmodaladdress}
                  onClick={handleAddresspopup}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  class="form-check-label view-address-label"
                  for="flexSwitchCheckDefault"
                >
                  View Associated Service Addresses{" "}
                </label>
              </div>
              <div class="form-check m-2 form-switch">
                <input
                  class="form-check-input neisltog view-address"
                  type="checkbox"
                  onClick={handleShow}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  class="form-check-label view-address-label"
                  for="flexSwitchCheckDefault"
                >
                  Enable Niesl
                </label>
              </div>
              <div class="form-check m-2 ">
                <label
                  style={{ display: "flex", alignItems: "center" }}
                  className="mob-check-audit"
                >
                  <input
                    className="form-check-input"
                    style={{ "margin-right": "8px" }}
                    type="checkbox"
                    checked={isCheckedvalid}
                    onChange={handleCheckbox}
                  ></input>
                  Full Audit Report
                </label>
              </div>
             
            </div>
          </div>
          { popoverInfo && 
     <div  ref={popupRef} className= 'popdtnInfo'>
      <div className="legendDtnInfo mt-3">
                <span className="GreenDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Online
                </span>

                <span className="RedDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                   Previously authenticated but offline
                </span>

                <span className="YellowDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Authenticated but in WalledGarden
                </span>
               
              </div>
        </div>
      }
          <div className="mob-overflow-scroll">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "4rem" }} colSpan="6">
                    O2
                  </th>
                  {neisl && (
                    <>
                      <th colSpan="4">NEISL</th>
                    </>
                  )}

                  <th colSpan="4">
                    OnField <br></br>
                    <span className="disclaimer">
                      (Only N & numbers are allowed)
                    </span>
                  </th>

                  <th style={{ width: "0px", display: "none" }} rowSpan="2">
                    Comparison
                  </th>

                  <th style={{ width: "0px" }} rowSpan="2">
                    Droptype
                  </th>
                  <th style={{ width: "0px" }} rowSpan="2">
                    Issues{" "}
                  </th>
                 
                 
                 	   {/* <th style={{ width: "0px" }} rowSpan="2">
                    Optitap
                  </th> */}
                  <th style={{ width: "0px" }} rowSpan="2">
                    Case/Device
                  </th>
                  
                  <th style={{ width: "0px" }} rowSpan="2">
                    <div className="dtn-icon-info"> Dtn
                    <FontAwesomeIcon  icon={faCircleInfo} className="fa-icon-circle" onClick={handleDtnInfo}/>
                    </div>
                  </th>
                  <th style={{ width: "0px" }} rowSpan="2">
                    Image
                  </th>
                  <th style={{ width: "2rem" }} rowSpan={2} >
                    Reset
                  </th>
                </tr>
                <tr>
                  <th className="mstportStickhead">Terminal Port</th>
                  <th>Distribution Strand</th>
                  <th>Splitter Slot</th>
                  <th>Splitter Leg</th>
                  <th>Status</th>
                  <th >
                    O2 Address
                  </th>
                  {neisl && (
                    <>
                      <th>AP Port</th>
                      <th>Distribution Cable</th>
                      <th>Splitter Slot </th>
                      <th>Splitter Leg</th>
                    </>
                  )}
                  <th style={{ width: "4rem" }}>
                    Distribution Strand <span style={{ color: "red" }}>*</span>
                  </th>
                  <th style={{ width: "2rem" }}>
                    Splitter Slot <span style={{ color: "red" }}>*</span>
                  </th>
                  <th style={{ width: "2rem" }}>
                    Splitter Leg <span style={{ color: "red" }}>*</span>
                  </th>
                  <th >
                  On Field Address
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  <>
                    {filteredData.map((row, rowIndex) => (
                      <tr key={row.id}>
                        {row.idEdit === true ? (
                          <>
                            <td colspan="1" className="mob-pos tab-pos">
                              <FontAwesomeIcon
                                onClick={() => handleClick(row.idval, row)}
                                className="trashico"
                                icon={faTrash}
                              ></FontAwesomeIcon>
                              <input
                                className="form-control table-input"
                                id={`id-${rowIndex}`}
                               
                                value={row.mstPort || ""}
                                maxLength={2}
                                disabled={!enableEdit}
                                onChange={(event) =>
                                  handleInputChangeSplit(
                                    event,
                                    row.mstPort,
                                    row,
                                    rowIndex,
                                    "standid",
                                    "mstPort"
                                  )
                                }
                              ></input>
                            </td>
                          </>
                        ) : (
                          <>
                            <td colspan="1" className="mstportStick">
                              {row.mstPort}
                            </td>
                          </>
                        )}
                       
                        <td colspan="1">{row.o2fdhOpPort}</td>
                        <td colspan="1">{row.splitter}</td>
                        <td colspan="1">{row.splitter_leg}</td>
                        <td
                          className={Statusupdate(row.LEG_STATUS)}
                          colspan="1"
                        >
                          {row.LEG_STATUS}
                        </td>
                        <td style={{ "white-space": "pre-line" }}>
                          {Array.isArray(row.mstAddress) ? (
                            row.mstAddress.map((item, key) => {
                              return (
                                <>
                                  {item && (
                                    <>
                                      <p className="mstAdd" key={key}>
                                        {item}
                                      </p>
                                    </>
                                  )}{" "}
                                  <></>
                                </>
                              );
                            })
                          ) : (
                            <>
                              {row.mstAddress && (
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="mstAdd"
                                >
                                  {row.mstAddress}
                                </p>
                              )}{" "}
                            </>
                          )}
                         
                        </td>
                        {neisl && (
                          <>
                            <td colspan="1">{row.ndsapPort}</td>
                            <td colspan="1">{row.ndsDistCable}</td>
                            <td colspan="1">{row.ndsSplitter}</td>
                            <td colspan="1">{row.ndsSplitterLeg}</td>
                          </>
                        )}

                        <td>
                          {isEditing ? (
                            <input 
                              className="form-control table-input"
                              maxLength={4}
                              name="stand"
                              id={`stand-${rowIndex}`}
                              disabled={!enableEdit}
                              value={row.onfieldStrand || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "st",
                                  "onfieldStrand"
                                )
                              }
                            />
                          ) : (
                            row.onfieldStrand
                          )}
                        </td>
                        <td>
                          {isEditing ? (
                            <input                           
                              className="form-control table-input"
                              maxLength={2}
                              name="split"
                              id={`split-${rowIndex}`}
                              disabled={!enableEdit}
                              value={row.onfieldSplitter || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "s",
                                  "onfieldSplitter"
                                )
                              }
                            />
                          ) : (
                            row.onfieldSplitter
                          )}
                         
                        </td>
                        <td>
                          {isEditing ? (
                            <input
                              className="form-control table-input"
                              maxLength={2}
                              name="leg"
                              disabled={!enableEdit}
                              id={`leg-${rowIndex}`}
                              value={row.onfieldSplitterLeg || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "l",
                                  "onfieldSplitterLeg"
                                )
                              }
                            />
                          ) : (
                            row.onfieldSplitterLeg
                          )}
                          
                        </td>
                        <td
                          style={{ color: "black", "white-space": "pre-line" }}
                        >
                          {(row.LEG_STATUS == "WORKING" && !isCheckedvalid) ? (
                            <>{row.updatedMstAddress}</>
                          ) : (
                            <>
                              <textarea
                                className="form-control table-input"
                                name="updatedMstAddress"
                                id={`updatedMstAddress-${rowIndex}`}
                                disabled={!enableEdit}
                                value={row.updatedMstAddress || ""}
                                onClick={() => handleAddresspopupOpen(rowIndex)}
                                onChange={(event) =>
                                  handleInputChangeSplit(
                                    event,
                                    row.id,
                                    row,
                                    rowIndex,
                                    "AD",
                                    "updatedMstAddress"
                                  )
                                }
                              />
                            </>
                          )}
                          
                        </td>

                        <td style={{ display: "none" }}>
                          {row.onFieldComparison}
                        </td>
                        <td>
                          {isEditing ? (
                            <select
                              className="form-control table-input"
                              name="DROP_TYPE"
                              id={`DROP_TYPE-${rowIndex}`}
                              disabled={!enableEdit}
                              value={row.DROP_TYPE || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "d",
                                  "DROP_TYPE"
                                )
                              }
                            >
                              <option value="">Select</option>
                              <option value="Aerial">Aerial</option>
                              <option value="Buried">Buried</option>
                              <option value="None">No Drop</option>
                            </select>
                          ) : (
                            row.DROP_TYPE
                          )}
                        </td>
                        <td>
                          {isEditing ? (
                            <select
                              className="form-control table-input"
                              name="MST_PORT_WISE"
                              id={`MST_PORT_WISE-${rowIndex}`}
                              value={row.MST_PORT_WISE || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "d",
                                  "MST_PORT_WISE"
                                )
                              }
                            >
                              {row.LEG_STATUS == 'SUSPENDED' || row.LEG_STATUS == 'DEFECTIVE' ? (<><option value="">Select</option>
                                <option value="Restore to Available">Restore to Available</option></>) :
                                (<>
                              <option value="">Select</option>
                              <option value="Broken Port">Broken Port</option>
                              {row.LEG_STATUS !== "WORKING" && <option value="Port in use">Port In Use</option>}
                              <option value="No Light">No Light</option>
                              <option value="Low Light">Low Light</option>
                              {row.LEG_STATUS !== "WORKING" && <option value="Not Spliced">Not Spliced</option>}
                             {row.LEG_STATUS !== "AVAILABLE" && row.LEG_STATUS !== 'LEGACY USER' &&<option value="Drop Placement">Drop Placement</option>}

                             {row.LEG_STATUS !== "AVAILABLE" && row.LEG_STATUS !== 'LEGACY USER'  && <option value="Casa Device">Casa Device</option>}

                              {accessBSW && row.LEG_STATUS !== "WORKING" && row.LEG_STATUS !== "AVAILABLE" && row.LEG_STATUS !== 'LEGACY USER'  &&

                              <option value="Conduit Extension">Conduit Extension</option>

                              }

                              <option value="Fiber Cut" hidden={hideOption}>Fiber Cut</option>
                              {row.optiTap == null ? (
                                <option value="YES">Terminal Port Splitter</option>
                              ) : (
                                <></>
                              )}
                              </>)}
                            </select>
                          ) : (
                            row.MST_PORT_WISE
                          )}
                             
                        </td>
                       
                      
                        
                        <td>{row.casaSerial}</td>

                        <td>
                        { (popoverVisible) && (clickedIndex == row.id ) &&
     <div  ref={popupRef} className={(dtnauthinfo.SessionStatus == 'O' && dtnauthinfo.WGName !== "") ? 'popyellow' : dtnauthinfo.SessionStatus == 'C' ? 'popred' :  dtnauthinfo.SessionStatus == 'O' ? 'popgreen' : 'popover-dtn'}>
      { dtnauthinfo.SessionStatus !== '' ? <>
      <div className="d-flex flex-column" style={{'text-align':'left'}}>
        <div>DTN: {dtnauthinfo.dtn} </div>
        <div>Status: {dtnauthinfo.SessionStatus}</div>
        {dtnauthinfo.SessionStatus == 'O' ? (
          <div>StartTime:{dtnauthinfo.StartTime}</div>
        ) :  (
          <div>StopTime:{dtnauthinfo.StopTime}</div>
        )}
        <div>DSLAM:{dtnauthinfo.DSLAMNode}</div>
          <div>WGName: {dtnauthinfo.WGName}</div>
        </div>
    
      
      </>  :<>
      
      <div className={ loadingpop === 'Failed to Authenticate'? 'failed-font':'' }>{loadingpop}</div> 
      </>
      }
       
        
      </div>
      }
                          {Array.isArray(row.dtn) ? (
                            row.dtn.map((item, key) => {
                              return (
                                <>
                                  {item && ( 
                                    <div className="d-flex dtn-container">
                                      <p
                                        className={ (clickedIndex == row.id && clickedIndexDtn == key) ? ((dtnauthinfo.SessionStatus == 'O' && dtnauthinfo.WGName !== "") ? 'dtnAddress-yellow': dtnauthinfo.SessionStatus == 'C' ? 'dtnAddress-red' :   dtnauthinfo.SessionStatus == 'O' ? 'dtnAddress-green': 'dtnAddress'):'dtnAddress'}
                                        style={{ cursor: "pointer" }}
                                        key={key}
                                      >
                                        {item}
                                      </p>
                                      <div className="d-flex icon-refresh"  >
                                      <div style={{ width: '10px', textAlign: 'center' }} title="Check Authentication"><FontAwesomeIcon icon={faCheck} onClick={() =>  getdtnauthenticate(item, row,key)}  style={{ cursor: "pointer" }}></FontAwesomeIcon></div>
                                      <div style={{ width: '10px', textAlign: 'center' }} title="Recheck Authentication"><FontAwesomeIcon icon={faRotateRight}  style={{ cursor: "pointer" }} onClick={() =>  getdtnauthenticate(item, row,key)} ></FontAwesomeIcon></div>
                                      <div style={{ width: '10px', textAlign: 'center' }} title="Navigate to ONT"><FontAwesomeIcon icon={faLocationArrow} style={{ cursor: "pointer" }} onClick={() => navigateOnt(item)} ></FontAwesomeIcon></div>
                              </div>
                                    </div>
                                  )}{" "}
                                  <></>
                                </>
                              );
                            })
                          ) : (
                            <>
                            {" "}
                              {row.dtn && (
                               <div className="d-flex dtn-container">
                                <p
                                  className={ (clickedIndex == row.id ) ? ((dtnauthinfo.SessionStatus == 'O' && dtnauthinfo.WGName !== "") ? 'dtnAddress-yellow' : dtnauthinfo.SessionStatus == 'C' ? 'dtnAddress-red' :  dtnauthinfo.SessionStatus == 'O' ? 'dtnAddress-green' : 'dtnAddress'):'dtnAddress'}
                                >
                                  {row.dtn}
                                </p>
                                 <div className="d-flex icon-refresh"  >
                                 <div style={{ width: '10px', textAlign: 'center' }} title="Check Authentication"><FontAwesomeIcon icon={faCheck} onClick={() => getdtnauthenticate(row.dtn,row)}  style={{ cursor: "pointer" }} ></FontAwesomeIcon></div>
                                 <div style={{ width: '10px', textAlign: 'center' }} title="Recheck Authentication"><FontAwesomeIcon icon={faRotateRight}  style={{ cursor: "pointer" }} ></FontAwesomeIcon></div>
                                 <div style={{ width: '10px', textAlign: 'center' }} title="Navigate to ONT"><FontAwesomeIcon icon={faLocationArrow} style={{ cursor: "pointer" }} onClick={() => navigateOnt(row.dtn)} ></FontAwesomeIcon></div>
                                 </div>
                                 </div>
                              )}
                            </>
                          )}
                        </td>
                        <td>
                        {row.imageBase64 !== '' ?  
                             <FontAwesomeIcon className="upload-ico" icon={faEye} onClick={()=>{handleImageShowport(row)}} ></FontAwesomeIcon>
                            :
                            <FontAwesomeIcon className="upload-ico" icon={faUpload} onClick={()=>{handleImageShowport(row)}} ></FontAwesomeIcon>
                        }

                        {/* <button
                  className="but-dark mob-but-fil mob-mar-adjust"
                  
                >
                  {row.imageBase64 !== '' ? "View Image" : "Upload Image"} 
                </button> */}
                        </td>
                        <td><FontAwesomeIcon  className="upload-ico-trash" onClick={() => handleResetdata(rowIndex)} icon={faTrash} ></FontAwesomeIcon></td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="14">
                        <b>Data not available</b>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="paginationfooter mobile-pagination">
            {totalPages > 1 && (
              <div className="page">
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            )}

            <div></div>

            <div>
              <span></span>
            </div>
          </div>

       
        </div>
        <div>
          <div className="add-button adds" onClick={toggleDropAdd}>
            + Add More
          </div>
          {showDropdown && (
            <div className="dropdown-contents">
              <div className="counter">
                <input
                  type="number"
                  value={numRowsToAdd}
                  onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
                />
                <div className="counter-icon" onClick={decrementCounter}>
                  <span>-</span>
                </div>
                <div className="counter-icon" onClick={incrementCounter}>
                  <span>+</span>
                </div>
              </div>
              <div className="dropdown-buttons">
                <button className="but-dark" onClick={addRows}>
                  Add
                </button>
                <button className="but-dark-outline" onClick={toggleDropdown}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MstTable;