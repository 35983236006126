import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faExclamationCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./ModalOntError.css";
import { useNavigate } from "react-router-dom";

const ModalFopReviewCase = ({ close,errormsg,caseId}) => {
  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    // Call the function received from the parent with the data
    close(dataToSend);
  };
 
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
          {errormsg === "Case status changed to Closed" ? (
            <>
            <div className="modals">
            
            <div
              style={{
                color: "#000",
                padding: "30px 40px",
                fontSize: "20px",
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
              <b>Closed</b> 
              <p>CaseNumber: {caseId}</p>
              <div style={{fontSize:"14px"}}> <b>{errormsg}</b></div>
            </div>
            <div className="modal-bottom">
            <button style={{ background:'#001F6A'}} className="alert-bottoms-onterror-success" onClick={handleClick}>Okay</button>
          </div>
            {/* <Modalalert /> */}
          </div>
            </>
          ) : errormsg === "Case owner updated to - Engineer Review Queue"? (
            <>
            <div className="modals">   
            <div
              style={{
                color: "#000",
                padding: "30px 40px",
                fontSize: "20px",
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
              <b>Updated</b> 
              <p>CaseNumber: {caseId}</p>
              <div style={{fontSize:"14px"}}> <b>{errormsg}</b></div>
            </div>
            <div className="modal-bottom">
            <button style={{ background:'#001F6A'}} className="alert-bottoms-onterror-success" onClick={handleClick}>Okay</button>
          </div>
          </div>
            </>
          )
          : errormsg === "Case owner is already updated to Engineer Review Queue"? (
            <>
            <div className="modals">   
            <div
              style={{
                color: "#000",
                padding: "30px 40px",
                fontSize: "20px",
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
              <b>Updated</b> 
              <p>CaseNumber: {caseId}</p>
              <div style={{fontSize:"14px"}}> <b>{errormsg}</b></div>
            </div>
            <div className="modal-bottom">
            <button style={{ background:'#001F6A'}} className="alert-bottoms-onterror-success" onClick={handleClick}>Okay</button>
          </div>
          </div>
            </>
          ):(
          <>
           {/* <div className="modals">   
            <div
              style={{
                color: "#000",
                padding: "30px 40px",
                fontSize: "20px",
              }}
            >
              <FontAwesomeIcon icon={faExclamationCircle} style={{color: "#ee2222",margin:"10px", height:"40px",width:"40px"}} /> <br/>
              <b>Unable to Create Case</b> 

              <div style={{fontSize:"14px"}}> <b>{}</b></div>
            </div>
          </div>
          <div className="modal-bottom">
            <button className="alert-bottoms-onterror" onClick={handleClick}>Close</button>
          </div> */}
          </>
          )}
          
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalFopReviewCase;
