import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./App.css";
import Modalerror from "./ModalError/ErrorModal";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faEdit,
  faPen,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/ctllogo.png";
import Topbar from "./topbar";
import ModalPage from "./ModalWhite/modalpage";
import FdhTable from "./fdhtable";
import axios from "axios";
import { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { API_KEY, BASE_API_URL } from "./environment";


function SecondTopbar(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const { fdhName } = useParams();
  // const { latitude } = useParams();
  // const { longitude } = useParams();
  const { address } = useParams();
  const [data, setData] = useState([]);
  const { jobNumber } = useParams();
  const [latitudeval, setLatitude] = useState("");
  const [longitudeval, setLongitude] = useState("");
  const [fdhAddress, setfdhAddress] = useState("");
  const[feeder,setFeeder]= useState("");
  const[distCable, setDistCable] = useState("");
  const[olt,setOlt]= useState("");
  const [inputValue, setInputValue] = useState("");
  const [showerrmodal, setShowerror] = useState(false);
  const [pagesavepop, setpagesavePop] = useState();
  const [fdhview, setFdhListview] = useState(false);
  const [pageactive, setPageactive] = useState(false);
  const [showpagemodal, setPageModal] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const[splittercount,setSplitterCount]=useState("");
  const Cuid = sessionStorage.getItem("encryptedKey");
 
  
  const nav = useNavigate();
 

  useEffect(() => {
    getLatLong(fdhName);
    console.log(props)
    if(props.data === true){
      setPageactive(props.data)
    }else{
      setPageactive(false)

    }
    // setpagesavePop(p)
  }, [props.data]);

  const formattedDate = new Date(props.auditTimeval?props.auditTimeval:'NA').toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedTime = new Date(props.auditTimeval?props.auditTimeval:'NA').toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });
 
  //to close list
  const onpagenav = ()=>{
    if(pageactive == true){
      setPageModal(true)
    }
    if(pageactive == false) {
      nav("/search")
    }
  }

  

var handleClickOutside = (event) => {
  setTimeout(() => {
    setFilteredSuggestions([]);
  }, 500);
}

const handlebeforeload = (e)=>{

  e.preventDefault();
  e.returnValue= 'HHSD';

};



// document.addEventListener('click' , handleClickOutside);
  const handleClearClick = () => {
    setInputValue('');
    setFilteredSuggestions([]);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    if (inputValue.length >= 2) {
      if (inputValue.includes("ME=")) {
        if (inputValue.length >= 5) {
          axios
            .get(process.env.REACT_APP_BASE_API_URL + "/getFdh?startWith=" + inputValue , headers )
            .then((response) => {
              const data = response.data;
              setFilteredSuggestions(data);
              console.log(filteredSuggestions, "sugg");
            });
        }
      } else {
        axios
          .get(process.env.REACT_APP_BASE_API_URL + "/getFdh?startWith=" + inputValue , headers)
          .then((response) => {
            const data = response.data;

            setFilteredSuggestions(data);
            console.log(filteredSuggestions, "sugg");
          });
      }
    }
  };
  const removeList = () => {
    setFilteredSuggestions([]);
  };
  const change = () => {
    setFdhListview(true);
  };
  const handleDataFromPage = () => {
    setPageModal(false);
  };
  const handleSelectSuggestion = (suggestion) => {
    setInputValue(suggestion.fdh);
    
    var fdhportCount = suggestion.fdhPortCount ? suggestion.fdhPortCount : "NA";
    var fdhAddress = suggestion.fdhAddress ? suggestion.fdhAddress : "NA";
    var latitude = suggestion.latitude ? suggestion.latitude : "NA";
    var longitude = suggestion.longitude ? suggestion.longitude : "NA";
    props.onDataFrom(suggestion)
    nav(
      "/main/" +
        suggestion.fdh +
        "/" +
        latitude +
        "/" +
        longitude +
        "/" +
        fdhAddress +
        "/" +
        fdhportCount
    );
    console.log(suggestion, "name");
    removeList();
  };

  const getLatLong = (fdhName) => {
    setShowerror(false);
    fetch(process.env.REACT_APP_BASE_API_URL + "/getFdh?startWith=" + fdhName  , headers)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then((data) => {
        const lat = data[0].latitude;
        const long = data[0].longitude;
        const fdhAddress = data[0].fdhAddress;
        const countsplit= data[0].portSlotCount;
        const feeder = data[0].feederCable;
        const olt = data[0].olt;
        const distCable = data[0].distributionCable;
        setOlt(olt);
        setFeeder(feeder);
        setDistCable(distCable);
        setLatitude(lat);
        setLongitude(long);
        setfdhAddress(fdhAddress);
        props.handlecount(countsplit);
        props.onDataFrom(data[0]);
        props.handlecable(distCable);
    })
    .catch((error) => {
      if (error) {
        if (
          error.status === 502 ||
          error.status === 500
        ) {
          setShowerror(true);
        }
        if(error.status === 400 || error.status == 404){
          setLatitude('NA');
          setLongitude('NA');
          setfdhAddress('NA');
          setFeeder('NA');
          setDistCable('NA');
          setOlt('NA');
        }
      } else {
      }
    });
  }
  const handleDataFromError = () => {
    setShowerror(false);
  };
  return (
    <>
     {showpagemodal && <ModalPage onDataFromChild={handleDataFromPage} />}
     {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="second-nav">
        <div className="container">
          <div className="row">
            <div className="col-2 mob-col-12 tab-res">
              <div className="content-one">
                <span className="hidden-mob">
                  {/* <Link > */}
                    <FontAwesomeIcon onClick={onpagenav}
                      className="ico-nav mb-2 p-3 mob-padding-5"
                      style={{ color: "#011E60" }}
                      icon={faHome}
                    />
                  {/* </Link> */}
                </span>
                <h6>FDH</h6>
              </div>
            </div>
            <div className="col-5 mob-col-12  ">
              <div className="content-two">
                <div className="d-flex flex-row">
                  <div
                    className="d-flex flex-column auditcount"
                    style={{ "padding-right": "28px" }}
                  >
                    <div>
                      <span>FDH ID:</span>
                    </div>
        
                    <div>
                      <span>FDH Address:</span>
                    </div>
                    <div>
                      <span>Feeder Cable:</span>
                    </div>
                    <div>
                      <span>OLT:</span>
                    </div>
                    <div>
                      <span>Audit Status:</span>
                    </div>
                    
                  </div>
                  <div className="d-flex flex-column auditcounts">
                    {fdhview ? (
                      <div className="" style={{position:'relative'}}>
                        <span>
                          <input
                            type="text"
                            value={inputValue}
                            style={{ margin: "0" }}
                            className="searchresult-input"
                            onChange={handleChange}
                            onBlur={()=> handleClickOutside()}
                          ></input>
                           {inputValue ? (<span className="clear-button" onClick={handleClearClick}>
                           <FontAwesomeIcon icon={faClose} />
                            </span>):( <span></span>)}
                        </span>
                       
                      </div>
                    ) : (
                      // <span><input></input></span>
                      <div>
                        {fdhName}
                        <span
                          onClick={change}
                          style={{ "padding-left": "6px" }}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    )}
                    <div>
                      {filteredSuggestions.length > 0 && (
                        <ul className="searchresult-searchSmall">
                          <span>
                            {filteredSuggestions.map((suggestion) => (
                              <li
                                key={suggestion.fdh}
                                onClick={() =>
                                  handleSelectSuggestion(suggestion)
                                }
                              >
                                {/* <FontAwesomeIcon className="icon-style" icon={faLocationDot} /> */}
                                {suggestion.fdh}
                              </li>
                            ))}
                          </span>
                        </ul>
                      )}
                    </div>
                   
                    <div>
                      <span>{fdhAddress}</span>
                    </div>
                    <div>
                      <span>
                      {feeder ? feeder : "NA"}
                      </span>
                    </div>
                    <div>
                      <span>
                      {olt ? olt : "NA"}
                      </span>
                    </div>
                    <div>
                      <span>{props.status}</span>
                    </div>
                
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ "padding-left": "20px" }}
                  >
                    {/* <p>Job Number: <span>{props.status}</span></p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 mob-col-6 ">
            <div className="content-two auditcount auditcounts">
                <div className="d-flex flex-row">
                  <div className="row">
                    {/* <div>
                  <span>Audit Date & Time : 
                  <span style={{'font-size':'12px'}}>{
                        formattedDate === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedDate} </span>
                        )
                      }</span>, &nbsp;
                      <span>{
                        formattedTime === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedTime}  CST</span>
                        )
                      }</span>


                  </span></div>  */}
                  <div><span>Distribution Cable : <span>{distCable ? distCable : "NA"} </span></span></div>
                  </div>
                  
                </div>
                
              </div>
              
          </div>
          </div>
         
       
        </div>
      </div>
    </>
  );
}

function Main() {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [datafromchild, setDatafromChild] = useState("");
  const [auditStat, setAuditStat] = useState("");
  const [wireState, setWirestate] = useState("");
  const [auditTime, setAuditTime] = useState("");
  const[splitcount,setSplitCount] = useState("");
  const[cablename,setcablename]= useState("");
  var transfer = {};
  const receivedata = (data)=>{
    setDatafromChild(data);
  }
  const receivestatus = (data)=>{
    setAuditStat(data);
  }
  const receivewirestate = (data) =>{
    transfer = data;
    setWirestate(data)
  }
  const receivedataTime = (data)=>{
    setAuditTime(data)
  }
  const handlecount =(data)=>{
    setSplitCount(data);
  }
const handlecable = (data) =>{
  setcablename(data);
}
  return (
    <>
 
      <Topbar data={datafromchild}></Topbar>
      <SecondTopbar onDataFrom={receivewirestate}  auditTimeval={auditTime} data={datafromchild} status={auditStat} handlecount={handlecount} handlecable={handlecable} />
      <Outlet />
      <FdhTable data = {wireState}  onPageReviewtime={receivedataTime} onPagesaveData={receivedata} onPageStatus={receivestatus}  splittercount={splitcount} cablename={cablename}></FdhTable>
    </>
  )
}

export default Main;
