import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck,faTimes } from "@fortawesome/free-solid-svg-icons";
import "../ModalConfirm/ConfirmModal.css";
import "./ModalNoLightConfirm.css";
import { API_KEY, BASE_API_URL } from "../environment";

const ModalNoLightConfirm = (props) => {
const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  console.log(props)
  const [selectedOption, SetSelectedOption] = useState("No");
  const [selectfacilityopt, setSelectfacility] = useState("Port");
  const [mstname, setmstname] = useState("");
  const [showDropdown, SetShowDropdown] = useState("false");
  const [availableportvalue, setavailableportvalue] = useState();
  const [addresslist, setaddresslist] = useState(props.availableaddress);
  const [addresslistmst, setaddresslistmst] = useState([]);
  const [mstavailablelist, setmstavailablelist] = useState('')
  const [loadingname, setloadingname] = useState('');
  const Cuid = sessionStorage.getItem("encryptedKey");
  console.log(mstavailablelist,'coming',props.availabelmst)
  const [isOpen, SetIsOpen] = useState("false");
  const handleToggle = () => {
    SetIsOpen(!isOpen);
  };
  useEffect(() => {console.log(props,'cehcking')
    setmstavailablelist(props.availabelmst)
    getavailableportmst(props.mstname)
  }, []);
  // var addresslist = [props.availableaddress];
  // console.log(addresslist,'checking', props.availableaddress)

  const handleSubmit = () => {
    if (selectedOption === "Yes") {
      if(props.status == 'PENDING' || props.status == 'LEGACY PENDING'){
        props.callo2api();
      }
    
    } else {
      props.closePopUp();
    }
    console.log("Submit Clicked");
  };
  const handleSubmitPlacement = () =>{
    if(props.status == 'PENDING' || props.status == 'LEGACY PENDING'){
      props.callo2dropPlacement();
    }
  }
  const handleClickCancel = () => {
    // Data you want to send to the parent
    
    props.closemodissueNoLight();
  };
  const handleOptionChange = (event) => {
    SetSelectedOption(event.target.value);
    if (event.target.value === "Yes") {
      SetShowDropdown(true);
    } else {
      SetShowDropdown(false);
    }
  };
  const handleavailbleTP = (event) =>{
    const selectvalue = event.target.value;
    selectvalue.split('_');
    console.log(selectvalue[0])
    console.log(selectvalue,'comingv')
    // setavailableportvalue(prevState => ({
    //   ...prevState,
    //   [name]:value.port
    // }))
    console.log(availableportvalue,"checj");
    setavailableportvalue(event.target.value);
    props.selectedPortAvailable(selectvalue);
  }

  const handlefacilityOptionChange = (event) => {
    setSelectfacility(event.target.value);
  };
  const handlemstChange = (event) => {
    setaddresslistmst([])
    if(event.target.value !== '0'){
      console.log(event.target.value,'ss',mstname)
    setmstname(event.target.value);
    getavailableportmst(event.target.value);
    props.selectedMstchange(event.target.value);
    }else{
      setmstname('');
      props.selectedMstchange("");
    }
  };

  const getavailableportmst = (mstName) => {
    setloadingname('Loading MST Port');
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getMstPortAvailableList?mst=" +
        mstName , headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setloadingname('')
          setaddresslistmst(data);
        }
      })
      .catch((error) => {
        if (error) {
          if (error.status === 404 || error.status === 400) {
            setloadingname('MST port Not available')
            setaddresslistmst([]);
          }
          if (
            
            error.status === 502 ||
            error.status === 504 ||
            error.status === 500
          ) {
            // setShowerror(true);
            setaddresslistmst([]);
            setloadingname('Gateway Timeout - Network Error, try again')
          }
        } else {
        }
      });
  };
  

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerconfirm">
          <div className="modalc-background">
            {props.dropPlacement == "Drop Placement" ? (
            <>
              <div>
            <div className="modalc">
              <div style={{display:"flex",float:"right",padding:'10px'}}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                /></div>
              <div
                style={{
                  color: "#000",
                  padding: "30px 40px",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{
                    color: "#001f6a",
                    margin: "10px",
                    height: "40px",
                    width: "40px",
                  }}
                />{" "}
                <br />
               
              </div>
              {
               (props.status=='PENDING' || props.status == 'LEGACY PENDING')?
                (
                  <>            
                <>
                  <div className="radio_buttons">
                    <label>
                      <input
                        type="radio"
                        className="yes_radio"
                        value="Port"
                        checked={selectfacilityopt === "Port"}
                        onChange={handlefacilityOptionChange}
                      />
                      Port Change
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="no_radio"
                        value="MST Change"
                        checked={selectfacilityopt === "MST Change"}
                        onChange={handlefacilityOptionChange}
                      />
                      MST Change
                    </label>
                  </div>
                </>
                  {selectfacilityopt === "Port" &&   (
                <>
                
                  <div >
                   
                            <>
                           {
                          addresslistmst.length > 0 ? (
                              <>
                               <select className="mstdrop-one mb-3 mt-2" value={availableportvalue}
                    onChange={(event) =>
                      handleavailbleTP(
                        event,
                      )
                    }
                    >
                      <option value='0'>Select Available TP</option>
                    {addresslistmst.map((item) => (
                          <option key={item.port} value={`${item.port}_${item.addressId}`}>
                            {item.port}
                          </option>
                        ))}
                     
                    </select>
                    <div className={`arrow ${isOpen ? "up" : "down"}`} />
                              </>
                          ):(
                            <><div className="mt-2 mb-2">{loadingname}</div></>
                          )
                           }
                            </>
                      
                    
                    
                  </div>
                </>
              )}
              {selectfacilityopt === "MST Change" && (
                <>
                  <div className="d-flex m-3 flex-column">
                    <div>
                      <div className="">
                        <select onChange={handlemstChange} className="mstdrop-one">
                          <option  value="0">Select MST</option>
                          {mstavailablelist.map((item) => (
                          <option key={item} value={item.deviceName}>
                            {item.deviceName} {item.range}
                          </option>
                        ))}
                        </select>
                        <div className={`arrow ${isOpen ? "up" : "down"}`} />
                      </div>
                    </div>
                    {
                      mstname !== '' && (
                        <>
                         {
                          addresslistmst.length > 0 ?
                          (<>
                          <div className="mt-2">
                          <select className="mstdrop-one "  value={availableportvalue}   onChange={(event) =>
                      handleavailbleTP(
                        event,
                      )
                    }>
                            <option value="0">Select MST TP</option>
                            {addresslistmst.map((item) => (
                          <option key={item.port} value={`${item.port}_${item.addressId}`}>
                            {item.portCable}
                          </option>
                        ))}
                          </select>
                          <div className={`arrow ${isOpen ? "up" : "down"}`} />
                        </div>
                          </>
                          ):(
                            <><div className="mt-2">{loadingname}</div></>
                          )
                         }
                        </>
                      )
                    }
                   
                    
                  </div>
                </>
              )}
              </>
                ):(
                  
<>  <div className="p-2">Port change will work for Pending Customers</div></>
                                     
                )
              }
              
            </div>
            <div className="modalc-bottom">
              <div className="">
                <span>               
                  <button className=  {(props.status == 'PENDING' || props.status == 'LEGACY PENDING') ? 'submit-bottom' : 'disabled-bottom' }  onClick={handleSubmitPlacement}>Submit</button>
                </span>
              </div>
            </div>
            </div>
            </>
            ) : (
               <div>
               <div className="modalc">
                 <div style={{display:"flex",float:"right",padding:'10px'}}>
                   <FontAwesomeIcon
                     icon={faTimes}
                     style={{
                       color: "#001f6a",
                       height: "25px",
                       width: "25px",
                       cursor: "pointer",
                     }}
                     onClick={handleClickCancel}
                   /></div>
                 <div
                   style={{
                     color: "#000",
                     padding: "30px 40px",
                     fontSize: "20px",
                   }}
                 >
                   <FontAwesomeIcon
                     icon={faCircleCheck}
                     style={{
                       color: "#001f6a",
                       margin: "10px",
                       height: "40px",
                       width: "40px",
                     }}
                   />{" "}
                   <br />
                   Can you complete the install using a different fiber assignment?
                   {props.message}
                 </div>
                 {/* <Modalalert /> */}
                 <div className="radio_buttons">
                   <label>
                     <input
                       type="radio"
                       className="yes_radio"
                       value="Yes"
                       checked={selectedOption === "Yes"}
                       onChange={handleOptionChange}
                     />
                     Yes
                   </label>
                   <label>
                     <input
                       type="radio"
                       className="no_radio"
                       value="No"
                       checked={selectedOption === "No"}
                       onChange={handleOptionChange}
                     />
                     No
                   </label>
                 </div>
                 
                 {
                  (( props.status=='PENDING' || props.status == 'LEGACY PENDING') && selectedOption == 'Yes')?
                   (
                     <>
                     {selectedOption === "Yes" && showDropdown && (
                   <>
                     <div className="radio_buttons">
                       <label>
                         <input
                           type="radio"
                           className="yes_radio"
                           value="Port"
                           checked={selectfacilityopt === "Port"}
                           onChange={handlefacilityOptionChange}
                         />
                         Same MST
                       </label>
                       <label>
                         <input
                           type="radio"
                           className="no_radio"
                           value="MST Change"
                           checked={selectfacilityopt === "MST Change"}
                           onChange={handlefacilityOptionChange}
                         />
                         MST Change
                       </label>
                     </div>
                   </>
                 )}
   
                     {selectfacilityopt === "Port" && selectedOption == "Yes" &&  (
                   <>
                   
                     <div >
                      
                               <>
                              {
                             addresslistmst.length > 0 ? (
                                 <>
                                  <select className="mstdrop-one mb-3 mt-2" value={availableportvalue}
                       onChange={(event) =>
                         handleavailbleTP(
                           event,
                         )
                       }
                       >
                         <option value='0'>Select Available TP</option>
                       {addresslistmst.map((item) => (
                             <option key={item.port} value={`${item.port}_${item.addressId}`}>
                               {item.port}
                             </option>
                           ))}
                        
                       </select>
                       <div className={`arrow ${isOpen ? "up" : "down"}`} />
                                 </>
                             ):(
                               <><div className="mt-2 mb-2">{loadingname}</div></>
                             )
                              }
                               </>
                         
                       
                       
                     </div>
                   </>
                 )}
                 {selectfacilityopt === "MST Change" && selectedOption == "Yes" && (
                   <>
                     <div className="d-flex m-3 flex-column">
                       <div>
                         <div className="">
                           <select onChange={handlemstChange} className="mstdrop-one">
                             <option  value="0">Select MST</option>
                             {mstavailablelist.map((item) => (
                             <option key={item} value={item.deviceName}>
                               {item.deviceName} {item.range}
                             </option>
                           ))}
                           </select>
                           <div className={`arrow ${isOpen ? "up" : "down"}`} />
                         </div>
                       </div>
                       {
                         mstname !== '' && (
                           <>
                            {
                             addresslistmst.length > 0 ?
                             (<>
                             <div className="mt-2">
                             <select className="mstdrop-one "  value={availableportvalue}   onChange={(event) =>
                         handleavailbleTP(
                           event,
                         )
                       }>
                               <option value="0">Select MST TP</option>
                               {addresslistmst.map((item) => (
                             <option key={item.port} value={`${item.port}_${item.addressId}`}>
                               {item.portCable}
                             </option>
                           ))}
                             </select>
                             <div className={`arrow ${isOpen ? "up" : "down"}`} />
                           </div>
                             </>
                             ):(
                               <><div className="mt-2">{loadingname}</div></>
                             )
                            }
                           </>
                         )
                       }
                      
                       
                     </div>
                   </>
                 )}
                 </>
                   ):(
                     
                       (selectedOption == 'Yes') && 
   <>  <div className="p-2">Port change will work for Pending Customers</div></>
                       
                     
                     
                   )
                 }
                 
               </div>
               <div className="modalc-bottom">
                 <div className="">
                   {/* <span><button className="confirm-bottom" onClick={confirmModule}>Yes</button></span>
                       <span><button className="cancel-bottom" onClick={closePopUp}>No</button></span> */}
                   <span>
                     { selectedOption === "Yes"  && (
                     <button className=  {(props.status == 'PENDING' || props.status == 'LEGACY PENDING') ? 'submit-bottom' : 'disabled-bottom' }  onClick={handleSubmit}>Submit</button>
                   )}
                   
                   {selectedOption === "No" &&(
                     <button className="submit-bottom mob-submit-wid-set" onClick={handleSubmit}>Submit</button>
                   )}
   
                 
                   </span>
                 </div>
               </div>
               </div>
            )}
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalNoLightConfirm;
