import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Modaladdress.css";
import { useEffect } from "react";
import { useFetcher } from "react-router-dom";
const Modalsplitterchange = ({close, data, submit }) => {
  const [selectIssue, setSelectIssue] = useState("");
  const [isdisable,setIsDisable]=useState(true);
  // Function to extract SP values
  const extractSPValue = (resourceDN) => {
    const match = resourceDN.match(/SL=(\d+)/);
    return match ? match[1] : null;
  };
  // Function to extract full resourceDN values based on selected SP
  const extractResourceDNs = (selectedSL) => {
    return data.filter((item) => item.resourceDN.includes(`SL=${selectedSL}`));
  };
  // Extract unique SP values
  const spValues = Array.from(
    new Set(data.map((item) => extractSPValue(item.resourceDN)).filter(Boolean))
  );
  const [selectedSL, setSelectedSL] = useState("");
  const [selectedResourceDN, setSelectedResourceDN] = useState("");
  // Update selectedResourceDN whenever selectedSL changes
  useEffect(() => {
    if (selectedSL) {
      const newResourceDNs = extractResourceDNs(selectedSL);
      setSelectedResourceDN(newResourceDNs[0]?.resourceDN || "");
    } else {
      setSelectedResourceDN("");
    }
  }, [selectedSL]);
  const handleClickCancel = () => {
    const dataToSend = false;
    close(dataToSend);
  };

  const handleissuechange = (event) => {
    console.log(event.target.value, "resoucedn");
    setSelectIssue(event.target.value);
   
  };
  const handleresourcesubmit = () => {
    
    const selectedDN = selectedResourceDN;
    console.log(selectedResourceDN);
    const resourcedata = {
      resourceDNdata: selectedDN,
      reasondata: selectIssue,
    };
    
    submit(resourcedata);
   
    close(false);
  };

  useEffect(()=>{
    if(selectedResourceDN && selectIssue && selectedSL){
      setIsDisable(false);
     
    }else{
      setIsDisable(true);
    }
  },[selectIssue,selectedResourceDN,selectedSL])
  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal">
              <div className="m-header">
                <span>
                  <b>Splitter Change</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
              <div className="modalcontent">
                <div className="m-splitter">
                  <div>
                    <select
                      className="searchresultfdh"
                      onChange={handleissuechange}
                    >
                      <option value="0">Select Issue</option>
                      <option value="SPLITTER_LIGHT_LOSS">No Light</option>
                      <option value="SPLITTER_PORT_IN_USE">Leg In Use</option>
                    </select>
                  </div>

                  <div>
                    {/* <label htmlFor="sp-select">Splitter:</label> */}
                    <select
                      id="sp-select"
                      className="searchresultfdh"
                      value={selectedSL}
                      onChange={(e) => setSelectedSL(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Splitter
                      </option>
                      {spValues.map((sl, index) => (
                        <option
                          key={index}
                          value={sl}
                        >{`Splitter ${sl}`}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {/* <label htmlFor="lg-select">LG:</label> */}
                    <select
                      id="lg-select"
                      className="searchresultfdh"
                      value={selectedResourceDN}
                      onChange={(e) => setSelectedResourceDN(e.target.value)}
                      disabled={!selectedSL} // Disable LG dropdown if no splitter is selected
                    >
                      <option value="" disabled>
                        Select Leg
                      </option>
                      {extractResourceDNs(selectedSL).map((item, index) => {
                        const lgMatch = item.resourceDN.match(/LG=(\d+)/);
                        const lg = lgMatch ? lgMatch[1] : "Unknown";
                        return (
                          <option
                            key={index}
                            value={item.resourceDN}
                          >{`LG=${lg}`}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <button  className={isdisable ? "greybutton" : "alert-bottom"} onClick={handleresourcesubmit} disabled={isdisable}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modalsplitterchange;
