import React, { useState, useEffect } from "react";

import "./css/Dropdown.css";

import { FaAngleDown } from "react-icons/fa";
import { API_KEY,BASE_API_URL } from "../environment";



const Dropdown = (props) => {
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
  const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  const [data, setData] = useState([]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_API_URL + "/getAuditDetailsByFDH?fdhName="+ props.FDH, headers  )
      .then((res) => res.json())
      .then((data) =>
      setData(data)
      );
  }, [props.FDH]);
  
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "120px 180px 10px",
          fontSize: "15px",
        }}
      >
        <div>Select Splitter:</div>

        <select className="dropDownOption">
          {data.map((value,key)=>{
            return(
              <option id="splitterOptions" value={value.o2Splitter}>{value.o2Splitter}</option>
            )
          })}
        </select>
      </div>
    </>
  );
};

export default Dropdown;
