import React, { useEffect, useReducer, useRef, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./App.css";
import "./fdhtable.css";
import "./popover.css";
import "./Editable.css";
import Loader from "./Loader/Loader";
import { useParams } from "react-router-dom";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faSearch,
  faDotCircle,
  faCircle,
  faFilter,
  faNewspaper,
  faPlus,
  faMinus,
  faTrash,
  faLocationArrow,
  faRotateRight,
  faCheck,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Modal from "react-modal";
import ModalBlack from "./ModalBlack/Modal";
import ModalWhite from "./ModalWhite/Modalsuccess";
import Modalerror from "./ModalError/ErrorModal";
import Modalconfirm from "./ModalConfirm/ConfirmModal";
import SaveConfirm from "./SaveConfirm/ConfirmModal";
import { FaCommentsDollar } from "react-icons/fa";
import { API_KEY, BASE_API_URL } from "./environment";
import ModalFdhIssue from "./ModalWhite/ModalFdhIssue";
import DragDropFile from "./Dragdrop";
import ModalMstIssue from "./ModalWhite/ModalMstIssue";
import ModalImageUpload from "./ModalWhite/ModalImage";
import Modalsplitter from "./ModalWhite/ModalSplitter";
import { headers } from "./headers";
import { headersImage } from "./headers";
import ModalNoLight from "./ModalWhite/ModalNoLight";
import { faDownload , faImage, faL,faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import ModalAuditInfo from "./ModalWhite/ModalAuditInfo";

const PAGE_SIZE = 96;

var initialState = [];
var isSpliterMatching = false;
var isLegMatching = false;
const reducer = (state, action) => {
  console.log(state, action);
  switch (action.type) {
    case "ADD_ENTRY":
    
      const entryIndex = state.findIndex((entry) => {
        const ent = entry.staticId ? entry.staticId : entry.distCblStd;
        const stats = action.payload.staticId
          ? action.payload.staticId
          : action.payload.distCblStd;
        if (ent === stats) {
          return entry;
        }
      });
      if (entryIndex !== -1) {
        
        return [
          ...state.slice(0, entryIndex),
          action.payload,
          ...state.slice(entryIndex + 1),
        ];
      } else {
        return [...state, action.payload];
      }
    // }

    case "EMPTY":
      console.log("trigger");
      return initialState;
    case "EDIT_ENTRY_LEG":
      return state.map((entry) =>
        entry.id === action.payload.id ? { ...entry, ...action.payload } : entry
      );
    case "DELETE_ITEM":
      const itemId = action.payload;
      console.log(itemId);
      console.log(state);
      const updatedItems = state.filter((item) => item.staticId !== itemId);
      return updatedItems;
    default:
      return state;
  }
};

function FdhTable({
  data,
  onPagesaveData,
  onPageStatus,
  onPageReviewtime,
  splittercount,
  cablename
}) {
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
  const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  const headersImage = {
    headers: {
      "apikey": process.env.REACT_APP_API_IMAGE_KEY,
    }
  }
  var resultArrayNds;
  var resultArrayo2;

  const inputRefs = useRef([]);
  const [responseData, setResponseData] = useState("");
  const [statusModal, setStatusModal] = useState("");
  const [savedata, dispatch] = useReducer(reducer, initialState);
  const [overallData, setoverallData] = useState([{}]);
  const [showmodalissue, setShowmodalIssue] = useState(false);
  const[fibercutRange,setFibercutRange] = useState("");
  const [modalnolight, setModalNoLight] = useState(false);
  const[childcase,setChildCase] = useState("");
  const [mstCaseNo, setMstCaseIdNo] = useState("");
  const [mstCaseNomst, setMstCaseIdNomst] = useState("");
  var [mstCaseId, setMstCaseId] = useState("");
  const popupRef = useRef(null);
  const [caseId, setCaseId] = useState("");
  const [errorMessageLight, setErrorMessageLight] = useState();
  const [isEditing, setIsEditing] = useState(true);
  const [error,setError] = useState({
    comments: "",
  });
  const [isPopoverNOpen, setIsPopoverNOpen] = useState(false);
  const[showcomplete,setShowComplete] = useState(false);
  const [isPopoverStdOpen, setIsPopoverStdOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [originalValueSplit, setOriginSplitval] = useState("");
  const [originalValueLeg, setOriginLegval] = useState("");
  const [ fiberstrand, setfiberstrand] = useState(false);
  const [popupmsg, setPopupmsg] = useState(
    "Are you sure want to confirm Audit?"
  );

  const [showloader, setshowloader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  var [loadingpop, setloadingpop] = useState("Loading...");
  const [clickedIndex, setClickedIndex] = useState(null);
  var [dtnauthinfo, setdtnauthinfo] = useState({
    SessionStatus: "",
    StartTime: "",
    StopTime: "",
    WGName: "",
    status:"",
    DSLAMNode:"",
    dtn:""
  });
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [totalnumberofItems, setTotalNumber] = useState([]);
  var [imageBase64, setImageBase64] = useState("");
  const [status, setCheckStatus] = useState("Pending");
  const role = sessionStorage.getItem("Role");
  const Cuid = sessionStorage.getItem("encryptedKey");
  const TechCuid = sessionStorage.getItem("cuid");
  
  let globalIndexOffset = 0;
  const [activeSave, setActivesave] = useState(false);
  const [showblackmodal, setBlackmod] = useState(false);
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);

  const [showmodalconfirm, setShowmodalconfirm] = useState(false);
  const [showmodalimg, setShowmodalimage] = useState(false);
  const [savemodalconfirm, setsavemodalconfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [pageval, setPageval] = useState(null);
  var [splittervalue, setSplittervalue] = useState({});
  var [legvalue, setLegvalue] = useState([]);
  const [formData, setFormData] = useState({
    comments: "",
    issues: "",
  });
  const [fdhissueval, setFdhIssueval] = useState("");
  const [fdhpopissue, setFdhPopIssue] = useState("");
  const [popoverInfo, setPopoverInfo] = useState(false);
  var [arrayrow, setArraysplit] = useState([]);
  var [imageURLdata, setImageURL] = useState({});
  var [legarrayrow, setArrayLeg] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isAuditsplit, setisAuditsplit] = useState(false);
 
  var [totalPagevalue, settotalPage] = useState();
  var [startPagevalue, setstartPagevalue] = useState();
  const [isFilter, setisFilter] = useState(false);
 
  const statusOptions = ["AVAILABLE", "WORKING", "PENDING"];
 
  const [filter, setFilter] = useState({ idRange: "", splitter_status: "" });

  var [splitterMatch, setSplitterMatch] = useState("");
  var [totalstrand, setTotalstrand] = useState(null);
 
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  var [useaddcount, setaddcount] = useState(0);
  const [isPageSave, setisPageSave] = useState(false);
  const[casepayload,setCasepayload] = useState([]);
  const [paramsave, setparamsave] = useState(false);
  const [casepayloadSuccess,setCasepayloadSuccess] = useState(false);
  var [splitterLegMatch, setSplitterLegMatch] = useState("");
  var [remark, setRemark] = useState("");
  var [legvalue, setLegvalue] = useState("");
  const { fdhName } = useParams();
  var resultString = fdhName;
  const { jobNumber } = useParams();
  const { latitude } = useParams();
  const { longitude } = useParams();
  const [apiData, setApiData] = useState(null);
  var [fdhNamevalue, setFDHname] = useState("");
  var [enableEdit, setenableEdit] = useState(false);
  const[auditInfo,setAuditInfo] = useState([]);
  const[auditInfoPopup,setAuditInfoPopup]=useState(false);
  const[statusCount,SetStatusCount] = useState([]);
  var [enableEditFiber, setenableEditFiber] = useState(false);
  var [imageName, setImageName] = useState('');
  var [viewimageupload, setactiveviewimage] = useState(false);
  var [neisl, setNeisl] = useState(false);
  var [isCheckedvalid, setIsCheckedvalid] = useState(false);
  var [enablesplitterview, setSplitterview] = useState(false);
  var [enableAuditsplitview, setAuditsplitview] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  var [auditStatus, setAuditStatus] = useState("Not Audited");
  var [apidatalist, setapidatalist] = useState([]);
  var [fiberset, setfiberval] = useState(false);
  const [resetarraylist, setResetarray] = useState([]);
  var username = sessionStorage.getItem("Name");
  const [errors, setErrors] = useState({
    comments: "",
    issues: "",
  });
  var cuid = sessionStorage.getItem("cuid")
    ? sessionStorage.getItem("cuid").toUpperCase()
    : "NA";
  useEffect(() => {
    checkaccess();
    handleAuditData();
    getStatusCount(fdhName)
    setoverallData([]);
    setResponseData(data);
    getstrand(fdhName);
    dispatch({ type: "EMPTY" });
    setSplittervalue([]);
    setLegvalue([]);
    deviceStatus(fdhName);
  }, [fdhName, jobNumber]);

  // useEffect(()=>{
  //   if(paramsave == true){
  //     handleSave();
  //   }
  // },[paramsave])
  useEffect(()=>{
    setTimeout(() => {
       if(fiberstrand == true){
      handleSave();
      
      setFilter({ idRange: "", splitter_status: "" })
    }
    }, 2000);
   

  },[fiberstrand])

  useEffect(()=>{
    if(isAuditsplit){
        fetchDatasplitter(null);
    }else{
      getstrand(fdhName)
    }
      },[isAuditsplit])

  const [splitterViewData, setSplitterViewData] = useState([]);
  const[splitOptions, setSplitOptions]=useState([]);
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            "/getSplitterView?fdhName=" +
            fdhName ,
          headers
        );
        const data = response.data;

        setSplitterViewData(data); 
        const slValues = data.map(item => {
          const slMatch = item.splitter.match(/SL=(\d{3})/);
          if (slMatch) {
            const slNumber = slMatch[1]; // Extract the number part
            return `Splitter ${parseInt(slNumber, 10)}`; // Convert to "Splitter X"
          }
          return null;
        });
 
        // Filter out null values and remove duplicates
        const uniqueSlValues = [...new Set(slValues.filter(Boolean))];
 
        // Update the options state with the unique SL values
        setSplitOptions(uniqueSlValues); 
      } catch (error) {
        console.log(error.response, "jk");
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      }
    };
    fetchData();
  }, []);
  useEffect(() =>{
    if(resetarraylist.length > 0){
      setoverallData(resetarraylist)
    }
    
   
  },[resetarraylist])
  useEffect(() => {
    getImageUpload();
  }, [viewimageupload]);

  const checkaccess = () => {
    switch (role) {
      case "Super Admin":
        setenableEdit(true);
        setenableEditFiber(true);
      break;
      case "Field Operations":
        setenableEdit(true);
        break;
        case "Field Activation":
          setenableEdit(true);
      case "Cable Technician":
        setenableEdit(true);
        setenableEditFiber(true);
      break;
      case "Facility Support":
      case "SRO":
      case "View Only":
      case "Structure Load":
      case "Field Technician":
        setenableEdit(true);
        break;
      case "Engineer":
        navigate("/");
        break;
    }
  };
  const handleSplitvalue = (e)=>{
    console.log(e.target.value,'splitva')
    if(e.target.value){
     var data =  extractNumberFromString(e.target.value);
     fetchDatasplitter(data)
    }

  }
  
  function extractNumberFromString(input) {
    // Use a regular expression to find the number in the string
    let match = input.match(/\d+/); // \d+ matches one or more digits

    // Return the matched number or null if no match is found
    return match ? parseInt(match[0], 10) : null;
}
  const handleResetdata = (index)=>{
    setoverallData([])
    var indexToReset = index;
    function compareArrays(indexToReset) {
      var propertiesToReset = [
        "onFieldComparison",
        "onField_splitter",
        "onField_splitter_leg",
        "onfieldStrand"
      ];
      const result = overallData.map((item2, index) => {
        if (index !== indexToReset) return item2;
  
        const item1 = apidatalist[index];
        const newItem = { ...item2 };
  
        propertiesToReset.forEach(key => {
          if (item1[key] !== item2[key]) {
            newItem[key] = ""; // Reset the property to an empty string or initial value
          }
        });
  
        return newItem;
       
      });
      console.log(result)
      setResetarray(result);
    }
    
    var comparedArray = compareArrays(indexToReset);
    console.log(comparedArray);
    
  }
  const fetchDatasplitter = async (split) => {
    if(split){
      setBlackmod(true);
    try {
      const responsetwo = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFdhSpliterDtls?fdh=" +
          resultString +
          "&distriStartStrand=" +
          '1' +
          "&distriStopStrand=" + 
          totalstrand + '&sortedType=splitter' + '&splitterFilter=' + split
          ,
        headers
      );
      var datas = responsetwo.data;
      setApiData(datas);

      resultArrayo2 =  Object.values(datas).flat()
      console.log(resultArrayo2)

      const transformArray = (resultData) => {
        return resultData.map(item => {
          const { onFieldSplitter, onFieldSplitterLeg, ...rest } = item;
          return {
            ...rest,
      onField_splitter: onFieldSplitter,
      onField_splitter_leg: onFieldSplitterLeg
          };
        });
      };
      var transformedArray = transformArray(resultArrayo2);
      setapidatalist(transformedArray);

      console.log(resultArrayo2, "hgh");
    } catch (error) {
      console.log(error.response, "sss");
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }

    
    let mergedDataMap = {};

   
    // resultArrayo2?.forEach((item) => {
    //   mergedDataMap[item.id] = {
    //     ...mergedDataMap[item.id],
    //     ...item,
    //   };
    // });

  

   
    let mergedDataArray = resultArrayo2 && resultArrayo2.length > 0 ? Object.values(resultArrayo2).map((item) => ({
      id: item.distCblStd,
      MST: item.mst || null,
      FDH: item.fdh || null,
      MST_PORT: item.mstPort || null,
      distCblStd: item.distCblStd || null,
      ndsSplitter: item.ndsSplitter || null,
      ndsSplitterLeg: item.ndsSplitterLeg || null,
      spliterSlotNumber: item.spliterSlotNumber || null,
      splitter: item.splitter || null,
      splitter_leg: item.leg || null,
      splitter_slot: item.splitterSlot || null,
      splitter_status: item.legStatus || null,
      onField_splitter: item.onFieldSplitter || null,
      onField_splitter_leg: item.onFieldSplitterLeg || null,
      onfieldStrand: item.onfieldStrand || null,
      DTN: item.dtn || null,
      MST_Address: item.mstAddress || null,
      remark: item.onFieldComparison,
      fdhStrandIssue:item.fdhStrandIssue,
      
    }))
    : 
    [];
    console.log(mergedDataArray);
    setoverallData(mergedDataArray);

    setTotalNumber(mergedDataArray.length);

    setBlackmod(false);
    } else {
      setBlackmod(true);
    try {
      const responsetwo = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFdhSpliterDtls?fdh=" +
          resultString +
          "&distriStartStrand=" +
          '1' +
          "&distriStopStrand=" + 
          totalstrand + '&sortedType=Splitter'
          ,
        headers
      );
      var datas = responsetwo.data;
      setApiData(datas);

      resultArrayo2 =  Object.values(datas).flat()
      console.log(resultArrayo2)

      const transformArray = (resultData) => {
        return resultData.map(item => {
          const { onFieldSplitter, onFieldSplitterLeg, ...rest } = item;
          return {
            ...rest,
      onField_splitter: onFieldSplitter,
      onField_splitter_leg: onFieldSplitterLeg
          };
        });
      };
      var transformedArray = transformArray(resultArrayo2);
      setapidatalist(transformedArray);

      console.log(resultArrayo2, "hgh");
    } catch (error) {
      console.log(error.response, "sss");
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }

    
    let mergedDataMap = {};

   
    // resultArrayo2?.forEach((item) => {
    //   mergedDataMap[item.id] = {
    //     ...mergedDataMap[item.id],
    //     ...item,
    //   };
    // });

  

   
    let mergedDataArray = Object.values(resultArrayo2).map((item) => ({
      id: item.distCblStd,
      MST: item.mst || null,
      FDH: item.fdh || null,
      MST_PORT: item.mstPort || null,
      distCblStd: item.distCblStd || null,
      ndsSplitter: item.ndsSplitter || null,
      ndsSplitterLeg: item.ndsSplitterLeg || null,
      spliterSlotNumber: item.spliterSlotNumber || null,
      splitter: item.splitter || null,
      splitter_leg: item.leg || null,
      splitter_slot: item.splitterSlot || null,
      splitter_status: item.legStatus || null,
      onField_splitter: item.onFieldSplitter || null,
      onField_splitter_leg: item.onFieldSplitterLeg || null,
      onfieldStrand: item.onfieldStrand || null,
      DTN: item.dtn || null,
      MST_Address: item.mstAddress || null,
      remark: item.onFieldComparison,
      fdhStrandIssue:item.fdhStrandIssue,
      
    }));
    console.log(mergedDataArray);
    setoverallData(mergedDataArray);

    setTotalNumber(mergedDataArray.length);

    setBlackmod(false);
    }
  };
  const getStatusCount = (fdhName) => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditPageStatusCount?fdh=" +
        fdhName,
      headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        SetStatusCount(data[0]);
      })
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            SetStatusCount([]);
          }
        } else {
        }
      });
  };

  const getstrand = (fdhName) => {
    setShowerror(false);
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getStrandCountByFdh?fdhName=" +
        fdhName,
      headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        const datas = data[0].fdhPortCount;
        fetchDataNds(1, datas);
        setTotalstrand(datas);
      })
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });
  };
  
  const deviceStatus = (fdhName) => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        fdhName,
      headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setFormData({
            comments: data[0].fdhComments ? data[0].fdhComments : "",
            issues: data[0].fdhWise ? data[0].fdhWise : "",
          });
          if (data[0].auditStatus == "COMPLETED") {
            setIsChecked(true);
            setAuditStatus("Completed");
            setStatusModal(data[0].auditStatus);
            onPageReviewtime(data[0].auditTime);
            onPageStatus(data[0].auditStatus);
          } else if (data[0].auditStatus == "PENDING") {
            setAuditStatus("Pending");
            onPageStatus(data[0].auditStatus);
            onPageReviewtime(data[0].auditTime);
          } else {
            setIsChecked(false);
          }
         
        }
      })
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            setAuditStatus("Not Audited");
            onPageStatus("Not Audited");
            onPageReviewtime(null);
          }
        } else {
        }
      });
  };

  const getImageUpload = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_IMAGE_URL + "/get?fileName=" + fdhName,
        headersImage
      )
      .then((response) => {
        const promiseResult = response.data[0].imageBase64;
        setactiveviewimage(true);
        setImageURL(promiseResult);
        setImageName(response.data[0].imageName?response.data[0].imageName:'');
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };

  useEffect(() => {
    console.log("savedata", savedata);

    if (savedata.length > 0) {
      setisPageSave(true);
      onPagesaveData(true);
    } else {
      setisPageSave(false);
      onPagesaveData(false);
    }
  }, [savedata]);
  useEffect(()=>{
    if(casepayloadSuccess){
      createFibercase();
    }
  },[casepayloadSuccess]);

  useEffect(() => {
    const handlebeforeload = (e) => {
      e.preventDefault();
      e.returnValue = "HHSD";
    };
    window.addEventListener("beforeunload", handlebeforeload);

    return () => {
      window.removeEventListener("beforeunload", handlebeforeload);
    };
  }, [savedata]);

  const resetData = () => ({
    type: "EMPTY",
  });
  useEffect(() => {
    setPopupmsg(
      isChecked
        ? "Are you sure want to uncheck"
        : "Are you sure want to confirm Audit?"
    );
  }, [isChecked]);
  const fetchDataNds = async (start, end) => {
    setBlackmod(true);
    try {
      const responsetwo = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFdhSpliterDtls?fdh=" +
          resultString +
          "&distriStartStrand=" +
          start +
          "&distriStopStrand=" +
          end ,
        headers
      );
      var datas = responsetwo.data;
      setApiData(datas);

      resultArrayo2 = Object.entries(datas).map(([key, value]) => ({
        id: key,
        ...value[0],
      }));

      const transformArray = (resultData) => {
        return resultData.map(item => {
          const { onFieldSplitter, onFieldSplitterLeg, ...rest } = item;
          return {
            ...rest,
      onField_splitter: onFieldSplitter,
      onField_splitter_leg: onFieldSplitterLeg
          };
        });
      };
      var transformedArray = transformArray(resultArrayo2);
      setapidatalist(transformedArray);

      console.log(resultArrayo2, "hgh");
    } catch (error) {
      console.log(error.response, "sss");
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }

    
    let mergedDataMap = {};

   
    resultArrayo2?.forEach((item) => {
      mergedDataMap[item.id] = {
        ...mergedDataMap[item.id],
        ...item,
      };
    });

   
    let mergedDataArray = Object.values(mergedDataMap).map((item) => ({
      id: item.id,
      MST: item.mst || null,
      FDH: item.fdh || null,
      MST_PORT: item.mstPort || null,
      distCblStd: item.distCblStd || null,
      ndsSplitter: item.ndsSplitter || null,
      ndsSplitterLeg: item.ndsSplitterLeg || null,
      spliterSlotNumber: item.spliterSlotNumber || null,
      splitter: item.splitter || null,
      splitter_leg: item.leg || null,
      splitter_slot: item.splitterSlot || null,
      splitter_status: item.legStatus || null,
      onField_splitter: item.onFieldSplitter || null,
      onField_splitter_leg: item.onFieldSplitterLeg || null,
      onfieldStrand: item.onfieldStrand || null,
      DTN: item.dtn || null,
      MST_Address: item.mstAddress || null,
      remark: item.onFieldComparison,
      fdhStrandIssue:item.fdhStrandIssue,
      
    }));
    console.log(mergedDataArray);
    setoverallData(mergedDataArray);

    setTotalNumber(mergedDataArray.length);

    setBlackmod(false);
  };
  const handleNavigateMst = (event,
    id,
    row) => {
  const mstname = row.MST;
  const fdhname = row.FDH ? row.FDH : "NA";
  if(mstname !== null){
    navigate("/mstmain/" + mstname + "/" + fdhname + "/" + "NA");
  } else {
    setErrorMessage("Distrubution strand is not connected to MST");
  }
  };
  const fetchfibercut = async (start, end) => {
    
    try {
      const responsetwo = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFdhSpliterDtls?fdh=" +
          resultString +
          "&distriStartStrand=" +
          start +
          "&distriStopStrand=" +
          end,
        headers
      );
      var datas = responsetwo.data;
      setApiData(datas);
      const allItems = Object.values(datas).flat();
      
      const filteredItems = allItems.filter(item => item.mst);
      
      const groupedItems = filteredItems.reduce((acc, item) => {
        if (!acc[item.mst]) {
          acc[item.mst] = [];
        }
        acc[item.mst].push(item);
        return acc;
      }, {});
     const mstdata =  Object.values(groupedItems)
      const Finaldatapayload = [];
      for (let index = 0; index < mstdata.length; index++) {
        const element = mstdata[index];
         var dtnlist = [];
         var fdhnameitem ='';
         var mstnameitem='';
        const mstfilter = element.map((e) =>{
          fdhnameitem = e.fdh;
          mstnameitem = e.mst;
            if(e.legStatus === 'WORKING' || e.legStatus === 'PENDING'){
              if(e.dtn){
                dtnlist.push(e.dtn);
              }      
            }
        });
        const datapayload = {
          "DTNList": dtnlist,
          "wireCenter": data.wireCenter,
          "MSTName": mstnameitem,
          "FDHName": fdhnameitem,
          "DistributionStrand": "",
          "SplitterSlot": "",
          "SplitterLeg": ""
        }
        Finaldatapayload.push(datapayload);
      }
      setCasepayload(Finaldatapayload);
      setCasepayloadSuccess(true); 
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }
  };
  const handleFiberClick = () => {
    setoverallData(filteredData)
    const strandvalue = filter.idRange;
    setFibercutRange(strandvalue);
    const val = strandvalue.split('-');
    console.log(val,'ssske')
    var start = val[0];
    var stop = val[1];
    fetchfibercut(start,stop); 

  }
  const handleDtnInfo = () =>{
    setPopoverInfo(true);
  }
  const createFibercase = () => { 
    setshowloader(true); 
    const dtnpayload = {
     "MSTcaseDetails": casepayload
    };
  const payload =  {
      "Action": "Create Case",
      "Issue": "Fiber Cut",
      "Subject": "",
      "Cable": cablename,
       "Strands":fibercutRange,
      "TechCuid": TechCuid,
      "MSTcaseJson":JSON.stringify(dtnpayload)
    }
    axios.post(process.env.REACT_APP_BASE_API_SF_URL, payload)
      .then((response) => {
        setshowloader(false);
        
        var jsonString = response.data;
        console.log(jsonString,'res')
        if(jsonString){

          const strandvalue = filter.idRange;
    setFibercutRange(strandvalue);
    const val = strandvalue.split('-');
    var start = val[0];
    var stop = val[1];
       const  mapped = filteredData.map((item,index) => {
              item.fdhStrandIssue='Fiber Cut'
              return item
          });
        for (let index = 0; index < mapped.length; index++) {
          const data = {
            fdh: fdhName,
            mst: mapped[index].MST,
            mstPort: mapped[index].MST_PORT,
            distCblStd: mapped[index].id,
            staticId: mapped[index].idval ? mapped[index].idval : null,
            splitterSlot: mapped[index].splitter_slot,
            leg: mapped[index].splitter_leg,
            ndsSplitter: mapped[index].ndsSplitter,
            ndsSplitterLeg: mapped[index].ndsSplitterLeg,
            onfieldSplitter: mapped[index].onField_splitter,
            onfieldSplitterLeg: mapped[index].onField_splitter_leg,
            onfieldStrand: mapped[index].id,
            fdhStrandIssue:mapped[index].fdhStrandIssue,
            onFieldComparison: mapped[index].remark,
            
            dropType: "",
          };
          handleAdd(data)
        }
        setfiberstrand(true);
        setFdhIssueval('Fiber Cut')
        }
        console.log(jsonString,"hello");
        const caseId = jsonString.caseId;
        const caseNumber = jsonString.caseNumber;
        setCaseId(caseId);
        setMstCaseId(caseId);
        setMstCaseIdNomst(caseNumber);
        setMstCaseIdNo(caseNumber);
        setChildCase(jsonString.ParentCases);
        setErrorMessageLight(jsonString.Message);
       
        setModalNoLight(true);
      })
      .catch((error) => {
        setshowloader(false);
        if (error.response) {
          if (error.response.status === 404) {
            setShowerror(true);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };
  const handleSelectDrop = (event) => {
    const setvalue = event.target.value;
    setFdhIssueval(setvalue);
    if (setvalue === "No Light" || setvalue === `No Light-${fdhpopissue}`) {
      setShowmodalIssue(true);
    } else {
      setFdhPopIssue("");
      setShowmodalIssue(false);
    }
    
  };

  const handleInputFDHLevel = (e) => {
    const newErrors = {
      comments: "",
    };
    setErrors(newErrors);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };
  const handlePageChange = (newPage) => {
    setPageval(newPage);
    console.log(newPage);
    var totalvalue = newPage * PAGE_SIZE;

    console.log(totalvalue, "dssd");

    var start = totalvalue - PAGE_SIZE + 1;
    console.log(totalPages);
    console.log(totalvalue, "tttt");
    console.log(filteredData);
    console.log(savedata, "ss");
    settotalPage(totalvalue);
    setstartPagevalue(start);
    
    if (savedata.length == 0) {
      console.log("check");
      setCurrentPage(newPage);
      
    } else {
      setsavemodalconfirm(true);
    }
    
  };

  const executesave = () => {};

  const handleViewMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

 
  const [showDropdown, setShowDropDown] = useState(false);
  const [numRowsToAdd, setNumRowsToAdd] = useState(1);

  const toggleDropdown = () => {
    setShowDropDown(!showDropdown);
  };
  const deleteStrand = (index) => {
    overallData.pop(index);
  };

  const handleClick = (itemId, value) => {
    console.log(itemId, "id");
    const updatedItems = [...overallData];
    updatedItems.map((item, index) => {
      console.log(item, index);
      if (item.idval === itemId) {
        updatedItems.splice(index, 1);
        setoverallData(updatedItems);
        dispatch({ type: "DELETE_ITEM", payload: itemId });
      }
    });
  };

  const toggleDropAdd = () => {
    if (totalstrand / currentPage == PAGE_SIZE) {
      setaddcount(useaddcount + 1);
      handlePageChange(currentPage + 1);
    }
    const lastindex = overallData.length + 1;
    console.log(lastindex);
    const newRows = Array.from({ length: 1 }, (_, index) => ({
      id: "",
      idval: lastindex,
      idEdit: true,
    }));
    console.log(newRows, "kh");
    setoverallData((prevData) => [...prevData, ...newRows]);
  };

  

  const addRows = () => {
    

    const lastindex = overallData.length - 1;
    const value = overallData[lastindex].id;
    console.log(value);
    const lastId =
      value > 0 ? Math.max(...overallData.map((row) => row.id)) : 0;
    console.log(lastId);
    const newRows = Array.from({ length: numRowsToAdd }, (_, index) => ({
      id: lastId + index + 1,
    }));
    console.log(newRows);
    setoverallData((prevData) => [...prevData, ...newRows]);
    
  };

  const incrementCounter = () => {
    setNumRowsToAdd(numRowsToAdd + 1);
  };

  const decrementCounter = () => {
    if (numRowsToAdd > 1) {
      setNumRowsToAdd(numRowsToAdd - 1);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };
  const listClassNames = {
    AVAILABLE: "class-list1",
    PENDING: "class-list2",
    WORKING: "class-list3",
    "LEGACY USER": "class-list4",
    
  };

  const Statusupdate = (splitter_status) => {
    return listClassNames[splitter_status] || "";
  };
  const handleSave = async() => {
    setIsEditing(true);
    var state = false;
    var stand = false;
    const newErrors = {
      comments: "",
      issues: "",
    };
    if (formData.comments === "") {
      newErrors.comments = "Comments field is required";
    }
    setErrors(newErrors);
    var auditstatus = "Pending";
   
    for (let index = 0; index < filteredData.length; index++) {
      const element = filteredData[index];
      if (
        (element.onField_splitter == null ||
          element.onField_splitter == "" ||
          element.onField_splitter_leg == null ||
          element.onField_splitter_leg == "") &&
        isCheckedvalid
      ) {
        setIsPopoverNOpen(true);
        setIsPopoverStdOpen(false);
       
        state = true;
      }
      if (
        (element.onField_splitter !== null &&
          element.onField_splitter !== "" &&
          element.onField_splitter_leg !== null &&
          element.onField_splitter_leg !== "") 
      ) {
        
        auditstatus="Completed";
        
      }else{
        auditstatus="Pending";
      }
      if (
        (element.distCblStd == null || element.distCblStd == "") &&
        isCheckedvalid
      ) {
        setIsPopoverNOpen(false);
        setIsPopoverStdOpen(true);
        stand = true;
        break;
      }
    }
    var splitval = enableAuditsplitview ? "Splitter" : "";
  if (state == false && stand == false) {
    
    setshowloader(true);
    const valuedata = [null];
    try {
      const responseone = await  axios.post(
        process.env.REACT_APP_BASE_API_URL +
          "/postFdhFieldAuditDetails?status=" +
          auditstatus +
          "&deviceName="  +
          fdhName +
          "&fdhWise=" +
          fdhissueval +
          "&fdhComments=" +
          formData.comments +
          "&wirecenter=" +
          data.wireCenter +
          "&state=" +
          data.state +
          "&fiberCutRange="  +
          fibercutRange +
          "&fdhIssueDevice=" +
          mstCaseNomst +
          "&postType=" +
          splitval + 
          "&user="+
          username +
          "%2F" +
          encryptedId,
          savedata.length > 0 ? savedata : valuedata,
        headers
      );

      var datas = responseone.data;
      if (datas == "Success") {
        deviceStatus(fdhName);
        handleAuditData();
        setshowloader(false);
        setWhitemod(true);
        setparamsave(false);
        if(fiberstrand == true){
          getstrand(fdhName);
        }
        
       
        var list = overallData.map((item) => {
          console.log(item);
          if (item && item.idEdit) {
            item.idEdit = false;
          }
          return item;
        });
        setoverallData(list);

        dispatch({ type: "EMPTY", payload: [] });
        setfiberstrand(false);
        setCasepayloadSuccess(false);
      } else {
      }
      console.log(datas);
    } catch (error) {
      setshowloader(false);
      console.log(error, datas);
      if (error.response) {
        if (error.response.status === 400) {
         
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }
  }

  };
  const handlePageSave = async () => {
    setIsEditing(true);
    for (let index = 0; index < filteredData.length; index++) {
      const element = filteredData[index];
    var auditstatus = "Pending";
    if (
      (element.onField_splitter !== null &&
        element.onField_splitter !== "" &&
        element.onField_splitter_leg !== null &&
        element.onField_splitter_leg !== "") 
    ) {
      auditstatus="Completed";
    }else{
      auditstatus="Pending";
    }}
    var splitval = enableAuditsplitview ? "Splitter" : "";
    try {
      const responseone = await axios.post(
        "/postFdhFieldAuditDetails?status=" +
        auditstatus +
        "&fdhWise=" +
        fdhissueval +
        "&fdhComments=" +
        formData.comments +
        "&wirecenter=" +
        data.wireCenter +
        "&state=" +
        data.state +
        "&fiberCutRange="  +
        fibercutRange +
        "&postType=" +
          splitval + 
        "&user=" +
        username +
        "%2F" +
        encryptedId,
        savedata,
        headers
      );

      var datas = responseone.data;
      if (datas == "Success") {
        setWhitemod(true);
        handleAuditData();    
        deviceStatus(fdhName);
        var list = overallData.map((item) => {
          console.log(item);
          if (item && item.idEdit) {
            item.idEdit = false;
          }
          return item;
        });
        setoverallData(list);
        dispatch({ type: "EMPTY", payload: [] });

        
      } else {
      }
      console.log(datas);
    } catch (error) {
      console.log(error, datas);
      if (error.response) {
        if (error.response.status === 400) {
          setIsPopoverOpen(true);
        }
        if (error.response.status === 502 || error.response.status === 500) {
          setShowerror(true);
        }
      } else {
        setShowerror(true);
      }
    }
  };
  const handleAuditData = ()  =>{  
    axios
    .get(
      process.env.REACT_APP_BASE_API_URL +
        "/getFdtComments?deviceName=" +
        fdhName,
      headers
    )
    .then((response) => {
      const data = response.data;
      setAuditInfo(data);
     console.log(data,"auditdata");
    })
    .catch((error) => {
      if (error) {
        if (
          error.status === 400 ||
          error.status === 502 ||
          error.status === 404 ||
          error.status === 500
        ) {
            setAuditInfo([]);
        }
      } else {
      }
    });

  }
  const handleAuditInfo =()=>{
    setAuditInfoPopup(true);
  }
  const handleCloseAudit = (data) =>{
    setAuditInfoPopup(data);
  }
  const handleFilter = async () => {
    setisFilter(!isFilter);
  };
  

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchTerm);
    setCurrentPage(1); 
  };

  

  var totalPages = Math.ceil(Number(totalstrand) / PAGE_SIZE);
  console.log(totalstrand, "ccc");
  const visibleData = overallData.slice(
    
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );
  var splitterArray;
  var legArray;
  var originalSplitval;
  var originalLegval;
  

  const handleInputChangeSplit = (
    event,
    rowIndex,
    row,
    index,
    from,
    column
  ) => {
    console.log(column, "checing value");
    if (event.target.value == "") {
      filteredData[index][column] = event.target.value;
    }
    const patternnumber = /^[0-9]*$/;
    const pattern = /^(?:[0-9]+|N+)$/;
    setErrorMessage("");

    if (!pattern.test(event.target.value) && column !== "id") {
      console.log(filteredData[index][column]);
      console.log(event.target.value);
      if (event.target.value == "") {
        filteredData[index]["remark"] = "";
        setActivesave(false);
      }
      if (event.target.value && event.target.value.endsWith("N")) {
        var repvalue = "N";
      } else {
        var repvalue = event.target.value.replace(
          /[^0-9N+]|^N|[^N][0-9]*N+/g,
          ""
        );
      }

      if (column === "onField_splitter" || column === "onField_splitter_leg" || column === "onfieldStrand") {
        setErrorMessage(
          "Please Enter only UPPERCASE 'N' and digits, Special characters and Alphanumeric not allowed"
        );
        if (from == "s") {
          document.getElementById("split-" + index).value = repvalue;
        }else if(from == "f"){
          document.getElementById("ondiststrand-" + index).value = repvalue;
        }else {
          document.getElementById("leg-" + index).value = repvalue;
        } 
      }
    } else if (column === "id") {
      if (!patternnumber.test(event.target.value)) {
        document.getElementById("id-" + index).value =
          filteredData[index][column];
        setErrorMessage(
          "Please Enter only Numbers, Special characters and Alphanumeric not allowed"
        );
      } else {
        filteredData[index][column] = event.target.value;
        document.getElementById("id-" + index).value = event.target.value;
      }
    } else {
      setErrorMessage("");
      const { name, value } = event.target;
      console.log(value);
      var spliter = "";
      var leg = "";
      var strand = "";
      setArraysplit(row);

      if (event.target.value.endsWith("N")) {
        filteredData[index][column] = "N";
        if (from == "s") {
          document.getElementById("split-" + index).value = "N";
        } else if (from == "f") {
          document.getElementById("ondiststrand-" + index).value = "N";
        } else{
          document.getElementById("leg-" + index).value = "N";
        } 
      } else {
        filteredData[index][column] = event.target.value;
      }
      // if (
      //   (column === "onField_splitter" && event.target.value == "N") ||
      //   (column === "onField_splitter_leg" && event.target.value == "N")
      // ) {
      //   filteredData[index]["onField_splitter_leg"] = "N";
      //   filteredData[index]["onField_splitter"] = "N";
      //   // if (index < tableData.length - 1) {
      //   //   setTimeout(() => {
      //   //     inputRefs.current[index + 1]?.focus();
      //   //   }, 0);
      //   // }
      //   setTimeout(() => {
      //     inputRefs.current[index + 1]?.focus();
      //   }, 0);
      // }
      if (column === "onField_splitter" && event.target.value === "N") {
        filteredData[index]["onField_splitter_leg"] = "N";
        document.getElementById("leg-" + index).value = "N"; // Ensure the input value is updated
        setTimeout(() => {
              inputRefs.current[index + 1]?.focus();
            }, 0);
    }

    if (column === "onField_splitter_leg" && event.target.value === "N") {
        filteredData[index]["onField_splitter"] = "N";
        document.getElementById("split-" + index).value = "N"; // Ensure the input value is updated
        setTimeout(() => {
              inputRefs.current[index + 1]?.focus();
            }, 0);
    }

      var letter = "N";
      if (
        (row.splitter_slot ? row.splitter_slot : letter) ===
          row.onField_splitter &&
        (row.splitter_leg ? row.splitter_leg : letter) ===
          row.onField_splitter_leg
      ) {
        filteredData[index]["remark"] = "Matching";
        setActivesave(true);
      } else {
        filteredData[index]["remark"] = "Mismatching";
        setActivesave(true);
      }
      if(enableAuditsplitview){
        if (
          (row.id ? row.id : letter) ===
            row.onfieldStrand 
        ) {
          filteredData[index]["remark"] = "Matching";
          setActivesave(true);
        } else {
          filteredData[index]["remark"] = "Mismatching";
          setActivesave(true);
        }
      }
      if (value == "") {
        filteredData[index]["remark"] = "";
        setActivesave(false);
      }

      if (from == "s") {
        spliter = value;
        leg = document.getElementById("leg-" + index).value;
        setSplittervalue((prevInputValues) => ({
          ...prevInputValues,
          [rowIndex]: { ...prevInputValues[rowIndex], [name]: value },
        }));
        console.log(parseInt(row.splitter_slot));
        console.log(parseInt(row.ndsSplitter));
        console.log(parseInt(value));
      } else if (from == "l") {
        leg = value;
        spliter = document.getElementById("split-" + index).value;
        setLegvalue((prevInputValues) => ({
          ...prevInputValues,
          [rowIndex]: { ...prevInputValues[rowIndex], [name]: value },
        }));
        console.log(parseInt(row.splitter_leg));
        console.log(parseInt(row.ndsSplitterLeg));
        console.log(parseInt(value));
      } else {
        filteredData[index][column] = event.target.value;
      }
      if (from == "standid") {
        filteredData[index][column] = event.target.value;
      }
      const onfieldvaluest = enableAuditsplitview ? row.onfieldStrand : row.id;
      const data = {
        fdh: fdhName,
        mst: row.MST,
        mstPort: row.MST_PORT,
        distCblStd: row.id,
        staticId: row.idval ? row.idval : null,
        splitterSlot: row.splitter_slot,
        leg: row.splitter_leg,
        ndsSplitter: row.ndsSplitter,
        ndsSplitterLeg: row.ndsSplitterLeg,
        onfieldSplitter: spliter,
        onfieldSplitterLeg: leg,
        onfieldStrand: row.onfieldStrand,
        onFieldComparison: row.remark,
        legStatus: row.splitter_status ? [row.splitter_status] : [""],
        mstPortWise: "",
        dropType: "",
      };

      handleAdd(data);
      console.log(filteredData);
    }
  };

  const handleAdd = (data) => {
    dispatch({ type: "ADD_ENTRY", payload: data });
  };

  const handleDataFromChild = () => {
    setWhitemod(false);
  };
  const handleConfirmData = () => {
    console.log(setIsChecked);
    setShowmodalconfirm(false);
  };
  const handleImagePass = () => {
    console.log(setIsChecked);
    setShowmodalimage(false);
  };
  const handleImageClose = (data) => {
    setShowmodalimage(data);
    getImageUpload();
  };
  const handleSplitClose = (data) => {
    setSplitterview(data);
    setIsSwitchOn(!isSwitchOn);
  };
  const handleSaveConfirm = () => {
    setsavemodalconfirm(false);
    handlePageSave();
    setCurrentPage(pageval);
  };

  const handleCancelConfirm = () => {
    setsavemodalconfirm(false);
  };
  const handleConfirm = (e) => {
    
    setShowmodalconfirm(true);
  };



  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    console.log(name,'33');
    if (name === "splitter_status") {
      setFilter({ ...filter, [name]: value });
    } else {
      const numericpattern = /^[0-9-]*$/;
      if (numericpattern.test(value)) {
        setFilter({ ...filter, [name]: value });
      }
    }
  };

  const handleIdFilter = () => {
    console.log('checking',filter);
   
    let minId, maxId;

    if (filter.idRange.includes("-")) {
      [minId, maxId] = filter.idRange.split("-").map(Number);
    } else {
      minId = maxId = Number(filter.idRange);
    }

    if (searchTerm.length > 0) {
      const filteredData = overallData.filter((item) =>
        Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const valueString = String(value);
            return valueString.includes(searchTerm);
          }
          return false;
        })
      );
      return filteredData;
    }

    

    if (filter.idRange === "") {
      

      const filteredData = visibleData.filter(
        (row) =>
          filter.splitter_status === "" ||
          row.splitter_status === filter.splitter_status
      );

      return filteredData;
    }

    

    const filteredData = overallData.filter((row) => {
      const idMatch =
        (Number.isNaN(minId) || row.id >= minId) &&
        (Number.isNaN(maxId) || row.id <= maxId);
      console.log(filter, "ffh");

      const splitterstatusMatch =
        filter.splitter_status === "" ||
        row.splitter_status === filter.splitter_status;
      console.log(filter.splitter_status);
      if (row.splitter_status === filter.splitter_status) {
        console.log("gfggfg");
      }

      const name = row.id !== null ? row.id.toLowerCase() : "";

      
      name.includes(searchTerm.toLowerCase());

      return splitterstatusMatch && idMatch;
    });

    return filteredData;
  };

  const handleShow = () => {
    setNeisl(!neisl);
  };
  const handleCheckbox = () => {
    setIsCheckedvalid(!isCheckedvalid);
  };
  const handlesplitShow = () => {
    setSplitterview(!enablesplitterview);
    setIsSwitchOn(!isSwitchOn);
  };
  const handlesplitAudit = () => {
    setisAuditsplit(!isAuditsplit);
    setAuditsplitview(!enableAuditsplitview);
  };
  const handleNOLightClose = () => {
    setModalNoLight(false);
  };
  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const filteredData = handleIdFilter();

  const showNoRecordFoundMessage =
    filter.idRange !== "" &&
    filter.splitter_status !== "" &&
    filteredData.length === 0;

  const handleDataFromError = () => {
    setShowerror(false);
  };
  const handleImageBase = (data) => {
    setImageBase64(data);
  };
  const togglePopover = () => {
    setIsPopoverOpen(false);
   setIsPopoverStdOpen(false);
   setIsPopoverNOpen(false);
  };
  const handleFdhIssueClose = (data) => {
    setShowmodalIssue(data);
  };
  const handleFdhIssuePop = (data, issueVal) => {
    setShowmodalIssue(data);
    const SplitVal = issueVal.map((item) => item.split(" ")[1]).join(",");
    setFdhIssueval("No Light-Splitter " + SplitVal);
    setFdhPopIssue("Splitter " + SplitVal);
  };

  const handleClickOutside = (e) =>{
    if(popupRef.current && !popupRef.current.contains(e.target)){
      setPopoverVisible(false);
      setPopoverInfo(false);
    }
  }

  useEffect(()=>{
    if(popoverVisible){
      document.addEventListener('mousedown',handleClickOutside);
    }
    if(popoverInfo){
      document.addEventListener('mousedown',handleClickOutside);
    }
    return () =>{
      document.removeEventListener('mousedown',handleClickOutside);
    }
  },[popoverVisible,popoverInfo])
  const getdtnauthenticate = (item, row) => {
    setloadingpop('Loading...')
    setdtnauthinfo({
      SessionStatus: "",
      StartTime: "",
      StopTime: "",
      WGName: "",
      DSLAMNode:"",
      status:"",
      dtn:""
    })
    
    var index = row.id;
    if (clickedIndex === null || clickedIndex !== index) {
      setPopoverVisible(true);
      fetch(
        process.env.REACT_APP_BASE_API_URL +
          "/lars/authentication/" +
          item +
          "?", headers
      )
        .then((response) => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setdtnauthinfo(data);
            setdtnauthinfo(prevData => ({ ...prevData, dtn: item }));
          }
        })
        .catch((error) => {
          if (error) {
            if (error.status === 404 ||  error.status === 400) {
              const data = {
                SessionStatus: "",
    StartTime: "",
    StopTime: "",
    WGName: "",
    DSLAMNode:"",
    status:"failed",
    dtn:""
              }
              setdtnauthinfo(data);
              setloadingpop('Failed to Authenticate')
            }
            if (
             
              error.status === 502 ||
              error.status === 500
            ) {
              setShowerror(true);
            }
          } else {
          }
        });
    setClickedIndex(index);
  } else {
    setPopoverVisible(false);
  }
  };
  
  const navigateOnt = (item) =>{
    const dtnname = item.replace(/\D/g,'');
    console.log(dtnname,"hello")
    navigate("/ontmain/" +dtnname + "/NA" + "/NA");
  }
  
  const additionalData = {
    FDHID: data.fdh,
    FDHAddrress:data.fdhAddress,
    FeederCable:data.feederCable,
    OLT:data.olt,
    FdhLevelIssue:fdhissueval,
  }; 
  const exportToExcel = () => {
      
      if (!apiData) {
        console.error('No data available to export.');
        return;
      }
    
      const capitalizeKeys = (obj) => {
        const newObj = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key.toUpperCase()] = obj[key];
          }
        }
        return newObj;
      };
  
      // Flatten the data structure, filter out empty arrays and capitalize keys
      const flattenedData = Object.values(apiData)
        .flat()
        .filter(item => Object.keys(item).length !== 0) // Filter out empty objects
        .map(item => capitalizeKeys(item));
  
  // Convert additional data into an array of arrays format
  const additionalRows = Object.entries(additionalData).map(([key, value]) => [key, value]);
        const worksheet = XLSX.utils.aoa_to_sheet([...additionalRows, []])
    
  
  // Convert the table data into an array of arrays format
  // const tableRows = flattenedData.map(obj => Object.values(obj));
  
  // Combine additional rows with table data
  // const finalData = [...additionalRows, [], ...tableRows];
   // Empty array for spacing
      // Create a new workbook
    XLSX.utils.sheet_add_json(worksheet,flattenedData,{origin:-1})
      const wb = XLSX.utils.book_new();
      //const ws = XLSX.utils.json_to_sheet(finalData);
      
      
  
      XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");
  
      const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
  
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      const fileName = "FDH_AUDIT.xls";
  
      const url = URL.createObjectURL(blob);
  
      const a = document.createElement("a");
  
      a.href = url;
  
      a.download = fileName;
  
      a.click();
  
      URL.revokeObjectURL(url);
    };

  return (
    <>
      {/* <ModalBlack/> */}
      {showmodalimg && (
        <ModalImageUpload
          data={fdhName}
          type="FDH"
          mode='audit'
          role={role}
          imagerole={viewimageupload}
          imageurl={imageURLdata}
          onDataFromChild={handleImagePass}
          closemodissue={handleImageClose}
          imgbase64={handleImageBase}
          imageName={imageName}
        />
      )}
      {auditInfoPopup && (<ModalAuditInfo close={handleCloseAudit} auditdata={auditInfo}/>)}
      {isPopoverNOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the N in Empty field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopoverStdOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the Distribution strand in Empty field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
        {modalnolight && (
        <ModalNoLight
          errormsg={errorMessageLight}
          close={handleNOLightClose}
          caseId={caseId}
          childcase={childcase}
          mstCaseNo={mstCaseNo}
        />
      )}
      {enablesplitterview && (
        <Modalsplitter
          closemodissue={handleSplitClose}
          dataSplitter={splitterViewData}
          responseData={data}
          statusModal={statusModal}
        />
      )}

      {showmodalconfirm && (
        <Modalconfirm
          onDataFromChild={handleConfirmData}
          onDatacheck={setIsChecked}
          message={popupmsg}
          isSelect={isChecked}
        />
      )}
      {showmodalissue && (
        <ModalFdhIssue
          addFdhIssue={handleFdhIssuePop}
          datacount={splittercount}
          closemodissue={handleFdhIssueClose}
        />
      )}
      {savemodalconfirm && (
        <SaveConfirm
          onDataFromChild={handleSaveConfirm}
          onDataCancel={handleCancelConfirm}
        />
      )}
      {showwhitemodal && <ModalWhite onDataFromChild={handleDataFromChild} />}
      {showblackmodal && <ModalBlack />}
      {showloader && <Loader />}
      
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {isPopoverOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the Data in Onfield Column</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
 <div className="task-bar row">
  <div className="col-6">
  <div className="search-container-fdhtable">
          <FontAwesomeIcon icon={faSearch} className="search-icon-top" />
          <input type="text"  className="search-tasks" 
           value={searchTerm}
           onChange={handleSearch}
           placeholder="Search..."

          />
        </div>
        <select

              name="issues"
              onChange={(event) => handleSelectDrop(event)}
              className="select-issues"
            >
              <option value="0">Select FDH Level Issue</option>
              <option value="ADDRESS CORRECTION">Address Correction</option>
              <option value={"No Light"} className="no-light-text-overflow">
                {" "}
                {fdhpopissue ? `No Light-${fdhpopissue}` : "No Light"}
              </option>
              <option value="Insufficient labeling">
                    Insufficient labeling
                    </option>
              <option value="Disaster">Disaster</option>
            </select>
  </div>
<div className="col-6  right-save-content">
<div style={{ display:"flex",width: '20px', alignItems: 'center',cursor:"pointer",marginRight:"30px" }}  title="Comment Log">
              <FontAwesomeIcon icon={faPenToSquare} onClick={handleAuditInfo} style={{fontSize:"18px",color:"#001E64"}}/>
            </div>
  <button 
          onClick={exportToExcel} 
          disabled={!apiData}
          className="but-dark mob-but-fil"
          >
                Export{" "}
                <span
                //  className="hidden-mob"
                 >
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </button>
              <button
                  onClick={handleFilter}
                  className="but-dark-outline mob-but-fil"
                >
                  <span>
                    <FontAwesomeIcon className="" ico icon={faFilter} />
                     Filter
                  </span>
                </button>
                {enableEdit && (
                  <button onClick={handleSave} className="but-dark mob-but-fil">
                    Save
                  </button>
                )}
</div>

      </div>
      <div className="fdhTable-outer mt-3">
          <div className="row" style={{alignItems:"baseline"}}>
            <div className="col-6 mob-col-12 block-in-mobile">
              <div className="legend-content mt-3">
                <span className="Available">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Available({statusCount.available})
                </span>
 
                <span className="Pending">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                   Pending({statusCount.pending})
                </span>
 
                <span className="Working">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                   Working({statusCount.working}) 
                </span>
                <span className="Legacy">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Legacy Customer({statusCount.legacyUser})
                </span>
                <span className="Suspended_Defective">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Defective/Suspended({statusCount.defectiveOrSuspended})
                </span>
              </div>
            </div>
            <div className="col-3 mob-col-6">
            <div class="form-check form-switch">
              <button
                className="img-upload-btn mob-but-fil"
                onClick={handleImageShow}
              >
                {viewimageupload == true ? "View Image" : "Upload Image"}
              </button>
            </div>
          </div>
       
          <div className="col-3 mob-col-6 mob-marg-top" style={{display:"flex"}}
          >
            <textarea
              placeholder="Comment"
              name="comments"
              defaultValue={formData.comments}
              disabled={!enableEdit}
              onChange={handleInputFDHLevel}
              className="text-wise"
            ></textarea>
             {errors.comments && (
                <p style={{ color: "red" }} className="mob-mstwise-text">
                  {errors.comments}
                </p>
              )}
          </div>
 
           
 
           
         
           
          </div>
        </div>
      <div className="container">
        
        
        {isFilter && (
          <div className="filterCard arrow-top mt-3">
            <div className=" d-flex flex-column">
              <div className="filter-title">Filter By</div>
              <div className="row mt-3">
                <div
                  className="col-lg-3 mob-col-5"
                  style={{ "text-align": "left" }}
                >
                  <div className="d-flex flex-column">
                    <div>
                      <span>Status</span>
                    </div>
                    <div>
                      <select
                        className="form-control select-fdh"
                        name="splitter_status"
                        value={filter.splitter_status}
                        onChange={handleFilterChange}
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map((splitter_status) => (
                          <option key={splitter_status} value={splitter_status}>
                            {splitter_status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 mob-col-6"
                  style={{ "text-align": "left" }}
                >
                  <div className="">
                    <div className="d-flex flex-column">
                      <div>
                        <span>Dist Cable</span>
                      </div>
                      <div>
                        <input
                          className="form-control select-fdh"
                          type="text"
                          name="idRange"
                          value={filter.idRange}
                          onChange={handleFilterChange}
                          placeholder="1-20"
                        />
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
                <div
                  className="col-lg-3 mob-col-6"
                  style={{ "text-align": "left" }}
                >
                {enableEditFiber &&
                  <div className="">
                    <div className="d-flex flex-column mt-4">
                      <div>
                        {/* <span>Dist Cable</span> */}
                      </div>
                      <div>
                     <button   className="img-upload-btn mob-but-fil" onClick={handleFiberClick} >Create Fiber Cut Issue</button>
                      </div>
                    </div>
                    
                  </div>
                }
                  
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 table-responsive fdh-table">
          <div className="col-3 mob-col-12 block-in-desktop">
            <div className="d-flex justify-content-around block-in-desktop">
              <div className="Available">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ "padding-right": "10px" }}
                />
                Available
              </div>

              <div className="Pending">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ "padding-right": "10px" }}
                />
                Pending
              </div>

              <div className="Working">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ "padding-right": "10px" }}
                />
                Working
              </div>
            </div>
          </div>
        </div>
      
        <div className="row">
        <div className="col-2 form-check mob-col-6 ">
                <label
                  style={{ display: "flex", alignItems: "center" }}
                  className="mob-check-audit"
                >
                  <input
                    className="form-check-input"
                    style={{ "margin-right": "8px" }}
                    type="checkbox"
                    checked={isCheckedvalid}
                    onChange={handleCheckbox}
                  ></input>
                  Full Audit Report
                </label>
          </div>
          <div className="col-2 mob-col-6 mob-marg-top">
            <div class="form-check form-switch">
              <input
                class="form-check-input neisltog mob-marg-left"
                type="checkbox"
                onClick={handleShow}
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                Enable Niesl
              </label>
            </div>
          </div>
          <div className="col-2 mob-col-6 mob-marg-top">
            <div class="form-check form-switch">
              <input
                class="form-check-input neisltog mob-marg-left"
                type="checkbox"
                checked={isSwitchOn}
                onClick={handlesplitShow}
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                Enable Splitter view
              </label>
            </div>
          </div>
         
        <div className="col-2 mob-col-6 mob-marg-top">
            <div class="form-check form-switch">
              <input
                class="form-check-input neisltog mob-marg-left"
                type="checkbox"
                checked={isAuditsplit}
                onClick={handlesplitAudit}
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                Audit by Splitter
              </label>
            </div>
          </div>
          {
            isAuditsplit && (
<div className="col-2 mob-col-6 mob-marg-top">
          <select className="text-wise mob-mst-place select-drop-margin" onChange={handleSplitvalue} defaultValue="">
              <option value="" disabled>Select Splitter</option>
                  {splitOptions.map((slValue, index) => (
                      <option key={index} value={slValue}>
                        {slValue}
                      </option>
                  ))}
          </select>
          </div>
            )
          }
          
         
        </div>
        {errorMessage && (
          <div className="d-flex justify-content-center">
            <span style={{ color: "red" }}>{errorMessage}</span>
          </div>
        )}
          { popoverInfo && 
     <div  ref={popupRef} className= 'popdtnInfo'>
      <div className="legendDtnInfo mt-3">
                <span className="GreenDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Online
                </span>

                <span className="RedDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                   Previously authenticated but offline
                </span>

                <span className="YellowDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Authenticated but in WalledGarden
                </span>
               
              </div>
        </div>
      }

        <div className="mt-4  remove-margin-mobile">
          <div className="mob-overflow-scroll">
            <table className="table table-bordered">
              <thead className="thead-lightfdh">
                <tr>    
                  <th style={{ width: "4rem" }} colSpan="4">
                    O2
                  </th>
                  {neisl && (
                    <>
                      <th colSpan="2">NEISL</th>
                    </>
                  )}
                   {
                    enableAuditsplitview == false && 
                    <th colSpan="2">
                    OnField <br></br>
                    <span className="disclaimer">
                      (Only N & numbers are allowed)
                    </span>
                  </th>
                    }
                     {
                    enableAuditsplitview == true && 
                  <th colSpan="1">
                    OnField <br></br>
                    <span className="disclaimer">
                      (Only N & numbers are allowed)
                    </span>
                  </th>
                    }   

                  <th style={{ width: "0px" }} rowSpan="2">
                    Comparison
                  </th>
                  <th style={{ width: "0px" }} colSpan="2" rowSpan="2">
                    Address
                  </th>
                  <th style={{ width: "0px" }} rowSpan="2">
                  <div className="dtn-icon-info"> Dtn
                    <FontAwesomeIcon  icon={faCircleInfo} className="fa-icon-circle" onClick={handleDtnInfo}/>
                    </div>
                  </th>
                  <th style={{ width: "0px" }} rowSpan="2">
                    Strand Issue
                  </th>
                  <th style={{ width:"0px"}} rowSpan="2">
                    Reset
                  </th>
                </tr>
                <tr>
                {
                    enableAuditsplitview == false && 
                    <th
                    className="mstportStickhead"
                    style={{ width: "9rem" }}
                    rowSpan="2"
                  >
                    <span className="head-name" />
                  </th>
                  }
                  <th>Splitter</th>
                  <th>Leg</th>
                  {
                    enableAuditsplitview == true && 
                    <th
                    className="mstportStickhead"
                    style={{ width: "9rem" }}
                    rowSpan="2"
                  >
                    <span className="head-name" />
                  </th>
                  }
                  <th>Status</th>
                  {neisl && (
                    <>
                      <th>Splitter</th>
                      <th>Leg</th>
                    </>
                  )}
                   {
                    enableAuditsplitview == true && 
                    <th style={{ width: "9rem" }}>
                      Dist Strand <span style={{ color: "red" }}>*</span>
                  </th>
                  }
                     {
                    enableAuditsplitview == false &&
                    <> 
                  <th style={{ width: "9rem" }}>
                    Splitter <span style={{ color: "red" }}>*</span>
                  </th>
                  <th style={{ width: "9rem" }}>
                    Leg <span style={{ color: "red" }}>*</span>
                  </th>
                  </>
                  }
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  <>
                    {filteredData.map((row, rowIndex) => (
                      <tr key={row.id}>
                        {row.idEdit === true ? (
                          <>
                            <td colspan="1">                            
                              <FontAwesomeIcon
                                onClick={() => handleClick(row.idval, row)}
                                className="trashico"
                                icon={faTrash}
                              ></FontAwesomeIcon>
                              <input
                                className="form-control table-input"
                                id={`id-${row.id}`}
                                defaultValue={row.id || ""}
                                disabled={!enableEdit}
                                //  value={row.id}
                                maxLength={4}
                                onChange={(event) =>
                                  handleInputChangeSplit(
                                    event,
                                    row.id,
                                    row,
                                    rowIndex,
                                    "standid",
                                    "id"
                                  )
                                }
                              ></input>                             
                            </td>
                          </>
                        ) : (
                          <>
                           {
                    enableAuditsplitview == false && 
                            <td
                              colspan="1"
                              className={row.MST ? "mstportStick distStrand_mst ":"mstportStick distStrand_noMst"}
                             
                              onClick={(event) =>
                                handleNavigateMst(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                )
                              }
                              
                            >
                            
                              {row.id}
                              
                            </td>
}
                          </>
                        )}

                        <td colspan="1">{row.splitter_slot}</td>
                        <td colspan="1">{row.splitter_leg}</td>
                        {
                    enableAuditsplitview == false && 
                        <td
                          className={Statusupdate(row.splitter_status)}
                          colspan="1"
                        >
                          {row.splitter_status}
                        </td> }
                        {
                    enableAuditsplitview == true && 
                            <td>{row.id}</td>
                        }
                        {
                    enableAuditsplitview == true && 
                        <td
                          className={Statusupdate(row.splitter_status)}
                          colspan="1"
                        >
                          {row.splitter_status}
                        </td> } 
                        {neisl && (
                          <>
                            <td colspan="1">{row.ndsSplitter}</td>
                            <td colspan="1">{row.ndsSplitterLeg}</td>
                          </>
                        )}
                       {
                    enableAuditsplitview == true &&
                        <td>
                          {isEditing ? (
                            <input  
                              className="form-control table-input"
                              isEditing={isEditing}
                              disabled={!enableEdit}
                              name="ondiststrand"
                              id={`ondiststrand-${rowIndex}`}
                              maxLength={4}
                              defaultValue={row.onfieldStrand || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "f",
                                  "onfieldStrand"
                                )
                              }
                            />
                          ) : (
                            row.onfieldStrand
                          )}
                          
                        </td>
                        }
                           {
                    enableAuditsplitview == false &&
                    <>
                        <td>
                          {isEditing ? (
                            <input
                              
                              className="form-control table-input"
                              isEditing={isEditing}
                              disabled={!enableEdit}
                              name="split"
                              ref={(el) => (inputRefs.current[rowIndex] = el)}
                              id={`split-${rowIndex}`}
                              maxLength={2}
                              defaultValue={row.onField_splitter || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "s",
                                  "onField_splitter"
                                )
                              }
                            />
                          ) : (
                            row.onField_splitter
                          )}
                          
                        </td>
                        <td>
                          {isEditing ? (
                            <input
                              className="form-control table-input"
                              isEditing={isEditing}
                              maxLength={2}
                              disabled={!enableEdit}
                              name="leg"
                              id={`leg-${rowIndex}`}
                              defaultValue={row.onField_splitter_leg || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "l",
                                  "onField_splitter_leg"
                                )
                              }
                            />
                          ) : (
                            row.onField_splitter_leg
                          )}
                          
                        </td>
                        </>
}
                        <td>{row.remark}</td>
                        
                        <td  colSpan="2" >
                          <p className={row.MST_Address ? "mstAddress":""}>{row.MST_Address}</p></td>
                          <td colSpan="1">
                          <div className="dtn-fdh-pop">
                        { (popoverVisible) && (clickedIndex == row.id ) &&
     <div  ref={popupRef} className={dtnauthinfo.SessionStatus == 'O' ? 'popgreen' : dtnauthinfo.SessionStatus == 'C' ? 'popred' :  (dtnauthinfo.SessionStatus == 'O' && dtnauthinfo.WGName !== '') ? 'popyellow' : 'popover-dtn'}>
      { dtnauthinfo.SessionStatus !== '' ? <>
      <div className="d-flex flex-column" style={{'text-align':'left'}}>
        <div>DTN: {dtnauthinfo.dtn} </div>
        <div>Status: {dtnauthinfo.SessionStatus}</div>
        {dtnauthinfo.SessionStatus == 'O' ? (
          <div>StartTime:{dtnauthinfo.StartTime}</div>
        ) :  (
          <div>StopTime:{dtnauthinfo.StopTime}</div>
        )}
        <div>DSLAM:{dtnauthinfo.DSLAMNode}</div>
          <div>WGName: {dtnauthinfo.WGName}</div>
        </div>
    
      
      </>  :<>
      
      <div className={ loadingpop === 'Failed to Authenticate'? 'failed-font':'' }>{loadingpop}</div> 
      </>
      }
       
        
      </div>
      }
                          {Array.isArray(row.DTN) ? (
                            row.DTN.map((item, key) => {
                              return (
                                <>
                                  {item && (
                                    <div className="d-flex dtn-container">
                                      <p
                                        className={ (clickedIndex == row.id ) ? (dtnauthinfo.SessionStatus == 'O' ? 'dtnAddress-green' : dtnauthinfo.SessionStatus == 'C' ? 'dtnAddress-red' :  (dtnauthinfo.SessionStatus == 'O' && dtnauthinfo.WGName !== '') ? 'dtnAddress-yellow' : 'dtnAddress'):'dtnAddress'}
                                        style={{ cursor: "pointer" }}
                                        key={key}
                                        // onClick={() =>
                                        //   // handledtnNavigate(item, key)
                                        //   getdtnauthenticate(item, row)
                                        // }
                                      >
                                        {item}
                                      </p>
                                      <div className="d-flex icon-refresh"  >
                                      <div style={{ width: '10px', textAlign: 'center' }} title="Check Authentication">   <FontAwesomeIcon icon={faCheck} onClick={() =>  getdtnauthenticate(item, row)}  style={{ cursor: "pointer" }}></FontAwesomeIcon></div>
                                      <div style={{ width: '10px', textAlign: 'center' }} title="Recheck Authentication"><FontAwesomeIcon icon={faRotateRight}  style={{ cursor: "pointer" }} onClick={() =>  getdtnauthenticate(item, row)} ></FontAwesomeIcon></div>
                                      <div style={{ width: '10px', textAlign: 'center' }} title="Navigate to ONT"> <FontAwesomeIcon icon={faLocationArrow} style={{ cursor: "pointer" }} onClick={() => navigateOnt(item)}></FontAwesomeIcon></div>
                              </div>
                                    </div>
                                  )}{" "}
                                  <></>
                                </>
                              );
                            })
                          ) : (
                            <>
                            {" "}
                              {row.DTN && (
                               <div className="d-flex dtn-container">
                                <p
                                  style={{ cursor: "pointer" }}
                                  className={ (clickedIndex == row.id ) ? (dtnauthinfo.SessionStatus == 'O' ? 'dtnAddress-green' : dtnauthinfo.SessionStatus == 'C' ? 'dtnAddress-red' :  (dtnauthinfo.SessionStatus == 'O' && dtnauthinfo.WGName !== '') ? 'dtnAddress-yellow' : 'dtnAddress'):'dtnAddress'}
                                  // onClick={() => handledtnNavigate(row.dtn)}
                                  onClick={() => getdtnauthenticate(row.DTN, row)}
                                >
                                  {row.DTN}
                                </p>
                                 <div className="d-flex icon-refresh"  >
                                 <div style={{ width: '10px', textAlign: 'center' }} title="Check Authentication"><FontAwesomeIcon icon={faCheck} onClick={() => getdtnauthenticate(row)}  style={{ cursor: "pointer" }}></FontAwesomeIcon></div>
                                 <div style={{ width: '10px', textAlign: 'center' }} title="Recheck Authentication"><FontAwesomeIcon icon={faRotateRight}  style={{ cursor: "pointer" }} ></FontAwesomeIcon></div>
                                 <div style={{ width: '10px', textAlign: 'center' }} title="Navigate to ONT"><FontAwesomeIcon icon={faLocationArrow} style={{ cursor: "pointer" }} onClick={() => navigateOnt(row.DTN)}></FontAwesomeIcon></div>
                                 </div>
                                 </div>
                              )}
                            </>
                          )}
                          </div>
                        </td>
                          <td colspan="1">
                         {row.fdhStrandIssue}
                          </td>
                          <td><FontAwesomeIcon  className="upload-ico-trash" onClick={() => handleResetdata(rowIndex)} icon={faTrash} ></FontAwesomeIcon></td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="8">
                        <b>Data not available in this page</b>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="paginationfooter mobile-pagination">
            
            {filter.idRange == "" ? (
              <>
                <div className="page">
                  <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                  {Array.from({ length: totalPages + useaddcount }).map(
                    (_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={currentPage === index + 1 ? "active" : ""}
                      >
                        {index + 1}
                      </button>
                    )
                  )}
                  <button
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            
          </div>
          
        </div>
        <div>
          
          
          <div className="add-button add" onClick={toggleDropAdd}>
            + Add
          </div>
          {showDropdown && (
            <div className="dropdown-content">
              <div className="counter">
                <input
                  type="number"
                  value={numRowsToAdd}
                  onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
                />
                <div className="counter-icon" onClick={decrementCounter}>
                  <span>-</span>
                </div>
                <div className="counter-icon" onClick={incrementCounter}>
                  <span>+</span>
                </div>
              </div>
              <div className="dropdown-buttons">
                <button className="but-dark" onClick={addRows}>
                  Add
                </button>
                <button className="but-dark-outline" onClick={toggleDropdown}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default FdhTable;

