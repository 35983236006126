import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalOntoperation.css";

const ModalOntSuccess = ({ close, onDatafromOpti }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  console.log(onDatafromOpti);
  //   const closeFunction = () => {
  //     props.action();
  //   };

  const handleClick = () => {
    // Data you want to send to the parent
    close(false)
  };
  const handleClickitem = () => {
    const dataToSend = {
      value: "",
      status: false,
    };
    onDatafromOpti(dataToSend);
  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modals">
              <div className="d-flex justify-content-end">
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                />
              </div>

              <>
                <div
                  style={{
                    color: "#000",
                    padding: "30px 40px",
                    fontSize: "20px",
                  }}
                >
                  {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/> */}
                  Please correct facilities prior to proceeding
                </div>

                
              </>
            </div>
            <div className="modal-bottom">
              <button className="alert-bottoms" onClick={handleClick}>
                OK
              </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalOntSuccess;
