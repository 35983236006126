// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table Styles */
 
table {
  border-collapse: collapse;
 
  width: 100%;
  text-align: center;
  /* margin-bottom: 20px; */
}
 
th,
td {
  border: 1px solid #CCCCCC!important;
 
  padding: 3px;
 
  text-align: center;
  font-size: 11px;
}
 
th {
  background-color: #E1E1E1!important;
  border: 1px solid #CCCCCC!important;
}
 
.editable {
  background-color: #ffffcc;
}
 
/* Pagination Styles */
 
 
 
/* Search Bar Styles */
 `, "",{"version":3,"sources":["webpack://./src/Editable.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,yBAAyB;;EAEzB,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;;EAEE,mCAAmC;;EAEnC,YAAY;;EAEZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,mCAAmC;AACrC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,sBAAsB;;;;AAItB,sBAAsB","sourcesContent":["/* Table Styles */\n \ntable {\n  border-collapse: collapse;\n \n  width: 100%;\n  text-align: center;\n  /* margin-bottom: 20px; */\n}\n \nth,\ntd {\n  border: 1px solid #CCCCCC!important;\n \n  padding: 3px;\n \n  text-align: center;\n  font-size: 11px;\n}\n \nth {\n  background-color: #E1E1E1!important;\n  border: 1px solid #CCCCCC!important;\n}\n \n.editable {\n  background-color: #ffffcc;\n}\n \n/* Pagination Styles */\n \n \n \n/* Search Bar Styles */\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
