import React from "react";
import ReactDOM from "react-dom";
import { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalAuditInfo.css";
import axios from "axios";
const ModalAuditInfo = ({close,auditdata}) => {
   console.log(auditdata,"checkaudit")
  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    close(dataToSend);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal-audit">
                <>
                <div className="m-header">
                <span style={{ color: "#001f6a" }}>
                  <b>Comment Log</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
                 <div className="modalcontent">
                <div className="mob-oveflow-scroll">
                    {auditdata.length > 0 ? (
                         <table>
                         <thead>
                          <tr>
                             <th>CUID</th>
                             <th>NAME</th>
                             <th>TIME STAMP</th>
                             <th>COMMENTS</th>
                          </tr>
                         </thead>
                         <tbody>
                             {auditdata.map((item,index)=>(
                                 <tr key={index}>
                                     <td>{item.cuid}</td>
                                     <td>{item.name}</td>
                                     <td>{item.timeStamp}</td>
                                     <td>{item.comment}</td>
                                 </tr>
                             ))}
                         </tbody>
                       </table>
                    ) : (
                        <div className="d-flex justify-content-center">
                       <p className="mt-2">No Comment Information Found</p>
                        </div>
                    )}

                </div>
              </div>
                </>      
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalAuditInfo;