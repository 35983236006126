// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading animation keyframes */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Loading container */
  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  /* Loading spinner */
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff; /* Change the color to your preferred loading spinner color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Apply the loading animation */
  }
  `, "",{"version":3,"sources":["webpack://./src/Review/css/contentloading.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA,sBAAsB;EACtB;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA,oBAAoB;EACpB;IACE,oCAAoC;IACpC,6BAA6B,EAAE,6DAA6D;IAC5F,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC,EAAE,gCAAgC;EACtE","sourcesContent":["/* Loading animation keyframes */\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  /* Loading container */\n  .loading-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 200px;\n  }\n  \n  /* Loading spinner */\n  .loading-spinner {\n    border: 4px solid rgba(0, 0, 0, 0.3);\n    border-top: 4px solid #007bff; /* Change the color to your preferred loading spinner color */\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite; /* Apply the loading animation */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
