import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalFdhIssue.css";
import { useEffect } from "react";

const ModalFdhIssue = ({addFdhIssue,closemodissue,datacount}) => {
    const [count,setcount]= useState('');
    useEffect(() => {
        setcount(Number(datacount));
      }, [datacount]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      const checkboxValue = value; 
      if (checked) {
        setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
      } else {
        setSelectedCheckboxes(selectedCheckboxes.filter(checkbox => checkbox !== checkboxValue));
      }

      // Pass the selected checkboxes to the App component
    };
 
const handleClick = () =>{
    addFdhIssue(false,selectedCheckboxes);
    console.log("check", selectedCheckboxes)
}
  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    closemodissue(dataToSend);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal">
              <div className="m-header">
                <span>
                  <b>CHOOSE NO LIGHT SPLITTER</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancel}
                />
              </div>
              <div className="modalcontent">
                <div className="checkbox-container">
              {[...Array(count).keys()].map((index) => (
              <div key={index}  className="checkbox-fdh-content">
              <input
               type="checkbox"
               id={`checkbox-name-${index + 1}`}
               value={`Splitter ${index + 1}`}
               onChange={handleCheckboxChange}
               className="checkbox-fdh-content-input"
              />
             <label htmlFor={`checkbox-name-${index + 1}`}>Splitter {index + 1}</label>
            </div>
          ))}
             </div>     
              </div>
              
                <button className="alert-bottom" onClick={handleClick} >
                  ADD
                </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalFdhIssue;
