import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ModalOntoperation.css";
import axios from "axios";
import ModalOsgError from "./ModalOsgError";
import { useParams } from "react-router-dom";

const ModalOntoperation = ({ close, onDatafromOpti,discoverOnt, dataForRecord, enableEditactive  }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  
  const handleClick = () => {
    if(selectedValue === "Swap existing ONT" || selectedValue === "Activate New ONT"){
      discoverOnt(selectedValue)
    }else if(selectedValue === "Record Update Only"){
      close(false)
      dataForRecord();
    }else{
      close(false)
    }
  };
  const handleClickitem = () => {
    const dataToSend = {
      value: "",
      status: false,
    };
    onDatafromOpti(dataToSend);
  };

  const [osgErrorMsg, setOsgErrorMsg] = useState();
  const [showerrorOsg, setErrorOsg] = useState(false);
  const handleosgClose = () => {
    setErrorOsg(false);
  };
  
 

  return (

    <>
    {showerrorOsg && (
        <ModalOsgError close={handleosgClose} errormsg={osgErrorMsg} />
      )}
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modals">
              <div className="d-flex justify-content-end">
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: "#001f6a",
                    height: "25px",
                    width: "25px",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                />
              </div>

              <>
                <div
                  style={{
                    color: "#000",
                    padding: "30px 40px",
                    fontSize: "20px",
                  }}
                >
                  {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/> */}
                  Please Select the Action
                </div>

                <div className="radio-container">
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="Record Update Only"
                      checked={selectedValue === "Record Update Only"}
                      onChange={handleRadioChange}
                    />
                    <span className="radio-value">Record Update Only</span>
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="Activate New ONT"
                      disabled={!enableEditactive}
                      checked={selectedValue === "Activate New ONT"}
                      onChange={handleRadioChange}
                    />
                    <span className="radio-value">Activate New ONT</span>
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="Swap existing ONT"
                      checked={selectedValue === "Swap existing ONT"}
                      disabled={!enableEditactive}
                      onChange={handleRadioChange}
                    />
                    <span className="radio-value">Swap existing ONT</span>
                  </label>
                </div>
              </>
            </div>
            <div className="modal-bottom">
              <button className="alert-bottoms" onClick={handleClick}>
                OK
              </button>
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalOntoperation;