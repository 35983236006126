import React, { useEffect, useReducer, useRef, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./App.css";
import "./fdhtable.css";
import "./Editable.css";
import "./msttable.css";
import "./popover.css";
import "./directactivation.css";
import "./oltorders.css";

import "./Loader/Loader";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ModalSuccess from "./ModalWhite/Modalsuccess";
import modalOnt from "./ModalWhite/modalOnt";
import ModalOnt from "./ModalWhite/modalOnt";
import ModalOntError from "./ModalWhite/ModalOntError";
import { faCheck, faCircleInfo, faEye, faPen, faRotateRight, faSquareCheck, faUpload } from "@fortawesome/free-solid-svg-icons";
import { API_KEY, BASE_API_URL } from "./environment";
import ActivationFlow from "./activationflow.json";
import CalixStep from "./calixprogress.json";
import AdtronStep from "./adtranprogress.json";

import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faSearch,
  faDotCircle,
  faCircle,
  faFilter,
  faNewspaper,
  faPlus,
  faMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import ModalBlack from "./ModalBlack/Modal";
import ModalWhite from "./ModalWhite/Modal";
import Modalerror from "./ModalError/ErrorModal";
import Modalconfirm from "./ModalConfirm/ConfirmModal";
import Modalviewaddress from "./ModalWhite/Modalviewadd";
import ModalAddressOnt from "./ModalWhite/ModalAddressOnt";
import { FaCircle, FaCommentsDollar } from "react-icons/fa";
import Loader from "./Loader/Loader";
import ModalOsgError from "./ModalWhite/ModalOsgError";
import ModalFacilityInfo from "./ModalWhite/ModalFaciltyInfo";
import ModalsuccessOsg from "./ModalWhite/ModalsuccessOsg";
import ModalImageUpload from "./ModalWhite/ModalImage";
import { headersImage } from "./headers";
import ModalOntoperation from "./ModalWhite/ModalOntoperation";
import ModalOntSuccess from "./ModalWhite/ModalOntSuccess";
import StatusDialog from "./StatusPop/statusPop";
import { Card } from "@mui/material";
import StepProgressBar from "./StepProgress/step-progressbar";
import Success from "./SuccessPopup/success";
import ModalImageUploadPort from "./ModalWhite/ModalImageport";
import Modalsplitterchange from "./ModalWhite/Modalsplitterchange";
import ModalNoLightYes from "./ModalWhite/ModalNoLightYes";
const PAGE_SIZE = 25; //

const initialState = [];
var isSpliterMatching = false;
var isLegMatching = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_ENTRY":
      // const existingEntry = state.find(
      //   (entry) =>
      //     entry.distributionCableStrand ===
      //     action.payload.distributionCableStrand
      // );
      // console.log(existingEntry)
      // console.log(state)
      // if (existingEntry) {
      //   // Update the existing person with the new data
      //   return state.map((entry) =>
      //     entry.id === action.payload.id ? action.payload : entry
      //   );
      // } else {
      // Add the new person to the state
      //console.log(state)
      console.log(action.payload);
      const logger = [...state, action.payload];
      console.log(logger);
      // const existingEntry = state.find(
      //   (entry) =>
      //     entry.mstPort ===
      //     action.payload.mstPort
      // );
      const entryIndex = state.findIndex((entry) => {
        const ent = entry.staticId ? entry.staticId : entry.id;
        const stats = action.payload.staticId
          ? action.payload.staticId
          : action.payload.id;
        if (ent === stats) {
          return entry;
        }
      });
      // old
      // const entryIndex = state.findIndex(
      //   (entry) =>
      //     entry.mstPort ===
      //     action.payload.mstPort
      // );
      // console.log(existingEntry);
      console.log(entryIndex);
      // if (existingEntry) {
      //   return [...]
      // }
      if (entryIndex !== -1) {
        // Update the user object using the spread operator
        return [
          ...state.slice(0, entryIndex),
          action.payload,
          ...state.slice(entryIndex + 1),
        ];
      } else {
        return [...state, action.payload];
      }
    // }

    case "EMPTY":
      return initialState;
    case "EDIT_ENTRY_LEG":
      return state.map((entry) =>
        entry.id === action.payload.id ? { ...entry, ...action.payload } : entry
      );
    case "DELETE_ITEM":
      const itemId = action.payload;
      console.log(itemId);
      console.log(state);
      const updatedItems = state.filter((item) => item.staticId !== itemId);
      return updatedItems;
    default:
      return state;
  }
};

function Onttable({
  sendsplitteroff,
  passfdhname,
  passcablename,
  passmstname,
  passontname,
  passontserial,
  passmodelnumber,
  passontdata,
  splitter,
  passStatus,
  onPagesaveData,
  onPageStatus,
  onPageReviewtime,
  serialontname,
  selectedModel,
}) {
 
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
  headers: {
    "apikey": process.env.REACT_APP_API_KEY,
    "sessionId" : sessionId,
    "encryptedId":encryptedId
  }
}
  // var mergedData = [];
  // const [data, setData] = useState([]);
  // const [O2data, setO2Data] = useState([]);
  var resultArrayNds;
  var resultData;
  var resultArrayo2;
  const facilitylist = [ {
    "OLT PORT" : "",
    "FEEDER,STRAND" : "",
    "FDH ID" : "",
    "SPLITTER,LEG" : "",
    "FDH ADDRESS" : "",
    "DISTRIBUTION STRAND" : "",
    "MST, TERMINAL PORT" : "",
    "ONT" : ""
  } ]
  console.log(BASE_API_URL, "sss");
  const [isHovered, setIsHovered] = useState(false);
  const [addressIndex, setAddressIndex] = useState("");
  const[networkSuspend,setNetworkSuspend] = useState("");
  const [savedata, dispatch] = useReducer(reducer, initialState);
  const [overallData, setoverallData] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [options, setOptions] = useState([]);
  const [fdhnames, setfdhnames] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [showNoLightYes, setShowNoLightYes] = useState(false);
  const [mstnames, setmstnames] = useState("");
  const[apiData,setApiData] = useState();
  const [originalValueSplit, setOriginSplitval] = useState("");
  const [originalValueLeg, setOriginLegval] = useState("");
  const [popoverInfo, setPopoverInfo] = useState(false);
  const [mstwise, setmstwise] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rejectOps, setRejectOps] = useState(false);
  var [neisl, setNeisl] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const[resourceData,setResourceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [successosg, setsuccessOsg] = useState(false);
  const [totalnumberofItems, setTotalNumber] = useState([]);
  const [status, setCheckStatus] = useState("Pending");
 const popupRef = useRef(null);
  const [isDiscovering, setIsDiscovering] = useState(false);
  const [isDiscovered, setIsDiscovered] = useState(false);
  const [discoveredData, setDiscoveredData] = useState(null);
  const [discoveredErrorMessage, setDiscoveredErrorMessage] = useState("");
  const [discoverErrorPopup, setDiscoverErrorPopup] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [dialogHead, setDialogHead] = useState(null);
  const [activationProgress, setActivationProgress] = useState("Activate");
  let globalIndexOffset = 0;
  var [imageBase64, setImageBase64] = useState("");
  const [imageportId, setImageportId] = useState('');
  const [showblackmodal, setBlackmod] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverNOpen, setIsPopoverNOpen] = useState(false);
  const [showwhitemodal, setWhitemod] = useState(false);
  const [showsuccModal, setsuccModal] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  var [audittypeval, setAudittype] = useState("static");
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [successInpop, setSuccessInpop] = useState("");
  const [showmodalconfirm, setShowmodalconfirm] = useState(false);
  const [showmodaladdress, setShowmodalAddress] = useState(false);
  const [showmodalsplitter,setShowmodaSplitter] = useState(false);
  const [showfacipopup, setShowFacipopup] = useState(false);
  const [showSuccessOk, setShowSuccessOk] = useState(false);
  var [isChecked, setIsChecked] = useState(false);
  var [splittervalue, setSplittervalue] = useState({});
  var [legvalue, setLegvalue] = useState([]);
  const [showloader, setshowloader] = useState(false);
  const [enablemstlev, setenablemstlev] = useState(false);
  var [arrayrow, setArraysplit] = useState([]);
  var [legarrayrow, setArrayLeg] = useState([]);
  const [showontmodal, setOntmodal] = useState(false);
  const [showOntError, setshowOntError] = useState(false);
  const [indexvalue, setIndexvalue] = useState();
  const [triggerstatus, setTrigger] = useState("default");
  const [showmodalimg, setShowmodalimage] = useState(false);
  const Cuid = sessionStorage.getItem("encryptedKey");
  const UserCuid = sessionStorage.getItem("cuid");
  const [showmodalimgport, setShowmodalimageport] = useState(false);
  // setFilter is changed to setisFilter

  const [isFilter, setisFilter] = useState(false);
  //filter  added this for status filter
  const statusOptions = ["AVAILABLE", "WORKING", "PENDING"];
  //filter  to get the status and range
  const [filter, setFilter] = useState({ idRange: "", splitter_status: "" });
  const navigate = useNavigate();
  var [splitterMatch, setSplitterMatch] = useState("");
  var [splitterLegMatch, setSplitterLegMatch] = useState("");
  var [remark, setRemark] = useState("");
  const [isPageSave, setisPageSave] = useState(false);
  var [legvalue, setLegvalue] = useState("");
  var [compstatus, setCompStatus] = useState(null);
  var [addresslist, setAddressList] = useState([]);
  var [dtnauthinfo, setdtnauthinfo] = useState({
    SessionStatus: "",
    StartTime: "",
    StopTime: "",
    WGName: "",
    DSLAMNode:"",
    status: "",
    dtn: "",
  });
  var [loadingpop, setloadingpop] = useState("Loading...");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { fdhName } = useParams();
  const { dtnName } = useParams();
  const { mstTpName } = useParams();
  const { mstName } = useParams();
  const { Tpport } = useParams();
  const { ontName } = useParams();
  var [failureiss, setfailureiss] = useState("");
  //   let splitArray = fdhName.split(" ");
  var resultString = "";
  const { jobNumber } = useParams();
  const { latitude } = useParams();
  const { longitude } = useParams();
  var [fdhNamevalue, setFDHname] = useState("");
  var [imageList, setImagelist] = useState([]);
  var [loadingPort, setloadingPort] = useState("Select Port");
  var [mstLevelDrop, setMstlevelDrop] = useState("");
  var [overallFacility, setoverallFacility] = useState(facilitylist);
  var [imageURLdata, setImageURL] = useState({});
  var [viewimageupload, setactiveviewimage] = useState(false);
  const role = sessionStorage.getItem("Role");
  const [showmodaladdressUpdate, setShowmodalAddressUpdate] = useState(false);
  // var [roleactive, setRoleactive] = useState(true);
  const [osgErrorMsg, setOsgErrorMsg] = useState();
  const [showerrorOsg, setErrorOsg] = useState(false);
  // var [roleactiveont, setRoleactiveOnt] = useState(true);
  var [checkstatus, setcheckstatus] = useState(false);
  var [checkfilter, setcheckfilter] = useState([]);
  const [showFacility, setShowFacility] = useState(false);
  const [showontoperation, setShowontoperation] = useState(false);
  const [facilitypass, setShowfacilitypop] = useState(false);
  var [enableEdit, setenableEdit] = useState(false);
  var [enableEditactive, setenableEditactive] = useState(false);
  var [viewimageuploadport, setactiveviewimageport] = useState(false);
  var [currentModel, setCurrntModel] = useState("");
  var [transactionval, settransactionval] = useState("");
  var [ontnameitemval, setOntnameitem] = useState("");
const[splitterDn,setSplitterDn]= useState(false);
const[splitterDnDtn,setsplitterDnDtn] = useState("");
var [imageName, setImageName] = useState('');
  var [facilityname, setfacilityname] = useState({
    fdhnameitem: "",
    mstnameitem: "",
    ontnameitem: "",
    ontPortname: "",
    cableNameitem: "",
    modelNumber: "",
  });
  var username = sessionStorage.getItem("Name");
  var ontval = "";
  const [portchangedata, setportChangeData] = useState({});
  var [accessOSG, setAccessOSG] = useState("");
  var [accessONT, setAccessONT] = useState("");
  var [ontNameitem, setontName] = useState("");
  var [rontid, setrontid] = useState("");
  var [cvlan, setcvlan] = useState("");
  var [svlan, setsvlan] = useState("");
  var [oltdevicetypeitem, setoltdeviceitem] = useState("");
  var [technology, settechnology] = useState("");
  var [modalnumberitem, setmodalnumber] = useState("");
 var [productSpeed, setproductSpeed] = useState("");
 var [oltName, setoltName] = useState("");
 var [legNumber, setlegNumber] = useState("");
 var [o2address, seto2address] = useState("");
 var [ethernetport, setethernetport] = useState("");
 var [o2ShelfSlotPort, seto2ShelfSlotPort] = useState("");
 var [oltVendor, setoltVendor] = useState("");
 var [oltPonPort, setoltPonPort] = useState("");
 var [ontheaders, setontheaders] = useState("");
 const [fromsplitterdn,setFromSplitterDn]=useState("");
  var [ontPortfacility, setontFacility] = useState("");
  const [formData, setFormData] = useState({
    comments: "",
  });
  const [errors, setErrors] = useState({
    comments: "",
    drop: "",
  });
  var cuid = sessionStorage.getItem("encryptedKey");

  const facilitypath = (val) => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFacilityE2E?ont=" +
          val ,
        headers
      )
      .then((data) => {
        setoverallFacility(data.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            setoverallFacility([]);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setoverallFacility([]);
            // setShowerror(true);
          }
        } else {
          setoverallFacility([]);
          // setShowerror(true);
        }
      });
  };

  useEffect(() => {
    setCurrntModel(selectedModel);
    setmodalnumber(selectedModel)
  }, [selectedModel]);

  useEffect(()=>{
      getImageUpload(ontnameitemval)
  },[ontnameitemval])

  useEffect(()=>{
    if(splitterDn){
      handlesplitterDN();
    }
  },[splitterDn]);

  useEffect(()=>{
if(splitter){
   handleSplitterChange();
}
  },[splitter])
  useEffect(() => {
    checkaccess();
    // fetchDataO2(); // Fetch initial data
    console.log(fdhName, "as");
    // fetchDataNds();
    dispatch({ type: "EMPTY" });
    setSplittervalue([]);
    setLegvalue([]);
    setoverallData([]);

    console.log(checkfilter);
    fetchDatamst();
    deviceStatus(mstName);
  }, [mstName,viewimageupload,imageList]);
  useEffect(() => {
    if (serialontname) {
      for (let index = 0; index < filteredData.length; index++) {
        const datas = {
          id: filteredData[index].id,
          mst: facilityname.mstnameitem || "",
          fdh: facilityname.fdhnameitem || "",
          ont: facilityname.ontnameitem || "",
          ontPort: filteredData[index].ontPort || "",
          ontName: filteredData[index].ontPortName || "",
          ontStatus: filteredData[index].ontStatus || "",
          portType: filteredData[index].portType || "",
          dtn: filteredData[index].dtn || "",
          customerAddress: filteredData[index].customerAddress || "",
          remarks: filteredData[index].remarks || "",
          portChangeTo: filteredData[index].portChangeTo || "",
          category: filteredData[index].category || "",
          ontSerialNumber: serialontname || "",
          auditStatus:
            filteredData[index].auditStatus == "COMPLETED"
              ? "COMPLETED"
              : "PENDING",
          auditBy: username || "",
          auditTime: null || "",
          transactionId: filteredData[index].dtn == dtnName? transactionval :"",
          staticId: filteredData[index].idval
            ? filteredData[index].idval
            : null || "",

        };
        handleAdd(datas);
      }
    }
  }, [serialontname, transactionval]);
  // useEffect(()=>{
  //   fetchDatamst()

  // },[])

  console.log(serialontname);
  useEffect(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_API_ONT_ACT + "/oauth?grant_type=client_credentials",
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_OAUTH}`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setAccessONT(data.data.access_token);
        var head = {
           headers: {
          Authorization: `Bearer ${data.data.access_token}`,
          "Content-Type": "application/json",
        }
      }
      setontheaders(head);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            // setShowerror(true);
          }
        } else {
          // setShowerror(true);
        }
      });

      axios({
        method: "post",
        url:
          process.env.REACT_APP_BASE_API_SAK_URL +
          "/oauth/token?grant_type=client_credentials",
        headers: {
          Authorization: `Basic dW40dnk5bEV1eE9mSjVKZTBHR0U2RXExU3NkZG9BcUM6Zmx5V0JDTGZEQkJuRG5iYQ==`,
          "Content-Type": "application/json",
        },
      })
        .then((data) => {
          setAccessOSG(data.data.access_token);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
            }
            if (
              error.response.status === 400 ||
              error.response.status === 502 ||
              error.response.status === 500
            ) {
              // setShowerror(true);
            }
          } else {
            // setShowerror(true);
          }
        });
  }, []);

  useEffect(() => {
    if (savedata.length > 0) {
      console.log("working");
      setisPageSave(true);
      onPagesaveData(true);
    } else {
      setisPageSave(false);
      onPagesaveData(false);
    }
  }, [savedata]);

  useEffect(() => {
    const handlebeforeload = (e) => {
      e.preventDefault();
      e.returnValue = "HHSD";
    };
    window.addEventListener("beforeunload", handlebeforeload);

    return () => {
      window.removeEventListener("beforeunload", handlebeforeload);
    };
  }, [savedata]);
  const handlefacilitypop = () => {
    setShowFacility(true);
  };

  const checkaccess = () => {
    switch (role) {
      case "Super Admin": 
      case "Field Activation":
            setenableEditactive(true); 
            setenableEdit(true)
          break;
      case "Facility Support":
      case "Field Technician":
      case "Cable Technician":
      case "Structure Load":
        setenableEdit(true);
        break;
      case "SRO":
      case "View Only":
      case "Field Operations":
        setenableEdit(false);
        break;
      case "Engineer":
        navigate("/");
        break;
    }
  };
  const getAddresslist = () => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAddressLinkedWith?ont=" +
        ontval,
      headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setAddressList(data);
        }
      })
      .catch((error) => {
        if (error) {
          if (error.status === 404) {
            setAddressList([]);
          }
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            //  setShowerror(true)
          }
        } else {
        }
      });
  };

  const deviceStatus = (fdhName) => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        fdhName ,
      headers
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data[0].auditStatus == "COMPLETED") {
            setIsChecked(true);
            onPageReviewtime(data[0].auditTime);
            onPageStatus(data[0].auditStatus);
          } else if (data[0].auditStatus == "PENDING") {
            onPageStatus(data[0].auditStatus);
            onPageReviewtime(data[0].auditTime);
          } else {
          }
          // setHeaderreview(data[0]);
        }
      })
      .catch((error) => {
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 404 ||
            error.status === 500
          ) {
            onPageStatus("Not Audited");
            onPageReviewtime(null);
          }
        } else {
        }
      });
  };

  const handleShow = () => {
    setNeisl(!neisl);
  };

  const fetchDatamst = () => {
    setBlackmod(true);
    var URL_DATA = "/getOntDtls?dtn=" + dtnName;
    if (dtnName == "NA") {
      URL_DATA = "/getOntDtls?mstTp=" + mstTpName + "/" + Tpport;
    }
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL + URL_DATA ,
        headers
      )
      .then((data) => {
        var dataso2 = data;
        let mergedDataMap = {};
        var datas = dataso2.data;
        //var mstWise= dataso2.MST_WISE;
        // setmstwise(mstWise);
        // console.log(datas);
        // resultData = Object.entries(datas).map(([key, value]) => ({
        //   id: key,
        //   ...value[0],
        // }));
        // console.log(resultData);

        // resultData?.forEach((item) => {
        //   mergedDataMap[item.id] = {
        //     ...mergedDataMap[item.id],
        //     ...item,
        //   };
        // });

        let mergedDataArray = Object.values(datas).map((item, index) => ({
          id: index,
          o2mstName: item.mst || null,
          ontName: item.ont || null,
          ontSerialNumber: item.ontSerialNumber || null,
          ontPortName: item.ontName || null,
          ontPort: item.ontPort || null,
          programmingStatus: item.programmingStatus || null,
          dtn: item.dtn || null,
          ontStatus: item.ontStatus || null,
          fdhName: item.fdh || null,
          cableName: item.cableName || null,
          ronta: item.ronta || null,
          technology: item.technology || null,
          oltDeviceType: item.oltDeviceType || null,
          lci: item.lci || null,
          programmingStatus: item.programmingStatus || null,
          orderNumber: item.orderNumber || null,
          portType: item.portType || null,
          customerAddress: item.customerAddress || null,
          portChangeTo: item.portChangeTo || null,
          category: item.category || null,
          remarks: item.remarks || null,
          modelNumber: item.modelNumber || null,
          auditStatus: item.auditStatus || null,
          auditBy: item.auditBy || null,
          auditTime: item.auditTime || null,
          oltName: item.oltName || null,
          productSpeed:item.productSpeed || null,
          legNumber : item.legNumber || null,
          o2ShelfSlotPort : item.o2ShelfSlotPort || null,
          oltVendor: item.oltVendor || null,
          oltPonPort:  item.oltPonPort || null,
          cvlan:  item.cvlan || null,
          svlan:  item.svlan || null,
        }));
        const dtns = mergedDataArray.filter(obj => obj?.dtn && obj?.dtn.trim() !== "").map(obj => obj?.dtn);
        const finaldtn = dtns[0];
        setsplitterDnDtn(finaldtn);
        const dtnresult = mergedDataArray.find(item => (item.dtn) === (dtnName == 'NA' ? finaldtn : dtnName));
        passfdhname(
          dtnresult.fdhName ? dtnresult.fdhName : "NA"
        );
        var checkstatus = mergedDataArray.some(item =>item.ontStatus === "WORKING" || item.ontStatus=== "LEGACY USER");
       
        var passvalue =!checkstatus;
        console.log(passvalue,"passvalue")
        passStatus(passvalue);
        passcablename(
          dtnresult.cableName ? dtnresult.cableName : "NA"
        );
        passmstname(
          dtnresult.o2mstName ? dtnresult.o2mstName : "NA"
        );
        passontname(
          dtnresult.ontName ? dtnresult.ontName : "NA"
        );
        passontserial(
          dtnresult.ontSerialNumber
            ? dtnresult.ontSerialNumber
            : "NA"
        );
        passmodelnumber(
          dtnresult.modelNumber ? dtnresult.modelNumber : "NA"
        );
        setCurrntModel(
          dtnresult.modelNumber ? dtnresult.modelNumber : "NA"
        );
        var o2add = mergedDataArray.find(item => item.dtn == dtnName ? dtnName : finaldtn);
        var ethernet_port = o2add.ontPort.replace(/^0+/, '');
        seto2address(o2add.customerAddress);
        setethernetport(ethernet_port)
        var product_speed =  dtnresult.productSpeed.replace('/','x')
        setproductSpeed(product_speed);
        settechnology(dtnresult.technology? dtnresult.technology:"NA");
        setrontid(dtnresult.ronta? dtnresult.ronta:"NA")
        setmodalnumber(dtnresult.modelNumber? dtnresult.modelNumber: "NA");
        var oltvalue = dtnresult.oltName;
        var oltnameval = oltvalue? oltvalue.replace('ME=', ''): 'NA';
        setoltName(oltnameval);
        setoltPonPort(dtnresult.oltPonPort? dtnresult.oltPonPort: "NA");
        seto2ShelfSlotPort(dtnresult.o2ShelfSlotPort? dtnresult.o2ShelfSlotPort: "NA");
        setoltVendor(dtnresult.oltVendor? dtnresult.oltVendor: "NA");
        setlegNumber(dtnresult.legNumber? dtnresult.legNumber: "NA");
        console.log(dtnresult.cvlan,'cvlan');
          setcvlan(dtnresult.cvlan? dtnresult.cvlan: "NA");
          setsvlan(dtnresult.svlan? dtnresult.svlan: "NA")
        
        passontdata({
          ronta: dtnresult.ronta,
          technology: dtnresult.technology,
          oltDeviceType:dtnresult.oltDeviceType,
        });
        const facilidata = {
          fdhnameitem: dtnresult.fdhName
            ? dtnresult.fdhName
            : "NA",
          mstnameitem: dtnresult.o2mstName
            ? dtnresult.o2mstName
            : "NA",
          ontnameitem: dtnresult.ontName
            ? dtnresult.ontName
            : "NA",
          ontportitem: dtnresult.ontPortName
            ? dtnresult.ontPortName
            : "NA",
          modelNumber: dtnresult.modelNumber
            ? dtnresult.modelNumber
            : "NA",
        };
        ontval = facilidata.ontnameitem;
        if (facilidata.ontportitem) {
          facilitypath(facilidata.ontportitem);
        }
       
        setfacilityname(facilidata);
        // setFDHname(dataso2.data[0].FDH);
        //props.onDataFromChild(mergedDataArray[0].fdhName);
        setBlackmod(false);
        console.log(dtnresult.ontName);
        fetchDataList(dtnresult.ontName);
        if (facilidata.ontnameitem !== "NA") {
          getAddresslist();
          // getImageUpload();
          setOntnameitem(dtnresult.ontName)
        }
        const mergeArrays = () => {
          return mergedDataArray.map(port => {
            const image = imageList.find(img => img.imageName.includes(`TP_${port.ontPort}`));
            return {
              ...port,
              imageName: image ? image.imageName : '',
              imageType: image ? image.imageType : '',
              imageLevel: image ? image.imageLevel : '',
              imageBase64: image ? image.imageBase64 : ''
            };
          });
        };
        var merged = mergeArrays();
        console.log(merged,'coming')
        setoverallData(merged);
        setSplitterDn(true);
        setcheckfilter(mergedDataArray);
      })
      .catch((error) => {
        setBlackmod(false);
        if (error.response) {
          if (error.response.status === 404) {
            setoverallData([]);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });
  };

  const fetchDataList = (ontName) => {
    const ontname = ontName;
    setloadingPort("Loading");
    const response = axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/getOntPortAvailableList?ont=" +
          ontname,
        headers
      )
      .then((response) => {
        setOptions(response.data);
        setloadingPort("Select Port");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            setloadingPort("Not Found");
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            // setShowerror(true);
            setloadingPort("Not found");
          }
        } else {
          setloadingPort("Not Found");
          // setShowerror(true);
        }
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    console.log(newPage);
    console.log(totalPages);
    console.log(filteredData);
  };

  const handleViewMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleAddresspopup = () => {
    setShowmodalAddress(!showmodaladdress);
  };
  // const handleAddAddress = (data) =>{
  //   setShowmodalAddress(false);
  //   filteredData[addressIndex]['MST_ADDRESS'] = data;
  //   console.log(filteredData[addressIndex]['MST_ADDRESS'])
  // }
  const handleAddressClose = (data) => {
    setShowmodalAddress(data);
  };
  const handleSplitterClose = (data) =>{
    setShowmodaSplitter(false);
    sendsplitteroff(false)
  }
  // Dynamic Row addition to the Table
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showDropdown, setShowDropDown] = useState(false);
  const [numRowsToAdd, setNumRowsToAdd] = useState(1);

  const toggleDropdown = () => {
    setShowDropDown(!showDropdown);
  };

  const handleClickOutside = (e) =>{
    if(popupRef.current && !popupRef.current.contains(e.target)){
      setPopoverVisible(false);
      setPopoverInfo(false);
    }
  }

  useEffect(()=>{
    if(popoverVisible){
      document.addEventListener('mousedown',handleClickOutside);
    }
    if(popoverInfo){
      document.addEventListener('mousedown',handleClickOutside);
    }
    return () =>{
      document.removeEventListener('mousedown',handleClickOutside);
    }
  },[popoverVisible,popoverInfo])

  const handleClick = (itemId, value) => {
    console.log(itemId, "id");
    const updatedItems = [...overallData];
    updatedItems.map((item, index) => {
      console.log(item, index);
      if (item.idval === itemId) {
        updatedItems.splice(index, 1);
        setoverallData(updatedItems);
        dispatch({ type: "DELETE_ITEM", payload: itemId });
      }
    });
  };
const handleSplitterChange =()=>{
  setShowmodaSplitter(true);
}
  const toggleDropAdd = () => {
    const lastindex = overallData.length + 1;
    const indexVal = overallData.length - 1;

    var portnameAdd =
      filteredData[indexVal]["ontPortName"].match(/PP=(\d+)/)[1];

    var newPP = parseInt(portnameAdd, 10) + 1;
    var updatename = filteredData[indexVal]["ontPortName"].replace(
      /PP=(\d+)/,
      "PP=00" + newPP
    );
    const newRows = Array.from({ length: 1 }, (_, index) => ({
      ontPort: "00" + lastindex,
      ontPortName: updatename,
      idval: lastindex,
      idEdit: true,
    }));
    setoverallData((prevData) => [...prevData, ...newRows]);
  };

  // const openModal = () => setModalIsOpen(true);
  // const closeModal = () => setModalIsOpen(false);

  const addRows = () => {
    // const lastId = overallData.length > 0 ? overallData[overallData.length - 1 ].id : 0;
    const lastId =
      overallData.length > 0
        ? Math.max(...overallData.map((row) => row.o2MSTerminalport))
        : 0;
    console.log(lastId);
    const newRows = Array.from({ length: numRowsToAdd }, (_, index) => ({
      o2MSTerminalport: lastId + index + 1,
    }));
    console.log(newRows);
    setoverallData((prevData) => [...prevData, ...newRows]);
    // closeModal();
  };

  const incrementCounter = () => {
    setNumRowsToAdd(numRowsToAdd + 1);
  };

  const decrementCounter = () => {
    if (numRowsToAdd > 1) {
      setNumRowsToAdd(numRowsToAdd - 1);
    }
  };

  const listClassNames = {
    AVAILABLE: "class-list1",
    PENDING: "class-list2",
    WORKING: "class-list3",
    "LEGACY USER": "class-list4",
    // "LEGACY WORKING CUSTOMER": "class-list4",
    // "LEGACY AVAILABLE CUSTOMER": "class-list4",
    // Add more mappings as needeD
  };

  const Statusupdate = (ontStatus) => {
    return listClassNames[ontStatus] || "";
    // switch (splitter_status) {
    //   case "AVAILABLE";
    //    return {borderLeft:'5px solid #03B94C',paddingleft:'8px'};
    //   case "pending":
    //     return { borderLeft:'5px solid #FA8836',paddingleft:'8px' };
    //   case "working":
    //     return { borderLeft:'5px solid #CACACA',paddingleft:'8px' };
    //   default;
    //     return {}; // Default color when status doesn't match any case
    // }
  };

  const getdtnauthenticate = (row) => {
    setloadingpop("Loading...");
    setdtnauthinfo({
      SessionStatus: "",
      StartTime: "",
      StopTime: "",
      WGName: "",
      status: "",
      DSLAMNode:"",
      dtn: "",
    });
    var index = row.id;
    if (clickedIndex === null || clickedIndex !== index) {
      setPopoverVisible(true);
      fetch(
        process.env.REACT_APP_BASE_API_URL +
          "/lars/authentication/" +
          row.dtn +
          "?",
        headers
      )
        .then((response) => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setdtnauthinfo(data);
            setdtnauthinfo((prevData) => ({ ...prevData, dtn: row.dtn }));
          }
        })
        .catch((error) => {
          if (error) {
            if (error.status === 404 || error.status === 400) {
              const data = {
                SessionStatus: "",
                StartTime: "",
                StopTime: "",
                WGName: "",
                DSLAMNode:"",
                status: "failed",
                dtn: "",
              };
              setdtnauthinfo(data);
              setloadingpop("Failed to Authenticate");
            }
            if (error.status === 502 || error.status === 500) {
              setShowerror(true);
            }
          } else {
          }
        });

      setClickedIndex(index);
      // setPopoverVisible(false);
    } else {
      setPopoverVisible(false);
    }
  };
  const handleenablePop = () => {
    setShowFacility(true);
    setShowfacilitypop(true);
  };
  const handlesaveitem = async () => {
    console.log(compstatus, "hhhj");
    var totalcount = 0;
    var state = false;
    // if (Object.values(newErrors).every((error) => error === "")) {
    for (let index = 0; index < filteredData.length; index++) {
      const element = filteredData[index];
      if (
        element.ontPortName == "" ||
        element.ontPortName == null ||
        element.ontPort == "" ||
        element.ontPort == null
      ) {
        setIsPopoverNOpen(true);
        totalcount++;
        state = true;
        break;
      }
      // }?
      // setErrors(newErrors);
    }
    if (state == false) {
      var auditstatus;
      if (isChecked === true) {
        auditstatus = "Completed";
      } else {
        auditstatus = "Pending";
      }
      setshowloader(true);
      const valuedata = [null];
      try {
        const responseone = await axios.post(
          process.env.REACT_APP_BASE_API_URL +
            "/postOntAuditDetails?" ,
          savedata,
          headers
        );

        var datas = responseone.data;

        if (datas == "Success") {
          setshowloader(false);
          setsuccModal(true);
          setIsChecked(false);
          // deviceStatus(mstName);
          var list = overallData.map((item) => {
            console.log(item);
            if (item && item.idEdit) {
              item.idEdit = false;
            }
            return item;
          });
          setoverallData(list);
          dispatch({ type: "EMPTY", payload: [] });
          console.log(savedata);
        } else {
        }
        console.log(datas);
      } catch (error) {
        console.error("Error fetching data:", error);
        setshowloader(false);
        if (error.response) {
          if (error.response.status === 400) {
            setIsPopoverOpen(true);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            // setShowerror(true);
          }
        } else {
          // setShowerror(true);
        }
      }
    }
  };

  // const handleFilter = async () => {
  //   setisFilter(!isFilter);
  // };
  // const handleSubcolumnChange = (
  //   rowIndex,
  //   columnName,
  //   subcolumnName,
  //   value
  // ) => {
  //   setData((prevData) => {
  //     const newData = [...prevData];
  //     newData[rowIndex][columnName][subcolumnName] = value;
  //     return newData;
  //   });
  // };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchTerm);
    setCurrentPage(1); // Reset to the first page when searching
  };

  // console.log(filteredData, "nds dasssssnbh");
  console.log(overallData);
  var ss = overallData.length;
  console.log(ss);

  const totalPages = Math.ceil(overallData.length / PAGE_SIZE);

  const visibleData = overallData.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );
  var splitterArray;
  var legArray;
  var originalSplitval;
  var originalLegval;
  // useEffect(() => {
  //   splitterArray = Object.entries(splittervalue).map(([id, data]) => ({
  //     id: parseInt(id),
  //     ...data,
  //   }));
  //   // console.log(splitterArray);
  //   // var itemsp = arrayrow.splitter || "NA";
  //   // var splitterO2 = itemsp.replace(/^0+/, "");
  //   // var itempsleg = arrayrow.splitter_leg || "NA";
  //   // console.log(splitterO2);
  //   // var splitterLegO2 = itempsleg.replace(/^0+/, "");
  //   // console.log(arrayrow.id);
  //   const splitfilter = splitterArray.filter((item) => item.id == arrayrow.id);
  //   const splitValue = splitfilter.length > 0 ? splitfilter[0].split : null;
  //   var originalSplitval = splitValue;
  //   setOriginSplitval(originalSplitval);
  //   // console.log(splitValue, originalSplitval);
  //   // // var splitterfield = originalSplitval.replace(/^0+/, "");
  //   // console.log(splitterLegO2);
  //   // console.log(arrayrow);

  //   const data = {
  //     onfieldSplitter: originalValueSplit,
  //     remark: remark,
  //   };
  //   console.log(data);
  //   dispatch({ type: "EDIT_ENTRY_SPLITER", payload: data });
  // }, [splittervalue, arrayrow]);

  // useEffect(() => {
  //   legArray = Object.entries(legvalue).map(([id, data]) => ({
  //     id: parseInt(id),
  //     ...data,
  //   }));
  //   const data = {
  //     onfieldSplitterLeg: originalValueLeg,
  //   };
  //   dispatch({ type: "EDIT_ENTRY_LEG", payload: data });
  // }, [legvalue, legarrayrow]);

  const handleInputChangeSplit = (
    event,
    rowIndex,
    row,
    index,
    from,
    column
  ) => {
    const { name, value } = event.target;
    var status = false;
    setErrorMessage("");
    switch (from) {
      case "port":
        if (value !== "0") {
          setOntmodal(true);
          setportChangeData(row);
          setIndexvalue(index);
          filteredData[index][column] = event.target.value;
        }
        break;
      case "cat":
        filteredData[index][column] = event.target.value;
        break;
      case "customerAddress":
        filteredData[index][column] = event.target.value;
        break;
      case "remark":
        filteredData[index][column] = event.target.value;
        break;
      case "standid":
        // filteredData[index][column] = event.target.value;
        const patternnumber = /^[0-9]*$/;
        if (!patternnumber.test(event.target.value)) {
          document.getElementById("id-" + index).value =
            filteredData[index][column];
          setErrorMessage(
            "Please Enter only Numbers, Special characters and Alphanumeric not allowed"
          );
        } else {
          filteredData[index][column] = event.target.value;
          document.getElementById("id-" + index).value = event.target.value;
        }

        break;
      case "ontportstandid":
        filteredData[index][column] = event.target.value;
        break;
      // case "auditStatus":
      //   if(event.target.value === null ||
      //      event.target.value === 'PENDING' ||  event.target.value === ""){
      //       filteredData[index][column] = 'COMPLETED';
      //   }
      //   if(event.target.value === 'COMPLETED'){
      //     filteredData[index][column] = 'PENDING';
      //   }
      //   break;

      default:
        break;
    }

    const data = {
      id: row.id,
      mst: facilityname.mstnameitem || "",
      fdh: facilityname.fdhnameitem || "",
      ont: facilityname.ontnameitem || "",
      ontPort: row.ontPort || "",
      ontName: row.ontPortName || "",
      ontStatus: row.ontStatus || "",
      portType: row.portType || "",
      dtn: row.dtn || "",
      customerAddress: row.customerAddress || "",
      remarks: row.remarks || "",
      portChangeTo: row.portChangeTo || "",
      category: row.category || "",
      auditStatus: "PENDING",
      auditBy: username || "",
      auditTime: null || "",
      staticId: row.idval ? row.idval : null || "",
    };
    console.log(data);
    handleAdd(data);
  };
  //mstwise issue save
  const handlemstwise = (event) => {
    const newErrors = {
      start: "",
      stop: "",
      comments: "",
    };
    setErrors(newErrors);
    const setvalue = event.target.value;
    setmstwise(setvalue);
  };
  const handleAdd = (data) => {
    dispatch({ type: "ADD_ENTRY", payload: data });
  };

  const handleDataFromChild = (data) => {
    setWhitemod(false);

    isChecked = data;
    setIsChecked(isChecked);

    // handleSave();
    console.log(isChecked);
  };
  const handlesplitterDN = () => {
    const dtn = dtnName !== "NA" ? dtnName : splitterDnDtn;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessOSG}`,
    };
    const optionsget = {
      headers: headers,
    };
      axios.get(
        process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/facilities/" + dtn
      , optionsget )
          .then((response) => {
            if (response.status == 204 || response.status == 207 || response.status == 200) {
              var data = response.data;
              var transferable = data[0].fibreInformation[0].transferableDNs;
              var splitterdn = transferable.splitterDN;
               var network = transferable.splitterNetworkDN;
              setNetworkSuspend(network);
              if(splitterdn){
                setFromSplitterDn(splitterdn);
                handlesplitter(splitterdn);
              }
            }
          })
          .catch((error) => {
            if (error) {
              if (error.response.status === 404 || error.response.status === 400) {
                // setShowModalDefective(true);
                // setMessageError('Unable to Complete');
              }
              if (error.response.status === 500) {
                setShowerror(true);
              }
              if (error.response.status === 502) {
                setShowerror(true);
              }
            } 
          });
      
  
  };
  const handlesplitter = (splitterDN)=>{
    axios.get(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/network/" +
          encodeURIComponent(splitterDN)  + "/transfer",
      {
        headers :{
           "Content-Type": "application/json",
            Authorization: `Bearer ${accessOSG}`,
         }
       },
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            const data = response.data;
            setResourceData(data);
          }
        })
        .catch((error) => {
          if (error) {
            if (error.response.status === 404 || error.response.status === 400) {
            setResourceData([]);
            }
            if (error.response.status === 502 || error.response.status === 500) {
              setShowerror(true);
            }
          } else {
          }
        });
  }

  const handleResourceChange = (datas)=>{
    console.log(datas,"ontatbleresouce")
    const dtn = dtnName !== "NA" ? dtnName : splitterDnDtn;
    setshowloader(true);
    const payload={
      toNetworkDN:datas.resourceDNdata,
      circuit:dtn,
      transferReason:datas.reasondata,
    }
    axios.patch
    (  
        process.env.REACT_APP_BASE_API_SAK_URL +
        "/Application/v1/O2/csf/api/v1/network/" + encodeURIComponent(fromsplitterdn) + "/transfer",payload,{
      headers: {
        Authorization: `Bearer ${accessOSG}`,
        "Content-Type": "application/json",
        }},
    )
      .then((response) => {
        if (response.status == 204 || response.status == 207 || response.status == 200) {
          var data = response.data;
          setShowNoLightYes(true);
          setModalMsg("Splitter Assign transfered successfully");
          setTimeout(() => {
            updateportdefect(datas);
          }, 300);
          
          setshowloader(false);

        }
      })
      .catch((error) => {
        setshowloader(false)
        if (error.response) {
          if (error.response.status === 500) {
            setShowNoLightYes(true);
            setModalMsg("Please Contact Facility Support To Facilitate Change.");
           
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 404
          ) {
            setShowNoLightYes(true);
            setModalMsg(error.response.data.message);
          }
        } else {
        }
      });
  }
  const updateportdefect = (datas)=>{

    const data = {
      status: "SUSPENDED",
      description: datas.reasondata,
    };

    axios.put(
      process.env.REACT_APP_BASE_API_SAK_URL +  "/Application/v1/O2/csf/api/v1/resources/" +
       encodeURIComponent(networkSuspend) +
          "?identifierType=NETWORK",
      data,
      {
        headers :{
           "Content-Type": "application/json",
            Authorization: `Bearer ${accessOSG}`,
         }
       },
      )
        .then((response) => {
          if(response.status == 204 || response.status == 207 || response.status == 200){
            setShowNoLightYes(true);
            setModalMsg("Splitter Leg Suspended");
            fetchDatamst();
          }
        })
        .catch((error) => {
          if (error) {
            if (error.response.status === 404 || error.response.status === 400) {
              setShowNoLightYes(true);
              setModalMsg(error.response.data.message);
            }
            if (error.response.status === 502 || error.response.status === 500) {
              
            }
          } else {
          }
        });
  }
  const handleSuccData = (data) => {
    setsuccModal(false);
  };
  const handleauditvalue = (data, isChecked) => {
    console.log(data);
    audittypeval = data;
    setAudittype(data);
    handleDataFromChild(isChecked);
  };

  const handleclosemodal = (data) => {
    setWhitemod(false);
  };

  const handleConfirmData = () => {
    setShowmodalconfirm(false);
  };
  const handleSubmit = () => {
    setWhitemod(true);
  };
  // const handleConfirm = (e)=>{

  //   setIsChecked(!isChecked);
  // console.log(isChecked)

  //   setShowmodalconfirm(true);
  // }

  //mouneesh- MY filter logics and code starts here

  // const handleFilterChange = (event) => {
  //   const { name, value } = event.target;
  //   const numericpattern = /^[0-9-]*$/;
  //   if (numericpattern.test(value)) {
  //     setFilter({ ...filter, [name]: value });
  //   } else {
  //   }
  // };
  const callosgapi = (row) => {
    const lci = row.lci;
    const orderNumber = row.orderNumber;
    const payload = {
      state: "IN-PROGRESS",
    };
    setshowloader(true);
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API_SAK_URL}/Application/v1/O2/csf/api/v1/serviceorder/${orderNumber}/prgrm_ticket?lci=${lci}`,
      headers: {
        Authorization: `Bearer ${accessOSG}`,
        "Content-Type": "application/json",
      },
      data: payload,
    })
      .then((response) => {
        if (response.status === 204) {
          setTrigger("Success");
          setshowloader(false);
          setsuccessOsg(true);
          fetchDatamst();
        }
      })
      .catch((error) => {
        setshowloader(false);
        setOsgErrorMsg(error?.response?.data?.message);
        if (error.response) {
          if (error.response.status === 404) {
            setErrorOsg(true);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setErrorOsg(true);
          }
        } else {
          // setShowerror(true);
        }
      });
  };
  const handleIdFilter = () => {
    let minId, maxId;

    if (filter.idRange.includes("-")) {
      [minId, maxId] = filter.idRange.split("-").map(Number);
    } else {
      minId = maxId = Number(filter.idRange);
    }

    // if (searchTerm.length > 0) {
    //   const filteredData = overallData.filter((item) =>
    //     Object.values(item).some((value) => {
    //       var name = value !== null ? value.toLowerCase() : "";
    //       return name.includes(searchTerm.toLowerCase());
    //     })
    //   );
    //   return filteredData;
    // }
    if (searchTerm.length > 0) {
      const filteredData = overallData.filter((item) =>
        Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const valueString = String(value);
            return valueString.includes(searchTerm);
          }
          return false;
        })
      );
      return filteredData;
    }

    // Check if the ID range input is empty and display full data

    if (filter.idRange === "") {
      // Filter the data based on the selected status

      const filteredData = visibleData.filter(
        (row) =>
          filter.splitter_status === "" ||
          row.splitter_status === filter.splitter_status
      );

      return filteredData;
    }

    // Filter the data based on the ID range and status

    const filteredData = visibleData.filter((row) => {
      const idMatch =
        (Number.isNaN(minId) || row.o2MSTerminalport >= minId) &&
        (Number.isNaN(maxId) || row.o2MSTerminalport <= maxId);

      // const splitterstatusMatch =
      //   filter.splitter_status === "" ||
      //   row.splitter_status === filter.splitter_status;
      // console.log(filter.splitter_status);
      // if (row.splitter_status === filter.splitter_status) {
      //   console.log("gfggfg");
      // }

      const name =
        row.o2MSTerminalport !== null ? row.o2MSTerminalport.toLowerCase() : "";

      // Perform the case-insensitive search
      name.includes(searchTerm.toLowerCase());

      return idMatch;
    });

    return filteredData;
  };

  const filteredData = handleIdFilter();
  const showNoRecordFoundMessage =
    filter.idRange !== "" &&
    filter.splitter_status !== "" &&
    filteredData.length === 0;

  const handleDataFromError = () => {
    setShowerror(false);
  };
  const togglePopover = () => {
    setIsPopoverOpen(false);
    setIsPopoverNOpen(false);
  };
  const handleInputMSTLevel = (e) => {
  };
  const handleSelectDrop = (event) => {
    const newErrors = {
      drop: "",
    };
    setErrors(newErrors);
    setMstlevelDrop(event.target.value);
  };
  const getImageUpload = (name) => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_IMAGE_URL +
          "/get?fileName=" +
          name,
        headersImage
      )
      .then((response) => {
        var promiseResult = response.data;
      const deviceLevel = promiseResult.filter(image => image.imageLevel === 'Device Level');
      if(deviceLevel.length>0){
        setImageName(deviceLevel[0].imageName?deviceLevel[0].imageName:'');
        console.log(response.data);
        var imgdevicerole = deviceLevel.length>0 ? true: false;
      setactiveviewimage(imgdevicerole);
      console.log(deviceLevel,'device');
      console.log(deviceLevel[0].imageBase64,'dd')
      setImageURL(deviceLevel[0].imageBase64?deviceLevel[0].imageBase64:'');
      setImageBase64('data:image/png;base64,' + deviceLevel[0].imageBase64?deviceLevel[0].imageBase64:'')
      }
      var portlevel = response.data;
      var portLevelImages = portlevel.filter(image => image.imageLevel === 'Port Level');
      if(portLevelImages.length >0){
        setImagelist(portLevelImages);
        var imgportrole = portLevelImages.length>0 ? true: false
        setactiveviewimageport(imgportrole);
        console.log(promiseResult,
          'd'
        )  
      }
      
    
      })
      .catch((error) => {
        if (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            setactiveviewimage(false);
          }
        } else {
        }
      });
  };

  const handlePortchange = async () => {
    // setshowloader(true);
    const portnumbers = portchangedata.portChangeTo;
    const portnumberValue = parseInt(portnumbers, 10);
    const valueChange = portchangedata.portType
      ? portchangedata.portType
      : "ETHERNET PORT";
    const porttype = valueChange.split(" ");
    const finalportType = porttype[0];

    const dataport = [
      {
        portName: portchangedata.ontPortName,
        portType: finalportType,
        portNumber: portnumberValue,
        status: portchangedata.ontStatus
          ? portchangedata.ontStatus
          : "AVAILABLE",
      },
    ];
    setshowloader(true);
    console.log(dataport);
    try {
      const responseone = await axios.patch(
        process.env.REACT_APP_BASE_API_URL +
          "/updatePortChange/" +
          portchangedata.dtn +
          "?",
        dataport,
        headers
      );
      console.log(responseone);
      var datas = responseone.data;
      console.log(datas.status);
      if (datas.status == "SUCCESS") {
        // setshowloader(false);
        // filteredData[indexvalue]['auditStatus'] = 'COMPLETED';
        setCompStatus("COMPLETED");
        setshowloader(false);
        const updateDataArray = updateAuditStatusForId(indexvalue, "COMPLETED");
        dispatch({
          type: "ADD_ENTRY",
          payload: updateDataArray[0],
        });
        const delaySave = setTimeout(() => {
          var myitem = document.getElementById("saveItem");
          myitem.click();
        }, 1000);
        // setsuccModal(true);
        fetchDatamst();
      } else {
        setshowloader(false);
        // alert(datas.status)
        setshowOntError(true);
      }
    } catch (error) {
      setshowloader(false);
      if (error.response) {
        if (error.response.status === 400) {
        }
        if (error.response.status === 502 || error.response.status === 500) {
        }
      } else {
      }
    }
  };

  function updateAuditStatusForId(idToUpdate, newAuditStatus) {
    const updateDataArray = savedata.map((data) => {
      if (data.id === idToUpdate) {
        return {
          ...data,
          auditStatus: newAuditStatus,
        };
      }
      return data;
    });
    return updateDataArray;
  }
  //   const handlePortchange = async () =>{
  //     setshowloader(true);
  //     const dataport=[{
  //       portName:portchangedata.ontName,
  //       portType:portchangedata.portType,
  //       portNumber:portchangedata.portChangeTo,
  //       status:portchangedata.ontStatus,
  //     }];
  //     console.log(dataport);
  //     const apiUrl = 'https://api-test1.centurylink.com/oauth/token';
  // const headers = {
  // 'Content-Type': 'application/x-www-form-urlencoded',
  // 'Authorization': 'Authorization: Basic dW40dnk5bEV1eE9mSjVKZTBHR0U2RXExU3NkZG9BcUM6Zmx5V0JDTGZEQkJuRG5iYQ==',
  // };
  // const datas ={
  //   'grant_type':'client_credentials'
  // }
  // axios.post(apiUrl, { headers },data)
  // .then(response => {
  //    console(response.data);

  //   }). catch (error=> {
  //       setshowloader(false);
  //       if (error.response) {
  //         if (error.response.status === 400) {

  //         }
  //         if (
  //           error.response.status === 502 ||
  //           error.response.status === 500
  //         ) {

  //         }
  //       } else {

  //       }
  //     })
  //   }

  const handleDataOnt = () => {
    setOntmodal(false);
    handlePortchange();
  };
  const handleDataOntClose = () => {
    setOntmodal(false);
  };
  const handleOntError = () => {
    setshowOntError(false);
  };
  const handleAddressCloseupdate = (data) => {
    setShowmodalAddressUpdate(data);
  };
  const handleAddAddressUpdate = (data) => {
    setShowmodalAddressUpdate(false);
    filteredData[addressIndex]["customerAddress"] = data;

    const datas = {
      id: filteredData[addressIndex].id,
      mst: facilityname.mstnameitem || "",
      fdh: facilityname.fdhnameitem || "",
      ont: facilityname.ontnameitem || "",
      ontPort: filteredData[addressIndex].ontPort || "",
      ontName: filteredData[addressIndex].ontPortName || "",
      ontStatus: filteredData[addressIndex].ontStatus || "",
      portType: filteredData[addressIndex].portType || "",
      dtn: filteredData[addressIndex].dtn || "",
      customerAddress: filteredData[addressIndex].customerAddress || "",
      remarks: filteredData[addressIndex].remarks || "",
      portChangeTo: filteredData[addressIndex].portChangeTo || "",
      category: filteredData[addressIndex].category || "",
      auditStatus:
        filteredData[addressIndex].auditStatus == "COMPLETED"
          ? "COMPLETED"
          : "PENDING",
      auditBy: username || "",
      auditTime: null || "",
      staticId: filteredData[addressIndex].idval
        ? filteredData[addressIndex].idval
        : null || "",
    };
    handleAdd(datas);
  };
  const handleImageBase = (data) => {
    setImageBase64(data);
  };
  const handleAddresspopupOpen = (rowIndex) => {
    setShowmodalAddressUpdate(true);
    setAddressIndex(rowIndex);
  };
  const handleosgClose = () => {
    setErrorOsg(false);
  };
  const handleclosefacility = () => {
    setShowFacility(false);
    setShowontoperation(false);
    setShowFacipopup(false);
    setRejectOps(false);
    setShowfacilitypop(false);
  };
  const handlegetpassinfo = (data) => {
    if (data == "Yes") {
      setShowFacility(false);
      setShowontoperation(true);
    } else {
      setShowFacility(false);
      setRejectOps(true);
    }
  };
  const handleosgclosesucc = () => {
    setsuccessOsg(false);
  };
  const handleImageShow = () => {
    setShowmodalimage(true);
  };
  const handleImageShowport = (row) => {
    setShowmodalimageport(true);
    setImageportId(row);
    setImageName(row.imageName?row.imageName:'')
  };
  const handleImageClose = () => {
    console.log(setIsChecked);
    setShowmodalimage(false);
    setShowmodalimageport(false);
    setOntnameitem('')
    fetchDatamst();
  };
  const handleImagePass = () => {
    console.log(setIsChecked);
    setShowmodalimage(false);
  };
  
  const randomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };
  const discoverOnt = () => {
    var transacid = randomString(30);
    settransactionval(transacid)
    console.log(transacid,'dsa')
    setShowFacility(false);
    setShowontoperation(false);
    setShowFacipopup(false);
    setRejectOps(false);
    setShowfacilitypop(false);
    var getCurrentEthernetPort = overallData.find(
      (eachData) => eachData.dtn === dtnName
    );
    console.log(
      getCurrentEthernetPort,
      dtnName,
      currentModel,
      serialontname,
      "getCurrentEthernetPort"
    );
    if (
      dtnName &&
      serialontname &&
      dtnName !== "" &&
      serialontname !== "" &&
      currentModel &&
      currentModel.trim() != "" &&
      getCurrentEthernetPort?.ontPort &&
      getCurrentEthernetPort?.ontPort.trim() != "" &&
      dtnName.length === 10
    ) {
      setIsDiscovering(true);
      setIsDiscovered(false);
      console.log('serial', serialontname)
      const data ={
        "dtn":dtnName,
        "fsan":serialontname,
        "ethernet_port":ethernetport,
        "olt_name": oltName,
        "ronta_id":rontid,
        "leg_number": legNumber,
        "o2_shelf_slot_port": o2ShelfSlotPort,
        "olt_technology": technology,
        "olt_vendor": oltVendor,
        "olt_pon_port": oltPonPort,
        "o2_addr":o2address,
        "product_speed": productSpeed,
        "clan": cvlan,
        "vlan":svlan,
        "model": modalnumberitem,
        "tech_cuid":UserCuid,
        "transaction_id": transacid
    }
    const headersont = {
      headers:{

      }
    } 
      axios
        .post( process.env.REACT_APP_BASE_API_ONT_ACT +  '/ServiceAssurance/v1/DiagnosticsADAPT' + '/autodiscover', data, ontheaders)
        .then(async (res) => {
          if (res?.data?.transactionId) {
            const newJson = res.data;
            console.log(res,'res')
            setDiscoveredData(newJson);
            setTimeout(() => {
              checkAutoDiscoverStatus(newJson.transactionId);
            }, 10000);
            return null;
          } else {
            // setConnectivityMsg("No Data Found");
            // setConnectivityStatus(false);
            // setLoaded(true);
            // setGettingSearchData(false);
            setDialogHead("No Data Found!!");
            setDialogType("failed");
            setDiscoveredErrorMessage("Failed To get ONT Details");
            setDiscoverErrorPopup(true);
            setIsDiscovered(false);
            setIsDiscovering(false);
            return null;
          }
        })
        .catch((res) => {
          console.log("Error ", res);
          // setApiError(true);
          // setLoaded(true);
          // setGettingSearchData(false);
          setIsDiscovering(false);
          setIsDiscovered(false);

          setDiscoverErrorPopup(true);
          if (res.code === "ECONNABORTED") {
            setDialogHead("API server timeout");
            setDialogType("Error");
            setDiscoveredErrorMessage("API failed due to server timeout");
          } else {
            setDialogHead("Failed to Discover");
            setDialogType("Error");
            setDiscoveredErrorMessage("Unable to get response from the api");
          }
        });
    } else {
      setIsDiscovered(false);
    }
  };
  const checkAutoDiscoverStatus = async (discoveringData, trycount) => {
    const fatchAutoDiscoverLog = await axios.get(
      process.env.REACT_APP_BASE_API_ONT_ACT +  '/ServiceAssurance/v1/DiagnosticsADAPT' +
      `/showLiveLogs?transaction_id=${discoveringData}&log_type=autodiscover`, ontheaders);
    // console.log("trans", fatchAutoDiscoverLog);

    if (fatchAutoDiscoverLog?.data) {
      if (
        fatchAutoDiscoverLog?.data &&
        fatchAutoDiscoverLog?.data?.status.toLowerCase() === "pending"
      ) {
        setTimeout(() => {
          var tryNo = trycount ? trycount + 1 : 1;
          if (tryNo <= 60) {
            checkAutoDiscoverStatus(discoveringData, tryNo);
          } else {
            setDiscoveredErrorMessage(
              "Timeout!! Please contact Programming Team"
            );
            setDialogHead("Failed to Discover");
            setDialogType("failed");
            setDiscoverErrorPopup(true);
            setIsDiscovering(false);
            setIsDiscovered(false);
          }
        }, 10000);
      } else if (
        fatchAutoDiscoverLog?.data &&
        fatchAutoDiscoverLog?.data?.status.toLowerCase() === "completed" &&
        (!fatchAutoDiscoverLog?.data?.message ||
          fatchAutoDiscoverLog?.data?.message.toLowerCase() === "none")
      ) {
        const newDiscoverData = Object.assign(
          {},
          discoveringData,
          fatchAutoDiscoverLog?.data
        );
        var splitSSF = null;
        var splitCSSF = null;
        if (
          newDiscoverData.shelf_slot_port &&
          newDiscoverData.shelf_slot_port !== "" &&
          newDiscoverData.shelf_slot_port.toUpperCase() !== "NAN" &&
          newDiscoverData.shelf_slot_port.toUpperCase() !== "NONE"
        ) {
          splitSSF = newDiscoverData.shelf_slot_port.split("/");
        }
        if (
          newDiscoverData.configured_shelf_slot_port &&
          newDiscoverData.configured_shelf_slot_port !== "" &&
          newDiscoverData.configured_shelf_slot_port.toUpperCase() !== "NAN" &&
          newDiscoverData.configured_shelf_slot_port.toUpperCase() !== "NONE"
        ) {
          splitCSSF = newDiscoverData.configured_shelf_slot_port.split("/");
        }
        const newJson = {
          ...newDiscoverData,
          activating:
            newDiscoverData?.serialnumber === null &&
            newDiscoverData?.pon_port_number === null
              ? true
              : false,
          activated: false,
          fdh_name_match: "",
          onu_name_match: "",
          fdh_distribution_strand_match: "",
          mst_name_match: "",
          mst_port_match: "",
          splitter_port_match: "",
          actual_fdh_name: null,
          actual_onu_number: null,
          actual_fdh_distribution_strand: null,
          actual_mst_name: null,
          actual_mst_port: null,
          actual_splitter_port: null,
          fdh_name_editing: false,
          onu_name_editing: false,
          fdh_distribution_strand_editing: false,
          mst_name_editing: false,
          mst_port_editing: false,
          splitter_port_editing: false,
          progress: [],
          validation_progress: [],
          serialnumber: newDiscoverData?.serialnumber,
          pon_port_number: newDiscoverData?.pon_port_number,
          final_status: [],
          d_shelf: splitSSF && splitSSF[0] ? splitSSF[0] : null,
          d_slot: splitSSF && splitSSF[1] ? splitSSF[1] : null,
          d_port: splitSSF && splitSSF[2] ? splitSSF[2] : null,
          c_shelf: splitCSSF && splitCSSF[0] ? splitCSSF[0] : null,
          c_slot: splitCSSF && splitCSSF[1] ? splitCSSF[1] : null,
          c_port: splitCSSF && splitCSSF[2] ? splitCSSF[2] : null,
        };
        if (
          newDiscoverData.serialnumber &&
          newDiscoverData.pon_port_number &&
          newDiscoverData.serialnumber.toUpperCase() !== "NULL" &&
          newDiscoverData.pon_port_number.toUpperCase() !== "NULL" &&
          newDiscoverData.serialnumber.toUpperCase() !== "NONE" &&
          newDiscoverData.pon_port_number.toUpperCase() !== "NONE" &&
          newDiscoverData.serialnumber.toUpperCase() !== "NAN" &&
          newDiscoverData.pon_port_number.toUpperCase() !== "NAN" &&
          !isNaN(newDiscoverData.pon_port_number)
        ) {
          setDiscoveredData(newJson);
          setIsDiscovered(true);
          setIsDiscovering(false);
          setDiscoveredErrorMessage("");
        } else if (
          (!newDiscoverData.installation_date &&
            !newDiscoverData.accountstatus__c) ||
          (newDiscoverData.installation_date === "null" &&
            newDiscoverData.accountstatus__c === "null")
        ) {
          setDiscoveredErrorMessage(
            "Couldn't discover Facility details for the entered Serial Number and DTN.Please contact Programming Team"
          );
          setDialogHead("Failed to Discover");
          setDialogType("failed");
          setDiscoverErrorPopup(true);
          setIsDiscovering(false);
          setIsDiscovered(false);
        } else {
          setDiscoveredErrorMessage(
            "Make sure device is powered up or check the end to end fiber connectivity and Please ensure that your light level is within the range of the customer's premises."
          );
          setDialogHead("Failed to Discover");
          setDialogType("failed");
          setDiscoverErrorPopup(true);
          setIsDiscovering(false);
          setIsDiscovered(false);
        }
      } else {
        var message = fatchAutoDiscoverLog?.data?.message;
        if (
          (message && message.toLowerCase().includes("exception")) ||
          !message
        ) {
          message = "Failed to Discover, Please contact the programming team";
        }
        setDialogHead("Failed to Discover");
        setDialogType("failed");
        setDiscoveredErrorMessage(message);
        setDiscoverErrorPopup(true);
        setIsDiscovering(false);
        setIsDiscovered(false);
      }
    }
  };
  const update = (i, index) => {
    setDiscoveredData((prev) => {
      const temp = structuredClone(prev);
      temp[index].progress[i][
        Object.keys(temp[index].progress[i])[0]
      ].checked = true;
      return temp;
    });
  };
  const activateONT = (data) => {
    var step_array = [];
    var validation_step_array = [];
    if (
      data &&
      data.olt_device_type__c &&
      data.olt_device_type__c.toUpperCase().includes("CALIX") &&
      data.technology__c &&
      data.technology__c.toUpperCase().includes("XGS")
    ) {
      step_array = CalixStep.calix3XGS.activation;
      validation_step_array = CalixStep.calix3XGS.validation;
    } else if (
      data &&
      data.olt_device_type__c &&
      data.olt_device_type__c.toUpperCase().includes("ADTRAN") &&
      data.technology__c &&
      data.technology__c.toUpperCase().includes("XGS")
    ) {
      // if (Number(data.configured_port) === Number(data.pon_port_number)) {
      //   if (Number(data.configured_onu) === Number(filterOnu)) {
      //     step_array = AdtronStep.adtran1XGS.activation;
      //     validation_step_array = AdtronStep.adtran1XGS.validation;
      //   } else {
      //     step_array = AdtronStep.adtran9XGS.activation;
      //     validation_step_array = AdtronStep.adtran9XGS.validation;
      //   }
      // } else {
      step_array = AdtronStep.adtran9XGS.activation;
      validation_step_array = AdtronStep.adtran9XGS.validation;
      // }
    } else if (
      data &&
      data.olt_device_type__c &&
      data.olt_device_type__c.toUpperCase().includes("ADTRAN") &&
      data.technology__c &&
      data.technology__c.toUpperCase().includes("GPON")
    ) {
      step_array = [
        {
          flow_name: "OUI_AVAILABLE_FOR_ACTIVATE",
          text: "OUI Available For Activate",
          status: null,
        },
      ];
    } else if (
      data &&
      data.olt_device_type__c &&
      data.olt_device_type__c.toUpperCase().includes("CALIX") &&
      data.technology__c &&
      data.technology__c.toUpperCase().includes("GPON")
    ) {
      step_array = [
        {
          flow_name: "OUI_AVAILABLE_FOR_ACTIVATE",
          text: "OUI Available For Activate",
          status: null,
        },
        {
          flow_name: "ETHERNET_PORT_AVAILABLE_FOR_ACTIVATE",
          text: "Ethernet Port Available For Activate",
          status: null,
        },
      ];
      // if (data.configured_shelf_slot_port) {
      //   step_array = CalixStep.calix2GPON.activation;
      //   validation_step_array = CalixStep.calix2GPON.validation;
      // } else {
      //   step_array = CalixStep.calix5GPON.activation;
      //   validation_step_array = CalixStep.calix3XGS.validation;
      // }
    } else {
      step_array = AdtronStep.adtran9XGS.activation;
      validation_step_array = AdtronStep.adtran9XGS.validation;
    }
    // if (
    //   // currentModel &&
    //   // currentModel.trim() !== "" &&
    //   // selectedPort &&
    //   // selectedPort.trim() !== "" &&
    //   filterOnu &&
    //   !isNaN(Number(filterOnu)) &&
    //   // splitterVMesg === "" &&
    //   splitterPort &&
    //   splitterPort !== "" &&
    //   splitterLeg &&
    //   splitterLeg !== "" &&
    //   // data.fdh_name_match !== "" &&
    //   // (data.fdh_name_match !== "NO"
    //   //   ? !data.fdh_name_editing
    //   //   : !data.fdh_name_editing) &&
    //   data.fdh_distribution_strand_match !== "" &&
    //   (data.fdh_distribution_strand_match !== "NO"
    //     ? !data.fdh_distribution_strand_editing
    //     : !data.fdh_distribution_strand_editing) &&
    //   data.mst_name_match !== "" &&
    //   (data.mst_name_match !== "NO"
    //     ? !data.mst_name_editing
    //     : !data.mst_name_editing) &&
    //   data.mst_port_match !== "" &&
    //   (data.mst_port_match !== "NO"
    //     ? !data.mst_port_editing
    //     : !data.mst_port_editing)
    //   // &&
    //   // data.splitter_port_match !== "" &&
    //   // (data.splitter_port_match !== "NO"
    //   //   ? !data.splitter_port_editing
    //   //   : !data.splitter_port_editing)
    // ) {
    if (activationProgress === "Activate") {
  
      setActivationProgress("Activating...");
      if (!data?.activating) {
        // axios.post().then((res) => {
        //       setTimeout(() => {
        //         getProgressBar(data);
        //         progressApi(data);
        //       }, 15000);
        //     });
        // axios
        //   .post(
        //     process.env.REACT_APP_API_URL +
        //       "/validateFacility?key=AIzaSyCl28L0FMaCKloYGOkYxMTN_ZdfJloXZAA",
        //     {
        //       olt: data.olt_name,
        //       configured_pon_port: data?.olt_port?.slice(-2),
        //       dtn: dtnName,
        //       o2_fdh_name: data.fiber_distribtn_hub_fdh__c,
        //       o2_fdh_distribution_strand: data.fdh_distribution_strand__c,
        //       o2_mst_name: data.multipt_serv_trmnl_mst_name_c,
        //       o2_mst_port: data.multipt_serv_trmnl_custport_c,
        //       o2_splitter_port: data.splitter_port__c,
        //       fdh_name_match: data.fdh_name_match,
        //       fdh_distribution_strand_match: data.fdh_distribution_strand_match,
        //       mst_name_match: data.mst_name_match,
        //       mst_port_match: data.mst_port_match,
        //       splitter_port_match: data.splitter_port_match,
        //       actual_fdh_name: data.actual_fdh_name
        //         ? data.actual_fdh_name
        //         : data.fiber_distribtn_hub_fdh__c,
        //       actual_fdh_distribution_strand:
        //         data.actual_fdh_distribution_strand
        //           ? data.actual_fdh_distribution_strand
        //           : data.fdh_distribution_strand__c,
        //       actual_mst_name: data.actual_mst_name
        //         ? data.actual_mst_name
        //         : data.multipt_serv_trmnl_mst_name_c,
        //       actual_mst_port: data.actual_mst_port
        //         ? data.actual_mst_port
        //         : data.multipt_serv_trmnl_custport_c,
        //       actual_splitter_port: data.actual_splitter_port
        //         ? data.actual_splitter_port
        //         : data.splitter_port__c,
        //       tech_cuid: sessionStorage.getItem("cuId"),
        //       oui: data.ronta_id__c.slice(-2).toString(),
        //       // fdh_splitter_leg: splitterLeg,
        //       // fdh_splitter_port: splitterPort,
        //       auto_discovered_ont: data.ronta_id__c.slice(-3),
        //       serial_number: data.serialnumber,
        //       transaction_id: data.transaction_id,
        //     }
        //   )
        //   .then((res) => {
        //     //     console.log(
        //     //       "Verified successfully ",
        //     //       res,
        //     //       step_array,
        //     //       validation_step_array
        //     //     );
            
        //   })
        //   .then(() => {
            var activationQuery;
            // if (
            //   data &&
            //   data.olt_device_type__c &&
            //   data.olt_device_type__c.toUpperCase().includes("CALIX")
            // ) {
            activationQuery = `&olt_clli=${data.olt_name}&ad_pon_port=${
              data.pon_port_number
            }&o2_onu=${data.leg_number}&dtn=${data.input_dtn}&olt_vendor=${
              data.olt_device_type__c
            }&ad_serialnumber=${data.serialnumber}&inp_onu=${data.ronta_id__c
              .slice(-2)
              .toString()}&tech=${data.technology__c}&o2_shelf_slot_port=${
              data.o2_shelf_slot_port
            }&ad_shelf_slot_port=${data.shelf_slot_port}&ethernet_port=${
              data.input_ethernet_port
            }&transaction_id=${
              data.transaction_id
            }&tech_cuid=${sessionStorage.getItem("cuid")}&olt_make_model=${
              true ? "C6500XK" : currentModel
            }&olt_technology=${data.technology__c}&product_speed=${
              data.ac_product_speed
            }&cvlan=${data.clan}&vlan=${data.vlan}&telnet_ip=${
              data.telnet_ip
            }&eth_undeploy_sts=${data.eth_undeploy_sts}&evc=${data.ac_evc}`;

            // } else if (
            //   data &&
            //   data.olt_device_type__c &&
            //   data.olt_device_type__c.toUpperCase().includes("ADTRAN")
            // ) {
            //   activationQuery = `&OLT_CLLI=${data.olt_clli}&PON_port=${
            //     data.pon_port_number
            //   }&ONU=${data.ronta_id__c.slice(-2)}&wtn=${data.dtn}&olt_type=${
            //     data.olt_device_type__c
            //   }&serialnumber=${data.serialnumber}&inp_onu=${filterOnu}&tech=${
            //     data.technology__c
            //   }&shelf_slot_port=${data.o2_shelf_slot_port}&ethernet_port=${
            //     data.input_ethernet_port
            //   }&transaction_id=${
            //     data.transaction_id
            //   }&tech_cuid=${sessionStorage.getItem(
            //     "cuId"
            //   )}&model=${currentModel}`;
            // }
                          
            const activateddata= {
                "tech_cuid" : UserCuid,
                "olt_clli" : data.olt_name,
                "ad_pon_port" : data.pon_port_number,
                "o2_onu" : data.leg_number,
                "dtn" : data.input_dtn,
                "olt_vendor" : data.olt_device_type__c,
                "ad_serialnumber" : data.serialnumber,
                "inp_onu" : data.ronta_id__c.slice(-2).toString(),
                "olt_technology" : data.technology__c,
                "o2_shelf_slot_port" : data.o2_shelf_slot_port,
                "ad_shelf_slot_port" : data.shelf_slot_port,
                "ethernet_port" : data.input_ethernet_port,
                "olt_make_model" :  currentModel,
                "product_speed" : data.ac_product_speed,
                "cvlan" : data.clan,
                "vlan" : data.vlan,
                "telnet_ip" : data.telnet_ip,
                "eth_undeploy_sts" : data.eth_undeploy_sts,
                "evc" : data.ac_evc,
                "transaction_id" :  data.transaction_id
            
            }

            axios.post(
              process.env.REACT_APP_BASE_API_ONT_ACT +  '/ServiceAssurance/v1/DiagnosticsADAPT' +   '/activate/', activateddata
           ,ontheaders )
          .then((res) => {
            
            setTimeout(() => {
              getProgressBar(data);
              setDiscoveredData((prev) => {
                const temp = prev;
                // temp.progress = step_array;
                // temp.validation_progress = validation_step_array;
                temp.activating = true;
                return temp;
              });
              // progressApi(data);
            }, 15000);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 404) {
              }
              if (
                error.response.status === 404 ||
                error.response.status === 502 ||
                error.response.status === 500
              ) {
                setShowerror(true);
              }
            } else {
            }
          });
      }
    } else {
      console.log("Already Started");
    }
    // }
  };
  const progressApi = (discoveredDetail, tryNumber, tryIndex) => {
    console.log(
      "discoveredDetail",
      discoveredDetail,
      tryNumber,
      tryIndex
    );
    // setActivationCompleted(false);
    // Make the API call here
    axios
      .get(
           process.env.REACT_APP_BASE_API_ONT_ACT +  '/ServiceAssurance/v1/DiagnosticsADAPT' +  `/showLiveLogs?transaction_id=${discoveredData.transaction_id}&log_type=activation`
      , ontheaders)
      .then((datum) => {
        // setApiResponse(data);
        const finalprog = Mergedprogress(
          discoveredDetail.progress,
          datum?.data
        );
        setDiscoveredData((prev) => {
          const temp = structuredClone(prev);
          temp.progress = finalprog;
          return temp;
        });
        if (datum?.data) {
          for (let index = 0; index < finalprog.length; index++) {
            // if (
            //   finalprog[index].flow_name.toUpperCase() ===
            //     "OUI_AVAILABLE_FOR_ACTIVATE" &&
            //   finalprog[index].status &&
            //   discoveredDetail &&
            //   discoveredDetail.olt_device_type__c &&
            //   discoveredDetail.olt_device_type__c
            //     .toUpperCase()
            //     .includes("ADTRAN") &&
            //   discoveredDetail.technology__c &&
            //   discoveredDetail.technology__c.toUpperCase().includes("GPON")
            // ) {
            //   console.log("aaaa");

            //   setTimeout(async () => {
            //     const fatchLiveLog = await axios.get(
            //      `https://ont-programming-api-dev1.adapt.gcp.dev.intranet/showLiveLogs?transaction_id=${discoveredData.transactionId}&log_type=activation      `
            //     );
            //     if (fatchLiveLog?.data) {
            //       checkOccupied(
            //         discoveredDetail,
            //         fatchLiveLog?.data,
            //         fatchLiveLog?.data?.activation_flow
            //       );
            //     }
            //   }, 15000);
            //   break;
            // } else if (
            //   (finalprog[index].flow_name.toUpperCase() ===
            //     "OUI_AVAILABLE_FOR_ACTIVATE" ||
            //     finalprog[index].flow_name.toUpperCase() ===
            //       "ETHERNET_PORT_AVAILABLE_FOR_ACTIVATE") &&
            //   finalprog[0].status &&
            //   finalprog[1].status &&
            //   discoveredDetail &&
            //   discoveredDetail.olt_device_type__c &&
            //   discoveredDetail.olt_device_type__c
            //     .toUpperCase()
            //     .includes("CALIX") &&
            //   discoveredDetail.technology__c &&
            //   discoveredDetail.technology__c.toUpperCase().includes("GPON")
            // ) {
            //   setTimeout(async () => {
            //     const fatchLiveLog = await axios.get(
            //       `https://ont-programming-api-dev1.adapt.gcp.dev.intranet/showLiveLogs?transaction_id=${discoveredData.transactionId}&log_type=activation`
            //     );
            //     if (fatchLiveLog?.data) {
            //       checkOccupiedCalix(
            //         discoveredDetail,
            //         fatchLiveLog?.data?.activation_flow
            //       );
            //     }
            //   }, 10000);
            //   // setTimeout(() => {
            //   //   checkOccupiedCalix(
            //   //     discoveredDetail,
            //   //     datum?.data?.activation_flow
            //   //   );
            //   // }, 10000);
            //   break;
            // } else
             if (finalprog[index].status === null) {
              setTimeout(() => {
                var currentTry = 1;
                var currentTryIndex = index + 1;
                if (tryIndex === index + 1) {
                  currentTry = tryNumber ? tryNumber + 1 : 1;
                }
                console.log(
                  "retyC",
                  tryIndex === index,
                  currentTryIndex,
                  currentTry
                );
                if (currentTry && currentTry > 40) {
                  setDiscoveredErrorMessage(
                    "Activation Encountered a timeout, Please contact the programming team"
                  );
                  setDialogHead("Activation Failed");
                  setDialogType("failed");
                  setDiscoverErrorPopup(true);
                  setActivationProgress("Activation Failed");
                } else {
                  progressApi(discoveredDetail, currentTry, currentTryIndex);
                }
              }, 5000);
              break;
            } else if (
              !finalprog[index].status ||
              (finalprog.length === index + 1 && finalprog[index].status)
            ) {
              if (!finalprog[index].status) {
                // setErrorInpop("Please contact the programming team");
                setSuccessPopUp(false);
                // setActivationCompleted(true);
                setDiscoveredErrorMessage(
                  "Please contact the programming team"
                );
                setDialogHead("Activation Failed");
                setDialogType("failed");
                setDiscoverErrorPopup(true);
                setActivationProgress("Activation Failed");
                if (
                  finalprog[index].flow_name.toUpperCase() ===
                    "OUI_AVAILABLE_FOR_ACTIVATE" ||
                  finalprog[index].flow_name.toUpperCase() ===
                    "CHECK_AVAILABLE_ONT"
                ) {
                  // setErrorInpop(
                  //   "The selected OUI is not Available Please contact the programming team"
                  // );
                  setDiscoveredErrorMessage(
                    "The selected OUI is not Available Please contact the programming team"
                  );
                  setDialogHead("Activation Failed");
                  setDialogType("failed");
                  setDiscoverErrorPopup(true);
                  setActivationProgress("Activate");
                } else if (
                  finalprog[index].flow_name.toUpperCase() ===
                  "ETHERNET_PORT_AVAILABLE_FOR_ACTIVATE"
                ) {
                  // setErrorInpop("");
                  setDiscoveredErrorMessage(
                    "The selected Ethernet port is not Available"
                  );
                  setDialogHead("Activation Failed");
                  setDialogType("failed");
                  setDiscoverErrorPopup(true);
                  setActivationProgress("Activate");
                }
              } else if (
                finalprog.length === index + 1 &&
                finalprog[index].status
              ) {
                startValidationprogress(discoveredDetail);
                setTimeout(() => {
                  setActivationProgress("Activated");
                  // setDiscoveredErrorMessage(
                  //   "The selected Ethernet port is not Available"
                  // );
                  // setDialogHead("Activated Successfully");
                  // setDialogType("success");
                  // setDiscoverErrorPopup(true);
                  // setActivationCompleted(true);
                  setSuccessPopUp(true);
                  // validTimer(60);
                }, 5000);
              }
              break;
            }
          }
        } else {
          setDiscoveredErrorMessage(
            "Failed to activate the ONT.Please contact the programming team"
          );
          setDialogHead("Activation Failed");
          setDialogType("failed");
          setDiscoverErrorPopup(true);
          // setActivationProgress("Activation Failed");
          setSuccessPopUp(false);
          // setActivationCompleted(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const getProgressBar = async (disData, trycount) => {
    const fatchLiveLog = await axios.get(
      process.env.REACT_APP_BASE_API_ONT_ACT +  '/ServiceAssurance/v1/DiagnosticsADAPT' + `/showLiveLogs?transaction_id=${disData.transaction_id}&log_type=activation`
   ,ontheaders );
    if (fatchLiveLog?.data) {
      var liveLog = fatchLiveLog?.data?.activation_flow;
      const ventor = fatchLiveLog?.data?.olt_vendor?.toLowerCase();
      const tech = fatchLiveLog?.data?.olt_technology?.toLowerCase();
      var currentDevicekey = null;
      if (ventor.includes("adtran") && tech.includes("xgs")) {
        if (!liveLog || !liveLog[0]) {
          liveLog = [
            "CREATE_ONT",
            "DEPLOY_ONT",
            "ACTIVATE_ONT",
            "CREATE_ONT_ETHERNET_PORT",
            "DEPLOY_ONT_ETHERNET_PORT",
            "ACTIVATE_ONT_ETHERNET_PORT",
            "FIND_SERVICE_PROFILE",
            "CREATE_SERVICE",
            "DEPLOY_SERVICE",
            "ACTIVATE_SERVICE",
            "CHECK_PROFILE_STATUS",
            "CHECK_MULTIPLE_DTN_STATUS",
            "GET_ONT_STATUS",
            "GET_IP_STATUS",
          ];
        }
        currentDevicekey = "adtranxgs";
      } else if (ventor.includes("calix") && tech.includes("xgs")) {
        if (!liveLog || !liveLog[0]) {
          liveLog = [
            "Create_ONT",
            "Update_EMS_Subscriber",
            "Create_Service",
            "Check_Operational_Status",
          ];
        }
        currentDevicekey = "calixxgs";
      } else if (ventor.includes("adtran") && tech.includes("gpon")) {
        currentDevicekey = "adtrangpon";
      } else if (ventor.includes("calix") && tech.includes("gpon")) {
        currentDevicekey = "calixgpon";
      }
      console.log(liveLog);
      var step_array = [];
      var validation_step_array = [];
      liveLog.map((Astep) => {
        console.log(Astep);
        var stepInLiveLog = ActivationFlow.activation.find(
          (log) =>
            Astep.toLowerCase() === log?.flow_name?.toLowerCase() &&
            currentDevicekey === log?.device
        );
        if (stepInLiveLog) {
          step_array.push(stepInLiveLog);
        }
        console.log("logs1", step_array, stepInLiveLog);

        var stepInLiveLogVal = ActivationFlow.validation.find(
          (log) =>
            Astep.toLowerCase() === log.flow_name.toLowerCase() &&
            currentDevicekey === log?.device
        );
        if (stepInLiveLogVal) {
          validation_step_array.push(stepInLiveLogVal);
        }
        console.log("log2", validation_step_array, stepInLiveLogVal);
      });
      disData.progress = step_array;
      disData.validation_progress = validation_step_array;
    }
    console.log("log2", disData.progress, disData.validation_progress);

    if (step_array && step_array[0]) {
      setDiscoveredData((prev) => {
        const temp = structuredClone(prev);
        temp.progress = step_array;
        temp.validation_progress = validation_step_array;
        return temp;
      });
      progressApi(disData);
    } else if (!trycount || (trycount && trycount < 3)) {
      setTimeout(() => {
        getProgressBar(disData, trycount ? trycount + 1 : 1);
      }, 15000);
    } else {
      setDiscoveredErrorMessage("Please contact the programming team.");
      setDialogHead("Failed to Activate");
      setDialogType("failed");
      setDiscoverErrorPopup(true);
      setActivationProgress("Activation Failed");
    }
  };
  const Mergedprogress = (baseProgress, responseProgress) => {
    var lastValue = baseProgress[baseProgress.length - 1];
    var lastValueResponse = responseProgress[lastValue.key];
    console.log(lastValue, lastValueResponse, "merge");
    var newProgress = [];
    baseProgress.map((prog, index) => {
      if (lastValueResponse) {
        prog.status = true;
        newProgress.push(prog);
      } else {
        if (index > 0 && responseProgress[prog.key]) {
          var lastone = newProgress[index - 1];
          var lastoneResponse = responseProgress[lastone.key];
          console.log(lastone, lastoneResponse, "merge");

          if (lastoneResponse === null) {
            newProgress[index - 1].status = true;
          }
        }
        var respFilter = responseProgress[prog.key];
        prog.status = respFilter;
        newProgress.push(prog);
      }
    });
    return newProgress;
  };
  const startValidationprogress = async (discoverD, retryCount, retryIndex) => {
    const liveLogs = await axios.get(
      process.env.REACT_APP_BASE_API_ONT_ACT +  '/ServiceAssurance/v1/DiagnosticsADAPT' + `/showLiveLogs?transaction_id=${discoverD.transaction_id}&log_type=activation`
  ,ontheaders  );
    if (liveLogs?.data) {
      const finalVprog = Mergedprogress(
        discoveredData.validation_progress,
        liveLogs?.data
      );
      setDiscoveredData((prev) => {
        const temp = structuredClone(prev);
        temp.validation_progress = finalVprog;
        return temp;
      });
      for (let index = 0; index < finalVprog.length; index++) {
        if (finalVprog[index].status === null) {
          // if (
          //   (lastTriggerIndx !== index && index > lastTriggerIndx) ||
          //   !lastTriggerIndx
          // ) {
          //   setLastTriggerIndx(index);
          //   setValidationTimer(60);
          // }
          setTimeout(() => {
            var currentTry = 1;
            var currentTryIndex = index + 1;
            if (retryIndex === index + 1) {
              currentTry = retryCount ? retryCount + 1 : 1;
            }
            if (currentTry && currentTry > 10) {
              console.log("stop");
              setShowSuccessOk(true);
              // setDialogHead("Activated Successfully");
              setSuccessInpop(
                <>
                  <div>
                    If ONT light is &nbsp;
                    <FaCircle style={{ color: "green" }} />
                    &nbsp;
                    <span style={{ color: "green" }}>
                      <b>Green</b>
                    </span>{" "}
                    your activation is successfully completed
                  </div>
                  <div>
                    If ONT light is&nbsp;
                    <FaCircle style={{ color: "blue" }} />
                    &nbsp;
                    <span style={{ color: "Blue" }}>
                      <b>Blue</b>
                    </span>
                    /&nbsp;
                    <FaCircle style={{ color: "Red" }} />
                    &nbsp;
                    <span style={{ color: "Red" }}>
                      <b>Red</b>
                    </span>
                    ,Please contact the programming team
                  </div>
                </>
              );
              // setSuccessInpop(
              //   "Plese contact the Programming team to know the status"
              // );
            } else {
              startValidationprogress(discoverD, currentTry, currentTryIndex);
            }
          }, 10000);
          break;
        } else if (
          !finalVprog[index].status ||
          (finalVprog.length === index + 1 && finalVprog[index].status)
        ) {
          setShowSuccessOk(true);
          if (!finalVprog[index].status) {
            // setValidationTimer(0);
            setSuccessInpop(
              <>
                <div>
                  If ONT light is &nbsp;
                  <FaCircle style={{ color: "green" }} />
                  &nbsp;
                  <span style={{ color: "green" }}>
                    <b>Green</b>
                  </span>{" "}
                  your activation is successfully completed
                </div>
                <div>
                  If ONT light is&nbsp;
                  <FaCircle style={{ color: "blue" }} />
                  &nbsp;
                  <span style={{ color: "Blue" }}>
                    <b>Blue</b>
                  </span>
                  /&nbsp;
                  <FaCircle style={{ color: "Red" }} />
                  &nbsp;
                  <span style={{ color: "Red" }}>
                    <b>Red</b>
                  </span>
                  ,Please contact the programming team
                </div>
              </>
            );
            setShowSuccessOk(true);
            // setSuccessPopUp(true);
          } else if (
            finalVprog.length === index + 1 &&
            finalVprog[index].status
          ) {
            // setValidationTimer(0);
            setShowSuccessOk(true);
          }
          break;
        }
      }
    }
  };
  const checkOccupied = (discoverdData, progressBar, liveLog) => {
    console.log(discoverdData, progressBar, liveLog, "ADTron");
    var step_array = [];
    var validation_step_array = [];
    const occupiedOui = progressBar.find(
      (prog) => prog.flow_name.toUpperCase() === "OUI_AVAILABLE_FOR_ACTIVATE"
    );
    const serialNum = progressBar.find(
      (prog) => prog.flow_name.toUpperCase() === "ACTIVE_SERIAL_NUMBER"
    );
    console.log(
      occupiedOui &&
        occupiedOui.status &&
        discoverdData &&
        discoverdData.configured_shelf_slot_port != null &&
        (Number(discoverdData.c_shelf) !== Number(discoverdData.d_shelf) ||
          Number(discoverdData.c_slot) !== Number(discoverdData.d_slot) ||
          Number(discoverdData.c_port) !== Number(discoverdData.d_port))
    );
    if (
      occupiedOui &&
      occupiedOui.status &&
      discoverdData &&
      discoverdData.configured_shelf_slot_port != null &&
      (Number(discoverdData.c_shelf) !== Number(discoverdData.d_shelf) ||
        Number(discoverdData.c_slot) !== Number(discoverdData.d_slot) ||
        Number(discoverdData.c_port) !== Number(discoverdData.d_port))
    ) {
      setSuccessPopUp(false);
      // setActivationCompleted(true);
      setActivationProgress("Activation Failed");
      // setErrorInpop(
      //   "The selected OUI is not Available Please contact the programming team"
      // );
    } else {
      liveLog.map((Astep) => {
        console.log(Astep);
        var stepInLiveLog = AdtronStep.adtranAllGPON.activation.find(
          (log) => Astep.toLowerCase() === log.flow_name.toLowerCase()
        );
        if (stepInLiveLog) {
          step_array.push(stepInLiveLog);
        }
        console.log(step_array, stepInLiveLog);
        var stepInLiveLogVal = AdtronStep.adtranAllGPON.validation.find(
          (log) => Astep.toLowerCase() === log.flow_name.toLowerCase()
        );
        if (stepInLiveLogVal) {
          validation_step_array.push(stepInLiveLogVal);
        }
        console.log(validation_step_array, stepInLiveLogVal);
      });
    }
    discoverdData.progress = step_array;
    discoverdData.validation_progress = validation_step_array;
    if (step_array && step_array[0]) {
      setDiscoveredData((prev) => {
        const temp = structuredClone(prev);
        temp.progress = step_array;
        temp.validation_progress = validation_step_array;
        return temp;
      });
      progressApi(discoverdData);
    }
  };
  const checkOccupiedCalix = (discoverdData, liveLog) => {
    // console.log('dd',discoverdData,liveLog)
    var step_array = [];
    var validation_step_array = [];
    liveLog.map((Astep) => {
      console.log(Astep);
      var stepInLiveLog = CalixStep.calixAllGPON.activation.find(
        (log) => Astep.toLowerCase() === log.flow_name.toLowerCase()
      );
      if (stepInLiveLog) {
        step_array.push(stepInLiveLog);
      }
      console.log(step_array, stepInLiveLog);

      var stepInLiveLogVal = CalixStep.calixAllGPON.validation.find(
        (log) => Astep.toLowerCase() === log.flow_name.toLowerCase()
      );
      if (stepInLiveLogVal) {
        validation_step_array.push(stepInLiveLogVal);
      }
      console.log(validation_step_array, stepInLiveLogVal);
    });
    discoverdData.progress = step_array;
    discoverdData.validation_progress = validation_step_array;
    // console.log(step_array,validation_step_array)

    if (step_array && step_array[0]) {
      setDiscoveredData((prev) => {
        const temp = structuredClone(prev);
        temp.progress = step_array;
        temp.validation_progress = validation_step_array;
        return temp;
      });
      progressApi(discoverdData);
    }
  };
  const handleDtnInfo = () =>{
    setPopoverInfo(true);
  }
  const handleRecordValue = () =>{
    setshowloader(true);
    axios({
      method: "GET",
      url: ` ${process.env.REACT_APP_BASE_API_SAK_URL}/Application/v1/O2/csf/api/v1/facilities/${dtnName}/ont`,
      headers: {  
        Authorization: `Bearer ${accessOSG}`,
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setshowloader(false);
          setApiData(response.data);
          // setTrigger("Success");  
          // setsuccessOsg(true);
          fetchDatamst();
          onPagesaveData(true);
        }
      })
      .catch((error) => {
        setshowloader(false);
        setOsgErrorMsg(error?.response?.data?.message);
        if (error.response) {
          if (error.response.status === 404) {
            setErrorOsg(true);
          }
          if (
            error.response.status === 400 ||
            error.response.status === 502 ||
            error.response.status === 500
          ) {
            setErrorOsg(true);
          }
        } else {
          // setShowerror(true);
        }
      });
}
const handlemodalcloseyes = () => {
  setShowNoLightYes(false);
};
  return (
    <>
     {isDiscovering && (
        <StatusDialog
          statusDialogHead="Auto Discovering"
          statusType="auditLoading"
          statusDialogMsg="Please Wait, Discovering ONT Details"
        />
      )}
      {discoverErrorPopup && (
        <StatusDialog
          statusDialogHead={dialogHead}
          statusType={dialogType}
          statusDialogMsg={discoveredErrorMessage}
          closeDialog={() => setDiscoverErrorPopup(false)}
        />
      )}
       {showNoLightYes && (
        <ModalNoLightYes
          onDataFromChild={handlemodalcloseyes}
          modalMsg={modalMsg}
        />
      )}
      {successPopUp && (
        <Success
          showSuccessOk={showSuccessOk}
          vs_prog={discoveredData.validation_progress}
          successInpop={successInpop}
          onClose={() => {
            setSuccessPopUp(false);
          }}
        />
      )} 
      {showmodalimg && (
        <ModalImageUpload
          data={filteredData[0].ontName}
          type="ONT"
          mode='audit'
          role={role}
          imagerole={viewimageupload}
          imageurl={imageURLdata}
          onDataFromChild={handleImagePass}
          closemodissue={handleImageClose}
          imgbase64={handleImageBase}
          imageName={imageName}
        />
      )}
       {showmodalimgport && (
        <ModalImageUploadPort
          data={filteredData[0].ontName}
          id={imageportId}
          type='ONT'
          imagerole={viewimageuploadport}
          mode='audit'
          role={role}
          // imageurl={imageURLdata}
          onDataFromChild={handleImagePass}
          closemodissue={handleImageClose}
          imgbase64={handleImageBase}
          imageName={imageName}
        />
      )}
      {showmodaladdressUpdate && (
        <ModalAddressOnt
          closeont={handleAddressCloseupdate}
          addont={handleAddAddressUpdate}
          sendAddressont={handleAddAddressUpdate}
        />
      )}
      {showFacility && (
        <ModalFacilityInfo
          facility={overallFacility}
          facilityconfirm={facilitypass}
          close={handleclosefacility}
          passvalue={handlegetpassinfo}
        />
      )}
      {rejectOps && (
        <ModalOntSuccess
          facility={overallFacility}
          facilityconfirm={facilitypass}
          close={handleclosefacility}
          passvalue={handlegetpassinfo}
        />
      )}
      {showontoperation && (
        <ModalOntoperation
        enableEditactive = {enableEditactive}
        discoverOnt={discoverOnt}
          close={handleclosefacility}
          dataForRecord={handleRecordValue}

        ></ModalOntoperation>
      )}
      {showerrorOsg && (
        <ModalOsgError close={handleosgClose} errormsg={osgErrorMsg} />
      )}
      {successosg && <ModalsuccessOsg onDataFromChild={handleosgclosesucc} />}
      {showOntError && <ModalOntError close={handleOntError} />}
      {showontmodal && (
        <ModalOnt onDataFromChild={handleDataOnt} close={handleDataOntClose} />
      )}
      {showmodaladdress && (
        <Modalviewaddress lstAddress={addresslist} close={handleAddressClose} />
      )}
      {showmodalsplitter && (
        <Modalsplitterchange data={resourceData} close={handleSplitterClose}  submit={handleResourceChange} />
      )}
      {showmodalconfirm && <Modalconfirm onDataFromChild={handleConfirmData} />}
      {showwhitemodal && (
        <ModalWhite audittype={handleauditvalue} closemod={handleclosemodal} />
      )}
      {showblackmodal && <ModalBlack />}
      {showloader && <Loader />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      {showsuccModal && <ModalSuccess onDataFromChild={handleSuccData} />}
      {isPopoverOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please enter data in input field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopoverNOpen && (
        <div className="popover-backdrop">
          <div className="popover">
            <div className="popover-content">
              <p>Please Enter the ONT Port & Port Name in Empty field</p>
              <button onClick={togglePopover} className="popover-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="fdhTable-outer mt-3">
          <div className="row legend-ont">
            {/* <Card>Hai</Card> */}
            {isDiscovered ? (
              <div className="instant_activate olt_order_container">
                <div className="discovered-data-card px-2 pt-1">
                  <div className="discovered-data-headertitle">
                    Discovered On Field
                  </div>
                </div>
                <div className="d-flex-wrap my-2 px-3">
                  <div className="col-3">
                    <div className="discovered-data_lable">
                      <span>Discovered Serial Number</span>{" "}
                    </div>
                    <div className="discovered-data_value">
                      {discoveredData.serialnumber || "-"}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="discovered-data_lable">
                      Discovered PON Port
                    </div>
                    <div className="discovered-data_value">
                      {discoveredData &&
                      discoveredData.pon_port_number &&
                      discoveredData.pon_port_number !== ""
                        ? `XGS PON - ${discoveredData.pon_port_number}`
                        : "-"}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="discovered-data_lable">Shelf/Slot/Port</div>
                    <div className="discovered-data_value">
                      {(discoveredData && discoveredData.shelf_slot_port) ||
                        "-"}
                    </div>
                  </div>

                  <div className="col-4 text-right">
                    <div className="activate_button">
                      <button
                        className={
                          activationProgress === "Activate"
                            ? "view_btn active"
                            : "view_btn"
                        }
                        onClick={() => {
                          activateONT(discoveredData);
                        }}
                      >
                        {activationProgress}
                        {/* Activate */}
                      </button>
                    </div>
                  </div>
                </div>
                {discoveredData.activating && (
                  <div className="card_activating">
                    <div
                      className="activating_progress"
                      style={{
                        flexDirection: "row",
                        justifyContent: "end",
                      }}
                    >
                      {discoveredData?.progress?.length ? (
                        <StepProgressBar
                          totalData={discoveredData?.progress}
                          updateData={update}
                        />
                      ) : (
                        // <p className="error">
                        //   Serial Number is not found for this DTN
                        // </p>
                        <></>
                      )}
                      {/* {data.activated && <p>{activationMsg}</p>} */}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="col-5 mob-col-12 block-in-mobile">
              <div className="legend-content mt-3">
                <span className="Available">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "10px" }}
                  />
                  Available
                </span>

                <span className="Pending">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "10px" }}
                  />
                  Pending
                </span>

                <span className="Working">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "10px" }}
                  />
                  Working
                </span>
                <span className="Legacy">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "10px" }}
                  />
                  Legacy Customer
                </span>
              </div>
            </div>

            <div
              className="col-2 mob-col-6  mob-align-center"
              style={{ "text-align": "right" }}
            >
              <div className="mt-2">
                <button
                  className="but-dark mob-but-fil"
                  onClick={handleImageShow}
                >
                  {viewimageupload == true ? "View Image" : "Upload Image"}
                </button>
              </div>
            </div>
            <div className="col-2 mob-col-6" style={{ "text-align": "right" }}>
              <div className="mt-2">
                {enableEdit && (
                  <button
                  id="saveItemupdate"
                    onClick={handleenablePop}
                    className="but-dark mob-but-fil"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
            <div
              className="col-2 mob-col-6 mob-align-center"
              style={{ "text-align": "right" }}
            >
              <div className="mt-2">
                {enableEdit && (
                  <button
                    id="saveItem"
                    onClick={handlesaveitem}
                    className="but-dark mob-but-fil"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* <div className="row mt-3 justify-content-end">
         
            <div
              className="col-2 mob-col-6 tab-selectad"
              style={{ "text-align": "right" }}
            >
              <select
                className="text-wise "
                onChange={handleSelectDrop}
                style={{ "font-size": "12px", padding: "9px", color: "gray" }}
              >
                <option value="">Select MST Placement</option>
                <option value="Aerial">Aerial</option>
                <option value="Buried">Buried</option>
                <option value="None">No Drop</option>
              </select>
              {errors.drop && <p style={{ color: "red" }}>{errors.drop}</p>}
            </div>
            <div className="col-2 mob-col-6" style={{ "text-align": "right" }}>
              <textarea
                placeholder="Comment"
                name="comments"
                value={formData.comments}
                onChange={handleInputMSTLevel}
                className="text-wise "
              ></textarea>
              {errors.comments && (
                <p style={{ color: "red" }}>{errors.comments}</p>
              )}
            </div>
          </div> */}
        </div>
        {isFilter && (
          <div className="filterCard arrow-top mt-3">
            <div className=" d-flex flex-column">
              <div className="filter-title">Filter By</div>
              <div className="row mt-3">
                <div className="col-lg-9" style={{ "text-align": "left" }}>
                  {/* <div className="">
                    <div className="d-flex flex-column">
                      <div>
                        <span>MST Terminal</span>
                      </div>
                      <div>
                        <input
                          className="form-control select-fdh"
                          type="text"
                          name="idRange"
                          value={filter.idRange}
                          onChange={handleFilterChange}
                          placeholder="1-20"
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 mst-table">
          <div className="d-flex justify-content-around block-in-desktop">
            <div className="Available">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ "padding-right": "10px" }}
              />
              Available
            </div>

            <div className="Pending">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ "padding-right": "10px" }}
              />
              Pending
            </div>

            <div className="Working">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ "padding-right": "10px" }}
              />
              Working
            </div>
          </div>

          {errorMessage && (
            <div className="d-flex justify-content-center pb-2">
              <span style={{ color: "red" }}>{errorMessage}</span>
            </div>
          )}

          <div className=" mst-table">
            <div className="d-flex justify-content-start">
              <div class="form-check m-2 form-switch">
                <input
                  class="form-check-input neisltog view-address"
                  type="checkbox"
                  checked={showmodaladdress}
                  onClick={handleAddresspopup}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  class="form-check-label view-address-label"
                  for="flexSwitchCheckDefault"
                >
                  View Available ONT Addresses
                </label>
              </div>
              <div class="form-check m-2 form-switch">
                <input
                  class="form-check-input neisltog view-address"
                  type="checkbox"
                  checked={showfacipopup}
                  onClick={handlefacilitypop}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  class="form-check-label view-address-label"
                  for="flexSwitchCheckDefault"
                >
                  Facility Information
                </label>
              </div>
              <div class="form-check m-2 form-switch block-in-mobile status-osg">
                <span>C-Completed</span>
              </div>
              <div class="form-check m-2 form-switch block-in-mobile status-osg">
                <span>P-Pending</span>
              </div>
              <div class="form-check m-2 form-switch block-in-mobile status-osg">
                <span>I-Inprogress</span>
              </div>
              <div class="form-check m-2 form-switch block-in-mobile status-osg">
                <span>E-Error</span>
              </div>
              <div class="form-check m-2 form-switch block-in-mobile status-osg">
                <span>N-Not Sent</span>
              </div>
            </div>
          </div>
          { popoverInfo && 
     <div  ref={popupRef} className= ' popupontset'>
      <div className="legendDtnInfo mt-3">
                <span className="GreenDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Online
                </span>

                <span className="RedDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                   Previously authenticated but offline
                </span>

                <span className="YellowDtn">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  Authenticated but in WalledGarden
                </span>
               
              </div>
        </div>
      }
          <div className="mob-overflow-scroll">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="mstportStickhead">ONT Port</th>
                  <th>ONT Port Name</th>
                  <th> 
                    <div className="dtn-icon-info"> Dtn
                    <FontAwesomeIcon  icon={faCircleInfo} className="fa-icon-circle" onClick={handleDtnInfo}/>
                    </div>
                    </th>
                  <th>Status</th>
                  <th>Port Type</th>
                  <th>Address</th>
                  <th>Port Change</th>
                  <th>Category</th>
                  <th>Remarks</th>
                  <th
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      cursor:"pointer",
                    }}
                  >
                    PRG Status
                    {isHovered && (
                      <div
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "#ffffff",
                          color: "black",
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid #000000",
                          whiteSpace: "nowrap",
                          fontSize: "10px",
                          fontWeight:"100",
                          zIndex: 10,
                          marginTop: "15px",
                        }}
                      >
                        Automated Programming Status
                      </div>
                    )}
                  </th>
                  <th>PRG</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  <>
                    {filteredData.map((row, rowIndex) => (
                      <tr key={row.id}>
                        {row.idEdit === true ? (
                          <>
                            <td colspan="1" className="mob-pos tab-pos">
                              <FontAwesomeIcon
                                onClick={() => handleClick(row.idval, row)}
                                className="trashico"
                                icon={faTrash}
                              ></FontAwesomeIcon>
                              <input
                                className="form-control table-input ontportwidth"
                                id={`id-${rowIndex}`}
                                value={row.ontPort}
                                defaultValue={row.ontPort || ""}
                                maxLength={4}
                                disabled={!enableEdit}
                                onChange={(event) =>
                                  handleInputChangeSplit(
                                    event,
                                    row.ontPort,
                                    row,
                                    rowIndex,
                                    "standid",
                                    "ontPort"
                                  )
                                }
                              ></input>
                            </td>
                          </>
                        ) : (
                          <>
                            <td colspan="1" className="mstportStick ">
                              {row.ontPort}
                            </td>
                          </>
                        )}
                        {row.idEdit === true ? (
                          <>
                            <td colspan="1" className="mob-pos tab-pos">
                              <input
                                className="form-control table-ont-input ontportwidth"
                                id={`id-${rowIndex}`}
                                value={row.ontPortName}
                                defaultValue={row.ontPortName || ""}
                                disabled={!enableEdit}
                                onChange={(event) =>
                                  handleInputChangeSplit(
                                    event,
                                    row.ontPortName,
                                    row,
                                    rowIndex,
                                    "ontportstandid",
                                    "ontPortName"
                                  )
                                }
                              ></input>
                            </td>
                          </>
                        ) : (
                          <>
                            <td colspan="1">{row.ontPortName}</td>
                          </>
                        )}
                        <td colspan="1">
                          <div className="d-flex dtn-container">
                          {row.dtn ? (
                            <>
                            <div className="dtn-row">
                              {popoverVisible && clickedIndex == row.id && (
                            <div
                             ref={popupRef}
                              className={
                                (dtnauthinfo.SessionStatus == "O" &&
                                    dtnauthinfo.WGName !== "")
                                  ?  "popyellow-ont"
                                  : dtnauthinfo.SessionStatus == "C"
                                  ? "popred-ont"
                                  : dtnauthinfo.SessionStatus == "O"
                                  ? "popgreen-ont"
                                  : "popover-dtn-ont"
                              }
                            >
                              {dtnauthinfo.SessionStatus !== "" ? (
                                <>
                                  <div
                                    className="d-flex flex-column"
                                    style={{ "text-align": "left" }}
                                  >
                                    <div>DTN: {dtnauthinfo.dtn} </div>
                                    <div>
                                      Status: {dtnauthinfo.SessionStatus}
                                    </div>
                                    {dtnauthinfo.SessionStatus == "O" ? (
                                      <div>
                                        StartTime:{dtnauthinfo.StartTime}
                                      </div>
                                    ) : (
                                      <div>
                                        StartTime:{dtnauthinfo.StopTime}
                                      </div>
                                    )}
                                    <div>DSLAM:{dtnauthinfo.DSLAMNode}</div>
                                    <div>WGName: {dtnauthinfo.WGName}</div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className={
                                      loadingpop === "Failed to Authenticate"
                                        ? "failed-font"
                                        : ""
                                    }
                                  >
                                    {loadingpop}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                              <p
                                className={
                                  clickedIndex == row.id
                                    ?
                                    (dtnauthinfo.SessionStatus == "O" &&
                                        dtnauthinfo.WGName !== "") 
                                      ? "dtnAddress-yellow"
                                      : dtnauthinfo.SessionStatus == "C"
                                      ? "dtnAddress-red"
                                      :dtnauthinfo.SessionStatus == "O"
                                      ? "dtnAddress-green"
                                      : "dtnAddress"
                                    : "dtnAddress"
                                }
                               
                                // onClick={() => getdtnauthenticate(row)}
                              >
                                {row.dtn}
                              </p>
                           
                          
                              </div>
                              <div className="d-flex icon-refresh"  >
                              <div style={{ width: '10px', textAlign: 'center' }} title="Recheck Authentication"><FontAwesomeIcon icon={faRotateRight} onClick={() => getdtnauthenticate(row)} style={{ cursor: "pointer" }} ></FontAwesomeIcon></div>
                              <div style={{ width: '10px', textAlign: 'center' }} title="Check Authentication"><FontAwesomeIcon icon={faCheck} onClick={() => getdtnauthenticate(row)}  style={{ cursor: "pointer" }}></FontAwesomeIcon></div>
                              </div>
                              </>
                              ) : (
                                <></>
                              )}
                          </div>
                        </td>
                        <td className={Statusupdate(row.ontStatus)} colspan="1">
                          {row.ontStatus}
                        </td>
                        <td colspan="1">{row.portType}</td>
                        <td colspan="1">
                           <>{row.customerAddress}</>
                        </td>
                        <td>
                          {isEditing ? (
                            <select
                              className="form-control table-ont-input"
                              name="portChangeTo"
                              id={`portChangeTo-${rowIndex}`}
                              defaultValue={row.portChangeTo || ""}
                              disabled={!enableEdit || row.ontStatus == "WORKING" || row.ontStatus == "AVAILABLE"}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "port",
                                  "portChangeTo"
                                )
                              }
                            >
                              {options.length > 0 ? (
                                <>
                                  <option value="0">{loadingPort}</option>
                                  {options.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <option value="0">{loadingPort}</option>
                                </>
                              )}
                            </select>
                          ) : (
                            row.portChangeTo
                          )}
                        </td>
                        <td className="" style={{ color: "black" }}>
                          {isEditing ? (
                            <select
                              className="form-control table-ont-input"
                              // disabled={roleactive}
                              name="category"
                              id={`category-${rowIndex}`}
                              disabled={!enableEdit}
                              defaultValue={row.category || ""}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "cat",
                                  "category"
                                )
                              }
                            >
                              <option value="">Select</option>
                              <option value="Daisy-Chained">
                                Daisy-Chained
                              </option>
                              <option value="Worker">Worker</option>
                            </select>
                          ) : (
                            row.category
                          )}
                        </td>

                        <td className="" style={{ color: "black" }}>
                          <textarea
                            className="form-control table-ont-input"
                            // disabled={roleactive}
                            name="remarks"
                            id={`remarks-${rowIndex}`}
                            disabled={!enableEdit}
                            defaultValue={row.remarks || ""}
                            onChange={(event) =>
                              handleInputChangeSplit(
                                event,
                                row.id,
                                row,
                                rowIndex,
                                "remark",
                                "remarks"
                              )
                            }
                          />
                        </td>
                        <td>{row.programmingStatus}</td>
                        <td>
                          {enableEdit && (
                            <>
                              {row.programmingStatus == "C" ||
                              row.programmingStatus == "" ||
                              row.ontStatus == "WORKING" ||
                              row.ontStatus == "AVAILABLE" ? (
                                <></>
                              ) : (
                                <button onClick={() => callosgapi(row)}>
                                  Send
                                </button>
                              )}
                            </>
                          )}
                        </td>
                        <td>
                        {row.imageBase64 !== '' ?  
                             <FontAwesomeIcon className="upload-ico" icon={faEye} onClick={()=>{handleImageShowport(row)}} ></FontAwesomeIcon>
                            :
                            <FontAwesomeIcon className="upload-ico" icon={faUpload} onClick={()=>{handleImageShowport(row)}} ></FontAwesomeIcon>
                        }
                        </td>
                        {/* <td>
                          <div class="form-check">
                            <input
                              class="form-check-status-input"
                              type="checkbox"
                              
                              name="auditStatus"
                              id={`auditStatus-${rowIndex}`}
                              checked={row.auditStatus === 'COMPLETED'? true :false}
                              defaultValue={row.auditStatus}
                              onChange={(event) =>
                                handleInputChangeSplit(
                                  event,
                                  row.id,
                                  row,
                                  rowIndex,
                                  "auditStatus",
                                  "auditStatus"
                                )
                              }
                            /><br></br>
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Completed
                            </label>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="14">
                        <b>Data not available</b>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="paginationfooter mobile-pagination">
            {totalPages > 1 && (
              <div className="page">
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            )}

            <div></div>

            <div>
              <span></span>
            </div>
          </div>

          {/* <div className="viewmore">
      {currentPage * PAGE_SIZE < data.length && (
        <button onClick={handleViewMore}  className="view">View More</button>
      )}
      </div> */}
        </div>
        <div>
          <div className="add-button adds" onClick={toggleDropAdd}>
            + Add More
          </div>
          {showDropdown && (
            <div className="dropdown-contents">
              <div className="counter">
                <input
                  type="number"
                  value={numRowsToAdd}
                  onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
                />
                <div className="counter-icon" onClick={decrementCounter}>
                  <span>-</span>
                </div>
                <div className="counter-icon" onClick={incrementCounter}>
                  <span>+</span>
                </div>
              </div>
              <div className="dropdown-buttons">
                <button className="but-dark" onClick={addRows}>
                  Add
                </button>
                <button className="but-dark-outline" onClick={toggleDropdown}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Onttable;