// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaders-overlay {
  position: fixed;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  z-index: 9999;

  background: rgba(0, 0, 0, 0.8);
}

.loaders-container {
  position: absolute;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);

  /* Add other styles as needed */
}

.loaderss {
  border: 5px solid rgba(0, 0, 0, 0.1);

  border-top: 5px solid white;

  border-radius: 50%;

  width: 80px;

  height: 80px;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,eAAe;;EAEf,MAAM;;EAEN,OAAO;;EAEP,WAAW;;EAEX,YAAY;;EAEZ,aAAa;;EAEb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;;EAElB,QAAQ;;EAER,SAAS;;EAET,gCAAgC;;EAEhC,+BAA+B;AACjC;;AAEA;EACE,oCAAoC;;EAEpC,2BAA2B;;EAE3B,kBAAkB;;EAElB,WAAW;;EAEX,YAAY;;EAEZ,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loaders-overlay {\n  position: fixed;\n\n  top: 0;\n\n  left: 0;\n\n  width: 100%;\n\n  height: 100%;\n\n  z-index: 9999;\n\n  background: rgba(0, 0, 0, 0.8);\n}\n\n.loaders-container {\n  position: absolute;\n\n  top: 50%;\n\n  left: 50%;\n\n  transform: translate(-50%, -50%);\n\n  /* Add other styles as needed */\n}\n\n.loaderss {\n  border: 5px solid rgba(0, 0, 0, 0.1);\n\n  border-top: 5px solid white;\n\n  border-radius: 50%;\n\n  width: 80px;\n\n  height: 80px;\n\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
