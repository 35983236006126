const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
export const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
 
export const headersImage = {
    headers: {
      "apikey": process.env.REACT_APP_API_IMAGE_KEY,
    }
}