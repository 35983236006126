import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo,faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import "./ModalRestorePort.css";
import { convertLength } from "@mui/material/styles/cssUtils";

const ModalRestorePort = ({ yespop,casaserial,closepop }) => {
  const handleYes = () => {
    // Data you want to send to the parent
    const dataToSend = true;
    // Call the function received from the parent with the data
    yespop(dataToSend);
  };
  const handleClose = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    console.log(dataToSend)
    // Call the function received from the parent with the data
     closepop(dataToSend);
  };
  console.log(casaserial,"modalserialrestore")



  return (
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerconfirm">
          <div className="modalc-background">
            { (casaserial === "yescasa") ? (
                <>
                      <div className="modal-restore">
                      <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
              <div className="issue-title-restore-fieldops" >
              Close issue from Field Ops review to close corresponding Salesforce case!
              </div>
            </div>
            <div className="modalconfirm-bottom"onClick={handleClose}>




                  <button className="confirm-bottom"onClick={handleClose}>
                    OK
                  </button>


            </div>
               </>
            ) :  (
            <>
            <div className="modal-restore">
            <FontAwesomeIcon icon={faQuestionCircle} className="info-icon" />
              <div className="issue-title-restore" >
              Do you wish to continue?
              </div>
            <div className="modalc-bottom">
              <div className="modalconfirm-bottom">
                <span>
                  <button className="yes-button-restore" onClick={handleYes}>
                    Yes
                  </button>
                </span>
                <span>
                  <button className="no-button-restore" onClick={handleClose}>
                    No
                  </button>
                </span>
              </div>
            </div>
            </div>
            </>
        )}
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ModalRestorePort;