import React from "react";
import { useState, useEffect, useMemo } from "react";
import DatePicker from 'react-datepicker';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import FDHviewHeader from "./FDHviewHeader";
// import MSTdatatab from "./MSTdatatab";
// import MyComponent from "./Mycomponent";
import Modalblack from "../ModalBlack/Modal";
import Modalerror from "../ModalError/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLocationDot ,faCircle} from "@fortawesome/free-solid-svg-icons";
import "./css/MSTview.css";
import "./css/search.css";
import { Modal } from "bootstrap";
// import Contentloading from "./MSTContentloading";
import ContentFop from "./ContentFop";
import {API_KEY, BASE_API_URL } from "../environment";
import axios from "axios";
import { text } from "@fortawesome/fontawesome-svg-core";
import { useActionData } from "react-router-dom";
import FDHdatatab from "./FDHdatatab";
const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
  headers: {
    "apikey": process.env.REACT_APP_API_KEY,
    "sessionId" : sessionId,
    "encryptedId":encryptedId
  }
}
// const fdhinfo = [
//   // "CPCRFLJ0353",
//   // "CPCRFLJ0405",
//   // "CPCRFLJ0391",
//   // "CPCRFLJ0392",
//   // "CPCRFLJ0351",
//   // "CPCRFLJ0390",
//   // "CPCRFLJ0356",
//   // "CPCRFLJ0395",
//   // "CPCRFLJ0357",
//   // "CPCRFLJ0403",
//   // "CPCRFLJ0349",
//   // "CPCRFLJ0393",
//   // "CPCRFLJ0350",
//   // "NFMYFLJ0004",
//   // "NFMYFLJ0063",
//   // "CPCRFLJ0386",
//   // "NFMYFLJ0064",
//   // "CPCRFLJ0387",
//   // "CPCRFLJ0404",
//   // "CPCRFLJ0388",
//   // "CPCRFLJ0397",
//   // "CPCRFLJ0355",
//   // "CPCRFLJ0351",
//   // "CPCRFLJ0344",
//   // "CPCRFLJ0352",
//   // "CPCRFLJ0396",
//   // "CPCRFLJ0389",
//   // "CPCRFLJ0345",
//   // "CPCRFLJ0399",
//   // "CPCRFLJ0398",
//   // "CPCRFLJ0385",
//   // "NFMYFLJ0065",
//   // "NFMYFLJ0066",
//   // "NFMYFLJ0034",
// ];

// const fdhinfo=  [
//   {
//       "FDH": "ME=CPCRFLCC CPCRFLJ0393",

//   },
//   {
//       "FDH": "ME=CPCRFLCC CPCRFLJ0395",

//   },
//   {
//       "FDH": "ME=CPCRFLCJ CPCRFLJ0399",

//   },
//   {
//       "FDH": "ME=CPCRFLCJ CPCRFLJ0403",

//   },
//   {
//       "FDH": "ME=CPCRFLCJ CPCRFLJ0405",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0344",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0345",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0349",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0350",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0351",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0352",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0353",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0355",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0356",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0357",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0385",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0386",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0387",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0388",
//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0389",
//   },
//   {
//       "FDH": "ME=CPCRFLCV CPCRFLJ0390",
//   },
//   {
//       "FDH": "ME=CPCRFLCV CPCRFLJ0391",
//   },
//   {
//       "FDH": "ME=CPCRFLCV CPCRFLJ0392",
//   },
//   {
//       "FDH": "ME=CPCRFLDI CPCRFLJ0397",
//   },
//   {
//       "FDH": "ME=CPCRFLDI CPCRFLJ0398",

//   },
//   {
//       "FDH": "ME=CPCRFLFF CPCRFLJ0404",

//   },
//   {
//       "FDH": "ME=CPCRFLXB CPCRFLJ0396",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0004",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0063",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0064",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0065",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0066",

//   },
//   {
//       "FDH": "ME=NFMYFLXH NFMYFLJ0034",

//   }
// ]
var statuslist = [{ status: "COMPLETED" }, { status: "PENDING" }];
var stateList = [
  { value: "AZ",  label: "AZ" },
  { value: "CO",  label: "CO" },
  { value: "FL",  label: "FL" },
  { value: "IA",  label: "IA" },
  { value: "ID",  label: "ID" },
  { value: "IN",  label: "IN" },
  { value: "MN",  label: "MN" },
  { value: "MT",  label: "MT" },
  { value: "ND",  label: "ND" },
  { value: "NE",  label: "NE" },
  { value: "NM",  label: "NM" },
  { value: "NV",  label: "NV" },
  { value: "OR",  label: "OR" },
  { value: "SD",  label: "SD" },
  { value: "UT",  label: "UT" },
  { value: "WA",  label: "WA" },
  { value: "WI",  label: "WI" },
  { value: "WY",  label: "WY" },
];
const FDHview = (props) => {
  console.log("check fdh", props);
  const [activeButton, setactiveButton] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleErrModal, setToggleErrModal] = useState(false);
  const[callstatus,setcallstatus]=useState(false);
  const toggleButton = (buttonId) => {
    setactiveButton(activeButton === buttonId ? null : buttonId);
  };
  const [mstinputValue, setMstInputValue] = useState("");
  const [mstfilteredSuggestions, setmstFilteredSuggestions] = useState([]);
  var [statename, setstatename] = useState('');
  const sessionId = sessionStorage.getItem("userId");
  const[nodata,setNoData]=useState(["1","2"]);
  var [statekeyname, setstatekeyname] = useState();
  const [wirecent, setwirecent] = useState("");
  const [mstlevelissue, setMSTlevel] = useState("");
  const [msttpissue, setMSTtpissue] = useState("");
  const[customer,setCustomer] = useState("");
  const[caseselect,setCaseselect] = useState("");
  const[inputCaseVal,setInputCaseVal]=useState("");
  const[showAccess,setShowNoAccess]=useState(false);
  const[noaccessValue,setNoAccessValue] = useState("");
  const[selectedStateValue,setselectedStateValue] = useState("");
   const [wirecentlist, setWirecentList] = useState("");
  const [dataFDH, setDataFDH] = useState([]);
  const [tableheader, settableheader] = useState([]);
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [errorreview, seterrorMst] = useState();
  const [activeIndex, setActiveIndex] = useState(false);
  const [selectedIndex, setIndex] = useState("");
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [splitterKeys, setsplitterKeys] = useState([]);
  const[mstCase,setMstCase]= useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [showloading, setShowloading] = useState(false);
  const [filteredData12, setfilteredData12] = useState([]);
  var [reviewstatus, setHeaderreview] = useState("");
  var [wirecentervalue, setWireValue] = useState(null);
  const [showerrmodal, setShowerror] = useState(false);
  var [statevalue, setstatevalue] = useState(null);
  var [enablewirecenter, setEnableWire] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const[dataComments,setDataComments]=useState('');
  const [startDate, setStartDate] = useState();
   const [endDate, setEndDate] = useState();
   const [formatstartDate, setformatStartDate] = useState('');
   const [formatendDate, setformatEndDate] = useState('');
  const [dataWirecenter, setdataWirecenter] = useState([]);
  useEffect(() => {
    setToggleModal(true);
    // callFDHList();
  }, []);
  useEffect(()=>{
    if(formatstartDate || formatendDate){
      callDatefilter();
    }
      },[formatstartDate,formatendDate])
  useEffect(() => {
    if (props.fdhList) {
      // setShowloading(false)
      setfilteredData(props.fdhList);
      console.log("checking 11");
    } else {
      console.log(dataFDH);
    }
  }, [props.fdhList]);
  const datetimeString = reviewstatus.reviewTime; // 24-hour format
  const ndsdatetime = reviewstatus.ndsReviewTime;
    // Convert the date and time into desired formats
    const formattedDate = new Date(datetimeString?datetimeString:'NA').toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  
    const formattedTime = new Date(datetimeString?datetimeString:'NA').toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    const ndsformattedDate = new Date(ndsdatetime?ndsdatetime:'NA').toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  
    const ndsformattedTime = new Date(ndsdatetime?ndsdatetime:'NA').toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });

    
  //status filter
  const handleStatus = (event,passedmst) => {
    setwirecent("");
    setstatename("");
    setstatekeyname([]);
    setInputValue("");
    setStartDate("");
    setEndDate("");
    if(passedmst == 'mstpass'){
      settableheader([]);
      setsplitterKeys([]);
      setfilteredData([]);
      setfilteredData12([]);
      setLoadingToggle(false);
      statuslist = [{ status: "COMPLETED" }, { status: "PENDING" }];
      setEnableWire(false);
      document.getElementById("statusValue").selectedIndex = 0;
      const val = {
        deviceName: "NA",
        deviceType: "NA",
        auditStatus: "NA",
        auditBy: "NA",
        auditTime: "NA",
        reviewStatus: "NA",
        reviewBy: "NA",
        reviewTime: "NA",
        auditType:"NA",
        mstWise:"NA",
        strandRange :"NA",
        comments : "NA",
        reviewRemarks:"NA",
        auditDropType:"NA",
        ndsReviewStatus : "NA",
        ndsReviewBy: "NA",
        ndsReviewTime:"NA",
        faReason:"NA"
      }
      props.onChildDataChange(val);
      setHeaderreview(val);
      }else{
        var datavalue = event?.target?.value ? event.target.value : "PENDING";
        setSelectedDate("");
    setInputValue('');
    settableheader([]);
    setstatevalue(datavalue);
    setdataWirecenter([]);
    setLoadingToggle(false);
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA",
      mstWise:"NA",
      strandRange :"NA",
      comments : "NA",
      reviewRemarks:"NA",
      auditDropType:"NA",
      ndsReviewStatus : "NA",
      ndsReviewBy: "NA",
      ndsReviewTime:"NA"
    }
    props.onChildDataChange(val);
    setHeaderreview(val);
        if (datavalue != "0") {
          // /getAuditReviewInfo?deviceType=MST&auditStatus=PENDING&deviceName=ME=
    
          fetch(
            process.env.REACT_APP_BASE_API_URL +
              "/getAuditReviewInfo?deviceType=MST" +
              "&auditStatus="+
              datavalue +
              "&deviceName=", headers
          )
            .then((response) => {
              console.log(response);
              if (!response.ok) {
                throw response;
              }
              return response.json();
            })
            .then((data) => {
              console.log("check",data)
              setfilteredData(data);
              setfilteredData12(data);
            })
            .catch((error) => {
              console.log(error);
              if (error) {
                if (
                  error.status === 400 ||
                  error.status === 502 ||
                  error.status === 500
                ) {
                  setToggleErrModal(true);
                }
              } else {
              }
            });
          // callFDHList(param);
          setEnableWire(true);
        }else{
          setfilteredData([]);
          setfilteredData12([]);
          setEnableWire(false);
        }
      } 
  };

  //typeahead
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    // if (inputValue.length >= 2) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            "/getFieldOpsFilter?" +
            "dateFrom=" +
          formatstartDate +
            "&dateTo=" +
            formatendDate + 
            "&state=" +
            statename+
           "&wirecenter=" +
            wirecent +
            "&deviceName=" +
            inputValue +
            "&deviceType=FDH", headers
        )
        .then((response) => {
          const data = response.data;
          setfilteredData(data);
          setfilteredData12(data);
        })
        .catch((error) => {
          // if (error.response.status == 400 || error.response.status == 404) {
          //   setfilteredData([]);
          //   setfilteredData12([]);
          // } else {
          //   setToggleErrModal(true);
          // }
        });
    //}
  };
  const handleCaseChange = (e) => {
    const inputValue = e.target.value;
    setInputCaseVal(inputValue);
  };


  const handleStartDateChange = (date)=>{
    const inpDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    const formattedDate = inpDate.toISOString().split('T')[0];
    setStartDate(date);
    setformatStartDate(formattedDate);
    console.log(formattedDate,'dateitem')
    // callDatefilter();
    
  }
  const callDatefilter = ()=>{
    console.log(formatstartDate)
    setCaseselect("");
    setInputCaseVal("");
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA"
    }
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getFieldOpsFilter?" +
        "&state=" +
        statename+
        "&wirecenter=" +
        wirecent +
        "&deviceName=" + inputValue +  
        "&dateFrom=" + formatstartDate +
        "&dateTo=" + formatendDate +
        "&deviceType=FDH", headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        setfilteredData(data);
        setfilteredData12(data);
      }).catch((error) => {
        console.log(error);
        if (error) {
          if(error.status == 400 || error.status == 404){
            setfilteredData([]);
            setfilteredData12([]);
            settableheader([]);
            setHeaderreview(val);
            props.onChildDataChange(val);
          }
          if (
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
            console.log(error.status);
          }
        } else {
        }
      });
  }
  const handleEndDateChange = (date)=>{
    const inpDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    const formattedDate = inpDate.toISOString().split('T')[0];
    setEndDate(date);
    setformatEndDate(formattedDate);
  }
  
  const handleItemClick = (event, val,passedData) => {
    var deviceData=event?.target?.textContent ? event.target.textContent : selectedIndex
    setActiveIndex(deviceData);
    setIndex(deviceData);
    setLoadingToggle(true);
    if(passedData !== 'save'){
    settableheader([]);
    setsplitterKeys([]);
    }
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        deviceData , headers
    ).then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then((data) => {
        console.log(data, "arr");
        if (data) {
          // const lastIndex = data.length - 1;
          // const lastObject = data[lastIndex];
          setDataComments(data[0]);
          setHeaderreview(data[0]);
          const caseId = data[0].faIssueDevice;
          setcallstatus(caseId);
          props.onChildDataChange(data[0]);
          handlecaseStatus(caseId);
        }
      })
      .catch((e) => {
        setLoadingToggle(false)
        if (e.response) {
          if(e.response.status === 404){
            setHeaderreview(val);
          }
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        }
      });
      if(passedData !== 'save'){
    fetch(
      process.env.REACT_APP_BASE_API_URL + "/getSplitterView?fdhName=" + event.target.textContent  + "&portIssue=" + msttpissue , headers
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          if (data.status === "404") {
            setLoadingToggle(false);
            seterrorMst(data.message);
          }
          if (data.status === "502") {
            setToggleErrModal(true);
          }
        } else {
          // settableheader(() => {
          //   const arrayOfObjects = Object.keys(data)
          //     .map((key) => {
          //       const id = parseInt(key); // Convert the key to an integer as required
          //       return data[key].map((item) => ({ id, ...item }));
          //     })
          //     .flat();

          //   return arrayOfObjects;
          // });
          settableheader(data.map((dt,i)=>{
            return {id:i, ...dt}
          }));
          setsplitterKeys(Object.keys(data));
          setLoadingToggle(false);
          setToggleModal(false);
        }
      })

      .catch((e) => {
        if (e.response) {
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
          setToggleErrModal(true);
        }
      });
    }
  };
 const handlecaseStatus = (caseId) => {
   const id = caseId;
      axios
        .get(process.env.REACT_APP_BASE_API_SF_URL +"?caseId=" + id)
        .then((response) => {
          const data = response.data;
          setMstCase(data);
        })
        .catch((error) => {
         setMstCase("NA");
        });
    //}
  };
  const handleErrModal = () => {
    setToggleErrModal(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const handleState = (selected) =>{
    const statenamevalue = selected.value;
    setstatename(statenamevalue);
    setstatekeyname(selected);
    setwirecent("");
    setCaseselect("");
    setInputCaseVal("");
    setInputValue("");
    setStartDate("");
    setEndDate("");
    setMSTlevel('');
    setMSTtpissue('');
    // if(wirevalue !== '0'){
      fetch(
        process.env.REACT_APP_BASE_API_URL + "/getFieldOpsFilter?" + 'state=' + statenamevalue +
        "&deviceType=FDH", headers
       )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
              setfilteredData12([]);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }

  const handleSelectWire = (suggestion) => {
    var inputstring = suggestion;
    setwirecent(inputstring);
    setWirecentList([]);
    handlewirecenter(inputstring);
  };
  const handlewirecenter = (event) =>{
    
    const wirecentername = event;
    // if(wirevalue !== '0'){
      setInputValue("");
      setStartDate("");
      setEndDate("");
      setCaseselect("");
    setInputCaseVal("");
      fetch(
        process.env.REACT_APP_BASE_API_URL + "/getFieldOpsFilter?" + '&state=' + statename + '&wirecenter=' + wirecentername + "&deviceType=FDH" 
     , headers )
       .then(response => {
        console.log(response)
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
         .then((data) => {
         setWireValue(data);
         setfilteredData(data);
         setfilteredData12(data)
         })
         .catch((error) => {
          console.log(error)
          if (error) {
            if (
              error.status === 400 ||
              error.status === 404 
               
            ) {
              setfilteredData([]);
              setfilteredData12([]);
              settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
            }
          } else {
            setToggleErrModal(true);
          }
        });
    // }
  }
  const handleWirecent = (e) => {
    const wirecentervaluefdh = e.target.value;
    setwirecent(wirecentervaluefdh);
    // setFilteredSuggestions([]);
    // setFilteredMSTSuggestions([]);
      if (wirecentervaluefdh.length >= 1) {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/getWirecenter?fdhWirecenter=" +
              wirecentervaluefdh +
              "&state="+
              statename
             , headers
          )
          .then((response) => {
            const data = response.data;
            setWirecentList(data);
          })
          .catch((e) => {
            if (e.response) {
              if (
                e.response.status === 400 ||
                e.response.status === 502 ||
                e.response.status === 500
              ) {
                settableheader([]);
              setsplitterKeys([]);
              setLoadingToggle(false);
                setToggleErrModal(true);
              }
            } else {
              setToggleErrModal(true);
            }
          });
      } else {
        setWirecentList([]);
      }
  };
  
const handleMSTlevel = (e) =>{
  setfilteredData([]);
  setfilteredData12([]);
  setShowloading(true);
  const mstlevel = e.target.value;
    setMSTlevel(mstlevel);
    setShowNoAccess(e.target.value === "No MST access")
    setMSTtpissue('');
    setCaseselect("");
    setInputCaseVal("");
    axios
    .get(
      process.env.REACT_APP_BASE_API_URL +
        "/getFieldOpsFilter?" +
        "dateFrom=" +
      formatstartDate +
        "&dateTo=" +
        formatendDate + 
        "&state=" +
        statename+
       "&wirecenter=" +
        wirecent +
        "&deviceName=" +
        inputValue +
        "&deviceIssue=" +
        mstlevel + 
        "&portOrSlotIssue=" + 
        msttpissue +
        "&deviceType=FDH" , headers
    )
    .then((response) => {
      setShowloading(false);
      const data = response.data;
      setfilteredData(data);
      setfilteredData12(data);
    })
    .catch((error) => {
      setShowloading(false);
      console.log('ss')
      console.log(error.response)
      if (error.response.status == 400 || error.response.status == 404) {
        setfilteredData([]);
        setfilteredData12([]);
      } else {
        setToggleErrModal(true);
      }
    });
    
}
// const handleMSTlevelAccess = (e) =>{
//   setfilteredData([]);
//   setfilteredData12([]);
//   setShowloading(true);
//   const mstlevel = e.target.value;
//   setNoAccessValue(mstlevel);
//     setMSTtpissue('');
//     setCaseselect("");
//     setInputCaseVal("");
//     axios
//     .get(
//       process.env.REACT_APP_BASE_API_URL +
//         "/getMstName?" +
//         "dateFrom=" +
//       formatstartDate +
//         "&dateTo=" +
//         formatendDate + 
//         "&state=" +
//         statename+
//        "&wirecenter=" +
//         wirecent +
//         "&mstName=" +
//         inputValue +
//         "&mstIssue=" +
//         mstlevel + 
//         "&portIssue=" + 
//         msttpissue + , headers
//     )
//     .then((response) => {
//       setShowloading(false);
//       const data = response.data;
//       setfilteredData(data);
//       setfilteredData12(data);
//     })
//     .catch((error) => {
//       setShowloading(false);
//       console.log(error.response)
//       if (error.response.status == 400 || error.response.status == 404) {
//         setfilteredData([]);
//         setfilteredData12([]);
//       } else {
//         setToggleErrModal(true);
//       }
//     });
    
// }
const handlTPlevel = (e) =>{
  setfilteredData([]);
  setfilteredData12([]);
  setShowloading(true);
  const msttpissue = e.target.value;
    setMSTtpissue(msttpissue);
    setMSTlevel('');
    setNoAccessValue('');
    setCaseselect("");
    setInputCaseVal("");
    setShowNoAccess(false);
    axios
    .get(
      process.env.REACT_APP_BASE_API_URL +
        "/getFieldOpsFilter?" +
        "dateFrom=" +
      formatstartDate +
        "&dateTo=" +
        formatendDate + 
        "&state=" +
        statename+
       "&wirecenter=" +
        wirecent +
        "&deviceName=" +
        inputValue +
        "&deviceIssue=" +
        "&portOrSlotIssue=" + 
        msttpissue +
        "&deviceType=FDH", headers
    )
    .then((response) => {
      setShowloading(false);
      const data = response.data;
      setfilteredData(data);
      setfilteredData12(data);
    })
    .catch((error) => {
      setShowloading(false);
      if (error.response.status == 400 || error.response.status == 404) {
        setfilteredData([]);
        setfilteredData12([]);
      } else {
        setToggleErrModal(true);
      }
    });
    
}

const handleCaseSelect = (e) =>{
  setfilteredData([]);
  setfilteredData12([]);
  setInputCaseVal("");
  setShowloading(true);
  const casevalue = e.target.value;
    setCaseselect(casevalue);
    setMSTlevel('');
    setNoAccessValue('');
    setShowNoAccess(false);
    axios
    .get(
      process.env.REACT_APP_BASE_API_URL +
        "/getFieldOpsFilter?" +
        "dateFrom=" +
      formatstartDate +
        "&dateTo=" +
        formatendDate + 
        "&state=" +
        statename+
       "&wirecenter=" +
        wirecent +
        "&deviceName=" +
        inputValue +
        "&deviceIssue=" +
        mstlevelissue + 
        "&portOrSlotIssue=" + 
        msttpissue + 
        "&sfCaseNumber="+
        inputCaseVal +
        "&sfStatus="+
        casevalue +
        "&deviceType=FDH" , headers
    )
    .then((response) => {
      setShowloading(false);
      const data = response.data;
      setfilteredData(data);
      setfilteredData12(data);
    })
    .catch((error) => {
      setShowloading(false);
      if (error.response.status == 400 || error.response.status == 404) {
        setfilteredData([]);
        setfilteredData12([]);
      } else {
        setToggleErrModal(true);
      }
    });
    
}
const handleCaseNoSearch = (e) =>{
  setfilteredData([]);
  setfilteredData12([]);
  setShowloading(true);
   setCaseselect('');
    setMSTlevel('');
    setNoAccessValue('');
    setShowNoAccess(false);
    axios
    .get(
      process.env.REACT_APP_BASE_API_URL +
        "/getFieldOpsFilter?" +
        "dateFrom=" +
      formatstartDate +
        "&dateTo=" +
        formatendDate + 
        "&state=" +
        statename+
       "&wirecenter=" +
        wirecent +
        "&deviceName=" +
        inputValue +
        "&deviceIssue=" +
        mstlevelissue + 
        "&portOrSlotIssue=" + 
        msttpissue + 
        "&sfCaseNumber="+
        inputCaseVal +
        "&deviceType=FDH" , headers
    )
    .then((response) => {
      setShowloading(false);
      const data = response.data;
      setfilteredData(data);
      setfilteredData12(data);
    })
    .catch((error) => {
      setShowloading(false);
      if (error.response.status == 400 || error.response.status == 404) {
        setfilteredData([]);
        setfilteredData12([]);
      } else {
        setToggleErrModal(true);
      }
    });
    
}
  const Statusupdate = (item) => {
    let style={};
    if(item.mstStatus == 'Open'){
      style = { borderLeft: '5px solid #503296'}
    } else if(item.mstStatus == 'Closed'){
      style = {  borderLeft: '5px solid #38C6F4'}
    } else {
      style = {  borderLeft: '5px solid #03b94c'}
    }
    // let style={};
    // if(item.mstStatus == 'Open' && item.mstPortStatus == 'closed'){
    //   style = { borderLeft: '5px solid #95A3E2'}
    // } else if(item.mstPortStatus == 'closed' && item.mstStatus == 'Open'){
    //   style = {  borderLeft: '5px solid #503296'}
    // } else if(item.mstPortStatus == 'closed' && item.mstStatus == 'closed'){
    //   style = {  borderLeft: '5px solid #38C6F4'}
    // }
    //  else {
    //   style = {  borderLeft: '5px solid #03b94c'}
    // }
    return style;
  };
  const handleclear = (clear)=>{
    setLoadingToggle(false)
  }
  const handlestatuscolor = (item) =>{
    if(item.deviceStatus == 'Open'){
      return 'no-case'
    } else if(item.deviceStatus == 'Closed'){
      return 'no-case'
    } else if  (item.deviceStatus == ""){
      return 'no-case'
    }else{
      return ''
    }
    // if(item.mstPortStatus == 'Open' && item.mstStatus == 'closed'){
    //   return 'both-updateded'
    // } else if(item.mstPortStatus == 'closed' && item.mstStatus == 'Open'){
    //   return 'oupdated'
    // } else if(item.mstPortStatus == 'closed' && item.mstStatus == 'closed'){
    //   return 'ndupdateded'
    // } else if (item.mstPortStatus == 'Open' && item.mstStatus == 'Open'){
    //   return 'nothingupdated'
    // }else{
    //   return ''
    // }
  };
 const handlecase = () =>{
  handlecaseStatus(callstatus);
 }
 const fetchreview = (value, match) => {
  var num=["1","2"];
   fetch(
     process.env.REACT_APP_BASE_API_URL +
       "/getFieldOperationsReview?deviceName=" +
       value +
       "&portIssue=" + msttpissue +
      "&workingOrder=" +
       match
      , headers
   )
   .then((res) => res.json())
   .then((data) => {
     if (data.error) {
       if (data.status === "404") {
         setLoadingToggle(false);
         seterrorMst(data.message);
         setNoData([]);
       }
       if (data.status === "502") {
         setToggleErrModal(true);
       }
     } else {
       settableheader(data.map((dt,i)=>{
         return {id:i, ...dt}
       }));
       setsplitterKeys(Object.keys(data));
       setLoadingToggle(false);
       setToggleModal(false);
     }
   })
        .catch((e) => {
       if (e.response) {
        if(e.response.status === 404){
          // setHeaderreview(value);
         
        }
         if (
           e.response.status === 400 ||
           e.response.status === 502 ||
           e.response.status === 500
         ) {
           setToggleErrModal(true);
         }
       } else {
         setToggleErrModal(true);
       }
     });
 };

  return (
    <>
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="d-flex justify-content-between box-fdh pb-1 status-p ">
        <div>
        {/* <div className="d-flex flex-row filtertag mob-filtertag mob-width-100"> */}
        {/* <div className="search mob-width-100">
        <div className="mob-width-100">

          <div class="search-container-mst-re">
            <div className="s-icon-ones">
              <FontAwesomeIcon icon={faSearch} />
            </div>

            <input type="text" className="search-input-icon" placeholder="Search By MST"  value={mstinputValue} onChange={handleMstChange}/>
            
          </div>
          {mstfilteredSuggestions.length > 0 && (
                    <ul className="list-search-review" >
                      <span>
                        {mstfilteredSuggestions.map((suggestion) => (
                          <li key={suggestion.mst} onClick={() => handleMSTSelectSuggestion(suggestion)}>
                            {suggestion.mst}
                          </li>
                        ))}
                      </span>
                    </ul>
                  )}
        </div>
      </div> */}
       
        <div className="d-flex flex-row filtertags mob-col-12 mob-flex-wrap  tab-flex-wraps">
          {/* <div className="mob-col-12 review-font mb-nomargin marg-align" >
            
            <select
              className="filter-select mob-filtertag "
              id="statusValue"
              onChange={handleStatus}
            >
              <option value="0">Select Status</option>

              {statuslist.map((status) => (
                <option key={status} value={status.status}>
                  {status.status}
                </option>
              ))}
            </select>
          </div> */}
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
          <Select
          options={stateList}
          onChange={handleState}
          value={statekeyname}
          className="searchresultfdhselect"
          placeholder="Select State"
          isMulti={false} // Set to true if you want to allow multiple selections
          />
          )}
        </div>
        <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
               <div>
                  <input
                    className="searchresultfdh"
                    placeholder="Search Wirecenter"
                    value={wirecent}
                    autoComplete="off"
                    id="wirecenter"
                    onChange={handleWirecent}
                  ></input>
                  {wirecentlist?.length > 0 ? (
                    <ul className="wirecenterreview mob-wire-search tab-wire-search">
                      <span>
                        {wirecentlist.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() => handleSelectWire(suggestion)}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </span>
                    </ul>
                  ) : 
                  (
                    <></>
                  )
                  }
                </div>
            )}
            </div>
            
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="d-flex flex-row datepicker">
               
                <DatePicker selected={startDate} selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-date"
              placeholderText="From Date"
              onChange={handleStartDateChange} />
              
                
                
                </div>
            )}
          </div>
          <div className="mob-col-6 marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="d-flex flex-row datepicker">
              
               
                <DatePicker selected={endDate} selectsEnd
               startDate={startDate}
              endDate={endDate}
              placeholderText="To Date"
              className="form-date"
              minDate={startDate}
              onChange={handleEndDateChange} />
                
                </div>
            )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="mob-search-fop">
                <input
                  type="text"
                  value={inputValue}
                  style={{ margin: "0" }}
                  className="searchresultfdh"
                  placeholder="Search FDH"
                  onChange={handleChange}
                ></input>
              </div>
            )}
          </div>
        </div>
        {enablewirecenter && (
        <div className="d-flex  flex-row filtertags mob-col-12 mob-flex-wrap review-comment tab-tag tab-flex-wraps">
           <div className="mob-col-6 review-font marg-align mob-no-margin mob-review-mar-set">
            {enablewirecenter && (
              <div>
                  <select value={mstlevelissue} onChange={handleMSTlevel} className="searchresultfdh">
                    <option value="0">Select FDH Level Issue</option>
                    <option value="Address Correction">Address Correction</option>
                    <option value="Insufficient labeling">
                    Insufficient labeling
                    </option>
                    <option value="No Light">No Light</option>
                    <option value="Disaster">Disaster</option>
                    <option value="Fiber Cut">Fiber Cut</option>
                  </select>
              </div>
            )}
          </div>
          {/* <div className="mob-col-6 review-font marg-align mob-no-margin">
            {(enablewirecenter && showAccess ) &&  (
              <div>
                  <select value={noaccessValue} onChange={handleMSTlevelAccess} className="searchresultfdh">
                    <option value="0">Select No MST access </option>
                    <option value="No MST access-Trees">Trees</option>
                    <option value="No MST access-Private Property">Private Property</option>
                    <option value="No MST access-Safety Concerns">Safety Concerns</option>
                    <option value="No MST access-Other Resident">Other Resident</option>
                  </select>
              </div>
            )}
          </div> */}
          <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="mob-search-fop">
                  <select value={msttpissue} onChange={handlTPlevel}  className="searchresultfdh">
                    <option value="0">Select FDH Splitter Issue</option>
                    <option value="Wrong Light">Wrong Light</option>
                                  <option value="No Light">No Light</option>
                                  <option value="Incorrect Splitter Count">
                                    Incorrect Splitter Count
                                  </option>
                                  <option value="Splitter Missing">
                                    Splitter Missing
                                  </option>
                  </select>
              </div>
            )}
          </div>
          {/* <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="mob-search-fop">
                  <select value={customer} onChange={handleCustomer}  className="searchresultfdh">
                    <option value="0">Select Customer</option>
                    <option value="Broken Port">Working</option>
                    <option value="Not Working">Not Working</option>
                  </select>
              </div>
            )}
          </div> */}
          <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="mob-search-fop">
                  <select value={caseselect} onChange={handleCaseSelect}  className="searchresultfdh">
                    <option value="0">Select Case Status</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                  </select>
              </div>
            )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div className="d-flex">
              <div className="mob-search-fop">
                <input
                  type="text"
                  value={inputCaseVal}
                  style={{ margin: "0" }}
                  className="searchresultfdh"
                  placeholder="Search Case No"
                  onChange={handleCaseChange}
                ></input>
              </div>
              <div className="s-icon-ones">
              <FontAwesomeIcon icon={faSearch} onClick={handleCaseNoSearch} />
               </div>
                </div>
            )}
          </div>
        </div>
        )}
        
        
        </div>
      </div>

      {/* <div className="search-bar-fdhview">
        <div className="searchicon-fdhview">
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <input
          type="text"
          className="search-input-icon-fdhview"
          placeholder="Search By MST"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div> */}
      <div className="fdhview mob-row tab-row">
      <div className="col-8 mob-col-12 block-in-mobile">
              {/* <div className="legend-content mt-3">
                <span className="o2updated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                 Open
                </span>
                
                <span className="ndsupdated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                Closed
                </span>

                <span className="notupdated">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ "padding-right": "3px" }}
                  />
                  No Case
                </span>
              </div> */}
            </div>
        <div
          className="scrollable-table-container mob-col-4"
          style={{ width: "10%" }}
        >

          <FDHviewHeader tabhead={{ tabhead: "1" }} />
    
          <div
            className="scrollable-tbody-container mob-body-height"
            width="10%"
            style={{ height: "350px" }}
          >
            <table className="scrollable-table">
              <tbody>
                {/* {dataFDH.map((item, index) => (
                  <tr className="trfix" key={index}>
                    <td
                      key={index}
                      className={`button ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => handleItemClick(index)}
                    >
                      {item.fdhName.substring(12, 24)}
                      {activeButton === activeIndex && (
                        <span className="arrow"></span>
                      )}
                    </td>
                  </tr>
                ))} */}
               {
                filteredData.length > 0 ? (
                  <>
                   {filteredData?.map((item, index) => (
                  <tr style={Statusupdate(item)} key={index}>
                    <td
                      key={index}
                      // className={ handlestatuscolor(item)}
                      className={`button ${
                        item.device == activeIndex ? handlestatuscolor(item) : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleItemClick(e, item)}
                    >
                      {item.device}
                    </td>
                  </tr>
                ))}
                  </>
                ):(
                  <>
                  {showloading ? (
                    <>{showloading && <div><ContentFop/></div>}</>
                  ) : (
                     <tr>
                     <td>No data</td>
                   </tr>
                  )}
                 
                  </>
                )
               }
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="scrollable-table-container fdhdetailstab mob-col-8"
          style={{ height: "350px", width: "85%" }}
        >

          {/* <FdhviewHeader tabhead={{tabhead:"2",activeIndex:selectedIndex}} /> */}

          <FDHdatatab
            tdata={tableheader}
            spkeys={splitterKeys}
            errorreview={errorreview}
            reviewdata={reviewstatus}
            nodata={nodata}
            loading={loadingToggle}
            reviewcomments={dataComments}
            mstCase={mstCase}
            handleclear={handleclear}
            handleItemClick={handleItemClick}
            handleStatus={handleStatus}
            handlecase={handlecase}
          />
        </div>
      </div>
      {/* {toggleModal && <Modalblack />} */}
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
    </>
  );
};

export default FDHview;
