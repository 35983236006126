import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";
import "./statusdialog.css";
import No_Data from "../assets/nodatafoundimg.svg";
import Data_Error from "../assets/Computer troubleshooting.gif";
import Success_img from "../assets/success img.svg";
import Seach_gif from "../assets/Search (3).gif";
// import ModalloaderDot from "../LoadingDot/loadindot";

function StatusDialog({
  statusType,
  statusDialogMsg,
  closeDialog,
  statusDialogHead,
}) {
  return (
    <Dialog open={true}>
      <div className="" style={{ minWidth: "300px", maxWidth: "350px" }}>
        {statusType && statusType === "loading" ? (
          <>
            <div className="px-1 pt-2 pb-2">
              <div className="text-center" style={{ fontSize: "25px" }}>
                <CircularProgress />
              </div>
              <div
                className="status-loading"
                style={{ fontSize: "18px", paddingLeft: "70px" }}
              >
                {statusDialogMsg}
              </div>
            </div>
          </>
        ) : statusType &&
          (statusType === "auditLoading" || statusType === "savingData") ? (
          <>
            <div className="px-1 pt-1 d-flex justify-center">
              <div className="px-1 mY-auto" style={{ fontSize: "18px" }}>
                {statusDialogHead}
              </div>
            </div>
            <div className="pt-1 text-center">
              <img loading="lazy" height="150" src={Seach_gif} alt="" />
            </div>
            <>{/* <ModalloaderDot /> */}</>
            <div className="px-1 d-flex pb-2 justify-center">
              <div
                className="px-1 mY-auto text-center"
                style={{ fontSize: "14px" }}
              >
                {statusDialogMsg}
              </div>
            </div>
            {/* <div className="text-center pt-1 pb-2" style={{ fontSize: "10px" }}>
                  <CircularProgress className="color-lumen" size={15} />
                </div> */}
          </>
        ) : statusType && statusType === "success" ? (
          <>
            <div className="px-1 pt-1 d-flex justify-center ">
              <div className="px-1 mY-auto" style={{ fontSize: "18px" }}>
                {statusDialogHead}
              </div>
            </div>
            <div className="pt-1 text-center">
              <img loading="lazy" height="150" src={Success_img} alt="" />
            </div>
            <div className="px-1 pt-1 d-flex justify-center">
              <div
                className="px-1 mY-auto text-center"
                style={{ fontSize: "14px" }}
              >
                {statusDialogMsg}
              </div>
            </div>
            <div className="px-1 pt-2 pb-1 text-center">
              <Button
                variant="contained"
                className="bg-lumen"
                onClick={() => closeDialog()}
                style={{ width: "50%" }}
              >
                Ok
              </Button>
            </div>
          </>
        ) : statusType && statusType === "failed" ? (
          <>
            <div className="px-1 pt-1 d-flex justify-center">
              <div className="px-1 mY-auto" style={{ fontSize: "18px" }}>
                {statusDialogHead}
              </div>
            </div>
            <div className="pt-1 text-center">
              <img loading="lazy" height="150" src={No_Data} alt="" />
            </div>
            <div className="px-1 pt-1 d-flex justify-center">
              <div
                className="px-1 mY-auto text-center"
                style={{ fontSize: "14px" }}
              >
               {statusDialogMsg}<br></br>
                <span>1.Make sure the Device is powered up and Fiber is connected</span><br></br>
                <span>2.Make sure the light level is good near SmartNID </span><br></br>
                <span>3.If XGS make sure only C6500 is being used</span>
              </div>
            </div>
            <div className="px-1 pt-2 pb-1 text-center">
              <Button
                variant="contained"
                className="bg-red"
                onClick={() => closeDialog()}
                style={{ width: "50%" }}
              >
                Ok
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="px-1 pt-1">
              <div
                className="px-1 text-center"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                {statusDialogHead}
                {/* Oops! An error occured. */}
              </div>
              <div className="pt-1 text-center">
                <img loading="lazy" height="150" src={Data_Error} alt="" />
              </div>
              <div className="px-1 pt-1 d-flex justify-center">
                <div
                  className="px-1 mY-auto text-center"
                  style={{ fontSize: "12px" }}
                >
                  {statusDialogMsg}
                </div>
              </div>
              <div
                className="px-1 text-center"
                style={{ fontSize: "10px", color: "gray" }}
              >
                {/* {statusDialogMsg} */}
                Please, Retry in a Moment.
              </div>
            </div>
            <div className="px-1 pt-2 pb-1 text-center">
              <Button
                variant="contained"
                className="bg-red"
                onClick={() => closeDialog()}
                style={{ width: "50%" }}
              >
                Ok
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default StatusDialog;
