import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./ConfirmModal.css";

const Modal = ({ onDataFromChild,onDatacheck,message, isSelect }) => {
    console.log(onDataFromChild)
//   const closeFunction = () => {
//     props.action();
//   };

  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;

    // Call the function received from the parent with the data
    onDataFromChild(dataToSend);
    onDatacheck(!isSelect);

  };
  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    const dataTocancel=false;
    // Call the function received from the parent with the data
    onDataFromChild(false);
    onDatacheck(isSelect);

  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerconfirm">
          <div className="modalc-background">
            <div className="modalc">
            
              <div
                style={{
                  color: "#000",
                  padding: "30px 40px",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
                {/* Are you sure want to confirm Audit? */}
                {message}
              </div>
              {/* <Modalalert /> */}
            </div>
            <div className="modalc-bottom">
                <div className="">
                    <span><button className="confirm-bottom" onClick={handleClick}>Confirm</button></span>
                    <span><button className="cancel-bottom" onClick={handleClickCancel}>Cancel</button></span>
                </div>
            
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modal;
