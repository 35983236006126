import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./modal.css";

const Modal = ({ audittype, closemod}) => {
  const [selectedOption, setSelectedOption] = useState("static");
  const [isChecked,setIsChecked] =useState(false);
  // console.log(onDataFromChild);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleConfirm = (e)=>{
    
    setIsChecked(!isChecked);

  }
  const handleClick = () => {
    // Data you want to send to the parent
    const dataToSend = false;

    // Call the function received from the parent with the data
    // onDataFromChild(dataToSend);
    audittype(selectedOption, isChecked);
  };

  const handleClickCancel = () => {
    // Data you want to send to the parent
    const dataToSend = false;
    closemod(dataToSend)
    // const dataTocancel=false;
    // Call the function received from the parent with the data
    // onDataFromChild(dataToSend);
    // onDatacheck(dataTocancel);

  };

  return (
    // <>
    //   {ReactDOM.createPortal(<Modalout/>,document.getElementById('modal-root'))}
    // </>
    <>
      {ReactDOM.createPortal(
        <div id="modal-containerwhite">
          <div className="modal-background">
            <div className="modal">
              <div className="m-header">
                <span>
                  <b>Choose Audit Types</b>
                </span>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: "#001f6a", height: "25px", width: "25px",cursor:"pointer" }}
                  onClick={handleClickCancel}
                />
              </div>
              <div className="modalcontent">
                <div className="m-radio">
                  <label>
                    <input
                      type="radio"
                      value="static"
                      checked={selectedOption === "static"}
                      onChange={handleRadioChange}
                      required
                    />
                    STATIC
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="dynamic"
                      checked={selectedOption === "dynamic"}
                      onChange={handleRadioChange}
                    />
                    DYNAMIC
                  </label>
                </div>
                
                  <div className="m-check">
                    <input
                      class="form-check-input-aud"
                      checked={isChecked}
                      type="checkbox" onChange={handleConfirm}
                      value="Completed"
                      id="flexCheckDefault"
                      
                    />
                    <span>Completed Audit</span>
                  </div>
                 
                  <span className="check-text">Please Select Checkbox If You Completed Audit</span>
                  
              </div>
              
                <button className="alert-bottom" onClick={handleClick}>
                  SUBMIT
                </button>
              
              {/* <div
                style={{
                  color: "#000",
                  padding: "30px 40px",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon icon={faCircleCheck} style={{color: "#001f6a",margin:"10px", height:"40px",width:"40px"}} /> <br/>
                On Field Physical Port has been Successfully Updated
              </div> */}
              {/* <Modalalert /> */}
            </div>
          </div>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modal;
