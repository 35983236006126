import React from "react";
import { useState } from "react";
import "./Login.css";
import Logo from "../assets/Lumen Logo White.png";
import Footer from "../assets/loginFooter.png";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "./service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "./axios";
import { API_KEY, BASE_API_URL } from "../environment";

const Login = () => {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [error, setError] = useState("");
  const[pass,setshowPass]=useState(false);
  const [values, setValues] = useState({
    cuId: "",
    password: "",
  });
 
  const [name, setName] = useState("");
  const nav = useNavigate();
 
 
  const SearchCUID = async (cuId ,session) => {
    const postdata = {
      "cuid": cuId,
      "emailId": ""
   }
    try {
      const responsetwo = await axios.post(
        process.env.REACT_APP_BASE_API_URL +
        "/getAuditAdminDtls?"
        ,postdata , headers
      );
      var data =  responsetwo.data;
      var role = data[0].role;
      var encrypted = data[0].encryptedKey;
      sessionStorage.setItem("encryptedKey", encrypted);
      postSessionInfo(session,encrypted)
      if(data.cuId !== null){
      sessionStorage.setItem("Role", role);
      nav("/search");
      } else{
        setError('User Not Found in the Tool');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404 || error.response.status === 400) {
          setError('User Not Found in the Tool');
        }
        if (error.response.status === 502 || error.response.status === 500) {
          //   setShowerror(true);
        }
      } else {
        // setShowerror(true);
      }
    }
  };
 
  const postSessionInfo = async (session,encrypted) => { 
    const headersession = {
      headers: {
      "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : session,
        "encryptedId":encrypted
      }
  }
    try {
      const response = await axios.post( process.env.REACT_APP_BASE_API_URL +
        "/postSessionInfo?", []
        , headersession);
      console.log(response.data);
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };
  const [userId, setUserId] = useState('');
  const handleSubmit = (event) => {
    if (!values.cuId) {
      setError("Please enter CUID");
    } else if (!values.password) {
      setError("Please enter Password");
    } else {
      Service.loginAuth(values).then((response) => {
        if (response.data.msg === "User logged-in successfully!") {
          var user = response.data.name;
          var cuid = response.data.cuId;
          setName(user);
          sessionStorage.setItem("Name", user);
          sessionStorage.setItem("cuid", cuid);
          const newId = storeIdInSession();
          setUserId(newId);  
 
           if(user){
            SearchCUID(cuid, newId)
           }
         
         
        } else {
          setError(response.data.msg);
        }
      });
 
      // setError("");
    }
 
    event.preventDefault();
  };
 
  const handleLogin = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
 
  //handle password
  const handlepassword = () =>{
    setshowPass(!pass);
  }
  function generateId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
 
  function storeIdInSession() {
    const newId = generateId();
    console.log('Generated ID:', newId);  
    sessionStorage.setItem('userId', newId);
    return newId;
  }
 
  return (
    <div className="login-page">
      <div className="top-logo">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
 
      <div className="login-container">
        <form className="s-mob-form" onSubmit={handleSubmit}>
          <div>
            {" "}
            <h2>Login</h2>
          </div>
 
          <div className="loginerror">
            <p>{error}</p>
          </div>
          <div className="textLogin">
          <input
            type="text"
            name="cuId"
            className="inp"
            placeholder="Enter CUID"
            autocomplete="off"
            onChange={handleLogin}
          />
          </div>
          <div className="password">
          <input
            type={pass ?'text' : 'password'}
            name="password"
            placeholder="Enter password"
            className="inp passinp"
            onChange={handleLogin}
          />
          <span className="passSpan" onClick={handlepassword}>
             {pass? <FaEyeSlash/> : <FaEye/>}
          </span>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
 
      <div className="footer">
        <img src={Footer} alt="Logo" className="Footer-Image" />
      </div>
    </div>
  );
};
 
export default Login;