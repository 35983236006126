import React, { useEffect, useState } from "react";
import "./stepprogress.css";

const StepProgressBar = ({ totalData, updateData, index }) => {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [load, setLoad] = useState(true);
  const update = (i) => {
    if (
      i < totalData.length - 2 &&
      totalData[i][Object.keys(totalData[i])[0]].Status === 200
    ) {
      setInterval(() => {
        updateData(i, index);
        update(i + 1);
      }, 3000);
    }
  };
  useEffect(() => {
    if (load) {
      update(0);
      setLoad(false);
    }
  }, []);
  return (
    <div className="container-fluid">
      <ul className="list-unstyled multi-steps">
        {totalData.map((data, i) => {
          if (i <= totalData.length - 1) {
            return (
              <li
                key={i}
                id="step-1"
                className={
                  data.status === null
                    ? "is-active is-null"
                    : data.status === false
                    ? "is-active is-error"
                    : ""
                }
              >
                <span>{data?.text ? data?.text : data.step}</span>
                {i !== totalData.length - 1 && (
                  <div className="progress-bar progress-bar--success">
                    <div
                      className="progress-bar__bar"
                      style={{
                        transform: data.status && "translateX(100%)",
                        WebkitTransform: data.status && "translateX(100%)",
                      }}
                    ></div>
                  </div>
                )}
              </li>
            );
          } else {
            return <></>;
          }
        })}
      </ul>
    </div>
  );
};

export default StepProgressBar;
