// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar-fdhview {
  position: relative;
  font-family: 'Poppins';
  display: inline-block;
  margin-top: 15px;
  margin-left: 20px;
}

.search-input-icon-fdhview::placeholder {
  font-size: small;
}

.search-input-icon-fdhview {
  padding: 8px 28px 8px 10px;
  border: 1px solid #ccc;
  font-family: 'Poppins';
  border-radius: 5px;
  outline: none;
  width: 150px;
  margin-bottom: 2px;
  border-color: black;
  border-width: 1.5px;
  font-size: small;
}

.searchicon-fdhview {
  color: #38c6f4;
  position: absolute;
  top: 50%;
  right: 8px;
  /* left: 5px; */
  transform: translateY(-50%);
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/OntReview/css/search.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,2BAA2B;EAC3B,oBAAoB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');\n\n.search-bar-fdhview {\n  position: relative;\n  font-family: 'Poppins';\n  display: inline-block;\n  margin-top: 15px;\n  margin-left: 20px;\n}\n\n.search-input-icon-fdhview::placeholder {\n  font-size: small;\n}\n\n.search-input-icon-fdhview {\n  padding: 8px 28px 8px 10px;\n  border: 1px solid #ccc;\n  font-family: 'Poppins';\n  border-radius: 5px;\n  outline: none;\n  width: 150px;\n  margin-bottom: 2px;\n  border-color: black;\n  border-width: 1.5px;\n  font-size: small;\n}\n\n.searchicon-fdhview {\n  color: #38c6f4;\n  position: absolute;\n  top: 50%;\n  right: 8px;\n  /* left: 5px; */\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
