import React, { useEffect, useReducer, useState } from "react";
import ImgeIco from './assets/upload.png'

  // drag drop file component
  function DragDropFile() {
    // drag state
    
    return (
 <></>
    );
  }

  export default DragDropFile;