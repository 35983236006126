import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./App.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faEdit,
  faPen,
  faTimes,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/ctllogo.png";
import Topbar from "./topbar";
import FdhTable from "./fdhtable";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MstTable from "./msttable";
import { API_KEY, BASE_API_URL } from "./environment";
import Modalerror from "./ModalError/ErrorModal";
import ModalPage from "./ModalWhite/modalpage";
import Onttable from "./onttable";

function SecondTopbar(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  console.log(props, "ssc");
  const { fdhName } = useParams();
  const { mstportCount } = useParams();
  const { latitude } = useParams();
  const { longitude } = useParams();
  const { address } = useParams();
  const { fdhstring } = useParams();
  var mod = props.modelNumber;

  const [data, setData] = useState([]);
  const { jobNumber } = useParams();
  const [showerrmodal, setShowerror] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [fdhview, setFdhListview] = useState(false);
  const [penico, setpenico] = useState(false);
  const [fdhnameset, setFdhName] = useState("");
  // const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // const [mstfilteredSuggestions, setmstFilteredSuggestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pageactive, setPageactive] = useState(false);
  const [showpagemodal, setPageModal] = useState(false);
  const [modelNumberval, setModalnumberval] = useState("");
  const [objectONTdata, setobjectONTdata] = useState({});
  const [modelethernet, setmodalethernet] = useState([
    { name: "TA401", ethernetPort: 1 },
    { name: "TA362", ethernetPort: 2 },
    { name: "C424G", ethernetPort: 4 },
    { name: "TA324G1", ethernetPort: 4 },
    { name: "TA324RG", ethernetPort: 4 },
    { name: "TA334", ethernetPort: 4 },
    { name: "TA374", ethernetPort: 4 },
    { name: "TA372", ethernetPort: 2 },
    { name: "720GE", ethernetPort: 1 },
    { name: "740GE", ethernetPort: 1 },
    { name: "712GE", ethernetPort: 2 },
    { name: "721GE", ethernetPort: 2 },
    { name: "722GE", ethernetPort: 2 },
    { name: "742GE", ethernetPort: 2 },
    { name: "743GE", ethernetPort: 2 },
    { name: "744GE", ethernetPort: 2 },
    { name: "726GE", ethernetPort: 4 },
    { name: "726GE-I", ethernetPort: 4 },
    { name: "836GE-RSG", ethernetPort: 4 },
    { name: "C844G WI-FI INTEGRATED ONT", ethernetPort: 4 },
    { name: "714GE", ethernetPort: 2 },
    { name: "724GE", ethernetPort: 2 },
    { name: "727GE", ethernetPort: 4 },
    { name: "760GE", ethernetPort: 4 },
    { name: "760GX", ethernetPort: 4 },
    { name: "765GE", ethernetPort: 4 },
    { name: "765GX-R", ethernetPort: 4 },
    { name: "766GX", ethernetPort: 4 },
    { name: "766GX-R", ethernetPort: 4 },
    { name: "767GX-R", ethernetPort: 4 },
    { name: "762GE", ethernetPort: 8 },
    { name: "762GX", ethernetPort: 8 },
    { name: "763GX", ethernetPort: 8 },
    { name: "763GX-R", ethernetPort: 8 },
    { name: "TA324", ethernetPort: 4 },
    { name: "TA351", ethernetPort: 1 },
    { name: "TA352-4TH-GEN", ethernetPort: 2 },
    { name: "TA362S", ethernetPort: 2 },
    { name: "TA372R", ethernetPort: 2 },
    { name: "TA374-4TH-GEN", ethernetPort: 4 },
    { name: "TA424RG", ethernetPort: 4 },
    { name: "710", ethernetPort: 1 },
    { name: "711", ethernetPort: 2 },
    { name: "714", ethernetPort: 2 },
    { name: "720", ethernetPort: 1 },
    { name: "721", ethernetPort: 1 },
    { name: "724", ethernetPort: 2 },
    { name: "725", ethernetPort: 1 },
    { name: "710G", ethernetPort: 1 },
    { name: "710GX", ethernetPort: 1 },
    { name: "711G", ethernetPort: 2 },
    { name: "711GX", ethernetPort: 1 },
    { name: "714G", ethernetPort: 2 },
    { name: "714GX", ethernetPort: 2 },
    { name: "716GE-I R2", ethernetPort: 4 },
    { name: "720G", ethernetPort: 1 },
    { name: "720GX", ethernetPort: 1 },
    { name: "721G", ethernetPort: 1 },
    { name: "721GX", ethernetPort: 1 },
    { name: "722G", ethernetPort: 1 },
    { name: "724G", ethernetPort: 2 },
    { name: "725GE", ethernetPort: 2 },
    { name: "725GX", ethernetPort: 1 },
    { name: "740G", ethernetPort: 1 },
    { name: "762G", ethernetPort: 8 },
    { name: "765G", ethernetPort: 4 },
    { name: "765G-R", ethernetPort: 4 },
    { name: "766GX-R-24", ethernetPort: 4 },
    { name: "801G", ethernetPort: 1 },
    { name: "836GE", ethernetPort: 4 },
    { name: "DP010011-01", ethernetPort: 1 },
    { name: "DP040011-01", ethernetPort: 4 },
    { name: "DP080011-01", ethernetPort: 8 },
    { name: "DP160011-01", ethernetPort: 16 },
    { name: "C5500XK", ethernetPort: 1 },
    { name: "C6500XK", ethernetPort: 1 },
    { name: "803G", ethernetPort: 1 },
    { name: "711GE", ethernetPort: 2 },
    { name: "716GE-I", ethernetPort: 4 },
    { name: "716GE", ethernetPort: 4 },
    { name: "717GE", ethernetPort: 4 },
    { name: "TA411", ethernetPort: 1 },
    { name: "TA352", ethernetPort: 2 },
    { name: "TA452", ethernetPort: 2 },
    { name: "TA324-4TH-GEN", ethernetPort: 4 },
    { name: "TA454", ethernetPort: 4 },
    { name: "DP040011-01", ethernetPort: 1 },
    { name: "DP040011-01", ethernetPort: 4 },
    { name: "DP080011-01", ethernetPort: 8 },
    { name: "DP160011-01", ethernetPort: 16 },
  ]);
  const [serialontname, setserialOntname] = useState("");

  const Cuid = sessionStorage.getItem("encryptedKey");
  const { mstName } = useParams();
  const { ontName } = useParams();
  const navmst = useNavigate();
  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };
  const nav = useNavigate();

  useEffect(() => {
    setModalnumberval(props.modelNumber);
    if (props.data == true) {
      setPageactive(props.data);
      setobjectONTdata(props.ontobj);
    } else {
      setPageactive(false);
    }

    console.log(props, "ss");
    // setpagesavePop(p)
  }, [props.data]);
  useEffect(() => {
    if (props.modelNumber !== "") {
      setModalnumberval(props.modelNumber);
    }
  }, [props.modelNumber]);

  const formattedDate = new Date(
    props.auditTimeval ? props.auditTimeval : "NA"
  ).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedTime = new Date(
    props.auditTimeval ? props.auditTimeval : "NA"
  ).toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });
  console.log(props.auditTimeval, formattedTime, formattedDate, "echecl");
  //to close list
  const onpagenav = () => {
    if (pageactive == true) {
      setPageModal(true);
    }
    if (pageactive == false) {
      nav("/search");
    }
  };
  //var fdh = props.fdhNamevalue;

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/getFdhByMst?mst=" +
          mstName,
        headers
      )
      .then((response) => {
        const data = response.data;
        if (data.message === "No Records Found for the MST Name") {
          // setmstFilteredSuggestions([]);
        } else {
          var datafdh = data[0] ? data[0] : "NA";
          setFdhName(datafdh);
          // setmstFilteredSuggestions(data);
          handleFDHList(datafdh);
        }
      })
      .catch((error) => {
        // setmstFilteredSuggestions([]);
        if (error.response) {
          if (error.reponse.status === 404) {
            // setmstFilteredSuggestions([]);
          }
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  }, []);

  const handleFDHList = (fdhvalue) => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/getMstListByFdh?fdh=" +
          fdhvalue ,
        headers
      )
      .then((response) => {
        const data = response.data;
        if (data.message === "No Records Found for the FDH Name") {
          // setFilteredSuggestions([]);
        } else {
          // setFilteredSuggestions(data);
          // console.log(filteredSuggestions, "sugg");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 502 || error.response.status === 500) {
            setShowerror(true);
          }
        } else {
          setShowerror(true);
        }
      });
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    if (inputValue.length >= 2) {
      if (inputValue.includes("ME=")) {
        if (inputValue.length >= 5) {
          axios
            .get(
              process.env.REACT_APP_BASE_API_URL +
                "/searchByFdh?fdh" +
                inputValue ,
              headers
            )
            .then((response) => {
              const data = response.data;

              // setFilteredSuggestions(data);
            });
        }
      } else {
        axios
          .get(
            process.env.REACT_APP_BASE_API_URL +
              "/searchByFdh?fdh=" +
              inputValue,
            headers
          )
          .then((response) => {
            const data = response.data;

            // setFilteredSuggestions(data);
            // console.log(filteredSuggestions, "sugg");
          });
      }
    }
  };
  const removeList = () => {
    // setFilteredSuggestions([]);
  };
  const change = () => {
    setFdhListview(true);
  };
  const handleSelectSuggestion = (event) => {
    var inputstring = event.target.value;
    if (inputstring !== "0") {
      nav("/mstmain/" + inputstring + "/" + fdhstring + "/" + mstportCount);
    }
  };
  useEffect(() => {
    props.serialontnameItem(props.ontserial);
    setserialOntname(props.ontserial)
  }, [props.ontserial]);
  const handlemakeinp = () => {
    setpenico(!penico);
    setserialOntname(props.ontserial);
    props.serialontnameItem(props.ontserial);
  };
  const handleserialitem = (event) => {
    setserialOntname(event.target.value);
    props.serialontnameItem(event.target.value);
  };
  const handleSplitterLeg = (event) =>{
     props.handlesplitter(event);
  }
  const handleModelvalue = (event) => {
    setModalnumberval(event.target.value);
    props.updateSelectedModel(event.target.value);
    console.log(event.target.value);
  };
  const handleFDHSelectSuggestion = (event) => {
    var inputstring = event.target.value;
    // setFilteredSuggestions([])
    if (inputstring !== "0") {
      axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            "/getMstListByFdh?fdh=" +
            inputstring ,
          headers
        )
        .then((response) => {
          const data = response.data;

          // setFilteredSuggestions(data);
          // console.log(filteredSuggestions, "sugg");
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 502 ||
              error.response.status === 500
            ) {
              setShowerror(true);
            }
          } else {
            setShowerror(true);
          }
        });
    }
  };
  const handleDataFromPage = () => {
    setPageModal(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const navfdh = useNavigate();
  const handleFdhpage = () => {
    const fdhname = props.fdhNamevalue;
    const fdhnames = fdhname.split("/")[0];
    navfdh("/main/" + fdhnames + "/NA/NA/NA/NA");
  };
  const handleMstpage = () => {
    const mstname = props.mstNamevalue;
    const mstnames = mstname.split("/")[0];
    navmst("/mstmain/" + mstnames + "/NA/NA");
  };
  return (
    <>
      {showpagemodal && <ModalPage onDataFromChild={handleDataFromPage} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="second-nav">
        <div className="container">
          <div className="row">
            <div className="col-2 mob-col-12">
              <div className="content-one">
                <span className="hidden-mob">
                  {/* <Link > */}
                  <FontAwesomeIcon
                    onClick={onpagenav}
                    className="ico-nav mb-2 p-3 mob-padding-5"
                    style={{ color: "#011E60" }}
                    icon={faHome}
                  />
                  {/* </Link> */}
                </span>
                <h6>ONT</h6>
              </div>
            </div>
            <div className="col-6 mob-col-12">
              <div className="content-two tab-content-two">
                <div className="d-flex flex-row mob-b-row adjust-tab-content">
                  <div
                    className="d-flex flex-column mob-col-4 auditcounts"
                    style={{ "padding-right": "28px" }}
                  >
                    <div>
                      <span>ONT Name:</span>
                    </div>
                    <div>
                      <span>MST Name:</span>
                    </div>
                    <div>
                      <span>FDH Name:</span>
                    </div>
                    {/* <div>
                      <span>ONT Serial No:</span>
                    </div> */}
                    <div>
                      <span>Distribution Cable:</span>
                    </div>
                    {/* <div>
                      <span>Audit Status:</span>
                    </div>
                    <div>
                      <span>Audit Date & Time:</span>
                    </div> */}
                    {/* <div className="mt-2">
                      <span>MST:</span>
                    </div> */}
                  </div>
                  <div className="d-flex flex-column mob-col-8 auditcount-ont">
                    <div>
                      <b>{props.ontNamevalue ? props.ontNamevalue : "NA"}</b>
                    </div>
                    <div>
                      <b onClick={handleMstpage} style={{ cursor: "pointer" }}>
                        {props.mstNamevalue ? props.mstNamevalue : "NA"}
                      </b>
                    </div>
                    <div style={{display:"flex"}}>
                      <b onClick={handleFdhpage} style={{ cursor: "pointer" }}>
                        {props.fdhNamevalue ? props.fdhNamevalue : "NA"}
                      </b>
                      &nbsp;&nbsp;
                      {
                        props.showsplitico && (
                          <div style={{ width: '10px', textAlign: 'center' }} title="Change Splitter Assign">
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={handleSplitterLeg}
                            icon={faPen}
                          />
                        </div> 
                        )
                      }
                    </div>
                    {/* <div>
                      <span>{props.ontserial?props.ontserial:'NA'}</span>
                    </div> */}
                    <div>
                      {/* <span>{props.cableName?props.cableName:'NA'}</span> */}
                      <b style={{ cursor: "pointer" }}>
                        {props.cableNamevalue ? props.cableNamevalue : "NA"}
                      </b>
                    </div>
                    {/* <div className="">
                      <span>
                      {props.status} 
                      </span>
                    </div>
                    <div className="">
                    <span>{
                        formattedDate === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedDate} </span>
                        )
                      }</span>, &nbsp;
                      <span>{
                        formattedTime === 'Invalid Date'? (
                          <span>NA</span>
                        ):(
                          <span>{formattedTime}  CST</span>
                        )
                      }</span>
                    </div> */}
                  </div>
                  {/* <div
                    className="d-flex flex-column mob-col-12 pb-2 mob-remove-padding-left tab-remove-padding-left"
                    style={{ "padding-left": "20px" }}
                  >
                    <select
                          className="searchresult-input mb-2"
                          value={selectedOption}
                          onChange={handleSelectSuggestion}
                        >
                          <option value="0">Select MST</option>
                          {filteredSuggestions?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                   
                    
                    <div>
                    <select
                          className="searchresult-input"
                          value={selectedOption}
                          onChange={handleFDHSelectSuggestion}
                        >
                          <option value="0">Select FDH</option>
                          {mstfilteredSuggestions?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-2 mob-col-6">
              <div className="content-two tab-counts">
                <div className="d-flex flex-column mob-col-12 mob-remove-padding-left tab-padding">
                  <div>
                    OLT Device :{" "}
                    <b>
                      {props.ontobj.oltDeviceType
                        ? props.ontobj.oltDeviceType
                        : "NA"}
                    </b>
                  </div>
                  <div>
                    RONTA :{" "}
                    <b>{props.ontobj.ronta ? props.ontobj.ronta : "NA"}</b>
                  </div>
                  <div>
                    Technology :{" "}
                    <b>
                      {props.ontobj.technology ? props.ontobj.technology : "NA"}
                    </b>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2 mob-col-6">
              <div className="content-three">
                <div className="d-flex flex-column">
                  <div className="">
                    <>
                      <span style={{ "font-size": "12px" }}>
                        ONT Serial No:{" "}
                        <span>
                          {penico == false ? (
                            <>
                              <b>{props.ontserial ? props.ontserial : "NA"}</b>
                            </>
                          ) : (
                            <>
                              <input
                                className="serialinp"
                                value={serialontname}
                                onChange={handleserialitem}
                              />
                            </>
                          )}
                          &nbsp;&nbsp;
                          {penico == false ? (
                            <>
                              <FontAwesomeIcon
                                style={{ cursor: "pointer" }}
                                onClick={handlemakeinp}
                                icon={faPen}
                              />
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                style={{ cursor: "pointer" }}
                                onClick={handlemakeinp}
                                icon={faTimes}
                              />
                            </>
                          )}
                        </span>
                      </span>
                    </>
                  </div>
                  <div className="mt-1">
                    <select
                      className="serialinp"
                      value={modelNumberval}
                      onChange={handleModelvalue}
                    >
                      <option value="">Select Model</option>
                      {modelethernet.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function OntMain() {
  const [fdhNamevalue, setFDHNamevalue] = useState();
  const [mstNamevalue, setMSTNamevalue] = useState();
  const [cableNamevalue, setCableNamevalue] = useState();
  const [ontNamevalue, setOntNamevalue] = useState();
  var [ontserial, setOntSerial] = useState();
  const [ontserialfin, setOntSerialfin] = useState();
  const [modelNumber, setmodelNumber] = useState();
  const [datafromchild, setDatafromChild] = useState("");
  const [auditStat, setAuditStat] = useState("");
  const [auditTime, setAuditTime] = useState("");
  const [serialontname, setserialOntname] = useState("");
  const [ontobj, setontObj] = useState({});
  const[split,setSplit]=useState(false);
  const[showsplitico,setshowsplitico]=useState(false);
  const [SelectedmodelNumber, setSelectedmodelNumber] = useState(null);

  useEffect(()=>{
    setOntSerialfin(ontserial)
  },[ontserial])
  const receivestatus = (data) => {
    setAuditStat(data);
  };
  const receiveDataFromChild = (data) => {
    setDatafromChild(data);
  };
  const receivedata = (data) => {
    setDatafromChild(data);
  };
  const receivedataTime = (data) => {
    setAuditTime(data);
  };
  const receivedatamst = (data) => {
    setMSTNamevalue(data);
  };
  const receivedatafdh = (data) => {
    setFDHNamevalue(data);
  };
  const receivedatacablename = (data) => {
    setCableNamevalue(data);
  };
  const receivedataont = (data) => {
    setOntNamevalue(data);
  };
  const receiveserial = (data) => {
    setOntSerial(data);
  };
  const receivedModal = (data) => {
    setmodelNumber(data);
  };
  const receiveontobj = (data) => {
    setontObj(data);
  };
  const handleSerial = (data) => {
    setserialOntname(data);
  };
  const updateSelectedModel = (mdel) => {
    setSelectedmodelNumber(mdel);
  };
  const handlesplitter = (event)=>{
   setSplit(true);
  }
  const receivesplit = ()=>{
    setSplit(false);
  }
  const receivedatapend = (data)=>{
    setshowsplitico(data);
  }
 
  return (
    <>
      <Topbar data={datafromchild}></Topbar>
      <SecondTopbar
        data={datafromchild}
        showsplitico={showsplitico}
        serialontnameItem={handleSerial}
        handlesplitter={handlesplitter}
        auditTimeval={auditTime}
        status={auditStat}
        mstNamevalue={mstNamevalue}
        ontserial={ontserial}
        fdhNamevalue={fdhNamevalue}
        ontNamevalue={ontNamevalue}
        ontobj={ontobj}
        cableNamevalue={cableNamevalue}
        modelNumber={modelNumber}
        updateSelectedModel={updateSelectedModel}
      />
      <Outlet />
      <Onttable
        passfdhname={receivedatafdh}
        passcablename={receivedatacablename}
        passmstname={receivedatamst}
        passStatus={receivedatapend}
        splitter={split}
        passontname={receivedataont}
        passontserial={receiveserial}
        passontdata={receiveontobj}
        passmodelnumber={receivedModal}
        onPagesaveData={receivedata}
        onPageReviewtime={receivedataTime}
        onPageStatus={receivestatus}
        onDataFromChild={receiveDataFromChild}
        serialontname={serialontname}
        selectedModel={SelectedmodelNumber}
        sendsplitteroff={receivesplit}
      ></Onttable>
    </>
  );
}

export default OntMain;
