import axios from "axios";

export default axios.create({

    baseURL:"https://qfast-service-dev.kubeodc-test.corp.intranet",
    headers:{

        "Content-type":"application/json"

    }
})

 

 