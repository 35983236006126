import React from "react";

import MSTDropdown from "./MSTDropdown";

const MSTviewHeader = (props) => {
 
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {props.tabhead.tabhead == "1" && <th className="thstyle">MST</th>}

          {props.tabhead.tabhead == "2" && (
            <th className="thstyle" style={{ textAlign: "left" }}>
              <MSTDropdown FDH = {props.tabhead.activeIndex}/>
            </th>
          )}
        </tr>
      </thead>
    </table>
  );
};

export default MSTviewHeader;
