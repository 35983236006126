// import logo from './logo.svg';
import "./App.css";
import "./Search.css";
import { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Map from './assets/map.png';
import Dash from './assets/ash.png';
import { useLocation, useNavigate } from "react-router-dom";
import { faSearch,faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Topbar from "./topbar";
import axios from "axios";
import Modalerror from "./ModalError/ErrorModal";
import {API_KEY, BASE_API_URL } from "./environment";


function Search(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const[data,setData] =useState([]);
  const [activeTab,setactiveTab] = useState(true);

  const [inputValue, setInputValue] = useState('');
  const [mstinputValue, setMstInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [mstfilteredSuggestions, setmstFilteredSuggestions] = useState([]);
  // const nav = useNavigate();
  const navigatemst=useNavigate();

  var handleClickOutside = (event) => {
    setFilteredSuggestions([]);
    console.log("clickout");
    }
    
    document.addEventListener('click' , handleClickOutside);
      const handleClearClick = () => {
        setInputValue('');
     setFilteredSuggestions([]);
      };

   const [toggleErrModal, setToggleErrModal] = useState(false);
   const nav = useNavigate();
   const handleErrModal = () => {
    setToggleErrModal(false);
  };
   const handleChange=(e)=>{
    const inputValue =e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    if(inputValue.length >= 2){
      axios.get( BASE_API_URL + 'getFdh?startWith='+ inputValue + API_KEY ).then((response) =>{
        const data= response.data;
  
        setFilteredSuggestions(data);
        console.log(filteredSuggestions,"sugg");
      }) .catch((e) => {
        
        if (e.response) {
          if (e.response.status === 400 || e.response.status === 502 || e.response.status === 500) {
            setToggleErrModal(true);
          } 
        } else {
          setToggleErrModal(true);
        }
      });
    
   }



   
   }

   const navigateReport = ()=>{
    nav('/report');
      console.log('sdd')
   }

  //  
  const handleMstChange=(e)=>{
    const mstinputValue =e.target.value;
    setMstInputValue(mstinputValue);
    console.log(mstinputValue);
    if(mstinputValue.length >= 3){
      axios.get( BASE_API_URL + 'getMst?mst='+ mstinputValue + API_KEY).then((response) =>{
        const data= response.data;
        setmstFilteredSuggestions(data.mstSearch);
        console.log(filteredSuggestions,"sugg");
      }).catch((e) => {

        if (e.response) {
          if (e.response.status === 400 || e.response.status === 502 || e.response.status === 500) {
            setToggleErrModal(true);
          } 
        } else {
          setToggleErrModal(true);
        }
      });
   }   
   }

   //mst review navigate
  const  handlemstreview =()=>{
    
    navigatemst("/mstreview")
  }

   const handleSelectSuggestion =(suggestion)=>{
    setInputValue(suggestion.fdh);
    console.log(suggestion,"name");
    var fdhAddress = suggestion.fdhAddress?suggestion.fdhAddress:'NA';
    var latitude = suggestion.latitude?suggestion.latitude:'NA';
    var longitude = suggestion.longitude?suggestion.longitude:'NA';
    var fdhportCount = suggestion.fdhPortCount?suggestion.fdhPortCount:'NA'
    nav("/main/" + suggestion.fdh + '/' + latitude + '/' + longitude + '/' + fdhAddress + '/' + fdhportCount);
   }
   const handleMSTSelectSuggestion =(suggestion)=>{
    var inputstring = suggestion.mst;
    var mstPortCount = suggestion.mstPortCount?suggestion.mstPortCount:'NA';
    setMstInputValue(inputstring);
    nav("/mstmain/" + inputstring + '/' + mstPortCount);
   }
   const sendRedirect = ()=>{
    nav("/Review");
   }
   const changeTab = (value)=>{

    if(value == 'FDH'){
      setactiveTab(true)
    }
    else{
      setactiveTab(false)
    }
   
   }

  return (
    <>
      <Topbar></Topbar>
      <div className="search-audit">
        <div className="search-center">
          <div className="card">
            <div className="card-body">
              <div className="card-title d-flex justify-content-center">
                <b>AUDIT</b>
              </div>
              <div style={{"text-align":"center","display":'flex',"justify-content":'center'}}>
                <div  onClick={() => changeTab('FDH')} className={activeTab ? 'activeTab-class' : 'inactiveTab-class'}>FDH</div>
                <div onClick={() => changeTab('MST')} className={!activeTab ? 'activeTab-class' : 'inactiveTab-class'}>MST</div>
              </div>
              { activeTab &&
              <>
              <div className="search">
        <div className="mob-container">

          <div class="search-container-page">
            <div className="s-icon-one">
              <FontAwesomeIcon icon={faSearch} />
            </div>

            <input type="text" className="search-input-icon" placeholder="Search By FDH"  value={inputValue} onChange={handleChange}/>
            {inputValue ? (<span className="clear-buttons" onClick={handleClearClick}>
                              &times;
                            </span>):( <span></span>)}
          </div>
          {filteredSuggestions?.length > 0 && (
                    <ul className="list-search" >
                      <span>
                        {filteredSuggestions.map((suggestion) => (
                          <li key={suggestion.fdh} onClick={() => handleSelectSuggestion(suggestion)}>
                            {suggestion.fdh}
                          </li>
                        ))}
                      </span>
                    </ul>
                  )}


          <div class="container-wrapper">
            <div className="container-left">
              <img src={Map} className="App-logo" alt="logo" />

              <p className="mt-2">Search Near<br></br> By FDH</p>
            </div>

            <div className="container-right" onClick={()=>sendRedirect()}>
              <img src={Dash} className="App-logo " alt="logo" />

              <p className="mt-2">Review</p>
            </div>
          </div>
          
        </div>
              </div></>
              }
              { !activeTab &&
              <>
              <div className="search">
        <div className="mob-container">

          <div class="search-container-page">
            <div className="s-icon-one">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input type="text" className="search-input-icon" placeholder="Search By MST"  value={mstinputValue} onChange={handleMstChange}/>
                   
          </div>
          {mstfilteredSuggestions?.length > 0 && (
                    <ul className="list-search" >
                      <span>
                        {mstfilteredSuggestions.map((suggestion) => (
                          <li key={suggestion.mst} onClick={() => handleMSTSelectSuggestion(suggestion)}>
                            {suggestion.mst}
                          </li>
                        ))}
                      </span>
                    </ul>
                  )}


          <div class="container-wrapper">
            <div className="container-left">
              <img src={Map} className="App-logo" alt="logo" />

              <p className="mt-2">Search Near<br></br> By MST</p>
            </div>

            <div className="container-right" 
            // onClick={()=>sendRedirect()}
            onClick={handlemstreview}
            >
              <img src={Dash} className="App-logo " alt="logo" />

              <p className="mt-2">Review</p>
            </div>
          </div>
          <div className="mst_report"  onClick={() => navigateReport()}>
              <div className="d-flex justify-content-center">
                  <span className="report-text">MST Installation Report</span>         
              </div>
          </div>
        </div>
              </div></>
              }
            </div>
          </div>
        </div>
      </div>
      
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
    </>
  );
}

export default Search;
