import React, { useState, useEffect } from "react";
import Topbar from "./topbar";
import Select from "react-select";
import "./report.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "./LoaderReport/Loader";
import Modalerror from "./ModalError/ErrorModal";
import {  API_KEY,BASE_API_URL } from "./environment";
import Loader from "./LoaderReport/Loader";

var reversedDate;
var filteredData = [];
function SecondTopbar(props) {
  // useEffect(() => {}, []);
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
  const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  const nav = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [wirecent, setData] = useState([]);
  var [mstreport, setMstreport] = useState([]);
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [wirevalue, setWirevalue] = useState(null);
  const [showloader, setshowloader] = useState(false);
  const [activewire, setactivewire] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeIndex, setActiveIndex] = useState(false);
  const [showerrmodal, setShowerror] = useState(false);
  const [toggleErrModal, setToggleErrModal] = useState(false);
  const [apidate, setapidate] = useState("");
  const currentDate = new Date().toISOString().split('T')[0];
  
  const handleItemClick = (value) => {
    setActiveIndex(value.MST_NAME);
    var mstname = value.MST_NAME ? value.MST_NAME : "NA";
    var fdhname = value.FDH ? value.FDH : "NA" ;
    nav("/mstmain/" + "ME=" + mstname + "/"+ fdhname + "/NA");
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" }

  ];
  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };


  // const [datepass, setDatepass] = useState('03-09-2023');

  const datebasedwire = (date) => {
    setshowloader(true);
    fetch( process.env.REACT_APP_BASE_API_URL + "/getReportFromSF?date=" + date , headers)
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        var dat= data.WireCentersList;
       const wirdata = dat.map((value)=>({
      value, label:value
       }));
       console.log(wirdata);

        setData(wirdata);
        setMstreport(data.ReportFromSF);
        setshowloader(false);
        return data;
      })
      .catch((error) => {
        console.log(error);
        setshowloader(false);
        if (error) {
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {

        }
      });
  };

  const getdateval = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
    var originalDate = event.target.value;
    console.log(originalDate);
    setactivewire(true);
    const dateComponents = originalDate.split("-");
    // Check if the date format is valid (assuming MM/DD/YY format)
    if (dateComponents.length !== 3) {
      return "Invalid date format";
    }

    // Reorder the components to form the reversed date (YY/DD/MM format)
    reversedDate =
      dateComponents[1] + "/" + dateComponents[2] + "/" + dateComponents[0];
    console.log(reversedDate);

    setapidate(reversedDate);
    datebasedwire(reversedDate);
    return reversedDate;
  };

  const getwireval = (event) => {
    console.log(event)
    console.log(event.value);
    setSelectedValues(event)
    setWirevalue(event.value);
    callreport(event.value);
  };
  const callwirecent = () => {
    fetch( process.env.REACT_APP_BASE_API_URL + "/getFdh?startWith=ME=&state=FL" , headers)
      .then((res) => res.json())
      .then((data) => setData(data));
  };
  const handleErrModal = () => {
    setToggleErrModal(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  const callreport = (wire) => {
    setshowloader(true);
    fetch(
       process.env.REACT_APP_BASE_API_URL + "/getReportFromSF?&date=" + apidate + "&wirecenter=" + wire , headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data) {
          setMstreport(data.ReportFromSF);
          setshowloader(false);
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
        setshowloader(false);
        if (error) {
          setMstreport([])
          if (
            error.status === 400 ||
            error.status === 502 ||
            error.status === 500
          ) {
            setShowerror(true);
          }
        } else {
        }
      });
  };
  return (
    <>
      {showloader && <Loader />}
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="second-nav">
        <div className="row">
          <div className="col-2 mob-col-12">
            <div className="content-one">
              <span className="hidden-mob">
                <Link to="/search">
                  <FontAwesomeIcon
                    className="ico-nav mb-2 p-3 mob-padding-5"
                    style={{ color: "#011E60" }}
                    icon={faHome}
                  />
                </Link>
              </span>
              <h6>MST Report</h6>
            </div>
          </div>
          <div className="col-4 mob-dis-block" style={{ textAlign: "left" }}>
            <div className="content-sec"></div>
          </div>
          <div className="col-6 mob-dis-block" style={{ textAlign: "right" }}>
            <div className="content-sec mt-3"></div>
          </div>
        </div>
      </div>

      <div className="sub-card">
        <div className="row">
          <div className="col-2 mob-col-4">
            <div className="d-flex flex-column outerdate datewid">
              <div>Date</div>
              <div>
                <input
                  type="date"
                  id="dateinpt"
                  value={selectedDate}
                  onChange={getdateval}
                  min={currentDate}
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>
          <div className="mob-col-1 block-in-web"></div>
          <div
            className="col-8 wirecent mob-col-7"
            style={{ textAlign: "left" }}
          >
            <div className="d-flex flex-column outerdate">
            <div>
  
  


</div>
              {activewire && (
                <>
                  <div>Select Wirecenter</div>
                  <div>
                    {/* <select
                      className="form-control"
                      id="wire"
                      onChange={getwireval}
                      style={{ height: "34px" }}
                    >
                      <option value="0">Select Wirecenter</option>

                      <>
                        {wirecent?.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </>
                    </select> */}
                    <Select
    options={wirecent}
    onChange={getwireval}
    value={selectedValues}
    isMulti={false} // Set to true if you want to allow multiple selections
  />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="report-table">
          {mstreport?.length > 0 ? (
            <table className="table-responsive">
              <thead>
                <tr>
                  <th>MST NAME</th>
                  <th>DTN</th>
                  <th>FDH</th>
                  <th>FDH DISTRIBUTION STRAND</th>
                  <th>FDH LOCATION</th>
                  <th>JOB TYPE</th>
                  <th>LOCATION LATITUDE</th>
                  <th>LOCATION LONGITUDE</th>
                  <th>MST LOCATION</th>

                  <th>MST PORT</th>
                  <th>OLT</th>
                  <th>SERVICE ADDRESS</th>
                  <th>SERVICE APPOINTMENT NUMBER</th>
                  <th>SERVICE APPOINTMENT STATUS</th>
                  <th>SMARTNID ENABLED</th>
                  <th>SPLITTER PORT</th>
                </tr>
              </thead>
              <tbody>
                {mstreport.map((item, rowIndex) => (
                  <tr key={item.id}>
                    <td
                      key={item.id}
                      className={`mstnameact ${
                        item.MST_NAME == activeIndex ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleItemClick(item)}
                    >
                      {item.MST_NAME}
                    </td>
                    <td>{item.DTN}</td>
                    <td>{item.FDH}</td>
                    <td>{item.FDH_DISTRIBUTION_STRAND}</td>
                    <td>{item.FDH_LOCATION}</td>
                    <td>{item.JOB_TYPE}</td>
                    <td>{item.LOCATION__LATITUDE}</td>
                    <td>{item.LOCATION__LONGITUDE}</td>
                    <td>{item.MST_LOCATION}</td>
                    <td>{item.MST_PORT}</td>
                    <td>{item.OLT}</td>
                    <td>{item.SERVICE_ADDRESS}</td>
                    <td>{item.SERVICE_APPOINTMENT_NUMBER}</td>
                    <td>{item.SERVICE_APPOINTMENT_STATUS}</td>
                    <td>{item.SMARTNID_ENABLED}</td>
                    <td>{item.SPLITTER_PORT}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            // <p>Items are not available.</p>
            <div className="d-flex justify-content-center">
              {wirevalue != null ? (
                <>
                  <b>Data Not Available</b>
                </>
              ) : (
                <>
                  <b>Please Select the Filter</b>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function ReportMst() {
  return (
    <div className="App">
      <Topbar />
      <SecondTopbar></SecondTopbar>
    </div>
  );
}

export default ReportMst;
