import React from "react";
import { useState, useEffect, useMemo } from "react";
import ONTviewHeader from "./ONTviewHeader";
import ONTdatatab from "./ONTdatatab";
// import MyComponent from "./Mycomponent";
import Modalblack from "../ModalBlack/Modal";
import Modalerror from "../ModalError/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./css/ONTview.css";
import "./css/search.css";
import { Modal } from "bootstrap";
import Contentloading from "./ONTContentloading";
import {API_KEY, BASE_API_URL } from "../environment";
import axios from "axios";


// const fdhinfo = [
//   // "CPCRFLJ0353",
//   // "CPCRFLJ0405",
//   // "CPCRFLJ0391",
//   // "CPCRFLJ0392",
//   // "CPCRFLJ0351",
//   // "CPCRFLJ0390",
//   // "CPCRFLJ0356",
//   // "CPCRFLJ0395",
//   // "CPCRFLJ0357",
//   // "CPCRFLJ0403",
//   // "CPCRFLJ0349",
//   // "CPCRFLJ0393",
//   // "CPCRFLJ0350",
//   // "NFMYFLJ0004",
//   // "NFMYFLJ0063",
//   // "CPCRFLJ0386",
//   // "NFMYFLJ0064",
//   // "CPCRFLJ0387",
//   // "CPCRFLJ0404",
//   // "CPCRFLJ0388",
//   // "CPCRFLJ0397",
//   // "CPCRFLJ0355",
//   // "CPCRFLJ0351",
//   // "CPCRFLJ0344",
//   // "CPCRFLJ0352",
//   // "CPCRFLJ0396",
//   // "CPCRFLJ0389",
//   // "CPCRFLJ0345",
//   // "CPCRFLJ0399",
//   // "CPCRFLJ0398",
//   // "CPCRFLJ0385",
//   // "NFMYFLJ0065",
//   // "NFMYFLJ0066",
//   // "NFMYFLJ0034",
// ];

// const fdhinfo=  [
//   {
//       "FDH": "ME=CPCRFLCC CPCRFLJ0393",

//   },
//   {
//       "FDH": "ME=CPCRFLCC CPCRFLJ0395",

//   },
//   {
//       "FDH": "ME=CPCRFLCJ CPCRFLJ0399",

//   },
//   {
//       "FDH": "ME=CPCRFLCJ CPCRFLJ0403",

//   },
//   {
//       "FDH": "ME=CPCRFLCJ CPCRFLJ0405",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0344",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0345",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0349",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0350",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0351",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0352",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0353",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0355",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0356",

//   },
//   {
//       "FDH": "ME=CPCRFLCN CPCRFLJ0357",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0385",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0386",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0387",

//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0388",
//   },
//   {
//       "FDH": "ME=CPCRFLCR CPCRFLJ0389",
//   },
//   {
//       "FDH": "ME=CPCRFLCV CPCRFLJ0390",
//   },
//   {
//       "FDH": "ME=CPCRFLCV CPCRFLJ0391",
//   },
//   {
//       "FDH": "ME=CPCRFLCV CPCRFLJ0392",
//   },
//   {
//       "FDH": "ME=CPCRFLDI CPCRFLJ0397",
//   },
//   {
//       "FDH": "ME=CPCRFLDI CPCRFLJ0398",

//   },
//   {
//       "FDH": "ME=CPCRFLFF CPCRFLJ0404",

//   },
//   {
//       "FDH": "ME=CPCRFLXB CPCRFLJ0396",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0004",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0063",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0064",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0065",

//   },
//   {
//       "FDH": "ME=NFMYFLXB NFMYFLJ0066",

//   },
//   {
//       "FDH": "ME=NFMYFLXH NFMYFLJ0034",

//   }
// ]
const statuslist = [{ status: "COMPLETED" }, { status: "PENDING" }];
const ONTview = (props) => {
  const sessionId = sessionStorage.getItem("userId");
  const encryptedId = sessionStorage.getItem("encryptedKey");
  const headers = {
      headers: {
        "apikey": process.env.REACT_APP_API_KEY,
        "sessionId" : sessionId,
        "encryptedId":encryptedId
      }
  }
  console.log("check fdh", props);
  const [activeButton, setactiveButton] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleErrModal, setToggleErrModal] = useState(false);

  const toggleButton = (buttonId) => {
    setactiveButton(activeButton === buttonId ? null : buttonId);
  };
  const [mstinputValue, setMstInputValue] = useState("");
  const [mstfilteredSuggestions, setmstFilteredSuggestions] = useState([]);
  const [dataFDH, setDataFDH] = useState([]);
  const [tableheader, settableheader] = useState([]);
  const [errorreview, seterrorMst] = useState();
  const [activeIndex, setActiveIndex] = useState(false);
  const [selectedIndex, setIndex] = useState("");
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [splitterKeys, setsplitterKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [showloading, setShowloading] = useState(true);
  const [filteredData12, setfilteredData12] = useState([]);
  var [reviewstatus, setHeaderreview] = useState("");
  const [showerrmodal, setShowerror] = useState(false);
  var [statevalue, setstatevalue] = useState(null);
  var [enablewirecenter, setEnableWire] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const[dataComments,setDataComments]=useState('');
  const Cuid = sessionStorage.getItem("encryptedKey");
  const [dataWirecenter, setdataWirecenter] = useState([]);
  useEffect(() => {
    setToggleModal(true);
    // callFDHList();
  }, []);
  useEffect(()=>{
    console.log("tabel Data ", tableheader);
  },[tableheader])
  useEffect(() => {
    if (props.fdhList) {
      // setShowloading(false)
      setfilteredData(props.fdhList);
      console.log("checking 11");
    } else {
      console.log(dataFDH);
    }
  }, [props.fdhList]);
  const datetimeString = reviewstatus.reviewTime; // 24-hour format
  const ndsdatetime = reviewstatus.ndsReviewTime;
    // Convert the date and time into desired formats
    const formattedDate = new Date(datetimeString?datetimeString:'NA').toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  
    const formattedTime = new Date(datetimeString?datetimeString:'NA').toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    const ndsformattedDate = new Date(ndsdatetime?ndsdatetime:'NA').toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  
    const ndsformattedTime = new Date(ndsdatetime?ndsdatetime:'NA').toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });

    
  //status filter
  const handleStatus = (event) => {
    var datavalue = event.target.value;
    console.log(datavalue);
    setSelectedDate("");
    setInputValue('');
    settableheader([]);
    setstatevalue(datavalue);
    setdataWirecenter([]);
    setLoadingToggle(false);
    const val = {
      deviceName: "NA",
      deviceType: "NA",
      auditStatus: "NA",
      auditBy: "NA",
      auditTime: "NA",
      reviewStatus: "NA",
      reviewBy: "NA",
      reviewTime: "NA",
      auditType:"NA",
      mstWise:"NA",
      strandRange :"NA",
      comments : "NA",
      reviewRemarks:"NA",
      auditDropType:"NA",
      ndsReviewStatus : "NA",
      ndsReviewBy: "NA",
      ndsReviewTime:"NA"
    }
    props.onChildDataChange(val);
    setHeaderreview(val);

  
    if (datavalue != "0") {
      // /getAuditReviewInfo?deviceType=MST&auditStatus=PENDING&deviceName=ME=

      fetch(
        process.env.REACT_APP_BASE_API_URL +
          "/getOntReviewInfo?ont=" +
          // "&status="+
          datavalue , headers
      )
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          setfilteredData(data);
          setfilteredData12(data);
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            if (
              error.status === 400 ||
              error.status === 502 ||
              error.status === 500
            ) {
              setToggleErrModal(true);
            }
          } else {
          }
        });
      // callFDHList(param);
      setEnableWire(true);
    }else{
      setfilteredData([]);
      setfilteredData12([]);
      setEnableWire(false);
    }
  };

  //typeahead
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    console.log(inputValue);

    // if (inputValue.length >= 2) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            "/getOntReviewInfo?ont=" + 
            inputValue , headers
        )
        .then((response) => {
          const data = response.data;
          setfilteredData(data);
          setfilteredData12(data);
        })
        .catch((error) => {
          if (error.response.status == 400 || error.response.status == 404) {
            setfilteredData([]);
            setfilteredData12([]);
          } else {
            setToggleErrModal(true);
          }
        });
    //}
  };

  //date filter
  const getdateval = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
    const datevalue = event.target.value;
    fetch(
       process.env.REACT_APP_BASE_API_URL +
        "/getOntReviewInfo?ont=" +
        "&date=" +
        datevalue , headers
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        setfilteredData(data);
        setfilteredData12(data);
      })
      .catch((error) => {

        if (error) {
          if(error.status === 404 || error.status === 400){
            setfilteredData([]);
            setfilteredData12([]);
            settableheader([]);
          }
          if (
            error.status === 502 ||
            error.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
        }
      });
  };

  //TYPEAHEAD FDH
  // const handleMstChange=(e)=>{
  //   const mstinputValue =e.target.value;
  //   setMstInputValue(mstinputValue);
  //   console.log(mstinputValue);
  //   if(mstinputValue.length >= 3){
  //     axios.get(  process.env.REACT_APP_BASE_API_URL + 'getMst?mst='+ mstinputValue ).then((response) =>{
  //       const data= response.data;
  //       // setmstFilteredSuggestions(data.mstSearch);
  //       setfilteredData(data.mstSearch);
  //       setfilteredData12(data.mstSearch);
  //     }).catch((e) => {
  //       if (e.response) {
  //         if (e.response.status === 400 || e.response.status === 502 || e.response.status === 500) {
  //           setToggleErrModal(true);
  //         }
  //       } else {
  //         setToggleErrModal(true);
  //       }
  //     });
  //  }
  //  }
  //  const handleMSTSelectSuggestion =(suggestion)=>{
  //   var inputstring = suggestion.mst;
  //   console.log(inputstring);
  //   setMstInputValue(inputstring);
  //   // axios.get( BASE_API_URL + 'getFinalAuditReportByMST?mst='+ inputstring).then((response) =>{
  //   //   const data= response.data;
  //   //   // const tabledata = data;
  //   // })
  //  }
  // const callFDHList = ()=>{
  //   fetch(BASE_API_URL + "getFdh?startWith")
  //   .then((res) => res.json())
  //   .then((data) => {
  //     setDataFDH(data);
  //     setfilteredData(data);
  //     setfilteredData12(data);
  //     setShowloading(false);
  //     console.log('checking 1');
  //     setToggleModal(false);
  //   })
  //   .catch((e) => {
  //     // if (e.response) {
  //     //   if (e.response.status === 400 || e.response.status === 502 || e.response.status === 500) {
  //     //     setToggleErrModal(true);
  //     //   }
  //     // } else {
  //     //   setToggleErrModal(true);
  //     // }
  //   });

  // }

  //CPCRFLJ0392

  const handleclear = (clear)=>{
    setLoadingToggle(false)
  }
  const handleItemClick = (event, val,passedData) => {
    var deviceData=event?.target?.textContent ? event.target.textContent : selectedIndex
    setActiveIndex(deviceData);
    setIndex(deviceData);
    setLoadingToggle(true);
    if(passedData !== 'save'){
    settableheader([]);
    setsplitterKeys([]);
    }
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getAuditReviewInfo?deviceName=" +
        deviceData , headers
    ).then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then((data) => {
        console.log(data, "arr");
        if (data) {
          // const lastIndex = data.length - 1;
          // const lastObject = data[lastIndex];
          setDataComments(data[0]);
          setHeaderreview(data[0]);
          props.onChildDataChange(data[0]);
        }
      })
      .catch((e) => {
        if (e.response) {
          if(e.response.status === 404){
            setHeaderreview(val);
          }
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        }
      });
      if(passedData !== 'save'){
    fetch(
      process.env.REACT_APP_BASE_API_URL + "/getOntAuditReport?ont=" + event.target.textContent , headers
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          if (data.status === "404") {
            setLoadingToggle(false);
            seterrorMst(data.message);
          }
          if (data.status === "502") {
            setToggleErrModal(true);
          }
        } else {
          // settableheader(() => {
          //   console.log("data ",data);
          //   const arrayOfObjects = Object.keys(data)
          //     .map((key) => {
          //       const id = parseInt(key); // Convert the key to an integer as required
          //       return data.map((item) => ({ id:key, ...item }));
          //     })
          //     .flat();

          //   return arrayOfObjects;
          // });
          settableheader(data.map((dt,i)=>{
            return {id:i, ...dt}
          }));
          setsplitterKeys(Object.keys(data));
          setLoadingToggle(false);
          setToggleModal(false);
        }
      })

      .catch((e) => {
        if (e.response) {
          if (
            e.response.status === 400 ||
            e.response.status === 502 ||
            e.response.status === 500
          ) {
            setToggleErrModal(true);
          }
        } else {
          setToggleErrModal(true);
        }
      });
    }
  };

  const handleErrModal = () => {
    setToggleErrModal(false);
  };
  const handleDataFromError = () => {
    setShowerror(false);
  };
  // const handleSearchChange = (e) => {
  //   console.log(e.target.value);
  //   setSearchTerm(e.target.value);
  //   // setActiveIndex(false);
  //   console.log();
  //   const filteredDataup = filteredData12?.filter((fdh) =>
  //     fdh.FDH.toLowerCase().includes(e.target.value.toLowerCase())
  //   );
  //   console.log(filteredDataup);
  //   setfilteredData(filteredDataup);
  // };

  return (
    <>
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="d-flex justify-content-between box-fdh pb-1 status-p ">
        <div>
        {/* <div className="d-flex flex-row filtertag mob-filtertag mob-width-100"> */}
        {/* <div className="search mob-width-100">
        <div className="mob-width-100">

          <div class="search-container-mst-re">
            <div className="s-icon-ones">
              <FontAwesomeIcon icon={faSearch} />
            </div>

            <input type="text" className="search-input-icon" placeholder="Search By MST"  value={mstinputValue} onChange={handleMstChange}/>
            
          </div>
          {mstfilteredSuggestions.length > 0 && (
                    <ul className="list-search-review" >
                      <span>
                        {mstfilteredSuggestions.map((suggestion) => (
                          <li key={suggestion.mst} onClick={() => handleMSTSelectSuggestion(suggestion)}>
                            {suggestion.mst}
                          </li>
                        ))}
                      </span>
                    </ul>
                  )}
        </div>
      </div> */}
       
        <div className="d-flex flex-row filtertags mob-col-12 mob-flex-wrap  tab-flex-wraps">
          <div className="mob-col-12 review-font mb-nomargin marg-align" >
         {/*   <select
              className="filter-select mob-filtertag "
              onChange={handleStatus}
            >
              <option value="0">Select Status</option>
              <option value="COMPLETED">Successfull Port Swap</option>
              <option value="PENDING">Unsuccessfull Port Swap</option>

            </select> */}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
            {enablewirecenter && (
              <div>
                <input
                  type="text"
                  value={inputValue}
                  style={{ margin: "0" }}
                  className="searchresultfdh"
                  placeholder="Search ONT"
                  onChange={handleChange}
                ></input>
              </div>
            )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin mob-aligen-right">
            {enablewirecenter && (
              <div className="d-flex flex-column outerdate datewid">
                <div>
                  <input
                    type="date"
                    id="dateinpt"
                    value={selectedDate}
                    onChange={getdateval}
                    className="form-date"
                  ></input>
                </div>
              </div>
            )}
          </div>
          {/* <div className="mob-col-6 review-font marg-align mob-no-margin">
          {enablewirecenter && (
          <div className="d-flex flex-column outerdate datewid">
                  <div className="">
                  O2 Review By
                  </div>
                  <div className="">
                   {reviewstatus.reviewBy?reviewstatus.reviewBy:'NA'}
                  </div>
             </div>
          )}
          </div>
          <div className="mob-col-6 review-font marg-align mob-no-margin">
          {enablewirecenter && (
          <div className="d-flex flex-column outerdate datewid">
                  <div className="">
                  O2 Review Status
                  </div>
                  <div className="">
                   {reviewstatus.reviewStatus?reviewstatus.reviewStatus:'NA'}
                  </div>
             </div>
          )}
          </div> */}
         
        </div>
        
        
        
        </div>

       
        {/* </div> */}

        {/* <div className="filter-count">
      <div className="d-flex flex-row">
      <div className="m-2">
        <div className="d-flex flex-column">
        <div><b className="count-size" style={{"color":"gray"}}>22</b></div>
        <div>Overall</div>
        </div>
      </div>
      <div className="m-2">
      <div className="d-flex flex-column">
        <div><b className="count-size" style={{"color":"#0eb80e"}}>21</b></div>
        <div>Completed</div>
        </div>
      </div>
      <div className="m-2">
      <div className="d-flex flex-column">
        <div><b className="count-size" style={{"color":"orange"}}>23</b></div>
        <div>Pending</div>
        </div>
      </div>
      </div>
      
    </div> */}
      </div>

      {/* <div className="search-bar-fdhview">
        <div className="searchicon-fdhview">
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <input
          type="text"
          className="search-input-icon-fdhview"
          placeholder="Search By MST"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div> */}
      <div className="fdhview mob-row">
        <div
          className="scrollable-table-container mob-col-4"
          style={{ width: "10%" }}
        >
          <ONTviewHeader tabhead={{ tabhead: "1" }} />

          <div
            className="scrollable-tbody-container mob-body-height"
            width="10%"
            style={{ height: "350px" }}
          >
            <table className="scrollable-table">
              <tbody>
                {/* {dataFDH.map((item, index) => (
                  <tr className="trfix" key={index}>
                    <td
                      key={index}
                      className={`button ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => handleItemClick(index)}
                    >
                      {item.fdhName.substring(12, 24)}
                      {activeButton === activeIndex && (
                        <span className="arrow"></span>
                      )}
                    </td>
                  </tr>
                ))} */}
               {
                filteredData.length > 0 ? (
                  <>
                   {filteredData?.map((item, index) => (
                  <tr className="trfix" key={index}>
                    <td
                      key={index}
                      className={`button ${
                        item == activeIndex ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleItemClick(e, item)}
                    >
                      {item}
                    </td>
                  </tr>
                ))}
                  </>
                ):(
                  <>
                  <tr>
                    <td>No data</td>
                  </tr>
                  </>
                )
               }
                {showloading && <div>{/* <Contentloading/> */}</div>}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="scrollable-table-container fdhdetailstab mob-col-8"
          style={{ height: "350px", width: "85%" }}
        >
          {/* <FdhviewHeader tabhead={{tabhead:"2",activeIndex:selectedIndex}} /> */}

          <ONTdatatab
            tdata={tableheader}
            spkeys={splitterKeys}
            errorreview={errorreview}
            reviewdata={reviewstatus}
            handleclear={handleclear}
            loading={loadingToggle}
            reviewcomments={dataComments}
            handleItemClick={handleItemClick}
          />
        </div>
      </div>
      {/* {toggleModal && <Modalblack />} */}
      {toggleErrModal && <Modalerror onDataFromError={handleErrModal} />}
    </>
  );
};

export default ONTview;
