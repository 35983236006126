import React, { useState, useEffect } from "react";
import Topbar from "../topbar";
import MSTview from "./MSTview";
import Modalerror from "../ModalError/ErrorModal";
import "../App.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { API_KEY, BASE_API_URL } from "../environment";
import ReviewtabMST from "../ReviewtabMst";


function SecondTopbar(props) {
  const sessionId = sessionStorage.getItem("userId");
const encryptedId = sessionStorage.getItem("encryptedKey");
const headers = {
    headers: {
      "apikey": process.env.REACT_APP_API_KEY,
      "sessionId" : sessionId,
      "encryptedId":encryptedId
    }
}
  const [dataaudt, setData] = useState({
    deviceName: "NA",
    deviceType: "NA",
    auditStatus: "NA",
    auditBy: "NA",
    auditTime: "NA",
    reviewStatus: "NA",
    reviewBy: "NA",
    reviewTime: "NA",
    auditType: "NA",
  });
  console.log("checkvalue", props);
  const [showerrmodal, setShowerror] = useState(false);
  const [Datetime, setDatetime] = useState(false);
  const [fdhList, setFDHlist] = useState("");
  const Cuid = sessionStorage.getItem("encryptedKey");
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('option2');
  const [overallFDH, setOverallFDH] = useState("");
  const [datacount, setdataCount] = useState({
    completed: "0",
    pending: "0",
    total: "0",
  });

  useEffect(() => {
    console.log(props);

    if (props.auditdata) {
      setData(props.auditdata);
      setDatetime(true);
    } else {
      setData({
        deviceName: "NA",
        deviceType: "NA",
        auditStatus: "NA",
        auditBy: "NA",
        auditTime: "NA",
        reviewStatus: "NA",
        reviewBy: "NA",
        reviewTime: "NA",
        auditType: "NA",
      });
    }

   
  }, [props.auditdata]);
  var completed = "10";
  var pending = "23";
  var total = "33";
  console.log(dataaudt, "sd");
  // if (data != "") {
  //   completed = data[0]?.COUNT || 0;
  //   pending = data[1]?.COUNT || 0;
  //   total = parseInt(completed + pending);
  // }

  const datetimeString = dataaudt.auditTime; // 24-hour format

  // Convert the date and time into desired formats
  const formattedDate = new Date(datetimeString).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedTime = new Date(datetimeString).toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  console.log("Formatted Date:", formattedDate); // Formatted Date: 01/08/23
  console.log("Formatted Time:", formattedTime); // Formatted Time: 08:41 PM

  const handleoverallfdh = () => {};

  const handleOverall = () => {
    var val;
    // const data = [
    //   {
    //     FDH: "ME=CPCRFLCC CPCRFLJ0393",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCC CPCRFLJ0395",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCJ CPCRFLJ0399",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCJ CPCRFLJ0403",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCJ CPCRFLJ0405",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0344",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0345",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0349",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0350",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0351",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0352",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0353",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0355",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0356",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCN CPCRFLJ0357",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCR CPCRFLJ0385",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCR CPCRFLJ0386",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCR CPCRFLJ0387",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCR CPCRFLJ0388",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCR CPCRFLJ0389",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCV CPCRFLJ0390",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCV CPCRFLJ0391",
    //   },
    //   {
    //     FDH: "ME=CPCRFLCV CPCRFLJ0392",
    //   },
    //   {
    //     FDH: "ME=CPCRFLDI CPCRFLJ0397",
    //   },
    //   {
    //     FDH: "ME=CPCRFLDI CPCRFLJ0398",
    //   },
    //   {
    //     FDH: "ME=CPCRFLFF CPCRFLJ0404",
    //   },
    //   {
    //     FDH: "ME=CPCRFLXB CPCRFLJ0396",
    //   },
    //   {
    //     FDH: "ME=NFMYFLXB NFMYFLJ0004",
    //   },
    //   {
    //     FDH: "ME=NFMYFLXB NFMYFLJ0063",
    //   },
    //   {
    //     FDH: "ME=NFMYFLXB NFMYFLJ0064",
    //   },
    //   {
    //     FDH: "ME=NFMYFLXB NFMYFLJ0065",
    //   },
    //   {
    //     FDH: "ME=NFMYFLXB NFMYFLJ0066",
    //   },
    //   {
    //     FDH: "ME=NFMYFLXH NFMYFLJ0034",
    //   },
    // ];
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getFdh?startWith=ME" ,
      headers
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setOverallFDH(data);
        props.onChildDataChange(data);
      })
      .catch((e) => {});
    console.log();
  };
  const handleItemStatus = (val) => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getFDHAuditStatus?status=" +
        val ,
      headers
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "arr");
        setFDHlist(data);
        console.log(data);
        props.onChildDataChange(data);
      })
      .catch((e) => {});
  };

  const handleDataFromError = () => {
    setShowerror(false);
  };
  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);

  //   // Perform navigation or any other action based on selected option
  //   if (event.target.value === "option1") {
  //     // Navigate to page 1
  //     navigate('/fdhfopreview')
  //   } else if (event.target.value === "option2") {
  //     // Navigate to page 2
  //     navigate('/fopreview')
  //   }
  // };
  return (
    <>
      {showerrmodal && <Modalerror onDataFromError={handleDataFromError} />}
      <div className="second-nav">
        <div className="row">
          <div className="col-2 mob-col-12">
            <div className="content-one">
              <span className="hidden-mob">
                <Link to="/search">
                  <FontAwesomeIcon
                    className="ico-nav mb-2 p-3 mob-padding-5"
                    style={{ color: "#011E60" }}
                    icon={faHome}
                  />
                </Link>
              </span>
              <h6>Conduit Extension </h6>
            </div>
          </div>
          <div
            className="col-4 mob-col-12 auditcount"
            style={{ "text-align": "left" }}
          >
            <div className="content-sec ">
              <div className="d-flex mt-3 flex-row justify-content-center">
                {/* <div className="d-flex m-2 flex-row">
                  <label className="m-2" clfor="option1">FDH</label>
                  <input
                    type="radio"
                    id="option1"
                    name="options"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleOptionChange}
                  />
                </div> */}
                {/* <div className="d-flex m-2 flex-row">
                  <label className="m-2" for="option2">MST</label>
                  <input
                    type="radio"
                    id="option2"
                    name="options"
                    value="option2"
                    checked={selectedOption === "option2"}
        onChange={handleOptionChange}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div
            className="col-6 mob-col-12 auditcounts"
            style={{ "text-align": "right" }}
          >
            <div className="content-sec mt-3 audittab">
              <div className="row">
                <div
                  className="col-2 mob-col-3"
                  style={{ "text-align": "left" }}
                >
                  <div>Audit By:</div>
                </div>
                <div
                  className="col-6 mob-col-6"
                  style={{ "text-align": "left" }}
                >
                  <div>{dataaudt.auditBy}</div>
                </div>
                {Datetime && (
                  <div
                    className="col-4  mob-col-3 mob-aligne-righ"
                    style={{ "text-align": "left" }}
                  >
                    <div>
                      {formattedDate === "Invalid Date" ? (
                        <div> </div>
                      ) : (
                        <div>{formattedDate} </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div
                  className="col-2 mob-col-3"
                  style={{ "text-align": "left" }}
                >
                  <div>Audit Status:</div>
                </div>
                <div
                  className="col-6 mob-col-6"
                  style={{ "text-align": "left" }}
                >
                  <div>{dataaudt.auditStatus}</div>
                </div>
                {Datetime && (
                  <div
                    className="col-4 mob-col-3 mob-aligne-right"
                    style={{ "text-align": "left" }}
                  >
                    <div>
                      {formattedTime === "Invalid Date" ? (
                        <div> </div>
                      ) : (
                        <div>{formattedTime} CST</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div
                  className="col-2 mob-col-3"
                  style={{ "text-align": "left" }}
                >
                  <div>Audit Type:</div>
                </div>
                <div
                  className="col-6 mob-col-6"
                  style={{ "text-align": "left" }}
                >
                  <div className="audittype">{dataaudt.auditType}</div>
                </div>
              </div>
            </div>

            {/* <div className="content-sec-right">
              <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                  <div className="m-1">Audit Status</div>
                  <div className="m-1">Completed</div>
               </div>
               <div className="d-flex flex-row">
                  <div className="m-1">Audit By</div>
                  <div className="m-1">UserName</div>
               </div>
               <div className="d-flex flex-row">
                  <div className="m-1">Audit Date</div>
                  <div className="m-1">08-09-23</div>
               </div>
               <div className="d-flex flex-row">
                  <div className="m-1">Audit Time</div>
                  <div className="m-1">12:31 PM</div>
               </div>
              </div>
             
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

function MSTFOPMain() {
  const [parentData, setParentData] = useState();
  const [fdhData, setfdhData] = useState();

  const handleChildDataChange = (newData) => {
    console.log("valuesss", newData);
    setParentData(newData);
  };

  const handleFDHdataChange = (data) => {
    setfdhData(data);
    console.log(data);
  };
  const data = [
    "Row 1",
    "Row 2",

    "Row 3",

    "Row 4",

    "Row 5",

    "Row 1",

    "Row 2",

    "Row 3",

    "Row 4",

    "Row 5",

    "Row 1",

    "Row 2",

    "Row 3",

    "Row 4",

    "Row 5",

    /* Add more rows as needed */
  ];

  return (
    <div className="App">
      <Topbar />
      {/* <ReviewtabMST/> */}
      <SecondTopbar
        onChildDataChange={handleFDHdataChange}
        auditdata={parentData}
      ></SecondTopbar>
      <MSTview
        data={data}
        fdhList={fdhData}
        onChildDataChange={handleChildDataChange}
      />
    </div>
  );
}

export default MSTFOPMain;
