import React, { useState } from "react";
import { useEffect } from "react";
import { Outlet, Link, useNavigate,useLocation } from "react-router-dom";
import ModalPage from "./ModalWhite/modalpage";
import "./App.css";
import "./ReviewtabMst.css";
import {
  faCheckCircle,
  faClose,
  faAngleLeft,
  faBellSlash,
  faBell,
  faUserCircle,
  faHome,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CenturyLinklogo from "./assets/Lumen Logo White.png";
import mstreview from "./assets/mstreview.png";
import field from "./assets/fieldoperation.png";

function ReviewtabFieldops(props) {
  const navigate = useNavigate();
  const storedName = sessionStorage.getItem("Name");
  const role = sessionStorage.getItem("Role");
  const [pageactive, setPageactive] = useState(false);
  const [showpagemodal, setPageModal] = useState(false);
  const [showdrop, setDropdown] = useState(false);

  const history = useLocation();
  const currentpath = history.pathname;
  console.log("currentpath",currentpath);


  useEffect(() => {
    if (storedName == null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (props.data == true) {
      setPageactive(props.data);
    } else {
      setPageactive(false);
    }
    // setpagesavePop(p)
  }, [props.data]);

  const onpagenav = () => {
    if (pageactive == true) {
      setPageModal(true);
    }
    if (pageactive == false) {
      navigate("/search");
    }
  };

  const handleLogout = () => {
    // sessionStorage.clear();
    // navigate('/');
    setDropdown(!showdrop);
  };
  const handlechangepath = (value)=>{
    navigate(value)
  }
  const callLogout = () =>{
    sessionStorage.clear();
    navigate('/')
  }
  const callAccess = () =>{
    navigate('/access')
  }
  const handleDataFromPage = () => {
    setPageModal(false);
  };
  return (
    <>
      <div className="review-tab">
        <div className="d-flex flex-row">
            <div onClick={()=>{handlechangepath('/fopreview')}} className= { (currentpath == '/fopreview' || currentpath == '/fdhfopreview') ? 'section-tab-review reviewtab-active' : 'section-tab-review' }>
              <span ><img  src={mstreview} className="review-icon-size"/></span>&nbsp;&nbsp;<span>FieldOps Review</span>
            </div>
            <div onClick={()=>{handlechangepath('/archivefopfdh')}} className={ currentpath == '/archivefopfdh' ? 'section-tab-review reviewtab-active' : 'section-tab-review' }>
            <span ><img  src={field} className="review-icon-size"/></span>&nbsp;&nbsp;<span> FieldOps FDH Archive</span>
            </div>
            <div onClick={()=>{handlechangepath('/archivefopmst')}} className={ currentpath == '/archivefopmst' ? 'section-tab-review reviewtab-active' : 'section-tab-review' }>
            <span ><img  src={field} className="review-icon-size"/></span>&nbsp;&nbsp;<span>FieldOps MST Archive</span>
            </div>
        </div>
      </div>
    </>
  );
}

export default ReviewtabFieldops;
